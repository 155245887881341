import { Component, OnInit } from '@angular/core';
import { TransactionLog } from '../models/transaction-log';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-transaction-log-list',
  templateUrl: './transaction-log-list.component.html',
  styleUrls: ['./transaction-log-list.component.scss']
})
export class TransactionLogListComponent implements OnInit {
  transactionLogs: TransactionLog[] = [];

  constructor(private paymentService: PaymentService) { }

  ngOnInit(): void {
    this.getTransactionLogs();
  }


  getTransactionLogs() {
    this.paymentService.getTransactionLogs().subscribe(
      (logs) => {
        this.transactionLogs = logs;
      },
      (error) => {
        console.error('Error fetching transaction logs', error);
      }
    );
  }


}
