<ng-container *ngIf="!saveSuccess">

<div class="card" style="width: 100%;">
    <div class="card-body"> 

        <!-- <h5 class="card-title" [ngStyle]="{'color': isDataNull=== true ? 'rgb(78, 77, 77)' : 'rgb(78, 77, 77)'}">
        
          {{ isDataNull === true ? 'Emergency Contact (Incomplete)' : 'Emergency Contact' }} </h5>
        <hr> -->
        <form [formGroup]="frmGroupcontact">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="desicode" class="col-sm-4 col-form-label">Contact Person<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="name" placeholder="Person Name...">
                  
              <input type="hidden" class="form-control" formControlName="id" >
               
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="email" class="col-sm-4 col-form-label">Email</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="email" placeholder="Email...">
                </div>
              </div> 
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="email" class="col-sm-4 col-form-label">Mobile Number <span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="contact" placeholder="Mobile Number...">
                </div>
              </div> 
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="email" class="col-sm-4 col-form-label">Address <span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="homeAddress" placeholder="Address...">
                </div>
              </div> 
            </div>
            
          </div>
          

          <!-- <div class="row"> 
            <div class="col-md-6">
              <div class="form-group row">
                <label for="sortorder" class="col-sm-4 col-form-label">Designation<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="designation" placeholder="Designation...">
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="organization" class="col-sm-4 col-form-label">Organization</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="organization"
                    placeholder="organization...">
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="contact" class="col-sm-4 col-form-label">Contact</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="contact" placeholder="Contact...">
                </div>
              </div> 
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Relation<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="relation" placeholder="Relation...">
                </div>
              </div> 
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="occupation" class="col-sm-4 col-form-label">Occupation</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="occupation" placeholder="Occupation...">
                </div>
              </div> 
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="officeAddress" class="col-sm-4 col-form-label">Office Address</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="officeAddress"
                    placeholder="Office Address..">
                </div>
              </div>
            </div> 
            <div class="col-md-6">
              <div class="form-group row">
                <label for="homeAddress" class="col-sm-4 col-form-label">Home Address</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="homeAddress" placeholder="Home Address..">
                </div>
              </div>
            </div>  

          </div> -->

       
        <button title="Save" type="submit"
        (click)="onContactsubmit()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
        Save
      </button>
      <!-- <button [disabled]="!frmGroupcontact.valid" data-toggle="tooltip" title="Save" type="submit"
        (click)="onContactsubmit()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
        Save
      </button> -->
      <button type="button" data-toggle="tooltip" title="Refresh" (click)="Contactreset()"
        class="btn btn-secondary"
        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
        
          

        </form>
      </div>
   
  </div>
</ng-container>

  <ng-container *ngIf="saveSuccess">
    <app-professional-info (professionUpdateSuccess)="getProfileUpdateInfo()"></app-professional-info>
  </ng-container>