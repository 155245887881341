
    <div class="card" style="width: 100%;">
      <div class="card-body">
        <div class="">
      
          <h5 class="card-title" [ngStyle]="{'color': Banklist.length === 0 ? 'red' : 'black'}">
            {{ Banklist.length === 0 ? 'Bank Account Details (Incomplete)' : 'Bank Account Details' }}
          </h5>
          <hr>
          <form [formGroup]="frmGroupBankAcc">
            <div asp-validation-summary="All" class="text-danger"></div>

            <div class="row mt-2">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="bankName" class="col-sm-4 col-form-label">Bank Name</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="bankName" placeholder="Type here...">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="branchName" class="col-sm-4 col-form-label">Branch Name</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="branchName" placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="accountNo" class="col-sm-4 col-form-label">Account No.</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="accountNo" placeholder="Type here....">
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <label for="accountType" class="col-sm-4 col-form-label">Account Type</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="accountType" placeholder="Type here....">
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <label for="avgBalance" class="col-sm-4 col-form-label">Average Balance</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="avgBalance" style="text-align: right;"
                    (input)="frmGroupBankAcc.get('avgBalance').setValue(formatNumber($event.target.value))" placeholder="0">
                    <span *ngIf="frmGroupBankAcc.get('avgBalance').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
                  </div>
                </div>
              </div>

            </div>

            <div class="row" style="float: right;">
              <button [disabled]="!frmGroupBankAcc.valid" data-toggle="tooltip" title="PostBankAccount" type="submit"
                (click)="PostBankAccount()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
                <span>{{ isEditBank ? 'Update' : 'Save' }}</span>
                </button>
                <button type="button"  title="Refresh"class="btn btn-secondary" (click)="resetBankFrm()" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
            </div>


          </form>
        </div>
      </div>
    </div>
    <div class="card" style="width: 100%;">
      <div class="card-body">
        <div class="container ">
          <h5 class="card-title" style="color: black">Bank Account Details List</h5>
          <hr>
          <table id="example" class="table table-striped table-bordered table-responsive-sm" style="width:100%">
            <thead>
              <tr>
                <th>SL No.</th>
                <th>Bank & Branch Name</th>
                <th>Account No.</th>
                <th>Account Type</th>
                <th>Average Balance</th>
                <th style="width: 20%;">Action</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr *ngFor="let item of Bankflist; let i = index;"> -->
              <tr *ngFor="let item of Banklist; let i = index;">
               
                <td>{{i + 1}}</td>
                <td>Bank Name: {{item.bankName}}<br>
                  Branch Name: {{item.branchName}}
                <td>{{item.accountNo}}</td>
                <td>{{item.accountType}}</td>
                <td>{{item.avgBalance | number}}</td>
                <td>
                  <button class="btn-sm btn-primary" (click)="editBankAccount(item)"><i class="fa fa-edit"></i></button>
                  <button [disabled]="isEditBank" class="btn-sm btn-danger" style="margin-left: 5px;"
                    (click)="DeleteBankAcc(item.id)"><i class="fa fa-trash-o"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

