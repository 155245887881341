import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TaxKeyComponent } from './tax-key/tax-key.component';
import { BalancetypeComponent } from './balancetype/balancetype.component';
import { InterestCalculationFrequencyComponent } from './interest-calculation-frequency/interest-calculation-frequency.component';
import { InterestCalculationTypeComponent } from './interest-calculation-type/interest-calculation-type.component';
import { ExciseDutyComponent } from './excise-duty/excise-duty.component';
import { AccuredComponent } from './accured/accured.component';
import { DaySizeComponent } from './day-size/day-size.component';
import { CommissionComponent } from './commission/commission.component';
import { ChargeComponent } from './charge/charge.component';
import { CapitalizationRule } from './model/capitalizationRule';
import { CapitalizationRuleComponent } from './capitalization-rule/capitalization-rule.component';
import { SlabComponent } from './slab/slab.component';
import { TransectionTypeComponent } from './transection-type/transection-type.component';
import { InterestCalculationBalanceComponent } from './interest-calculation-balance/interest-calculation-balance.component';
import { CapitalizeFrequencyComponent } from './capitalize-frequency/capitalize-frequency.component';
import { InterestCalculationTypeListComponent } from './interest-calculation-type-list/interest-calculation-type-list.component';
import { LoanLimitListComponent } from './loan-limit-list/loan-limit-list.component';
import { LoanLimitEntryComponent } from './loan-limit-entry/loan-limit-entry.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { CreditScoreInsertComponent } from './credit-score-insert/credit-score-insert.component';

const routes: Routes = [
  // {path: "taxkey",component: TaxKeyComponent,data: {title: "Tax Key",},}
  { path: '',
   children: [{ path: 'taxkey',component: TaxKeyComponent,data: { title: 'Tax Key'}},] },
      {
        path:'interest-calculation-frequency',
        component: InterestCalculationFrequencyComponent,
        data:{
          title:'Interest Calculation Frequency'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'interest-calculation-type',
        component: InterestCalculationTypeComponent,
        data:{
          title:'Interest Calculation Type'
        },
        canActivate:[AuthGuard]
      },
      
      {
        path: 'interest-calculation-type/:id',
        component: InterestCalculationTypeListComponent,
        data:{
          title:'Interest Calculation list'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'interest-calculation-type-list',
        component: InterestCalculationTypeListComponent,
        data:{
          title:'Interest Calculation list'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'interest-calculation-balance',
        component: InterestCalculationBalanceComponent,
        data:{
          title:'Interest Calculation Balance'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'capitalize-frequency',
        component: CapitalizeFrequencyComponent,
        data: {
          title: 'Capitalize Frequency'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'exciseduty',
        component: ExciseDutyComponent,
        data: {
          title: 'Excise Duty'
        },
        canActivate:[AuthGuard]
      },
    
      {
        path: 'balancetype',
        component: BalancetypeComponent,
        data: {
          title: 'Balance Type'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'accured',
        component: AccuredComponent,
        data: {
          title: 'Accured'
        },
        canActivate:[AuthGuard]
      },      
      {
        path: 'day-size',
        component: DaySizeComponent,
        data: {
          title: 'Day Size'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'commission',
        component: CommissionComponent,
        data: {
          title: 'Commission'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'charge',
        component: ChargeComponent,
        data: {
          title: 'Charge'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'capitalization',
        component: CapitalizationRuleComponent,
        data: {
          title: 'Capitalization Rule'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'slab',
        component: SlabComponent,
        data: {
          title: 'Slab'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'transection-type',
        component: TransectionTypeComponent,
        data:{title:'Transection Type'},
        canActivate:[AuthGuard]
      },
      {
        path:'loan-limit-list',
        component: LoanLimitListComponent,
        data:{title:'Loan Limit'},
        canActivate:[AuthGuard]
      },
      {
        path:'loan-limit-entry',
        component: LoanLimitEntryComponent,
        data:{title:'Loan Limit Entry'},
        canActivate:[AuthGuard]
      },

  {
    path: 'credit-score-insert',
    component: CreditScoreInsertComponent,
    data: { title: 'credit-score-insert' },
    canActivate: [AuthGuard]
  },
      {path: 'loan-limit-entry/:id', component: LoanLimitEntryComponent,
      data:{title:'loan-limit-edit'}, canActivate:[AuthGuard]}
    
      
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
