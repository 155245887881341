import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { AccountingreportService } from '../accountingreport.service';
import { id } from '@swimlane/ngx-datatable';
import { Observable, of } from 'rxjs';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-ledger-book-report",
  templateUrl: "./ledger-book-report.component.html",
  styleUrls: ["./ledger-book-report.component.scss"],
})
export class LedgerBookReportComponent implements OnInit {
  fromDate: Date = new Date();
  toDate: Date = new Date();
  reportBy: number;
  Sbulist: any = [];
  projectlist: any = [];
  subledgerId: any = 0;
  ledgerId: any = [];
  AccountGroupList: Observable<any[]>;
  AccountNoList: Observable<any[]>;
  AccountSubLedgerList: any[] = [];
  subledgerStatus: string = "hide";

  constructor(
    private pdfService: PdfService,
    private accountingreportService: AccountingreportService,
    private _LedgerService: LedgerService,
    private accountReportService: AccountingreportService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getSbuList();
    this.GetProjectist();
    this.Getledgerlist();
    //this.GetAccountGroupList();
  }
  OpenPDF() {
    this.pdfService
      .LedgerBookReportDataViewpdf(
        this.ledgerId,
        this.subledgerId,
        this.fromDate,
        this.toDate,
        1,
        2
      )
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }

  getSbuList() {
    this.accountingreportService.GetSbuList().subscribe((res) => {
      this.Sbulist = res;
    });
  }
  GetProjectist() {
    this.accountingreportService.GetProjectist().subscribe((res) => {
      this.projectlist = res;
    });
  }
  Getledgerlist() {
    this._LedgerService.GetAllLedgerList().subscribe((res) => {
      this.AccountNoList = of(res);
    });
  }

  onLedgerChange(Id: any): void {
    // if (Id > 0 || Id != null || Id != "") {
    //   this.ledgerId=Id;
    //   this.getSubLedgerList(Id);
    // }

    this.AccountNoList.subscribe((accountList) => {
      const selectedAccount = accountList.find((item) => item.id == Id);

      if (selectedAccount?.ledger?.haveSubledger == 1) {
        this.subledgerStatus = "Show";
        this.ledgerId = Id;
        this.subledgerId = 0;
        this.getSubLedgerList(Id);
      } else {
        this.ledgerId = Id;
        this.subledgerId = 0;
        this.subledgerStatus = "hide";
      }
    });
  }

  onSubLedgerChange(Id: any): void {
    this.subledgerId = Id;
  }

  getSubLedgerList(Id: number) {
    this._LedgerService.GetAllSubLedgerbyLrId(Id).subscribe((res) => {
      console.log(res);
      this.AccountSubLedgerList = res;
    });
  }
  goBack() {
    this.location.back();
  }
}
