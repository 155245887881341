import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { SharedService } from 'app/services/shared.service';
import swal from 'sweetalert';
import { CustomerService } from 'app/customer/service/customer.service';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-lead-generation',
  templateUrl: './lead-generation.component.html',
  styleUrls: ['./lead-generation.component.scss']
})
export class LeadGenerationComponent implements OnInit {
  frmGroup: FormGroup;
  model: any = {};
  lgList:any[]=[];
  currentUser:any;
  customerList:any[]=[];
  productList:any[]=[];

  selectedCustomerId: string; 
  selectedProductId: string; 
  constructor(
    private _service: LeadGenerationServiceService,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private router: Router,
    private customerService: CustomerService,
    private _loanProductService:LoanProductService,
    private layoutService: LayoutService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
    ) 
    {
      this.getCustomerList();
      this.getLoanProductBuilderInfoList();
    }

  ngOnInit(): void {
   this.searchForm();
   this.getCustomerList();
   this.getLoanProductBuilderInfoList();

        this.layoutService.getCurrentUserName().subscribe(res=>{
          this.currentUser = res;
          }
        );
  }

  searchForm(){
    this.frmGroup = this.formBuilder.group({
      searchValue: ['', Validators.required],
    })
  }
 
  lgData : string;
  search() {
    this.model.searchValue = this.frmGroup.value.searchValue;
    this._service.searchLeadGeneration(this.model.searchValue).subscribe(
      (res: any) => {
        if (Array.isArray(res) && res.length > 0)
        {

          this.lgList = res.filter(item => item.isComplete !== 1);
          if (this.lgList.length > 0) 
          {
            this.lgData = 'dataFound';
            swal('Data Found', 'Data Found successfully!', 'success');
          }
           else {
            this.lgData = 'dataNotFound';
            swal('Data Not Found', 'Already Complete this loan.', 'info');
          }
        } 
        else{
          this.lgData = 'dataNotFound';
          swal('Data Not Found', 'No lead generation data found.', 'info');
        }
      },
      (error: any) => {
        swal('Error', 'Failed to retrieve lead generation list.', 'error');
      }
    );
  }
  

  getCustomerList(){
    this.customerService.getlist().subscribe(res=>
    {
        this.customerList=res;
        if (this.currentUser?.userName !== "opus.admin" && this.customerList.length > 0) {
          this.selectedCustomerId = this.customerList[0].id;
        }
        else{
          this.selectedCustomerId = '';
        }
        this.changeDetectorRef.detectChanges();
    })
  }


  getLoanProductBuilderInfoList() {
    this._loanProductService.getLoanProductList().subscribe(res => {
      this.productList= res;
      this.selectedProductId = '';
      this.changeDetectorRef.detectChanges();
    })
  }
  
  
  selectedOption: string;
  newLead : string;


  onRadioChange(value: string) {

    this.lgData='dataNotFound';
    if(value==="New"){
      // swal({
      //   title: 'Are you sure?',
      //   text: 'Do you want to create a new Lead?',
      //   icon: 'warning',
      //   //showCancelButton: true,
      //   //confirmButtonText: 'Yes Create',
      //   //cancelButtonText: 'Cancel'
      // }).then(result => {
      //   if (result === true) {
      //     this._service.getSaveLeadGeneration().subscribe(
      //       (response: any) => {
      //         // Handle the response containing the generated auto code
      //         const leadId = response.leadId;
      //         const autoCode = response.autoCode;
      //         // Perform any additional actions with the auto code
        
      //         swal('Auto Code Generated', `The auto code is: ${autoCode}`, 'success');
      //         this.router.navigate(['/loan-application/loan-application', leadId]);

      //       },
      //       error => {
      //         swal('Error', 'Failed to generate the auto code', 'error');
      //       }
      //     );
      //   }
      // });

      // this.router.navigate(['/loan-application/loan-application'])
    }
    this.searchForm();
    this.selectedOption = value;

  }



  NewLoanGenerate(value: string) {

    this.lgData='dataNotFound';
    if(value==="New"){
      swal({
        title: 'Are you sure?',
        text: 'Do you want to create a new Lead?',
        icon: 'success',
        //showCancelButton: true,
        //confirmButtonText: 'Yes Create',
        //cancelButtonText: 'Cancel'
      }).then(result => {
        if (result === true) {
          this._service.getSaveLeadGeneration(this.selectedCustomerId, this.selectedProductId).subscribe(
            (response: any) => {                            
              const leadId = response.leadId;
              const autoCode = response.autoCode;
              
              const customersId = this.selectedCustomerId;
              const loanProductsId = this.selectedProductId;
              
              swal('Auto Code Generated', `The auto code is: ${autoCode}`, 'success');
              this.router.navigate(['/loan-application/loan-application/'+ leadId + '/'+ customersId + '/'+  loanProductsId]);
              // this.router.navigate(['/loan-application/dynamic-loan-application/'+ leadId + '/'+ customersId + '/'+  loanProductsId]);
              this.changeDetectorRef.detectChanges();
            },
            error => {
              swal('Error', 'Failed to generate the auto code', 'error');
            }
          );
        }
      });

      // this.router.navigate(['/loan-application/loan-application'])
    }
    this.searchForm();
    this.selectedOption = value;

  }


  
  goLoanForm(leadId: any,customersId:any,loanProductsId:any){
     
    //this.router.navigate(['/loan-application/loan-application', leadId]);
    this.router.navigate(['/loan-application/loan-application/'+ leadId + '/'+ customersId + '/'+  loanProductsId]);
 
  }

  goBack() {
    this.location.back();
  }
}
