import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';
import { SharedService } from 'app/services/shared.service';
import swal from 'sweetalert';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-application-disburse-list",
  templateUrl: "./application-disburse-list.component.html",
  styleUrls: ["./application-disburse-list.component.scss"],
})
export class ApplicationDisburseListComponent implements OnInit {
  constructor(
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    private shared: SharedService
  ) {}
  LeadList: any[] = [];
  status: number;

  ngOnInit(): void {
    this.getLeadList();
  }

  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }
  fireRefreshEventOnWindow() {
    throw new Error("Method not implemented.");
  }

  //   getForwardApplication(){
  //   this._serviceDashboard.getLoanLeadInfoForAdmin().subscribe((res) =>{
  //     this.dataList =res.filter(item => item.statusInfoId ===2);
  //     this.changeDetectorRef.detectChanges();
  //   })
  // }

  getLeadList() {
    this._serviceDashboard
      .getAppliedLoanLeadInfoForDisburse()
      .subscribe((res) => {
        //this.LeadList =res.filter(item => item.statusInfoId ===3);
        this.LeadList = res;
        this.changeDetectorRef.detectChanges();
      });

    // this._serviceDashboard.getDisbursLoan(this.status).subscribe((res: any[]) =>{
    //   console.log(res)
    //   this.LeadList=res.sort((a,b)=>
    //   {
    //     return b.id-a.id;
    //   })
    //   this.changeDetectorRef.detectChanges();
    // })
  }
  // loanDisburse(id){
  //   swal('Confirm First', 'Do you want to disburse this loan', 'warning').then(x=>{
  //     this._serviceDashboard.disbursLoan(id).subscribe((res: any[]) =>{
  //       this.LeadList =res;
  //       if(res != null)
  //       {
  //         this.shared.ShowAlert('Thank you...', 'Saved succesfully!', 'success');
  //         this.getLeadList();
  //         this.changeDetectorRef.detectChanges();
  //       }
  //       else
  //       {
  //         this.shared.ShowAlert('Error', 'Failed to save.', 'error');
  //       }

  //     })
  //   });
  // }

  goDisburse(leadId: any, customersId: any, loanProductsId: any) {
    //this.router.navigate(['/loan-application/loan-application', leadId]);
    this.router.navigate([
      "/loan-application/disburse-lead-info/" +
        leadId +
        "/" +
        customersId +
        "/" +
        loanProductsId,
    ]);
  }
  goDetailsView(leadId: any) {
    this.router.navigate([
      "/loan-application/app-loan-application-full-view/" +
        leadId ,
    ]);
  }
  goBack() {
    this.location.back();
  }
}
