<section>
  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">

    <div class="card-body">
      <div class="container">
        <div class="row">
          <div class="col">
            <h5 class="card-title" style="color: black">Slab Info</h5>
            <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary" (click)="onClickShowSaveForm('saveList')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
          </div>
        </div>
        <hr>
        <form [formGroup]="frmGroup">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="" class="col-sm-4 ">Min Amount <span style="color: red;"> *</span></label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" name="min" formControlName="min" placeholder="Min Amount">
                  <input type="hidden" class="form-control" name="id" formControlName="id">
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-sm-4 ">Parcentage <span  style="color: red;"> *</span></label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" name="amountParcent" formControlName="amountParcent"
                    placeholder="Parcent">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Max Amount <span style="color: red;"> *</span></label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" name="max" formControlName="max" placeholder="Max Amount">
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Remarks</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="remarks" formControlName="remarks"
                    placeholder="Remarks">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="sortorder" class="col-sm-4 col-form-label">Product Type</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="productTypeId">
                    <ng-container *ngFor="let type of productBuilderTypes | keyvalue">
                      <option *ngIf="type.key!=='0' && type.key!=='1' && type.key!=='2'" [value]="type.value">{{ type.key }}</option>
                  </ng-container>
                  </select>
                </div>
              </div>
            </div>
          <div class="col-md-6">
          <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-success" style="float:right; margin-top:5px;">Save </button>
          </div></div>
        </form>
      </div>
    </div>
  </div>
  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
    <div class="card-body">
      <div class="container">
        <div class="row">
          <div class="col">
            <h5 class="card-title" style="color: black">Slab List</h5>
            <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary" (click)="onClickShowSaveForm('saveForm')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control"
              style="width:16%">
              <option value="5">5</option>
              <option selected value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="form-group col-md-6" style="text-align: right">
            <input type="text" class="form-control" [(ngModel)]="searchControl" placeholder="Search" (keyup)="onSearch()">
          </div>
        </div>
        <table id="example" class="table table-bordered  ">
          <thead>
            <tr>
              <th>SL No.</th>
              <th>Min Amount</th>
              <th>Max Amount</th>
              <th>Parcentage</th>
              <th>Remarks</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
              <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
              <td>{{item.min}}</td>
              <td>{{item.max}}</td>
              <td>{{item.amountParcent}}</td>
              <td>{{item.remarks}}</td>
              <td>{{productBuilderTypes[item.productBuilderType]}} Product</td>
              <td>
                <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;"
                  (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <span>Showing {{currentItems}} of {{totalItems}} entries</span>
          </div>
          <div class="col-md-6" style="text-align: right">
            <pagination-controls (pageChange)="onPageChange($event)">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>