

<section>
    <div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
          <h5 class="card-title" style="color: black">Nominee</h5>
          <hr>
  
          
  
          <table id="example" class="table table-striped table-bordered table-sm scrollable-table" style="width:100%">
            <thead>
                <tr>
                  <th>SL No.</th>
                  <th>Image</th>
                    <th>Name</th>
                    <th>nomineeDOB</th>
                    <th>nominee NID</th>                    
                    <th>nominee BirthId</th> 
                    <th>Share</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of nominee; let i = index;">
                <!-- <td>{{i + 1}}</td> -->
                <td>{{i + 1}}</td>
                <td><img class="avatar" src="data:image/jpeg;base64,{{item.baseString}}" alt="avatar" height="35" width="35" /></td>
                <td>{{item.name}}</td>
                <td>{{item.dateOfBirth}}</td>
                <td>{{item.nid}}</td>
                <td>{{item.birthCertificateNo}}</td>
                <td>{{item.sharingRate}}</td>
            
  
                <td> 
                    <button [disabled]="isEdit" class="btn btn-success button-spacing"  (click)="nomineeEdit(item.id)"><i class="fa fa-edit"></i></button>
                    <button class="btn btn-danger button-spacing"(click)="DeleteNomineeById(item.id)">
                      <i class="fa fa-trash-o 3x"></i>
                    </button>
                    
                </td>
                <!-- <td>
                  <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-info-circle"></i> Details</button>
                  <button [disabled]="isEdit" class="btn btn-danger" (click)="downloadPDF(item.Id)"><i class="fa fa-file-pdf-o"></i> PDF</button>
              </td> -->
              
              
              </tr>
             </tbody>
        </table>
  
         
  
        </div>  
      </div>
  </div>   
  </section>
  
   
  