import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'app/services/shared.service';
import { CustomerService } from '../service/customer.service';

@Component({
  selector: 'app-customer-account-list',
  templateUrl: './customer-account-list.component.html',
  styleUrls: ['./customer-account-list.component.scss']
})
export class CustomerAccountListComponent implements OnInit {
  list:any [] =[];
  constructor(
    private customerService: CustomerService,
    private modalService: NgbModal,
    private shared: SharedService,
    private changeDef:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getAccountList();
    //this.buildForm();
  }
  getAccountList(){
    this.customerService.GetAllCustomerAccount().subscribe(data=>{
      console.log(data);
      this.list = data;
    })
  }

  // buildForm(){
  //   this.scoreTagForm = this.formBuilder.group({
  //     id: [0],
  //     tagName: ['', Validators.required],
  //     tagPattern: ['', Validators.required]
  //   })
  // }

  // edit(id: number) {
  //   this.router.navigate(['/score/score-create', id]);
  //   this.isEdit =true;
    
  // }


  // delete(id:any){
  //   this._scoreService.delete(id).subscribe(data=>{
  //     this.shared.ShowAlert('Alert','Data deleted successfully.','warning');
  //     this.getScoreTagList();
  //   })
  // }
}
