import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DpsCreateComponent } from './dps-create/dps-create.component';
import { DpsListComponent } from './dps-list/dps-list.component';
import { DpsCollectionComponent } from './dps-collection/dps-collection.component';
import { DpsEncashmentComponent } from './dps-encashment/dps-encashment.component';
import { DpsEncashmentListComponent } from './dps-encashment-list/dps-encashment-list.component';
import { DpsImmatureEncashmentComponent } from './dps-immature-encashment/dps-immature-encashment.component';

const routes: Routes = [
  {
    path: "dps-create",
    component: DpsCreateComponent,
    data: {
      title: "dps-create",
    },
  },

  {
    path: "dps-list",
    component: DpsListComponent,
    data: {
      title: "dps-list",
    },
  },

  {
    path: "dps-collection",
    component: DpsCollectionComponent,
    data: {
      title: "dps-collection",
    },
  },
  {
    path: "dps-encashment",
    component: DpsEncashmentComponent,
    data: {
      title: "dps-encashment",
    },
  },
  {
    path: "dps-encashment-list",
    component: DpsEncashmentListComponent,
    data: {
      title: "dps-encashment-list",
    },
  },
  {
    path: "dps-immature-encashment",
    component: DpsImmatureEncashmentComponent,
    data: {
      title: "dps-immature-encashment",
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DpsAccountRoutingModule { }
