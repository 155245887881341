import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustomerService } from '../service/customer.service';
import { DatePipe,Location } from '@angular/common';
import swal from 'sweetalert';
import { LayoutService } from 'app/shared/services/layout.service';
import { date } from 'ngx-custom-validators/src/app/date/validator';
import { PdfService } from 'app/pdf-reporting/pdf.service';

@Component({
  selector: "app-cashtransaction-list",
  templateUrl: "./cashtransaction-list.component.html",
  styleUrls: ["./cashtransaction-list.component.scss"],
})
export class CashtransactionListComponent implements OnInit {
  accountNo: string = "";
  accountList: any[] = [];
  startDate: Date = new Date();
  EndDate: Date = new Date();

  transactionList: any[] = [];
  SearchAccNo: boolean = false;
  pdfView: boolean = false;

  currentUser: string = "";
  userTypeId: number;
  noDataAvailable: boolean = false;

  generateAccNo: boolean = false;

  bsicAccountInfo: any;
  lastBalance: any;
  AccountNoList: any = [];
  userName: any;
  constructor(
    private customerService: CustomerService,
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private changeDetect: ChangeDetectorRef,
    private datePipe: DatePipe,
    private location: Location
  ) {
    //this.gettransactionList();
  }

  ngOnInit(): void {
    this.getloginUserType();
    this.GetCustomerAccountNo();
    this.layoutService.getCurrentUserName().subscribe((res) => {
      this.currentUser = res;

      //   console.log('userName');
      //  console.log(res);
    });
  }

  getloginUserType() {
    this.layoutService.getCurrentUserName().subscribe((res) => {
      this.userTypeId = res.userTypeId;
      if (res.userTypeId == 3 || res.userTypeId == 1) {
        this.SearchAccNo = true;
        this.generateAccNo = true;
      } else {
        // this.gettransactionList();
      }
    });
  }

  GetCustomerAccountNo() {
    this.customerService.CustomerAccountListInfo().subscribe((res) => {
      this.AccountNoList = res;
    });
  }

  loadUserDataByAccountNoAndDateStatement() {
    this.customerService
      .getAllStatementViewByAccNoAndDate(
        this.accountNo,
        this.startDate,
        this.EndDate
      )
      .subscribe((res) => {
        if (res != null) {
          this.pdfView = true;
          this.bsicAccountInfo = res.accountInfosVM;
          this.transactionList = res.accountStatementByAccNoVMs;

          const lastTransaction =
            this.transactionList.length > 0
              ? this.transactionList[this.transactionList.length - 1]
              : null;

          this.lastBalance = lastTransaction ? lastTransaction.balance : 0;

          this.noDataAvailable = this.transactionList.length === 0;
          this.changeDetect.detectChanges();
        } else {
          swal("Warning", "Invalid Account No", "warning");
          this.noDataAvailable = true;
        }
      });
  }

  OpenPDF() {
    this.pdfService
      .CashTransactionPdf(this.accountNo, this.startDate, this.EndDate)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }

  formatDate(transactionDate: any): string {
    const dateObject = new Date(transactionDate);
    if (!transactionDate || isNaN(dateObject.getTime())) {
      return "";
    } else {
      return this.datePipe.transform(dateObject, "dd-MM-yyyy") || "";
    }
  }
  goBack() {
    this.location.back();
  }

  // loadUserDataByAccountNo(){
  //   this.customerService.getCustomerByAccountNo(this.accountNo).subscribe(res => {
  //     if(res != null){

  //       this.gettransactionListForAdmin(res.customerAccountNo)
  //       // this.accountNo = res.customerAccountNo;
  //       }
  //     else{
  //       swal('Warning', 'Invalid Account No', 'warning');
  //     }
  //   })
  // }

  //New
  // GetCustomerAccountListByUserName(userName:string){
  //   this.customerService.GetCustomerAccountList(userName).subscribe(res => {
  //     if(res != null){
  //        this.accountList = res;
  //     }
  //   })
  // }

  //// For Customer
  // gettransactionList() {

  //   this.customerService.gettransactionList().subscribe(res => {
  //     this.transactionList = res;
  //     this.transactionList.forEach(item => {
  //       item.transactionDate = new Date(item.transactionDate);
  //     });

  //     this.transactionList.sort((a, b) => {
  //       return b.transactionDate.getTime() - a.transactionDate.getTime();
  //     });

  //     this.changeDetect.detectChanges();
  //   })
  // }

  // For Admin
  // loadUserDataByAccountNoAndDate(){

  //     this.customerService.getAllCashTransactionForAdminByAccNoAndDate(this.accountNo,this.startDate,this.EndDate).subscribe(res => {
  //       if(res != null){
  //         this.pdfView=true;
  //         //this.gettransactionListForAdmin(res.customerAccountNo)

  //         //this.gettransactionListForAdmin(this.accountNo)
  //         this.transactionList = res;
  //         console.log(res)
  //         this.transactionList.forEach(item => {
  //           item.transactionDate = new Date(item.transactionDate);
  //         });

  //         this.transactionList.sort((a, b) => {
  //           return b.transactionDate.getTime() - a.transactionDate.getTime();
  //         });
  //         this.noDataAvailable = this.transactionList.length === 0;
  //         this.changeDetect.detectChanges();

  //         }
  //       else{
  //         swal('Warning', 'Invalid Account No', 'warning');
  //         this.noDataAvailable = true;
  //       }
  //     })
  //   }

  // gettransactionListForAdmin(accountNo: string): void {

  //   this.customerService.allCashTransactionForAdminByAccNo(accountNo).subscribe(res => {
  //     this.transactionList = res;
  //   });
  // }
}
