import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-loan-overdue',
  templateUrl: './loan-overdue.component.html',
  styleUrls: ['./loan-overdue.component.scss']
})
export class LoanOverdueComponent implements OnInit {
  asOnDate:Date = new Date();
  reportBy:number;
 
  constructor(private pdfService:PdfService,
    private location: Location) { }

  ngOnInit(): void {
  }
  OpenPDF(){
    this.pdfService.LoanOverDueReport(this.asOnDate,this.reportBy).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }
  
  goBack() {
    this.location.back();
  }
}
