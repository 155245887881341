
export class Nominee {
    // Id:number;
    // nomineeName: string;
    // nomineeFather: string; 
    // nomineeMother: string;
    // nomineeSpouse : string;
    // nomineeDOB : string;
    // nomineePresentAddress : string;
    // nomineePermanentAddress : string;
    // nomineeOccupation : string;
    // nomineeNID : string;
    // nomineePassport : string;
    // nomineeBirthIdentity : string;
    // nomineeETin : string;
    // nomineeDriveLinc : string;
    // nomineeShare : string;
    // photo : string;
    // baseString : string;
    // relationId : number

    id : any;
   CustomersAccountId : number;
    CustomersAccount: Number;
    Name: string;
    FatherName: string;
    MotherName: string;
    SpouseName: string;
    DateOfBirth: string;
    PresentAddress: string;
    PermanentAddress: string;
    Nid: string;
    BirthCertificateNo: string;
    Passport: string;
    ETin: string;
    DrivingLisence: string;
    PhotoPath: string;
    SignaturePath: string;
    SharingRate: number;
    ImagePath: string;
   baseString : string;
   relationId: number;
    relation:  string;
     

}
