
<div class="card" style="width: 100%;">
<div class="card-body">

  <h5 class="card-title" [ngStyle]="{'color': IncomeList.length === 0 ? 'red' : 'black'}">
    {{ IncomeList.length === 0 ? 'Income Details (Incomplete)' : 'Income Details' }}
  </h5>

  <hr>

  <form [formGroup]="frmGroupIncome">
    <div asp-validation-summary="All" class="text-danger"></div>
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="form-group row">
          <label for="monthlyIncomeAmount" class="col-sm-4 col-form-label">Monthly Amount</label>
          <div class="col-sm-8">
            <input type="text" style="text-align: right;" class="form-control" formControlName="monthlyIncomeAmount"
            (input)="frmGroupIncome.get('monthlyIncomeAmount').setValue(formatNumber($event.target.value))" placeholder="0">
            <span *ngIf="frmGroupIncome.get('monthlyIncomeAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
            <input type="hidden" class="form-control" formControlName="id">
          </div>
        </div>

      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <label for="jointApplicantIncomeAmount" class="col-sm-4 col-form-label">Joint Applicant Amount</label>
          <div class="col-sm-8">
            <input type="text" style="text-align: right;" class="form-control" formControlName="jointApplicantIncomeAmount"
            (input)="frmGroupIncome.get('jointApplicantIncomeAmount').setValue(formatNumber($event.target.value))" placeholder="0">
            <span *ngIf="frmGroupIncome.get('jointApplicantIncomeAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
          </div>
        </div>

      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <label for="rentalIncomeAmount"  class="col-sm-4 col-form-label">Rental Amount </label>
          <div class="col-sm-8">
            <input type="text" style="text-align: right;" class="form-control" formControlName="rentalIncomeAmount"
            (input)="frmGroupIncome.get('rentalIncomeAmount').setValue(formatNumber($event.target.value))" placeholder="0">
            <span *ngIf="frmGroupIncome.get('rentalIncomeAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
          </div>
        </div>

      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <label for="interestIncomeAmount" class="col-sm-4 col-form-label">Interest Amount </label>
          <div class="col-sm-8">
            <input type="text" style="text-align: right;" class="form-control" formControlName="interestIncomeAmount"
            (input)="frmGroupIncome.get('interestIncomeAmount').setValue(formatNumber($event.target.value))" placeholder="0">
            <span *ngIf="frmGroupIncome.get('interestIncomeAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <label for="otherIncomeAmount" class="col-sm-4 col-form-label">Other's Income Amount</label>
          <div class="col-sm-8">
            <input type="text" style="text-align: right;" class="form-control" formControlName="otherIncomeAmount"
            (input)="frmGroupIncome.get('otherIncomeAmount').setValue(formatNumber($event.target.value))" placeholder="0">
            <span *ngIf="frmGroupIncome.get('otherIncomeAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <label for="totalAmount" class="col-sm-4 col-form-label">Total Income Amount</label>
          <div class="col-sm-8">
            <input type="text" style="text-align: right;" class="form-control" formControlName="totalAmount"
            (input)="frmGroupIncome.get('totalAmount').setValue(formatNumber($event.target.value))" placeholder="0">
            <span *ngIf="frmGroupIncome.get('totalAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="float: right;">
      <button (click)="onIncomeSubmit()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
        <span>Save</span></button>
      <!-- <button title="Save" type="submit" value="Submit" (click)="onIncomeSubmit()" class="btn btn-success"
          style="float:right; margin-top:5px;">
          <span>{{ isIncomeEdit ? 'Update' : 'Save' }}</span>
        </button> -->
      <button type="button" (click)="refreshIncome()" title="Refresh" class="btn btn-secondary"
        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
    </div>
  </form>

</div>
</div>
<br>

<!-- <div  *ngFor="let item of IncomeList; let i = index;"> -->
<!-- <div class="" *ngFor="let item of customerDetailsInfos?.incomeDetails; let i = index;">  -->

  <div class="card" *ngFor="let item of IncomeList; let i = index;">

    <div class="card-body">
      <div class="row">
        <div class="col-md-8">
          <h5 class="card-title" style="color: black"> {{i +1}} : Income info </h5>
        </div>
        <div class="col-md-4">
          <button title="Update" type="submit" value="Submit" (click)="UpdateIncome(item.id)" class="btn btn-success"
            style="float:right; margin-top:5px;"> <i class="fa fa-edit 3x"></i>
          </button>
        </div>


      </div>

      <hr>

      <div asp-validation-summary="All" class="text-danger"></div>
      <div class="row mt-2">
        <div class="col-md-6">
          <div class="form-group">
            <label class=" col-form-label">
              <i class="fa fa-money ic" aria-hidden="true"></i>
              Monthly Amount : <span class="ic"> {{item.monthlyIncomeAmount | number:'1.0-2'}}</span></label>

          </div>

        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-form-label">
              <i class="fa fa-money ic" aria-hidden="true"></i>
              Joint Applicant Amount
              : <span class="ic"> {{item.jointApplicantIncomeAmount | number:'1.0-2'}}</span>

            </label>

          </div>

        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-form-label">
              <i class="fa fa-money ic" aria-hidden="true"></i>
              Rental Amount
              : <span class="ic"> {{item.rentalIncomeAmount | number:'1.0-2'}}</span>
            </label>

          </div>

        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-form-label">
              <i class="fa fa-money ic" aria-hidden="true"></i>
              Interest Amount
              : <span class="ic"> {{item.interestIncomeAmount | number:'1.0-2'}}</span>

            </label>

          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-form-label">
              <i class="fa fa-money ic" aria-hidden="true"></i>
              Other's Income Amount
              : <span class="ic"> {{item.otherIncomeAmount | number:'1.0-2'}}</span>
            </label>

          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-form-label">
              <i class="fa fa-money ic" aria-hidden="true"></i>
              Total Income Amount
              : <span class="ic"> {{item.totalAmount | number:'1.0-2'}}</span>
            </label>

          </div>
        </div>
      </div>


    </div>
  


</div>