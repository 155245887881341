export class Ledger {
    id:number;
    groupId:number;
    accountCode:string;
    accountName:string;
    isActive:number;
    ledgerType:string;
    effectiveDate:Date;
    refType:string;
    remarks:string;
    subGrpId:number;
    ledgerId:number;
    countryId:number;
    BDGenerateCode:number;
    haveSubledger:number;
}
