<!-- Product Builder Input start -->
<section>
    <div class="card" style="width: 100%;">
        <div class="card-body">

           
            <div class="container">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title" style="color: black">Product Builder</h5>
                    </div>
                    <!-- <div class="col" style="text-align: right;">
                        <button type="button" data-toggle="tooltip" (click)="areaView(1)" class="btn btn-info"
                            style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Go to List</button>
                    </div> -->

                    <div class="col-2" style="text-align: right;">
                        <button type="button" data-toggle="tooltip" [routerLink]="['/product/product-builder-list']" class="btn btn-info"
                            style="float:right;margin-right:5px;margin-left: 8px;">Go
                            to List</button>
                    </div>
                    <div class="col-1">
                        <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                            <!-- <i class="ft-chevrons-left"> Back</i> -->
                            <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                        </button>
                    </div>
                </div>
                <hr>
                <form [formGroup]="frmGroup">
                    <div asp-validation-summary="All" class="text-danger"></div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Product Category</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="productCategoryId">
                                        <option value="">N/A</option>
                                        <ng-container *ngFor="let item of categoryList">
                                            <option [value]="item.id" *ngIf="item.id !== 5">
                                                {{ item.categoryName }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Version" class="col-sm-4 col-form-label">Version</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="Version"
                                        placeholder="Version">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Code" class="col-sm-4 col-form-label">Code</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="Code" placeholder="Code" [readonly]="isReadOnly">
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Name" class="col-sm-4 col-form-label">Name</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="Name" placeholder="Name" [readonly]="isReadOnly">
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Description" class="col-sm-4 col-form-label">Description</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="Description"
                                        placeholder="Description">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Interest Rate</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="interestRate"
                                        placeholder="Interest Rate">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Minimum Interest Rate</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="InterestRateMin"
                                        placeholder="Interest Rate Min">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="InterestRateMax" class="col-sm-4 col-form-label">Maximum Interest
                                    Rate</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="InterestRateMax"
                                        placeholder="Interest Rate Max">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Currency" class="col-sm-4 col-form-label">Currency</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="Currency">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of currencyList" [value]="item.id">{{item.currency}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Day Size</label>
                                <div class="col-sm-7">
                                    <select class="form-control" formControlName="DaySizeId" (change)="onDaySizeChange()">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of daySizeList" [value]="item.id">
                                            {{item.name}}-{{item.size}} ({{item.upMark}}/{{item.downMark}})</option>
                                    </select>
                                </div>
                                <button type="button" title="Details" id="loadDaySize" (click)="openModal(contentDaySize)"
                                    value="Load" style="text-align: center;height: 37px;" class="btn btn-sm col-sm-1">
                                    <i style="font-size: 30px;" class="ft-alert-circle"></i>
                                </button>
                            </div>

                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Tax Key</label>
                                <div class="col-sm-7">
                                    <select class="form-control" formControlName="taxKeyId" (change)="onTaxChange()">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of taxKeyList" [value]="item.taxKey.id">
                                            {{item.taxKey.taxCode}}</option>
                                    </select>
                                </div>
                                <button type="button" title="Details" id="loadTaxData" (click)="openModal(contentTax)"
                                    value="Load" style="text-align: center;height: 37px;" class="btn btn-sm col-sm-1">
                                    <i style="font-size: 30px;" class="ft-alert-circle"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Excise Duty</label>
                                <div class="col-sm-7">
                                    <select class="form-control" formControlName="exciseDutyId"
                                        (change)="onExciseDutyChange()">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of exciseDutyList" [value]="item.id">
                                            {{item.exciseDutyCode}}
                                        <!-- {{item.minAmountRange}} - {{item.maxAmountRange}} -->
                                        </option>
                                    </select>
                                </div>
                                <button type="button" data-toggle="tooltip" title="Details" id="loadExciseData"
                                    (click)="openModal(contentExcise)" value="Load"
                                    style="text-align: center;height: 37px;" class="btn btn-sm col-sm-1">
                                    <i style="font-size: 30px;" class="ft-alert-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Interest Calculation Type</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="interestCalculationTypeSelect"
                                        (change)="onCalTypeSelect()">
                                        <option value="0">Single</option>
                                        <option value="1">Slab</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="intCalTypeDiv">
                                <label class="col-sm-4 col-form-label"></label>
                                <div class="col-sm-8">
                                    <div class="form-group ml-0 row">
                                        <select class="form-control col-sm-10"
                                            formControlName="InterestCalculationTypeId" (change)="onCalTypeChange()">
                                            <option value="0">N/A</option>
                                            <option *ngFor="let item of intCalculationTypeList" [value]="item.id">
                                                {{item.name}}</option>
                                        </select>
                                        <!-- <button type="button" title="Details" id="loadCalTypeData"
                                            (click)="openModal(contentCalType)" value="Load"
                                            style="text-align: center;height: 37px;" class="btn btn-sm col-sm-2">
                                            <i style="font-size: 30px;" class="ft-alert-circle"></i>
                                        </button> -->
                                        <button type="button" data-toggle="tooltip" title="Details" id="loadCalTypeData" value="Load" (click)="openModal(contentCalType)" value="Load"
                                         style="text-align: center;height: 37px;" class="btn btn-sm col"><i style="font-size: 30px;" class="ft-alert-circle"></i></button>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="intCalTypeMultipleDiv">
                                <label class="col-sm-4 col-form-label"></label>
                                <div class="col-sm-8 pr-0">
                                    <div class="form-group ml-0 row">
                                        <select class="form-control col-sm-8" style="margin-right: 8px;" formControlName="InterestCalculationTypeSlabId" (change)="onCalTypeSlabChange()">
                                            <option value="">N/A</option>
                                            <option *ngFor="let item of allSlab" [value]="item.id">
                                                {{item.min}}-{{item.max}} - {{item.amountParcent}}%</option>
                                        </select>
                                        <button class="btn btn-sm col-sm-1" title="Details" id="loadCalTypeData" value="Load"
                                        (click)="openModal(contentCalSlabType)" style="margin-right: 15px;margin-left: -2px"><i style="font-size: 30px;" class="ft-alert-circle"></i></button>
                                        <button class="btn btn-primary  btn-sm" title="Add" (click)="addSlabForIntCalTypeMulti()" >Add</button>
                                                                              
                                    </div>
                                    <div class="form-group ml-0 row">
                                        <ul>
                                            <li *ngFor="let item of instCalTypeSlab">{{item.min}}-{{item.max}}
                                                ({{item.amountParcent}})%</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Interest Calculation
                                    Frequency</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="InterestCalculationFrequencyId">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of intCalculationFreqList" [value]="item.id">
                                            {{item.name}} - {{item.days}} Days</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Capitalize Rule</label>
                                <div class="col-sm-6">
                                    <select class="form-control" formControlName="CapitalizationRuleId" (change)="onCapitalizeRuleChange()">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of capRuleList" [value]="item.id">{{item.code}}
                                        </option>
                                    </select>
                                </div>
                                <button title="Details" value="Details"class="btn btn-sm col-sm-1" (click)="openModal(contentCapRule)" style="height: 100%;"><i style="font-size: 30px;" class="ft-alert-circle"></i></button>
                                <button type="button" title="Add" (click)="addMultipleCapRuleMulti()"  class="btn btn-primary btn-sm">Add</button>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label"></label>
                                <div class="col-sm-8">
                                    <ul>
                                        <li *ngFor="let item of capRuleSelectList">
                                            {{item.code}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Capitalization Frequency</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="capitalizationFrequencyId">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of capFrequencyList" [value]="item.id">{{item.name}} -
                                            {{item.days}} Days</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="row">
                        <!-- <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Accured</label>
                                <div class="col-sm-8">
                                    <select class="form-control"  formControlName="AccuredId">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of accuredList" [value]="item.id">
                                            {{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Balance Type</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="BalanceTypeId">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of balanceTypeList" [value]="item.id">
                                            {{item.name}}</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="status" class="col-sm-4 col-form-label">Status</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="status">
                                        <option value="">N/A</option>
                                        <option value="1">Active</option>
                                        <option value="0">in-Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Charge</label>
                                <div class="col-sm-6">
                                    <select class="form-control" formControlName="chargeId" (change)="onChargeChange()">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of chargeList" [value]="item.id">
                                            {{item.code}} - {{item.name}}</option>
                                    </select>
                                </div>
                                <button title="Details" value="Details"class="btn btn-sm col-sm-1" (click)="openModal(contentCharge)" style="height: 100%;"><i style="font-size: 30px;" class="ft-alert-circle"></i></button>

                                <button type="button" title="Add" (click)="addMultipleCharge()"  class="btn btn-primary btn-sm">Add</button>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label"></label>
                                <div class="col-sm-8">
                                    <ul>
                                        <li *ngFor="let item of selectedChargeList">
                                            {{item.code}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Commission</label>
                                <div class="col-sm-6">
                                    <select class="form-control" formControlName="commissionId" (change)="onCommissionChange()">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of commissionList" [value]="item.id">{{item.code}} - {{item.name}}</option>
                                    </select>
                                </div>
                                <button title="Details" value="Details"class="btn btn-sm col-sm-1" (click)="openModal(contentCommission)" style="height: 100%;"><i style="font-size: 30px;" class="ft-alert-circle"></i></button>
                                <button type="button" title="Details" (click)="addMultipleCommission()" class="btn btn-primary btn-sm">Add</button>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label"></label>
                                <div class="col-sm-8">
                                    <ul>
                                        <li *ngFor="let item of selectedCommissionList">
                                            {{item.code}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <span class="col-md-12">Account Setup</span>
                    <div class="col-md-4 border">
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                              <label for="basic-form-2">Account Group</label>
                              <select class="form-control" formControlName="ledgerRelationId"  (change)="onGroupChange($event.target.value,1)">
                                <option value="">N/A</option>
                                <option *ngFor="let item of accGroupList" [value]="item.id">{{item.groupCode}} - {{item.groupName}}
                                </option>
                            </select>
                            </div>
                        </div>
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                              <label for="basic-form-2">GL</label>
                              <select class="form-control" name="ledgerId" formControlName="ledgerId" >
                                <option value="">N/A</option>
                                <option *ngFor="let item of ledgerListAcc" [value]="item.id">
                                    {{item.accountName}}</option>
                            </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 border">
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                              <label for="basic-form-2">Profit/Loss Interest Account Group</label>
                              <select class="form-control" formControlName="plInterestGroupId"  (change)="onGroupChange($event.target.value,3)">
                                <option value="">N/A</option>
                                <option *ngFor="let item of incomeExpenseGroupList ; trackBy: trackByFn" [value]="item.id">{{item.groupCode}} - {{item.groupName}}
                                </option>
                            </select>
                            </div>
                        </div>
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                              <label for="basic-form-2">GL</label>
                              <select class="form-control" name="plInterestLedgerId" formControlName="plInterestLedgerId" >
                                <option value="">N/A</option>
                                <option *ngFor="let item of ledgerList" [value]="item.id">
                                    {{item.accountName}}</option>
                            </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 border">
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                              <label for="basic-form-2">Interest Provision Group</label>
                              <select class="form-control" formControlName="interestProvisionGroupId"  (change)="onGroupChange($event.target.value,2)">
                                <option value="">N/A</option>
                                <option *ngFor="let item of accGroupList" [value]="item.id">{{item.groupCode}} - {{item.groupName}}
                                </option>
                            </select>
                            </div>
                        </div>
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                              <label for="basic-form-2">GL</label>
                              <select class="form-control" name="ipLedgerId" formControlName="interestProvisionLedgerId" >
                                <option value="">N/A</option>
                                <option *ngFor="let item of ledgerIntProList" [value]="item.id">
                                    {{item.accountName}}</option>
                            </select>
                            </div>
                        </div>
                    </div>
                    
                       
      
                </div>
                <div class="row mt-4">
                    <span class="col-md-12" style="font-weight: 800;">Approval :</span>
                    <div class="col-md-12 border">
                        <div class="form-group row"
                            style="display: flex; justify-content: center; align-items: center; margin-top: 5px;">
                            <label for="" class="col-md-2">Approver</label>
                            <div class="col-md-4">
                                <select name="" id="approver" class="form-control"
                                    (change)="ChangeApprover2($event.target)">
                                    <option value="">Choose Approver</option>
                                    <option value="{{item.id}}" *ngFor="let item of allbankusers2">{{item.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2">
                                <a href="javascript:void(0)" class="btn btn-info btn-sm"
                                    (click)="AddApprover2()">Add</a>
                            </div>
                        </div>
                        <div class="form-group row"
                            style="display: flex; justify-content: center; align-items: center; margin-top: 5px;">
                            <span *ngFor="let item of addedApprovers2"
                                class="badge badge-primary mr-1 mb-1">{{item.order}}. {{item.roleName}}</span>
                        </div>
                    </div>
                </div>
                    <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit"
                        (click)="onsubmit()" value="Submit" class="btn btn-success"
                        style="float:right; margin-top:5px;">
                        <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                    </button>
                    <button type="button" data-toggle="tooltip" title="Refresh" (click)="reset()" [hidden]="isEdit" class="btn btn-secondary"
                        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
                </form>
            </div>
        </div>
    </div>


    <!-- <div class="card" style="width: 100%;" *ngIf="showArea === 1">
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h5 class="card-title" style="color: black">Product Builder List</h5>
                    </div>
                    <div class="col" style="text-align: right;">
                        <button type="button" data-toggle="tooltip" (click)="areaView(2)" class="btn btn-info"
                            style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Go to Entry</button>
                    </div>
                </div>
                <hr>
                <table id="example" class="table table-striped table-bordered" style="width:100%">
                    <thead>
                        <tr>
                            <th>SL No.</th>
                            <th>Product Builder Code</th>
                            <th>Product Builder Name</th>
                            <th>Version</th>
                            <th>Description</th>
                            <th style="width: 20%;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of list; let i = index;">
                            <td>{{i + 1}}</td>
                            <td>{{item.code}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.version}}</td>
                            <td>{{item.description}}</td>
                          
                            <td style="width: 20%;">
                                <button class="btn btn-info mb-1" style="margin-left: 5px;"
                                    (click)="openBuilderDetailsModal(productBuilderDetails)" (click)="getProductDetails(item.id)"><i
                                        class="fa fa-list"></i></button>
                                <button class="btn btn-primary mb-1" style="margin-left: 5px;" (click)="edit(item)"><i
                                        class="fa fa-edit"></i></button>
                                <button [disabled]="isEdit" class="btn btn-danger mb-1" style="margin-left: 5px;"
                                    (click)="delete(item.id)"><i class="fa fa-trash-o"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div> -->

    <!-- Modal -->
    <ng-template #contentDaySize let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Day Size Details</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <table class="table table-bordered">
                <tr>
                    <td>Day Size Name</td>
                    <td>{{daySize?.name}}</td>
                </tr>
                <tr>
                    <td>Size</td>
                    <td>{{daySize?.size}}</td>
                </tr>
                <tr>
                    <td>Up Value</td>
                    <td>{{daySize?.upMark}}</td>
                </tr>
                <tr>
                    <td>Down Value</td>
                    <td>{{daySize?.downMark}}</td>
                </tr>
                <tr>
                    <td>Remarks</td>
                    <td>{{daySize?.remarks}}</td>
                </tr>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
    
    <ng-template #contentTax let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Tax Key Details</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p *ngIf="taxKey!=null">Tax Code: {{taxKey.taxCode}}, Tax Name: {{taxKey.taxName}}, Type:
                {{taxKey.transectionType.typeName}}</p>
            <ul *ngIf="taxKeyDetails!=null">
                <li *ngFor="let item of taxKeyDetails">Amount: {{item.amount}}; Percent: {{item.taxPercent}}% {{item.type==0?'Extra to':'Up to'}}</li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>

    <ng-template #contentExcise let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Excise Duty Info</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    <div class="modal-body">
        <p *ngIf="exciseDuty!=null">Excise Code: {{exciseDuty.exciseDutyName}}, Excise Name:
            {{exciseDuty.exciseDutyCode}}, Type: {{exciseDuty.transectionType.typeName}}</p>
        <ul *ngIf="sortExcDutyDetails!=null">
            <li *ngFor="let item of sortExcDutyDetails">Excise Amount: {{item.dutyAmount}};
                Amount Range: {{item.minAmountRange}} - {{item.maxAmountRange}}
            </li>
        </ul>
    </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>

    <ng-template #contentCalType let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">INT. Calculation Type Detail</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p *ngIf="storeIntCalType!=null">INT. Calculation Type: {{storeIntCalType.name}}</p>
            <ul *ngIf="storeIntCalTypeDetails!=null">
                <li *ngFor="let item of storeIntCalTypeDetails">Amount: {{item.amount}}; Percent: {{item.taxPercent}}%
                </li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>

    <ng-template #contentCalSlabType let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">INT. Calculation Type Slab Detail</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ul>
                <li>Min Amount: {{this.storeIntCalType.min}};</li>
                <li>Max Amount: {{this.storeIntCalType.max}};</li>
                <li>Parcentage: {{this.storeIntCalType.amountParcent}};</li>
                <li>Remarks: {{this.remarks}};</li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
    <ng-template #contentCapRule let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Capitalize Rule Detail</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ul>
                <li>Code: {{capRule!=null?capRule.code:''}}</li>
                <li>Details: {{capRule!=null?capRule.details:''}}</li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
    <ng-template #contentCharge let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Charge Detail</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ul>
                <li>Code: {{charge!=null?charge.code:''}}</li>
                <li>Name: {{charge!=null?charge.name:''}}</li>
                <li>{{charge!=null && charge.type==1?"Amount":"Percentage"}}: {{charge!=null?charge.amount:''}}</li>
                <li>Type: {{charge!=null && charge.type==1?"Fixed":"Percentage"}}</li>
                <li>Remarks: {{charge!=null?charge.remarks:''}}</li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
    <ng-template #contentCommission let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Commission Detail</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ul>
                <li>Code: {{commission!=null?commission.code:''}}</li>
                <li>Name: {{commission!=null?commission.name:''}}</li>
                <li>Amount: {{commission!=null?commission.amount:''}}</li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
    <!-- <ng-template #productBuilderDetails let-c="close" let-d="dismiss">
        <div class="modal-dialog">
          
            <div class="modal-content" style="margin-left: -50%; width: 200%;">
                <div class="modal-header">
                    <h4 class="modal-title">Product Builder Details</h4>
                    <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <table class="table-bordered">
                    <th class="col-3">Code: </th>
                    <td class="col-3">{{ productDetails?.productInfo?.code }}</td>
                    <th class="col-3">Name: </th>
                    <td class="col-3">{{ productDetails?.productInfo?.name }}</td>
                </table>
                <table class="table-bordered">
                    <th class="col-3">Version: </th>
                    <td class="col-3">{{ productDetails?.productInfo?.version }}</td>
                    <th class="col-3">Description: </th>
                    <td class="col-3">{{ productDetails?.productInfo?.description }}</td>
                </table>
                <table class="table-bordered">
                    <th class="col-3">Currency: </th>
                    <td class="col-3">{{ productDetails?.productInfo?.currency }}</td>
                    <th class="col-3">Day Size: </th>
                    <td class="col-3">{{ productDetails?.productInfo.daySize?.name }}</td>
                </table>
                <table class="table-bordered">
                    <th class="col-3">Tax Key: </th>
                    <td class="col-3">{{ productDetails?.productInfo.taxKey?.taxKeyName }}</td>
                    <th class="col-3">Excise Duty: </th>
                    <td class="col-3">{{ productDetails?.productInfo.exciseDuty?.exciseDutyName }}</td>
                </table>
                <table class="table-bordered">
                    <th class="col-3">Interest Calculation Type: Slab</th>
                    <td class="col-3">
                        <ul>
                            <li *ngFor="let item of productDetails?.productInstCalTypes">
                                {{item.slab.min}}-{{item.slab.max}} ({{item.slab.amountParcent}})%
                            </li>
                        </ul>
                    </td>


                    <th class="col-3">Interest Calculation Frequency: </th>
                    <td class="col-3">{{productDetails?.productInfo?.interestCalculationFrequency?.name }} -
                        {{productDetails?.productInfo?.interestCalculationFrequency?.days }} {{productDetails?.productInfo?.interestCalculationFrequency?.days !=null? "days" : "" }}</td>
                </table>
                <table class="table-bordered">
                    <th class="col-3">Capitalize Rule:</th>
                    <td class="col-3">
                        <ul>
                            <li *ngFor="let item of productDetails?.productRules"> {{item.rule?.code}}
                            </li>
                        </ul>
                    </td>
                    <th class="col-3">Capitalization Frequency:</th>
                    <td class="col-3">
                        {{productDetails?.productInfo?.capitalizationFrequency?.name }} - {{
                        productDetails?.productInfo?.capitalizationFrequency?.days }} {{
                        productDetails?.productInfo?.capitalizationFrequency?.days !=null? "days" :
                        ""}}
                    </td>
                </table>
                <table class="table-bordered">
                    <th class="col-3">Balance Type:</th>
                    <td class="col-3">{{ productDetails?.productInfo?.balanceType?.name }}</td>
                    <th class="col-3">Status:</th>
                    <td class="col-3">
                        {{ productDetails?.productInfo?.status ===0 ? "in-Active" :
                        "Active"}}
                    </td>
                </table>
                <table class="table-bordered">
                    <th class="col-3">Charge:</th>
                    <td class="col-3">
                        <ul>
                            <li *ngFor="let item of productDetails?.productCharge">
                                {{item.charge?.code}}-{{item.charge?.name}}
                            </li>
                        </ul>
                    </td>
                    <th class="col-3">Commission:</th>
                    <td class="col-3">
                        <ul>
                            <li *ngFor="let item of productDetails?.productCommission">
                                {{item.commission?.code}}-{{item.commission?.name}}
                            </li>
                        </ul>
                    </td>
                </table>


                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
                </div>
            </div>
        </div>
    </ng-template> -->


    <!-- Modal -->
</section>
<!-- Product Builder Input end -->