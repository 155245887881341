<section>
    <div class="card" style="width: 100%;">
        <!-- <div class="card-header">
            <h5 class="card-title" style="color: #888888">White List Customer</h5>
        </div> -->
     
<div class="row card-header  d-flex flex-row align-items-center">
    <div class="col-6">
    <h5 class="card-title" style="color: #888888">White List Customer</h5>
    </div>
    <div class="col-6">
        <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">

            <i class="icon-arrow-left" style="font-size: 17px;"> </i>
        </button>
    </div>
</div>



        <hr>
        <div class="card-body">
            <div class="container">
               

                <div style="overflow: auto; max-height: 600px;">
                    <table id="example" class="table table-striped table-bordered scrollable-table" style="width:100%">
                        <thead style="position: sticky; top: 0; background-color: #f8f9fa; z-index: 1;">
                            <tr>
                                <th>SL No.</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Father Name</th>
                                <th>Mother Name</th>
                                <th>Credit Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of customerList; let i = index;">
                                <td>{{i + 1}}</td>
                                <td>{{item.customerNameEng}}</td>
                                <td>{{item.email}}</td>
                                <td>{{item.mobileNo}}</td>
                                <td>{{item.fatherNameEng}}</td>
                                <td>{{item.motherNameEng}}</td>
                                <td>{{item.creditScore}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>