import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HttpClient,HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSpinnerModule } from 'ngx-spinner';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
 
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { CustomerModule } from "./customer/customer.module";
import { CookieService } from 'ngx-cookie-service';

import { ProductCreateComponent } from "./user-interface/deposit/product/product-create/product-create.component"; 
import { ProductListComponent } from "./user-interface/deposit/product/product-list/product-list.component";
import { BasicModuleModule } from "./master-data/basic/basic-module/basic-module.module";
import { EmployeeModuleModule } from "./employee/employee-module/employee-module.module";
import { AuthInterceptor } from "./auth.interceptor";
import { ProductModule } from "./product/product.module";
import { ProductsModule } from "./master-data/products/products.module";
// import { NavbarsRoutingModule } from "./navbars/navbars-routing.module";
import { NavbarsModule } from "./navbars/navbars.module";
import { CustomerAccountModule } from "./customer-account/customer-account.module";
import { AccountModule } from "./accounting/account.module";
import { JwtModule } from "@auth0/angular-jwt";
import { PaymentModule } from "./payment/payment.module";
import { PdfReportingModule } from "./pdf-reporting/pdf-reporting.module";
import { COBProcessModule } from "./cobprocess/cobprocess.module";
//import { UserModule } from "./user/user.module";

import{UserModule} from "./user/user.module"
import { SharedModule } from "./shared/shared.module";
import { EKycModule } from "./e-kyc/e-kyc.module";
import { DashboardCustomModule } from "./dashboard/dashboard-custom.module";
import { LoanApplicationModule } from "./loan-application/loan-application.module";
import { AccountingReportsModule } from './accounting-reports/accounting-reports.module';
import { DaycloseProcessModule } from "./dayclose-process/dayclose-process.module";
import { FdrAccountModule } from "./fdr-account/fdr-account.module";
import { ScoreModule } from "./Score/score.module";
import { DpsAccountModule } from "./dps-account/dps-account.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { UiSwitchModule } from "ngx-ui-switch";
import { ChartsModule } from "./charts/charts.module";
import { ApiModule } from './api/api.module';

  

//import { OtpVerifyComponent } from './e-kyc/otp-verify/otp-verify.component';
//import { CountryListComponent } from './master-data/basic/basic-component/country-list/country-list.component';




//import { CountryInfoComponent } from './master-data/basic/country-info/country-info.component';

var firebaseConfig = {
  apiKey: "YOUR_API_KEY", //YOUR_API_KEY
  authDomain: "YOUR_AUTH_DOMAIN", //YOUR_AUTH_DOMAIN
  databaseURL: "YOUR_DATABASE_URL", //YOUR_DATABASE_URL
  projectId: "YOUR_PROJECT_ID", //YOUR_PROJECT_ID
  storageBucket: "YOUR_STORAGE_BUCKET", //YOUR_STORAGE_BUCKET
  messagingSenderId: "YOUR_MESSAGING_SENDER_ID", //YOUR_MESSAGING_SENDER_ID
  appId: "YOUR_APP_ID", //YOUR_APP_ID
  measurementId: "YOUR_MEASUREMENT_ID" //YOUR_MEASUREMENT_ID
};


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({

  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent, ProductCreateComponent, ProductListComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    NgbModule,
    NgxSpinnerModule,
    UserModule,
    ProductModule,
    CustomerModule,
    CustomerAccountModule,
    BasicModuleModule,
    EmployeeModuleModule,
    DashboardCustomModule,
    ProductsModule,
    AccountModule,
    NavbarsModule,
    UiSwitchModule,
    PaymentModule,
    COBProcessModule,
    PdfReportingModule,
    EKycModule,
    NgbDatepickerModule,
    LoanApplicationModule,
    AccountingReportsModule,
    DaycloseProcessModule,
    FdrAccountModule,
    DpsAccountModule,
    ScoreModule,
    NgSelectModule,
    ChartsModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: createTranslateLoader,
    //     deps: [HttpClient]
    //   }
    // }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    PerfectScrollbarModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('token');
        },
      },
    }),
    ApiModule,
  ],
  providers: [
    AuthService,
    AuthGuard,CookieService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
