
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { SharedService } from 'app/services/shared.service';
import swal from 'sweetalert';
import { CustomerService } from 'app/customer/service/customer.service';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { DatePipe } from '@angular/common';
import { Location } from "@angular/common";
@Component({
  selector: 'app-disburse-collection',
  templateUrl: './disburse-collection.component.html',
  styleUrls: ['./disburse-collection.component.scss']
})
export class DisburseCollectionComponent implements OnInit {

  frmGroup: FormGroup;
  frmGroupSearch: FormGroup;
  model: any = {};
  leadInfo: any = {};
  isAuto: boolean=false;
  outstanding: any;
  collectionAmount: any;


  lgList:any[]=[];
  currentUser:any;
  customerList:any[]=[];
  productList:any[]=[];

  selectedCustomerId: string; 
  selectedProductId: string; 
  constructor(
    private _service: LeadGenerationServiceService,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private router: Router,
    private customerService: CustomerService,
    private _loanProductService:LoanProductService,
    private layoutService: LayoutService,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private location: Location
    )  { 
      this.frmGroupSearch = this.formBuilder.group({
        customerId:0,
        leadId: 0,
        customerRCode: '',
        customerNameEng: '',
        customerNameBan: '',
        dateOfBirth: '',
        mobileNo: '',
        email: '',
        disbursementDate: '',
        loanAmount: '',
        interestRate: '',
        interestAmount:'',
        totalaount: '',
        scheduleCollection:'',
        outstanding:'',
      });

    }

  ngOnInit(): void {
    this.searchForm();
    
  }

  searchForm(){
    this.frmGroup = this.formBuilder.group({
      searchValue: ['', Validators.required],
      
    })
  }
  lgData : string;
  search() {
    this.model.searchValue = this.frmGroup.value.searchValue;
    this._service.GetLoanScheduleCode(this.model.searchValue).subscribe(
      (res: any) => {
      if(res != null){
        
         this.collectionAmount = res.scheduleCollection 
        

      }
  
    }
      
   );

    
    this._service.searchLeadInfoByLGCode(this.model.searchValue).subscribe(
      (res: any) => {
      //console.log(res.customer?.customerRCode);
      this.isAuto=true;
      if(res != null){
         const totalaount = res.loanAmount + res.interestAmount
         this.outstanding = totalaount - this.collectionAmount
         this.frmGroupSearch.patchValue({
          customerId: res.customerId,
          leadId: res.leadGeneration?.id,
          customerRCode: res.customer?.customerRCode,
          customerNameEng: res.customer?.customerNameEng,
          customerNameBan: res.customer?.customerNameBan,
          dateOfBirth: this.datePipe.transform(res.customer?.dateOfBirth, 'dd-MMM-yyyy'),
          mobileNo: res.customer?.mobileNo,
          email: res.customer?.email,
          disbursementDate: this.datePipe.transform(res.disbursementDate, 'dd-MMM-yyyy'), 
          loanAmount: res.loanAmount,
          interestRate: res.interestRate,
          interestAmount: res.interestAmount,
          totalaount: totalaount,
          scheduleCollection: this.collectionAmount,
          outstanding:this.outstanding
          
          
        });
      }
  
      },
      (error: any) => {
        swal('Error', 'Failed to retrieve lead generation list.', 'error');
      }
   );
  }



  overDue: any = 0;
  advance: any = 0;
  onscheduleCollectionChange(value: any) {
    this.overDue = this.collectionAmount - value;
    this.advance = value - this.collectionAmount;

    this.frmGroupSearch.patchValue({
         
      
      scheduleCollection: this.collectionAmount,
      outstanding:this.outstanding
      
      
    });

  }

  SavePersonalInfo(){

    if (this.collectionAmount > 0) {
    
      this.model.leadId = this.frmGroupSearch.value.leadId;
      this.model.installmentAmount = this.collectionAmount ;
      this.model.collectionAmount = this.frmGroupSearch.value.scheduleCollection;
      this.model.customerId = this.frmGroupSearch.value.customerId;
      this.model.interestAmt = this.frmGroupSearch.value.interestAmount;
      this.model.principleAmt = this.frmGroupSearch.value.loanAmount;
      this._loanProductService.saveloanCollections(this.model).subscribe(res => {
        this.shared.ShowAlert('Thank you...', 'You save succesfully!', 'success');
        this.refresh();
      })
    } 
    else {
      this.shared.ShowAlert('Warning...', 'Please Create Client First!', 'warning');
     
    }
  }

  refresh() {
    this.searchForm();
    this.frmGroupSearch = this.formBuilder.group({
      customerId:0,
      leadId: 0,
      customerRCode: '',
      customerNameEng: '',
      customerNameBan: '',
      dateOfBirth: '',
      mobileNo: '',
      email: '',
      disbursementDate: '',
      loanAmount: '',
      interestRate: '',
      interestAmount:'',
      totalaount: '',
      scheduleCollection:'',
      outstanding:'',
    });
    
  }

  goBack() {
    this.location.back();
  }
}
