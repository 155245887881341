<!-- <p>  <button class="btn btn-primary" (click)="goLoanFormSearch()" ><i class="fa fa-edit 3x">Loan Form</i></button>                  </p> -->
<!-- <div class="card-title" style="height: 40%;"></div> -->
<div class="row">
  <div class="col-10">
<h2 style="margin: 0;padding: 0;text-align: center;">Lead Generation</h2>

  </div>
  <div class="col-2">
    <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
      <i class="icon-arrow-left" style="font-size: 17px;"> </i>
    </button>
  </div>
</div>
<hr>
<div class="card" style="width: 100%;">
 
  <div class="card-body">
    <ul class="list-unstyled mb-0">
      <li class="d-inline-block mr-2">
        <div class="radio">
          <input type="radio" id="radio1" name="basic-radio-1" value="New" #option1
            (change)="onRadioChange(option1.value)">
          <label for="radio1" style="cursor: pointer;">
            <h3 style="padding-top: 10%;">New Lead</h3>
          </label>
        </div>
      </li>
      <li class="d-inline-block mr-2">
        <div class="radio">
          <input type="radio" name="basic-radio-1" id="radio2" value="Existing" #option2
            (change)="onRadioChange(option2.value)" />
          <label for="radio2" style="cursor: pointer;">
            <h3 style="padding-top: 7%;">Existing Lead</h3>
          </label>
        </div>
      </li>
    </ul>
  </div>
</div>

<div class="card" *ngIf="selectedOption === 'New'">
  <div class="card-body mt-3">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <label for="sortorder" class="col-sm-4 col-form-label">Loan Product</label>
          <div class="col-sm-8">
            <select class="form-control" [(ngModel)]="selectedProductId" name="selectedProductId">
              <option value="">Select</option>
              <option *ngFor="let item of productList" [value]="item.id">
                {{ item.name }} - {{ item.code }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <label for="sortorder" class="col-sm-4 col-form-label">Customer Name</label>
          <div class="col-sm-8">
            <select class="form-control" [(ngModel)]="selectedCustomerId" name="selectedCustomerId">
              <option value="">Select</option>
              <option *ngFor="let item of customerList" [value]="item.id">
                {{ item.customerNameEng }} - {{ item.mobileNo }}
              </option>
            </select>
          </div>
        </div>
      </div>
      
      <div class="col-md-12">
      <button class="btn " (click)="NewLoanGenerate('New')"
      style="background-color: #616D89;color:white;font-weight: bold;float: right;">Lead Generate </button>
    </div>
    </div>
  
  </div>
 
</div>


<div *ngIf="selectedOption === 'Existing'">
  <app-search _nghost-xyv-c325="" class="ng-star-inserted">
    <section _ngcontent-xyv-c325="" class="search-wrapper">
      <div _ngcontent-xyv-c325="" class="search-bar mt-3">
        <form _ngcontent-xyv-c325="" novalidate="" action="javascript:;" class="ng-untouched ng-pristine ng-valid"
          [formGroup]="frmGroup">
          <div _ngcontent-xyv-c325="" class="page-search-input form-group position-relative">
            <input _ngcontent-xyv-c325="" type="search" id="searchbar" placeholder="Search by lead code.."
              formControlName="searchValue" class="form-control rounded-right form-control-lg shadow pl-2">
            <button _ngcontent-xyv-c325="" style="background-color: #616D89;color:white;cursor: pointer;" type="button"
              [disabled]="!frmGroup.valid" (click)="search()"
              class="btn btn-primary search-btn rounded position-absolute mt-3">
              <span _ngcontent-xyv-c325="" class="d-none d-sm-block">Search</span>
              <i _ngcontent-xyv-c325="" class="ft-search d-block d-sm-none"></i>
            </button>
          </div>
        </form>
      </div>
    </section>
  </app-search>
</div>

<div class="card" style="width: 100%;" *ngIf="lgData === 'dataFound'">
  <div class="card-body">
    <div class="container">
      <h5 class="card-title" style="color: black">Lead Generation List</h5>
      <hr>
      <table id="example" class="table table-striped table-bordered" style="width:100%">
        <thead>
          <tr>
            <th>SL No.</th>
            <th>Lead Generation Code</th>
            <th style="width: 20%;">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of lgList; let i = index;">
            <td>{{i + 1}}</td>
            <td>{{item.leadCode}}</td>
            <td style="width: 20%;">
              <button class="btn" (click)="goLoanForm(item.id,item.customerId,item.loanProductId)"
                style="background-color: #616D89;color:white;font-weight: bold;"><i class="3x">Go to Form</i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>