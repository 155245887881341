import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { CapitalizeFrequencyService } from '../service/capitalize-frequency.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';

@Component({
  selector: 'app-capitalize-frequency',
  templateUrl: './capitalize-frequency.component.html',
  styleUrls: ['./capitalize-frequency.component.scss']
})
export class CapitalizeFrequencyComponent implements OnInit {

  capitalizeFrequencyList: any[] = [];
  isEdit: boolean = false;
  capFreqFromGrp: FormGroup;
  productTypeId: number=0;
  productBuilderTypes = ProductBuilderTypeEnum;
  capFreqModel: any = {};

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = '';

  constructor(private formBuilder: FormBuilder,
    private shared: SharedService,
    private _capFreqService: CapitalizeFrequencyService) {

  }

  ngOnInit(): void {
    this.capFrequencyForm();
    this.showSaveForm = 'saveList';
    this.getCapFrequencyList();
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getFiscalYearList();
  }
  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getFiscalYearList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getFiscalYearList();
  }

  capFrequencyForm() {
    this.capFreqFromGrp = this.formBuilder.group({
      id: [0],
      name: [''],
      days: [0],
      productTypeId:[0]
    })
  }

  refresh() {
    this.isEdit = false;
    this.capFrequencyForm();
    this.capFreqModel = {};
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();
  }

  getCapFrequencyList() {
    this._capFreqService.getCapitalizeFrequencyList().subscribe(res => {
      this.capitalizeFrequencyList = res;
    })
  }
  getFiscalYearList() {
    this._capFreqService.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.capitalizeFrequencyList = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }
  onSubmit() {
    if (this.isEdit) {
      this.capFreqModel.CapFrequencyId = this.capFreqFromGrp.value.id;
    }
    this.capFreqModel.name = this.capFreqFromGrp.value.name;
    this.capFreqModel.days = this.capFreqFromGrp.value.days;
    this.capFreqModel.productTypeId = this.capFreqFromGrp.value.productTypeId;

    this._capFreqService.saveCapitalizeFrequency(this.capFreqModel).subscribe(res => {
      //alert('Save successfully');
      this.shared.ShowAlert("Success", "Save Successfully", "success");
      this.getCapFrequencyList();
      this.isEdit = false;
      this.showSaveForm = 'saveList';
      //this.refresh();
    })
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    //alert(item.id)
    this.capFreqFromGrp.patchValue({
      id: item.id,
      name: item.name,
      days: item.days,
      productTypeId:item.productBuilderType
    })
  }

  delete(id: any) {
    this._capFreqService.deleteCapitalizeFrequency(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getCapFrequencyList();
      this.showSaveForm = 'saveList';
    })
  }

}
