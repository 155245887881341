import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl=environment.baseUrl + '/api/InterestCalculationBalance/'

@Injectable({
  providedIn: 'root'
})
export class InterestCalculationBalanceService {

  constructor(private http:HttpClient) { }

  getCalculationBalanceList():Observable<any>{
    return this.http.get(apiUrl+ 'GetAllInterestCalculationBalanceList')
  }

  getCalculationBalanceById(id:number){
    return this.http.get(apiUrl + 'GetInterestCalculationBalanceById/' + id)
  }

  saveInterestCalculationBalance(model:any){
    return this.http.post(apiUrl + 'SaveInterestCalculationBalance',model)
  }

  deleteInterestCalculationBalance(id:any){
    return this.http.delete(apiUrl + 'DeleteInterestCalculationBalance/' + id)
  }
  getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(apiUrl + 'GetInterestCalculationListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }
}
