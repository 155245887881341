<div class="card" style="width: 100%;">

<!-- <div class="card-headers" >
  <h5 class="card-title" style="color: rgb(73, 72, 72);font-family: Arial; font-size: 23px !important; margin-top: 19px; margin-left: 10px;">Customer Information</h5>
</div> -->
 
</div>
<!-- <div class="mt-3" style="text-align: center; padding: 10px; border-radius: 4px;" [ngStyle]="{'background-color': profileUpdate < 100 ? '#F4E869' : '#b0dbeb'}"> -->
<div class="mt-3" style="text-align: left; padding: 10px; border-radius: 4px;">
  <h3 style="color: rgb(78, 77, 77);">Profile Update: {{ profileUpdate }}% Complete</h3>
</div>

<!-- <p>HTML</p>
<div class="container">
  <div class="skills html">Profile Update: {{ profileUpdate }}% Complete</div>
</div> -->
 
<!-- <h3>Profile Update: {{ profileUpdate }}% Complete</h3> -->
<div class="row" *ngIf="profileUpdate < 100">


  <div class="col-xs-12 col-md-12 block">

  <div class="stepper-wrapper">
    <div class="stepper-item" [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('1-1')"
    [ngClass]="{'active': activeTab === '1-1'}">
      <div class="step-counter"><i class="ft-user"></i></div>
      <div class="step-name">Basic Information</div>
    </div>
    <div class="stepper-item" [ngClass]="{'active': activeSubButton === 3}" (click)="setActiveButton('1-3')"
    [ngClass]="{'active': activeTab === '1-3'}">
      <div class="step-counter"><i class="ft-map"></i></div>
      <div class="step-name">Address Details</div>
    </div>
    <div class="stepper-item active" [ngClass]="{'active': activeSubButton === 2}" (click)="setActiveButton('1-2')"
    [ngClass]="{'active': activeTab === '1-2'}">
      <div class="step-counter"><i class="ft-users"></i></div>
      <div class="step-name">Emergency Contact</div>
    </div>
    <div class="stepper-item" [ngClass]="{'active': activeSubButton === 4}" (click)="setActiveButton('1-4')"
    [ngClass]="{'active': activeTab === '1-4'}">
      <div class="step-counter"><i class="ft-home"></i></div>
      <div class="step-name">Professional Details</div>
    </div>
  </div>
 

  <!-- <div class="col-xs-12 col-md-12 block">
    <div class="wrapper-progressBar">
  <ol class="progressBar">
    <li  class="activeb active" [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('1-1')">
      <a class="nav-link" >Basic</a>
    </li>
    <li  class="activeb"  [ngClass]="{'active': activeSubButton === 3}" (click)="setActiveButton('1-3')"
        [ngClass]="{'active': activeTab === '1-3'}">
      <a class="nav-link" data-toggle="pill" >Address</a>
    </li>
    <li  class="activeb" [ngClass]="{'active': activeSubButton === 2}" (click)="setActiveButton('1-2')"
      [ngClass]="{'active': activeTab === '1-2'}">
   
      <a class="nav-link">Contact</a>

    </li>
     
    <li  class="activeb" [ngClass]="{'active': activeSubButton === 4}" (click)="setActiveButton('1-4')"
        [ngClass]="{'active': activeTab === '1-4'}">
      <a class="nav-link" data-toggle="pill" >Professional</a>
    </li>
      <li  class="activeb" [ngClass]="{'active': activeSubButton === 5}" (click)="setActiveButton('1-5')"
        [ngClass]="{'active': activeTab === '1-5'}">
      <a class="nav-link" data-toggle="pill"  >Income</a>
    </li>
    <li  class="activeb" [ngClass]="{'active': activeSubButton === 6}" (click)="setActiveButton('1-6')"
        [ngClass]="{'active': activeTab === '1-6'}">
      <a class="nav-link" data-toggle="pill"  >Expense</a>
    </li>
    <li  class="activeb" [ngClass]="{'active': activeSubButton === 7}" (click)="setActiveButton('1-7')"
        [ngClass]="{'active': activeTab === '1-7'}" >
      <a class="nav-link" data-toggle="pill"   >Bank Account</a>
    </li>
    <li  class="activeb" [ngClass]="{'active': activeSubButton === 8}" (click)="setActiveButton('1-8')"
        [ngClass]="{'active': activeTab === '1-8'}">
      <a class="nav-link" data-toggle="pill"  >Reference</a>
    </li>  
  </ol>
  </div>
  </div> -->


  <div class="tab-content">
    <div class="tab-pane active" *ngIf="activeTab === '1-1'">
      <app-customer-basic-info-for-edit></app-customer-basic-info-for-edit>
    </div>
    <div class="tab-pane active" *ngIf="activeTab === '1-2'">
      <!-- <app-contact-info></app-contact-info> -->
        <app-contact-info (contactUpdateSuccess)="getProfileUpdateInfo()"></app-contact-info>
    </div>
    <div  class="tab-pane active" *ngIf="activeTab === '1-3'">
        <app-address-info (addressUpdateSuccess)="getProfileUpdateInfo()"></app-address-info>
    </div>
    <div id="step-4" class="tab-pane active"  *ngIf="activeTab === '1-4'">
      <app-professional-info (professionUpdateSuccess)="getProfileUpdateInfo()"></app-professional-info>
    </div>

    <!-- <div id="step-5" class="tab-pane active" *ngIf="activeTab === '1-5'">
      <app-income-details (incomeUpdateSuccess)="getProfileUpdateInfo()"></app-income-details>
    </div>
    <div id="step-6" class="tab-pane active" *ngIf="activeTab === '1-6'">
      <app-expense-details (expenseUpdateSuccess)="getProfileUpdateInfo()"></app-expense-details>
    </div>
    <div id="step-7" class="tab-pane active"  *ngIf="activeTab === '1-7'">
      <app-bankaccount-details (bankaccountUpdateSuccess)="getProfileUpdateInfo()"></app-bankaccount-details>
    </div>
    <div id="step-8" class="tab-pane active" *ngIf="activeTab === '1-8'">
      <app-reference-details (referenceUpdateSuccess)="getProfileUpdateInfo()"></app-reference-details>
    </div> -->

  </div>
</div>

</div>
<!-- ------------------------------------------------------------ ------------------------------------------------------>
<div *ngIf="profileUpdate == 100">
<div class="row" >
  <div class="col-md-2">
    <div style="display: flex; flex-direction: column; margin-top: 14px;">
      <button class="activeb active" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
       [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('1-1')" >Basic Details</button>

      <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
        [ngClass]="{'active': activeSubButton === 2}" (click)="setActiveButton('1-2')"
        [ngClass]="{'active': activeTab === '1-2'}"> Contact Details</button>


      <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
        [ngClass]="{'active': activeSubButton === 3}" (click)="setActiveButton('1-3')"
        [ngClass]="{'active': activeTab === '1-3'}"> Address Details</button>

      <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
        [ngClass]="{'active': activeSubButton === 4}" (click)="setActiveButton('1-4')"
        [ngClass]="{'active': activeTab === '1-4'}">Professional Details</button>

      <!-- <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
        [ngClass]="{'active': activeSubButton === 5}" (click)="setActiveButton('1-5')"
        [ngClass]="{'active': activeTab === '1-5'}">Income Details</button>

      <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
        [ngClass]="{'active': activeSubButton === 6}" (click)="setActiveButton('1-6')"
        [ngClass]="{'active': activeTab === '1-6'}">Expense Details</button>

      <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
        [ngClass]="{'active': activeSubButton === 7}" (click)="setActiveButton('1-7')"
        [ngClass]="{'active': activeTab === '1-7'}">Bank Account Details</button>

      <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
        [ngClass]="{'active': activeSubButton === 8}" (click)="setActiveButton('1-8')"
        [ngClass]="{'active': activeTab === '1-8'}">Reference</button> -->
    </div>
  </div>

  <div class="col-md-10" style="padding-left: 48px;">
    <ng-container *ngIf="activeTab === '1-1'">
      <app-customer-basic-info-for-edit></app-customer-basic-info-for-edit>
    </ng-container>
    <ng-container *ngIf="activeTab === '1-2'">
      <app-contact-info></app-contact-info>
    </ng-container>
    <ng-container *ngIf="activeTab === '1-3'">
      <app-address-info></app-address-info>
    </ng-container>
    <ng-container *ngIf="activeTab === '1-4'">
      <app-professional-info></app-professional-info>
    </ng-container>
    <!-- <ng-container *ngIf="activeTab === '1-5'">
      <app-income-details></app-income-details>
    </ng-container>
    <ng-container *ngIf="activeTab === '1-6'">
      <app-expense-details></app-expense-details>
    </ng-container>

    <ng-container *ngIf="activeTab === '1-7'">
      <app-bankaccount-details></app-bankaccount-details>
    </ng-container>

    <ng-container *ngIf="activeTab === '1-8'">
      <app-reference-details></app-reference-details>
    </ng-container> -->
  </div>
</div>
</div>










