import { ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'app/customer/customer.service';
import { SharedService } from 'app/services/shared.service';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { LoanapplicationApprovalService } from 'app/loanapplication-approval/loanapplication-approval.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { ContactInfoComponent } from '../contact-info/contact-info.component';
import { BasicInfoSelfComponent } from '../basic-info-self/basic-info-self.component';

@Component({
  selector: 'app-dynamic-loan-application',
  templateUrl: './dynamic-loan-application.component.html',
  styleUrls: ['./dynamic-loan-application.component.scss']
})
export class DynamicLoanApplicationComponent implements OnInit {
  @ViewChild('childContainer', { read: ViewContainerRef }) container: ViewContainerRef;
    ref!: ComponentRef<BasicInfoSelfComponent>


  activeTab: string = '1-1';
  mainTab: string = '1';

  leadCode: any;
  leadId: any = 0;
  ProductId: any = 0;
  loanRegInfoId: any;
  ParentList:any []=[];
  Childist:any []=[];


  //selectedOption: string = '1';
  activeButton = 1;
  activeSubButton = 1;
  activeFormbody = 1;
  activeButtonparentId=1;
  loanId: number = 0;
  leadNo: string = "";
  isApprover: boolean = true;
  clientInfoId: any = 0;
  currentUser: any;
  loanRegisterId: number;
  
  com1: any = ContactInfoComponent;

  compon:string;


  // leadcom: any = ` <ng-container *ngIf="activeTab === '1-2'">
  //                  <app-contact-info></app-contact-info>

  //                   </ng-container>`
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal,
    private shared: SharedService,
    private _serviceClientInfo: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private _leadGennarationService: LeadGenerationServiceService,
    private loanService: LoanapplicationApprovalService,
    private layoutService: LayoutService,
    // private componentFactoryResolver: ComponentFactory<string>,
    // private viewContainerRef: ViewContainerRef
  )
   { }

  ngOnInit(): void {
    this.getLeadInfo();
    this.GetLoanMenuSetupforlist();
    //this.GetLoanMenuSetupforlistChild();
    this.childloadOnParentId(8)
    //this.loanRegisterId === null
    this.activeRoute.paramMap.subscribe(params => {
      this.leadId = parseInt(params.get('leadId'));
    });

    this.layoutService.getCurrentUserName().subscribe(res => {
      this.currentUser = res;
      // console.log('userName');
      // console.log(res);
    }
    );
    //this.getLoanRegisterId(this.leadId);
    //this.getLoanMenuSetUpByLoanProductId(this.ProductId);
    // this.ref = this.vcr.createComponent(BasicInfoSelfComponent)
    //const componentFactory = this.viewContainerRef.createComponent(BasicInfoSelfComponent);
  }

  getLeadInfo() {
    this.activeRoute.paramMap.subscribe(params => {
      const leadGNId = +params.get('leadId');
      this.leadId = +params.get('leadId');
      if (leadGNId != null) {
        this._leadGennarationService.searchLeadCodebyId(leadGNId).subscribe(
          (res: any) => {
            this.leadCode = res.leadCode;
            //this.getLoanRegisterId(this.leadId);
          },
          (error: any) => {
            this.shared.ShowAlert('Error', 'Failed to retrieve.', 'error');
          }
        );
      }
    });
  }


  setMainTab(number: string): void
   {

    this.mainTab = number;
    this.activeButton = parseInt(number, 10);
    if (number == '1') {
      this.activeTab = '1-1';
    }
    if (number == '2') {
      this.activeTab = '2-1';
    }
    if (number == '3') {
      this.activeTab = '3-1';
    }
    if (number == '4') {
      this.activeTab = '4-1';
    }

    if (number == '5') {
      this.activeTab = '5-1';
    }

    this.changeDetectorRef.detectChanges();
  }

//   loadChildComponent(componentName: string) {
// alert();
//      this.childContainer.createComponent(ContactInfoComponent);

//   }






  setActiveButton(buttonNumber): void {
    // alert(buttonNumber)
    ////this.activeButton = buttonNumber;
    //this.activeButton = parseInt(buttonNumber, 10);
    this.activeSubButton = 1;
    this.activeFormbody = buttonNumber;

    this.activeTab = buttonNumber;
    this.activeButton = buttonNumber;
    this.changeDetectorRef.detectChanges();
    //alert(buttonNumber)
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }











  //

  getLoanSetUpProductId(leadId: number) {
    this.loanService.getLoanregisterbyLeadId(leadId).subscribe((res: any) => {
      console.log(res)
      this.loanRegisterId = res;
      this.changeDetectorRef.detectChanges();
    })

  }
 

  //
  getLoanRegisterId(leadId: number) {
    this.loanService.getLoanregisterbyLeadId(leadId).subscribe((res: any) => {
      console.log(res)
      this.loanRegisterId = res;
      this.changeDetectorRef.detectChanges();
    })

  }


  forwardto(leadCode) {
    if (this.loanRegisterId === null || this.loanRegisterId === undefined || this.loanRegisterId === 0) {
      this.shared.ShowAlert("Error", "You must add  loan register first", "warning");
      return;
    }
    else {
      this.loanService.ForwardLeadByCodeForApproval(leadCode).subscribe((res: any) => {
        if (res.status === "forwarded") {
          this.shared.ShowAlert("Success", "Forwarded Successfully", "success");

          this.router.navigate(['/loan-application/lead-generation']);

        } else if (res.status === "fail") {
          this.shared.ShowAlert("Error", "Unable to Forwarded", "warning");
        } else {
          this.shared.ShowAlert("Error", "Unable to Forwarded", "warning");
        }
      });
    }
  }

  // getLoanMenuSetUpByLoanProductId(ProductId: number) {
  //   this.loanService.GetLoanMenuSetUpByLoanProductId(ProductId).subscribe(res => {
  //     console.log(555)
  //     console.log(res)
  //     //this.ParentList=res;
  //   })
  // }
  GetLoanMenuSetupforlist() {
    this.loanService.GetLoanMenuSetupforlist().subscribe(res => {
      console.log(555)
      console.log(res)
      this.ParentList=res;

    })
  }

  childloadOnParentId(parentId: any){
    //alert(parentId)
    this.loanService.GetLoanMenuSetupforlistChild(parentId).subscribe(res => {
      console.log(4444)
      console.log(res)
      this.Childist=res;
      this.activeTab = res[0].url;
      //this.activeButton = res[0].url;
      this.activeButtonparentId = parentId;
      this.activeButton = res[0].url;
     // alert(this.activeTab)
    })

  }

  GetLoanMenuSetupforlistChild(parentId: any) {
    this.loanService.GetLoanMenuSetupforlistChild(parentId).subscribe(res => {
      console.log(555)
      console.log(res)
      this.Childist=res;
      
    })
  }
}
