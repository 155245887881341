import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-home-loan',
  templateUrl: './home-loan.component.html',
  styleUrls: ['./home-loan.component.scss']
})
export class HomeLoanComponent implements OnInit {
  model: any = {};
  frmGroupHomeLoan: FormGroup;
  clientInfoId: any = 0;
  loanRegId: any = 0;
  propertytypeList: any[] = [];
  loanRegHome: any = 0;
  isHomeLoanInfoEdit: boolean = false;
  leadId: any = 0;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private shared: SharedService,
  ) { }

  ngOnInit(): void {
    this.getpropertytypeList();
    //this.getLeadInfo();
  }

  getpropertytypeList() {
    // this._serviceLoanReg.getpropertytypeList().subscribe(res => {
    //   this.propertytypeList = res;
    // })
  }

  HomeLoanpopulateForm() {
    this.frmGroupHomeLoan = this.formBuilder.group({
      id: [0],
      homeLoanPropertyId: ['', Validators.required],
      propertyAddress: ['', Validators.required],
      developerName: ['', Validators.required],
      developersCategory: ['', Validators.required],
      buildingProjectName: ['', Validators.required],
      totalLandArea: ['', Validators.required],
      proportionateLandArea: [''],
      propertyOrDeedAgreementVal: ['', Validators.required],
      noOfCarParking: [''],
      carParkingPrice: ['', Validators.required],
    })
  }

  GetHomeLoanInfoByRegId(id: number) {
    this.isHomeLoanInfoEdit = true;
    // this._serviceLoanReg.GetHomeLoanInfoByRegId(id).subscribe(
    //   (loanRegister: LoanRegister) => {
    //     this.loanRegHome = loanRegister.id;
    //     if (loanRegister && loanRegister.id > 0) {
    //       this.frmGroupHomeLoan.patchValue({
    //         id: loanRegister.id,
    //         homeLoanPropertyId: loanRegister.homeLoanPropertyId,
    //         propertyAddress: loanRegister.propertyAddress,
    //         developerName: loanRegister.developerName,
    //         developersCategory: loanRegister.developersCategory,
    //         buildingProjectName: loanRegister.buildingProjectName,
    //         totalLandArea: loanRegister.totalLandArea,
    //         proportionateLandArea: loanRegister.proportionateLandArea,
    //         propertyOrDeedAgreementVal: loanRegister.propertyOrDeedAgreementVal,
    //         noOfCarParking: loanRegister.noOfCarParking,
    //         carParkingPrice: loanRegister.carParkingPrice,
    //       });
    //     } else {
    //       this.HomeLoanpopulateForm();
    //     }
    //   }
    // );
  }
  onHomeLoanSubmit() {
    if (this.clientInfoId > 0) {
      if (this.loanRegId > 0) {
        this.model.id = this.frmGroupHomeLoan.value.id;
        this.model.homeLoanPropertyId = this.frmGroupHomeLoan.value.homeLoanPropertyId;
        this.model.propertyAddress = this.frmGroupHomeLoan.value.propertyAddress;
        this.model.developerName = this.frmGroupHomeLoan.value.developerName;
        this.model.developersCategory = this.frmGroupHomeLoan.value.developersCategory;
        this.model.buildingProjectName = this.frmGroupHomeLoan.value.buildingProjectName;
        this.model.totalLandArea = this.frmGroupHomeLoan.value.totalLandArea;
        this.model.proportionateLandArea = this.frmGroupHomeLoan.value.proportionateLandArea;
        this.model.propertyOrDeedAgreementVal = this.frmGroupHomeLoan.value.propertyOrDeedAgreementVal;
        this.model.noOfCarParking = this.frmGroupHomeLoan.value.noOfCarParking;
        this.model.carParkingPrice = this.frmGroupHomeLoan.value.carParkingPrice;
        this.model.loanRegisterMasterId = this.loanRegId;
        this.model.leadId = this.leadId;

        // this._serviceLoanReg.saveHomeLoan(this.model).subscribe(res => {
        //   if (this.model.id > 0) {
        //     this.shared.ShowAlert('Thank you...', 'Updated succesfully!', 'success');
        //   } else {
        //     this.shared.ShowAlert('Thank you...', 'Saved succesfully!', 'success');
        //   }
        //   this.loanRegHome = res;  
        //   //this.getLeadInfo();
        // })

      } else {
        this.shared.ShowAlert('Warning...', 'Please Register Loan First!', 'warning');
        //this.getLeadInfo();
      }

    } 
    else {
      this.shared.ShowAlert('Warning...', 'First Create a Client application!', 'warning');
     // this.getLeadInfo();
    }

  }

}
