import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import swal from 'sweetalert';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private baseUrl = environment.baseUrl;
    
  constructor(private http: HttpClient) { }

  ShowAlert(title: string, message: string, type: string):void{
    swal(title, message, type);
  }

  LastBusinessDate(): any {
    return this.http.get(`${this.baseUrl}/api/Ledger/GetLastActiveBusinessDate`);
  }
}
