import { Component, OnInit } from '@angular/core';
import { InterestCalculationService } from '../service/interest-calculation.service';
import { ExciseDutyService } from '../service/excise-duty.service';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-interest-calculation-type-list',
  templateUrl: './interest-calculation-type-list.component.html',
  styleUrls: ['./interest-calculation-type-list.component.scss']
})
export class InterestCalculationTypeListComponent implements OnInit {
  list: any[] = [];
  
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  frmGroup: FormGroup;

  TaxkeyId: any = 0;

  constructor(private _service: InterestCalculationService, private _exservice: ExciseDutyService,
    private _productService: ProductBuilderService,
    private router: Router, private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private shared: SharedService
  ) { }

  ngOnInit(): void {
    this.getInterestCalculationTypeList();



    this.route.paramMap.subscribe(params => {
      const TaxkeyId = +params.get('id');
      this.TaxkeyId = +params.get('id');
      // if (TaxkeyId > 0) {
      //   this.GetClientinfoById(clientId);
      //   this.GetContactByClientId(clientId);
      //   this.GetAddressInfoByClientId(clientId);
      //   this.GetprofesionalinfoByClientId(clientId);
      // }
    });
   
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getInterestCalculationTypeList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getInterestCalculationTypeList();
  }
 
  onSearch() {
    this.currentPage = 1;
    this.getInterestCalculationTypeList();
  }

  getInterestCalculationTypeList() {
    this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = [];
      res.list.forEach(sD => {
        this.list.push(sD);
      })
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }


  edit(item: any) {
    this.router.navigate(['/products/interest-calculation-type', item.instCalType.id]);
  }

}
