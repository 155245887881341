import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl=environment.baseUrl + '/api/TransectionType/'

@Injectable({
  providedIn: 'root'
})
export class TransectionTypeService {

  constructor(private http:HttpClient) { }

  getTransectionTypeList():Observable<any>{
    return this.http.get(apiUrl+ 'GetAllTransectionTypeList')
  }
  getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(apiUrl + 'GetTransactionTypeListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }
  getTransectionTypeById(id:number){
    return this.http.get(apiUrl + 'GetTransectionTypeById/' + id)
  }

  saveTransectionType(model:any){
    return this.http.post(apiUrl + 'SaveTransectionType',model)
  }

  deleteTransectionType(id:any){
    return this.http.delete(apiUrl + 'DeleteTransectionType/' + id)
  }

}
