import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardCustomRoutingModule } from './dashboard-custom-routing.module';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CutomerLeadComponent } from './cutomer-lead/cutomer-lead.component';
import { CustomerleadAppliedComponent } from './customerlead-applied/customerlead-applied.component';
import { CustomerleadApprovedComponent } from './customerlead-approved/customerlead-approved.component';
import { WaitingLoanAppComponent } from './admin-dashboard-page/waiting-loan-app/waiting-loan-app.component';
import { ForwardLoanAppComponent } from './admin-dashboard-page/forward-loan-app/forward-loan-app.component';
import { ApprovedLoanAppComponent } from './admin-dashboard-page/approved-loan-app/approved-loan-app.component';
import { RejectedLoanAppComponent } from './admin-dashboard-page/rejected-loan-app/rejected-loan-app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ApplicationDisburseListComponent } from './application-disburse-list/application-disburse-list.component';
import { AppApproveDisburseComponent } from './app-approve-disburse/app-approve-disburse.component';
import { EMIPayBillComponent } from './emi-pay-bill/emi-pay-bill.component';
import { EMIAmountPayComponent } from './emi-amount-pay/emi-amount-pay.component';
import { CollectionDetlsComponent } from './collection-detls/collection-detls.component';
import { FinalDisburseListComponent } from './admin-dashboard-page/final-disburse-list/final-disburse-list.component';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';
import { NewAdminDashboardComponent } from './new-admin-dashboard/new-admin-dashboard.component';
import { LoanSettlementComponent } from './loan-settlement/loan-settlement.component';
import { AccountantDashboardComponent } from './accountant-dashboard/accountant-dashboard.component';
import { OperationalDashboardComponent } from './operational-dashboard/operational-dashboard.component';
import { WaitingPaymentComponent } from './admin-dashboard-page/waiting-payment/waiting-payment.component';
import { TodayCollectionListComponent } from './today-collection-list/today-collection-list.component';
import { ClcalculationInfoComponent } from './clcalculation-info/clcalculation-info.component';
import { ChartsModule } from 'ng2-charts';
import { ChartistModule } from 'ng-chartist';
import { ReceivableListComponent } from './admin-dashboard-page/receivable-list/receivable-list.component';
import { PayableLoanListComponent } from './admin-dashboard-page/payable-loan-list/payable-loan-list.component';
import { OverdueLoanListComponent } from './admin-dashboard-page/overdue-loan-list/overdue-loan-list.component';

@NgModule({
  declarations: [
    AdminDashboardComponent,
    CustomerDashboardComponent,
    CutomerLeadComponent,
    CustomerleadAppliedComponent,
    CustomerleadApprovedComponent,
    WaitingLoanAppComponent,
    ForwardLoanAppComponent,
    ApprovedLoanAppComponent,
    RejectedLoanAppComponent,
    ApplicationDisburseListComponent,
    AppApproveDisburseComponent,
    EMIPayBillComponent,
    EMIAmountPayComponent,
    CollectionDetlsComponent,
    FinalDisburseListComponent,
    DashboardNewComponent,
    NewAdminDashboardComponent,
    LoanSettlementComponent,
    AccountantDashboardComponent,
    OperationalDashboardComponent,
    WaitingPaymentComponent,
    TodayCollectionListComponent,
    ClcalculationInfoComponent,
    ReceivableListComponent,
    PayableLoanListComponent,
    OverdueLoanListComponent
  ],
  imports: [
 
    BrowserModule,
    DashboardCustomRoutingModule,
    RouterModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    ChartistModule,
  ],
  providers: [
    DatePipe
  ],
})
export class DashboardCustomModule { }
