<section>
    <div class="card"> 
        <div class="row mt-3">
            <div class="col-10"><h3 class="col-12">Application Info</h3></div>
            <div class="col-2">
                <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                </button>
            </div>
        </div>
        <hr>
        <!-- </section>
        <section> -->
        <div class="row pt-2">
            <div class="col-10"></div>
            <div class="col-2">
                <button class="btn btn-sm btn-info pull-right" (click)="goDetails()" type="button" title="Download">Download <i
                        class="fa fa-download"></i></button>
        
            </div>
        </div>
        <!-- </section>
        <section> -->
    <div class="col-12 ml-4">
        <div class="row pt-2">
           
            <div class="col-8 row">
                <h4 class="col-12 text-center">Customer</h4>
                <div class="col-12 row">
                    <label class="form-control col-4">Customer Code</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.customerRCode}}" readonly />
                </div>
                <div class="col-12 row">
                    <label class="form-control col-4">Customer Name</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.customerNameEng}}" readonly />
                </div>
                <div class="col-12 row">
                    <label class="form-control col-4">Date Of Birth</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.dateOfBirth | date:'dd-MM-yyyy'}}"
                        readonly />
                </div>
                <div class="col-12 row">
                    <label class="form-control col-4">Gender</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.gender?.name}}" readonly />
                </div>
                <div class="col-12 row">
                    <label class="form-control col-4">Mobile (Office)</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.mobileNo}}" readonly />
                </div>
                <div class="col-12 row">
                    <label class="form-control col-4">Email</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.email}}" readonly />
                </div>
                <div class="col-12 row">
                    <label class="form-control col-4">NID No</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.nidNo}}" readonly />
                </div>
                <div class="col-12 row">
                    <label class="form-control col-4">Father Name</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.fatherNameEng}}" readonly />
                </div>
                <div class="col-12 row">
                    <label class="form-control col-4">Mother Name</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.motherNameEng}}" readonly />
                </div>
                <div class="col-12 row">
                    <label class="form-control col-4">Marital Status</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.maritalStatus}}" readonly />
                </div>
                <div class="col-12 row">
                    <label class="form-control col-4">Spouse Name</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.spouseNameEng}}" readonly />
                </div>
                <div class="col-12 row">
                    <label class="form-control col-4">Spouse Mobile No</label>
                    <input class="form-control col-8" value="{{loanInfo?.customerInfo?.spouseMobileNo}}" readonly />
                </div>
            </div>
            <div class="col-4">
                <h4 class="col-12 text-center">Address</h4>
        
                <div class="col-12 row" *ngFor="let item of loanInfo?.customerAddressInfo; let i = index;">
                    <h4>{{item?.type === 'present' ? 'Present' : item?.type === 'permanent' ? 'Permanent' : ''}}</h4>
                    <div class="col-12" style="border:1px solid;width: 100%;">
                        <p class="p-0 m-0">Road Number: {{item?.roadNo}}</p>
                        <p class="p-0 m-0">House No./Village: {{item?.houseNo}}</p>
                        <p class="p-0 m-0">Block Sector: {{item?.blockSector}}</p>
                        <p class="p-0 m-0">Address: {{item?.type === 'present' ? item?.presentAddress : item?.type ===
                            'permanent' ? item?.permamentAddress : ''}}</p>
                        <p class="p-0 m-0">Post Code: {{item?.postCode}}</p>
                        <p class="p-0 m-0">Post Office: {{item?.upazila?.upazilaName}}</p>
                        <p class="p-0 m-0">Thana Name: {{item?.roadNo}}</p>
                        <p class="p-0 m-0">District Name: {{item?.districts?.districtName}}</p>
                        <p class="p-0 m-0">Division Name: {{item?.divisions?.divisionName}}</p>
                    </div>
                </div>
            </div>
        </div>
   </div>
<hr>
<div class="col-12 ml-4">
<div class="row pt-2">
    <h3 class="col-12 text-center">Loan Info</h3>
    <div class="col-6 row">
        <div class="col-12 row">
            <label class="form-control col-5">Account Title</label>
            <input class="form-control col-7" value="{{loanInfo?.loanInfo?.leadGeneration?.loanProduct?.name}}"
                readonly />
        </div>
        <div class="col-12 row">
            <label class="form-control col-5">Loan Tenure</label>
            <input class="form-control col-7" value="{{loanInfo?.loanInfo?.tenure?.monthName}}" readonly />
        </div>
        <div class="col-12 row">
            <label class="form-control col-5">Interest Rate</label>
            <input class="form-control col-7" value="{{loanInfo?.loanInfo?.interestRate}}%" readonly />
        </div>
        <div class="col-12 row">
            <label class="form-control col-5">Loan Amount</label>
            <input class="form-control col-7" value="{{loanInfo?.loanInfo?.loanAmount}}" readonly />
        </div>
        <div class="col-12 row">
            <label class="form-control col-5">Interest Amount</label>
            <input class="form-control col-7" value="{{loanInfo?.loanInfo?.interestAmount}}" readonly />
        </div>
        <div class="col-12 row">
            <label class="form-control col-5">Monthly EMI</label>
            <input class="form-control col-7" value="{{loanInfo?.loanInfo?.installmentSize}}" readonly />
        </div>

    </div>
    <div class="col-6">
        <div class="col-12 row">
            <label class="form-control col-5">First Installment Date</label>
            <input class="form-control col-7" value="{{loanInfo?.loanInfo?.firstInstallmentDate | date:'dd-MM-yyyy'}}"
                readonly />
        </div>
        <div class="col-12 row">
            <label class="form-control col-5">Disburse Linking Account</label>
            <input class="form-control col-7"
                value="{{loanInfo?.loanInfo?.disburselinkingAccount==null?'':loanInfo?.loanInfo?.disburselinkingAccount?.accountNo}}"
                readonly />
        </div>
        <!-- <div class="col-12 row">
                        <label class="form-control col-5">Processing Fee</label>
                        <input class="form-control col-7" value="{{loanInfo.loanInfo?.processingFee}}"  readonly/>
                    </div> -->
        <div class="col-12 row">
            <label class="form-control col-5">MFS Account</label>
            <input class="form-control col-7" value="{{loanInfo?.loanInfo?.mfsNumber}}" readonly />
        </div>
        <div class="col-12 row">
            <label class="form-control col-5">Account Type</label>
            <input class="form-control col-7" value="{{loanInfo?.loanInfo?.mfsType}}" readonly />
        </div>
        <div class="col-12 row">
            <label class="form-control col-5">Account No</label>
            <input class="form-control col-7" value="{{loanInfo?.customerInfo?.mfsNumber}}" readonly />
        </div>
        <div class="col-12 row">
            <label class="form-control col-5">Processing Fee</label>
            <input class="form-control col-7" value="{{loanInfo?.customerInfo?.processingFee}}" readonly />
        </div>
    </div>
</div>
</div>
<hr>
        <div class="row pt-2">
            <div class="col-1"></div>
            <div class="col row">
                <h3 class="col-12 text-center">Contact Info</h3>
                <div class="col-12" style="border:1px solid;width: 100%;">
                    <p class="p-0 m-0">Contact Person: {{loanInfo?.customerContactInfo?.name}}</p>
                    <p class="p-0 m-0">Email: {{loanInfo?.customerContactInfo?.email}}</p>
                    <p class="p-0 m-0">Mobile Number: {{loanInfo?.customerContactInfo?.contact}}</p>
                    <p class="p-0 m-0">Address: {{loanInfo?.customerContactInfo?.homeAddress}}</p>
                </div>
            </div>
            <div class="col-1"></div>
            <div class="col row">
                <h3 class="col-12 text-center">Profession Info</h3>
                <div class="col-12" style="border:1px solid;width: 100%;">
                    <div *ngIf="cusProf1">
                        <p class="p-0 m-0">Company Name: {{loanInfo?.customerProfessionalInfo?.name}}</p>
                        <p class="p-0 m-0">Designation: {{loanInfo?.customerProfessionalInfo?.email}}</p>
                        <p class="p-0 m-0">Mobile Number: {{loanInfo?.customerProfessionalInfo?.contact}}</p>
                        <p class="p-0 m-0">Office Address: {{loanInfo?.customerProfessionalInfo?.homeAddress}}</p>
                    </div>
                    <div *ngIf="cusProf2">
                        <p class="p-0 m-0">Profession: {{loanInfo?.customerProfessionalInfo?.selfEmployedType?.type}}</p>
                    </div>
                    <div *ngIf="cusProf3">
                        <p class="p-0 m-0">Organization Name: {{loanInfo?.customerProfessionalInfo?.orgName}}</p>
                        <p class="p-0 m-0">Nature Of Business: {{loanInfo?.customerProfessionalInfo?.businessType}}</p>
                        <p class="p-0 m-0">Business Address: {{loanInfo?.customerProfessionalInfo?.businessAddress}}</p>
                        <p class="p-0 m-0">Designation: {{loanInfo?.customerProfessionalInfo?.presentDesignation}}</p>
                    </div>
                </div>
            </div>
            <div class="col-1"></div>
        </div>
        <hr>
        <div class="row pt-2">
            <div class="col-1"></div>
            <div class="col row">
                <h3 class="col-12 text-center">Income Details</h3>
                <div class="col-12" *ngFor="let item of loanInfo?.customerIncomeDetails; let i = index;"
                    style="border:1px solid;width: 100%;">
                    <h4 class="col-12 text-center">Income Details {{i+1}}</h4>
                    <div class="col-12" style="width: 100%;">
                        <p class="p-0 m-0">Monthly Amount: {{item?.monthlyIncomeAmount}}</p>
                        <p class="p-0 m-0">Joint Applicant Amount: {{item?.jointApplicantIncomeAmount}}</p>
                        <p class="p-0 m-0">Rental Amount: {{item?.rentalIncomeAmount}}</p>
                        <p class="p-0 m-0">Interest Amount: {{item?.interestIncomeAmount}}</p>
                        <p class="p-0 m-0">Other's Income Amount: {{item?.otherIncomeAmount}}</p>
                        <p class="p-0 m-0">Total Income Amount: {{item?.totalAmount}}</p>
                    </div>
                </div>
        
            </div>
            <div class="col-1"></div>
            <div class="col row">
                <h3 class="col-12 text-center">Expense Details</h3>
                <div class="col-12" *ngFor="let item of loanInfo?.customerExpenseDetails; let i = index;"
                    style="border:1px solid;width: 100%;">
                    <h3 class="col-12 text-center">Expense Details {{i+1}}</h3>
                    <div class="col-12" style="width: 100%;">
                        <p class="p-0 m-0">Rent & Utility Amount: {{item?.rentAndUtilityAmount}}</p>
                        <p class="p-0 m-0">Food And Clothing Amount: {{item?.foodAndClothingAmount}}</p>
                        <p class="p-0 m-0">Education Amount: {{item?.educationAmount}}</p>
                        <p class="p-0 m-0">Loan Repayment Amount: {{item?.loanRepaymentAmount}}</p>
                        <p class="p-0 m-0">Miscellaneous Amount: {{item?.miscellaneousAmount}}</p>
                        <p class="p-0 m-0">Total expense Amount: {{item?.totalAmount}}</p>
                    </div>
                </div>
            </div>
            <div class="col-1"></div>
        </div>
        <hr>
        <div class="row pt-2">
            <div class="col-1"></div>
            <div class="col row">
                <div class="col-12" *ngFor="let item of loanInfo?.customerBankAccountDetails; let i = index;"
                    style="border:1px solid;width: 100%;">
                    <h3 class="col-12 text-center">Bank Account Details {{i+1}}</h3>
                    <div class="col-12" style="width: 100%;">
                        <p class="p-0 m-0">Bank Name: {{item?.bankName}}</p>
                        <p class="p-0 m-0">Branch Name: {{item?.branchName}}</p>
                        <p class="p-0 m-0">Account No: {{item?.accountNo}}</p>
                        <p class="p-0 m-0">Account Type: {{item?.accountType}}</p>
                        <p class="p-0 m-0">Average Balance: {{item?.avgBalance}}</p>
                    </div>
                </div>
        
            </div>
            <div class="col-1"></div>
            <div class="col row">
                <div class="col-12" *ngFor="let item of loanInfo?.customerReferenceDetails; let i = index;"
                    style="border:1px solid;width: 100%;">
                    <h3 class="col-12 text-center">Reference Details {{i+1}}</h3>
                    <div class="col-12" style="width: 100%;">
                        <p class="p-0 m-0">Name: {{item?.name}}</p>
                        <p class="p-0 m-0">Relationship: {{item?.relationship}}</p>
                        <p class="p-0 m-0">Residence Address: {{item?.residenceAddress}}</p>
                        <p class="p-0 m-0">Residential Status: {{item?.residentialStatus}}</p>
                        <p class="p-0 m-0">Company Name: {{item?.companyName}}</p>
                        <p class="p-0 m-0">Ref Designation: {{item?.refDesignation}}</p>
                        <p class="p-0 m-0">Company Designation: {{item?.companyDesignation}}</p>
                        <p class="p-0 m-0">Company Address: {{item?.companyAddress}}</p>
                        <p class="p-0 m-0">Company Phone Residence: {{item?.companyPhoneResidence}}</p>
                        <p class="p-0 m-0">Company Phone Office: {{item?.companyPhoneOffice}}</p>
                        <p class="p-0 m-0">Company Mobile: {{item?.companyMobile}}</p>
                    </div>
                </div>
            </div>
            <div class="col-1"></div>
        </div>
        <hr>
        <div class="col-12 ml-4">
        <div class="row pt-2">
            <h3 class="col-12 text-center">Loan Guarantor</h3>
            <div class="col-12 row" *ngFor="let item of loanInfo?.loanGuarantors; let i = index;"
                style="border:1px solid;width: 90%;">
                <h3 class="col-12 text-center">Loan Guarantor {{i+1}}</h3>
                <div class="col-4" style="width: 90%;">
                    <p class="p-0 m-0">Guarantor Type: {{item?.type}}</p>
                    <p class="p-0 m-0">NID No: {{item?.nidNo}}</p>
                    <p class="p-0 m-0">Guarantor Name: {{item?.name}}</p>
                    <p class="p-0 m-0">Fathers Name: {{item?.fathersName}}</p>
                    <p class="p-0 m-0">Mothers Name: {{item?.mothersName}}</p>
                    <p class="p-0 m-0">Spouse Name: {{item?.spouseName}}</p>
                    <p class="p-0 m-0">Date of Birth: {{item?.dob | date:'dd-MM-yyyy'}}</p>
                    <p class="p-0 m-0">Relationship with applicant: {{item?.relationwithApplicant}}</p>
                    <p class="p-0 m-0">Residential Address: {{item?.presentResidentialAddress}}</p>
                    <p class="p-0 m-0">Present Address: {{item?.permanentAddress}}</p>
                    <p class="p-0 m-0">Business Address: {{item?.businessAddress}}</p>
                    <p class="p-0 m-0">Occupation: {{item?.occupation}}</p>
                    <p class="p-0 m-0">Mobile No: {{item?.mobile}}</p>
                    <p class="p-0 m-0">Telephone No: {{item?.telephone}}</p>
                    <p class="p-0 m-0">Fax No: {{item?.fax}}</p>
                    <p class="p-0 m-0">TIN No: {{item?.tinNo}}</p>
                    <p class="p-0 m-0">Email: {{item?.email}}</p>
                </div>
                <div class="col-4" style="width: 100%;">
                    <p class="p-0 m-0">Employee Name: {{item?.employersName}}</p>
                    <p class="p-0 m-0">Employee Designation: {{item?.employersDesignation}}</p>
                    <p class="p-0 m-0">Net Monthly Income: {{item?.netMonthlyIncome}}</p>
                    <p class="p-0 m-0">Comments Source: {{item?.commentsSource}}</p>
                    <p class="p-0 m-0">Marital Status: {{item?.maritalStatus}}</p>
                    <p class="p-0 m-0">Present address: {{item?.presentAddress}}</p>
                    <p class="p-0 m-0">Years: {{item?.years}}</p>
                    <p class="p-0 m-0">Residential Status: {{item?.residentialStaus}}</p>
                    <p class="p-0 m-0">District Name: {{item?.district.districtName}}</p>
                    <p class="p-0 m-0">Thana Name: {{item?.thana.upazilaName}}</p>
                    <p class="p-0 m-0">Monthly Expense: {{item?.monthlyExpense}}</p>
                    <p class="p-0 m-0">Last Tax Return Year: {{item?.lastTaxReturnYear}}</p>
                    <p class="p-0 m-0">Name of Enterprise: {{item?.nameOfEnterprise}}</p>
                    <p class="p-0 m-0">Establish Date of Enterprise: {{item?.establishDate| date:'dd-MM-yyyy'}}</p>
                    <p class="p-0 m-0">Sales of Business: {{item?.businessSales}}</p>
                    <p class="p-0 m-0">Stock of Concern: {{item?.concernStock}}</p>
                    <p class="p-0 m-0">Age of Enterprise: {{item?.enterpriseAge}}</p>
                </div>
                <div class="col-4" style="width: 100%;">
                    <p class="p-0 m-0">Legal Entity: {{item?.legalEntity}}</p>
                    <p class="p-0 m-0">Nature of Enterprise: {{item?.enterpriseNature}}</p>
                    <p class="p-0 m-0">Business Location: {{item?.businessLocation}}</p>
                    <p class="p-0 m-0">Trade Licence Register No: {{item?.tradeLicenceNo}}</p>
                    <p class="p-0 m-0">Segment of Enterprise: {{item?.segmentEnterprise}}</p>
                    <p class="p-0 m-0">Sub Sector of Enterprise: {{item?.subSectorEnterprise}}</p>
                    <p class="p-0 m-0">Name of Cluster: {{item?.clusterName}}</p>
                    <p class="p-0 m-0">Initial Equity Absolute Figure: {{item?.initialEquityAbsoluteFigure}}</p>
                    <p class="p-0 m-0">Present Equity Absolute Figure: {{item?.presentEquityAbsoluteFigure}}</p>
                    <p class="p-0 m-0">Relationship With EBL: {{item?.relationshipWithEBL}}</p>
                    <p class="p-0 m-0">Personal Worth: {{item?.personalWorth}}</p>
                    <p class="p-0 m-0">Educational Qualification: {{item?.eduQualification}}</p>
                    <p class="p-0 m-0">Organization Name: {{item?.organizationName}}</p>
                    <p class="p-0 m-0">Organization Address: {{item?.organizationAddress}}</p>
                    <p class="p-0 m-0">Guarantee Amount: {{item?.guaranteeAmount}}</p>
                </div>
            </div>
        </div>
        </div>
        <hr>
        <div class="col-12 ml-4">
        <div class="row pt-2">
            <h3 class="col-12 text-center">Loan Document</h3>
            <div class="col-12 row" style="width: 100%;">
                <table id="example" class="table table-striped table-bordered" style="width:100%">
                    <thead>
                        <tr>
                            <th style="width: 20%;">Image/File</th>
                            <th style="width: 20%;">Document Type </th>
                            <th style="width: 20%;">File Name</th>
                            <th style="width: 20%; text-align: center;">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let document of loanInfo?.loanDocumentinfos; let i = index;">
                            <td style="width: 20%;">
                                <img [src]="getPreviewUrl(document?.fileUrl)" (click)="fullPreviewImage(document?.fileUrl)"
                                    style="width: 40%; height: 30%;" alt="Preview" *ngIf="document?.fileType === 'image/jpeg'" />
                                <img [src]="defaultPdfImage" style="width: 40%; height: 30%;" alt="Default PDF"
                                    *ngIf="document?.fileType === 'application/pdf'" />
                            </td>
                            <td style="width: 20%;">{{document?.documentsType?.typeName}}</td>
                            <td style="width: 20%;">{{document?.fileName}}</td>
                            <td style="width: 20%; text-align: center;">
                                <button data-toogle="tooltip" title="Preview" class="btn btn-info" style="margin-right: 3px;"
                                    (click)="fullPreviewImage(document?.fileUrl)"
                                    *ngIf="document?.fileType === 'image/jpeg' ||  document?.fileType === 'image/png'"><i
                                        class="icon-size-fullscreen"></i></button>
                                <button data-toogle="tooltip" title="Download" class="btn btn-primary"
                                    (click)="downloadFile(document?.id)"><i class="fa fa-download"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    </div>
    
</section>
