<div class="card" style="width: 100%;" id="div1">
    <div class="card-body">
        <div class="col-12">
            <form enctype="multipart/form-data" (submit)="onSubmit()">
                <div>
                    <div>
                        <h4>Upload File</h4>
                    </div>
                    <hr>
                    <div class="row mr-3">
                        <div class="col-md-6">
                            <!-- <input id="file-input" accept=".xlsx" type="file" (change)="onFileChange($event)" /> -->
                            <input id="file-input" accept=".xlsx" type="file" />
                        </div>
                        <div class="col-md-6">
                            <button type="submit" class="btn btn-success ml-1">Save</button>
                            <button type="button" class="btn btn-info ml-1" (click)="fileForamtExcel()">File
                                Format</button>
                           
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<br />

<div class="card">
    <div class="card-body" *ngIf="showTable">
        <table class="table table-striped table-bordered table-responsive-lg table-responsive-md table-responsive-sm">
          
            <tbody>
                <!-- <tr *ngFor="let item of tableData">
                    <td>{{ item.CreateLead }}</td>
                    <td>{{ item.LeadName }}</td>
                    <td>{{ item.LeadShortName }}</td>
                    <td>{{ item.OwnerShipType }}</td>
                    <td>{{ item.Sector }}</td>
                    <td>{{ item.ClientType }}</td>
                    <td>{{ item.GroupName }}</td>
                    <td>{{ item.LeadStatus }}</td>
                    <td>{{ item.LeadSource }}</td>
                    <td>{{ item.WebsiteLink }}</td>
                    <td>{{ item.TotalEmployees }}</td>
                    <td>{{ item.SourceDescription }}</td>
                </tr> -->
            </tbody>
        </table>
    </div>
</div>

