import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-loan-disbursement',
  templateUrl: './loan-disbursement.component.html',
  styleUrls: ['./loan-disbursement.component.scss']
})
export class LoanDisbursementComponent implements OnInit {

  fromDate:Date=new Date();
  toDate:Date=new Date();
  constructor(private pdfservice:PdfService,private location:Location) 
  { }

  ngOnInit(): void {
  }

  OpenPdf(){
    this.pdfservice.LoanDisbursementReport(this.fromDate,this.toDate).subscribe(x=>{
      let blob:Blob=x.body as Blob;
      let url=window.URL.createObjectURL(blob);

      window.open(url);
    })
  }
  OpenExcel(){
    this.pdfservice.LoanDisbursemetExcelReport(this.fromDate,this.toDate).subscribe(x=>{
      let blob:Blob=x.body as Blob;
      let url=window.URL.createObjectURL(blob);

      window.open(url);
    })
  }

  goBack() {
    this.location.back();
  }
}
