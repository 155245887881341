import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { SharedService } from 'app/services/shared.service';
import { CustomerService } from 'app/customer/customer.service';
import { DatePipe } from '@angular/common';
import swal from 'sweetalert';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-customer-basic-info-for-edit',
  templateUrl: './customer-basic-info-for-edit.component.html',
  styleUrls: ['./customer-basic-info-for-edit.component.scss']
})
export class CustomerBasicInfoForEditComponent implements OnInit {
  frmGroup: FormGroup;
  model: any = {};
  customersId: any = 0;
  leadId: any = 0;
  clientInfoId: any = 0;
  GenderList: any[] = [];
  customerDetailsInfos: any;
  isAuto: boolean=false;
  profileUpdate: any;
  selectedMaritalStatus: string = '0' ;   
  constructor(
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private _leadGennarationService: LeadGenerationServiceService,
    private shared: SharedService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit(): void {
     this.GetCustomerDetailsForEdit();
     this.getAllGender();
    // this.getLeadInfo();
     this.populateForm();
     this.reset();
     this.route.paramMap.subscribe(params => {
       if (params.get("id") == null) {
         this.customerService
           .GetCustomerDetailsForEdit()
           .subscribe((data: any) => {
             this.customersId = data.customerDetailsInfos.customerId;
             this.LoadInfo(this.customersId);
           });
       } else {
         this.customersId = parseInt(params.get("id"), 10);
         this.LoadInfo(this.customersId);
       }
       
       
       
     });

     //alert(this.customersId);
 
     
   }
   LoadInfo(customersId:number){
     this.customerService.GetLeadGenerationByLeadNoforCustomer(customersId).subscribe(res =>{
       if(res != null){
         this.isAuto=true;

         this.frmGroup.patchValue({
           id: res.id,

           //  leadId: res.id,

           customerRCode: res.customerRCode,
           customerNameEng: res.customerNameEng,
           customerNameBan: res.customerNameBan,
           DateOfBirth: this.datePipe.transform(res.dateOfBirth, "dd-MMM-yyyy"),
           GenderId: res.gender.id,
           GenderName: res.gender.name,
           mobileNo: res.mobileNo,
           email: res.email,
           occupation: res.occupation,
           nidNo: res.nidNo,
           tin: res.tin,
           fatherNameEng: res.fatherNameEng,
           fatherNameBan: res.fatherNameBan,
           motherNameEng: res.motherNameEng,
           motherNameBan: res.motherNameBan,
           maritalStatus: res.maritalStatus,
           spouseNameEng: res.spouseNameEng,
           spouseNameBan: res.spouseNameBan,
           spouseMobileNo: res.spouseMobileNo,
         });
       }
       else{
         this.frmGroup = this.formBuilder.group({
           id: 0,
           // leadId: 0,
           customerRCode: "",
           customerNameEng: "",
           customerNameBan: "",
           DateOfBirth: "",
           GenderId: "",
           mobileNo: "",
           email: "",
           nidNo: "",
           tin: "",
           fatherNameEng: "",
           fatherNameBan: "",
           motherNameEng: "",
           motherNameBan: "",
           maritalStatus: "0",
           spouseNameEng: "",
           spouseNameBan: "",
           spouseMobileNo: "",
         });
       }
     })
   }
  gender: string = 'select';
  onGenderStatusChange(value: string) {
    this.gender = value;
  }
   populateForm() {
     this.frmGroup = this.formBuilder.group({
       id: [0],
       customerRCode: [""],
       customerNameEng: [""],
       customerNameBan: [""],
       DateOfBirth: [""],
       GenderId: [""],
       GenderName: [""],
       mobileNo: [""],
       email: [""],
       nidNo: [""],
       tin: [""],
       fatherNameEng: [""],
       fatherNameBan: [""],
       motherNameEng: [""],
       motherNameBan: [""],
       maritalStatus: ["0"],
       spouseNameEng: [""],
       spouseNameBan: [""],
       spouseMobileNo: [""],
     });
   }

 
  SavePersonalInfo(){ 

      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to update this Information!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Save it!"
      }).then((res) => {
        if (res.isConfirmed) {



      this.model.id = this.frmGroup.value.id;
      this.model.customerRCode = this.frmGroup.value.customerRCode;
      this.model.customerNameEng = this.frmGroup.value.customerNameEng;
      this.model.customerNameBan = this.frmGroup.value.customerNameBan;
      this.model.DateOfBirth = this.datePipe.transform(this.frmGroup.value.DateOfBirth, 'yyyy-MM-dd');
      //this.frmGroup.value.dateOfBirth;
      this.model.GenderId = this.frmGroup.value.GenderId;
      this.model.mobileNo = this.frmGroup.value.mobileNo;
      this.model.email = this.frmGroup.value.email;
      this.model.nidNo = this.frmGroup.value.nidNo;
      this.model.fatherNameEng = this.frmGroup.value.fatherNameEng;
      this.model.fatherNameBan = this.frmGroup.value.fatherNameBan;
      this.model.motherNameEng = this.frmGroup.value.motherNameEng;
      this.model.motherNameBan = this.frmGroup.value.motherNameBan;
      this.model.maritalStatus = this.frmGroup.value.maritalStatus;
      this.model.spouseNameEng = this.frmGroup.value.spouseNameEng;
      this.model.spouseNameBan = this.frmGroup.value.spouseNameBan;
      this.model.spouseMobileNo = this.frmGroup.value.spouseMobileNo;    
      this.model.tin = this.frmGroup.value.tin;
  
      this.customerService.savebasic(this.model).subscribe(res =>{
        this.shared.ShowAlert('Thank you...', 'Update succesfully!', 'success');   
        this.LoadInfo(this.customersId);
        this.getProfileUpdateInfo();
      })
    }
    else if (res.isDismissed) {
      this.router.navigate(['/customer/customer-edit']);
    }
  });
  
}
    


  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe(res => {
      this.profileUpdate = res;
    })
    this.changeDetectorRef.detectChanges();
  }

    getAllGender() {
      this.customerService.getlist().subscribe(res => {
        
        this.GenderList = res;

        this.changeDetectorRef.detectChanges;
      });
      
    }
 
  reset() {
 
     this.populateForm();
     this.model = {};
   }

   GetCustomerDetailsForEdit() {
        this.customerService.GetCustomerDetailsForEdit().subscribe((data:any)  => {
         this.customerDetailsInfos = data;
         this.clientInfoId = data.customerDetailsInfos.customerId;

    
       })
   }

   getmaritalStatus(value:string){
    this.selectedMaritalStatus= value
  }

}
