import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl=environment.baseUrl + '/api/CapitalizationRule/'
@Injectable({
  providedIn: 'root'
})
export class CapitalizationRuleService {

  constructor(private http:HttpClient) { }
 

   //#region Capitalization
   getlist():Observable<any>{
    return this.http.get(apiUrl+ 'GetAllCapitalizationRuleList')
  }
   getlistByProBuilderType(id:number):Observable<any>{
    return this.http.get(apiUrl+ 'GetAllCapRuleListByProBuilderType?id='+id)
  }
  
  GetCapitalizationById(id:number){
    return this.http.get(apiUrl + 'GetCapitalizationRuleById')
  }
  GetProductWiseIntaCalculationRuleId(id:number){
    return this.http.get(apiUrl + 'GetProductWiseIntaCalculationRuleId/' + id)
  }
  
  SaveCapitalization(model:any){
    return this.http.post(apiUrl + 'SaveCapitalizationRule',model)
  }
  getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(apiUrl + 'GetCapitalListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }
  DeleteCapitalization(id:any){
    return this.http.delete(apiUrl + 'DeleteCapitalizationRule/' + id)
  }

  GetCapitalizationRuleById(id:number){
    return this.http.get(apiUrl + 'GetCapitalizationRuleById')
  }
  //#endregion
}
