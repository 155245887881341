import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustomerService } from "../service/customer.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'app/services/shared.service';
import { Nominee } from '../model/nominee';
import { Router } from '@angular/router';
import { id } from '@swimlane/ngx-datatable';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RelationServiceService } from 'app/master-data/basic/basic-service/relation-service.service';
import { Observable } from 'rxjs';
import { NomineeService } from '../service/nominee.service';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nominee-list',
  templateUrl: './nominee-list.component.html',
  styleUrls: ['./nominee-list.component.scss']
})
export class NomineeListComponent implements OnInit {
  list:Nominee[]=[];
  nominee: Nominee[] = [];
  isEdit: boolean = false;
  model:any = new Nominee();
  NomineeForm: FormGroup;
  showSaveForm:string='';
  NomineeList: Observable<any[]>;
  relationList: Observable<any[]>;
  message='';
  backPreview = '';
  progress = 0;
  selectedFiles?: FileList;
  currentFile?: File;
  relationName: string; 
  relationId: number;
  CustomersAccountId: number;
  selectedIdentifier: string = '1' ;   
  Nid: string = '';
  BirthCertificateNo: string = '';
  Passport: string = '';
  ETin: string = '';
  totalShare: number = 0;
   
  constructor(
    private customerService: CustomerService,
    private modalService: NgbModal,
    private shared: SharedService,
    private router: Router,
    private changeDef:ChangeDetectorRef,
    private formBuilder: FormBuilder, 
    private nomineeSvc : NomineeService,
    private changeDetectorRef: ChangeDetectorRef
    )  {

      this.getNomineeList(); 
      this.buildForm();
       
     }

    ngOnInit(): void {
      this.getNomineeList();
      this.showSaveForm='saveList';
      this.LoadRelationList();
    }
    buildForm() {
      this.NomineeForm = this.formBuilder.group({
        Name: [''],
        DateOfBirth: [''],
        //SharingRate: [''],
        SharingRate: [null, [Validators.required, Validators.min(1), Validators.max(100)]],
        Nid: [''],
        BirthId: [''],
        relationId: [''],
        ETin: [''],
        Passport: [''],
        BirthCertificateNo: [''],
        CustomersAccountId : [''],
        CustomersAccount: [''], 
      });
    }
    onClickShowSaveForm(value:string){
      this.showSaveForm=value;
      this.refresh();
    }
    getNomineeList() {
      
      this.customerService.getNomineeList().subscribe(res => {
        this.nominee = res;
        this.changeDef.detectChanges();
      })
    }
    getNomineeIdentifier(value:string){
      this.selectedIdentifier= value
    }
   
     
    
    edit(item: any) {
       
      this.isEdit = true;
      this.showSaveForm = 'saveForm';
       
      this.model = {                       
        id: item.id, 
        Name: item.name,
        CustomersAccountId : item.customersAccountId,
        CustomersAccount: item.customeraccount,   
        DateOfBirth: item.dateOfBirth,
        Nid: item.nid,
        BirthCertificateNo: item.birthCertificateNo,
        Passport: item.Passport,
        ETin: item.ETin,
        SharingRate: item.sharingRate, 
        baseString : item.baseString, 
        relation:  item.relation,
        relationId : item.relationId
      };
     
      
    }
      
    
  
    onKeyPress(event: any) {
      const inputValue = event.key;
      const currentValue = this.model.get('sharingRate').value || 0;
      const newValue = currentValue * 10 + parseInt(inputValue);
  
      if (newValue > 100) {
        event.preventDefault();
      }
    }
     
   
    //relationList: any;
    LoadRelationList(){
      this.nomineeSvc.getRelationList().subscribe(res=>{
        this.relationList = res;
      });
      this.changeDetectorRef.detectChanges();
    }
  
    deleteNm(id:number){
      debugger;
      this.nomineeSvc.deleteNominee(id).subscribe(res=>{
        
        alert('Delete')
        this.shared.ShowAlert('Alert','Data deleted successfully.','warning');
        this.getNomineeList();
        this.showSaveForm='saveList';
      }) 
    }
    
    selectNidBackPic(event: any): void {
      this.message = '';
      this.backPreview = '';
      this.progress = 0;
      this.selectedFiles = event.target.files;
  
      if (this.selectedFiles) {
        const file: File | null = this.selectedFiles.item(0);
  
        if (file) {
          this.backPreview = '';
          this.currentFile = file;
  
          const reader = new FileReader();
  
          reader.onload = (e: any) => {
            //alert(e)
            this.backPreview = e.target.result;
            this.changeDetectorRef.detectChanges();
            const div = document.getElementById('imageBackPreview');
           // div.style.width = 90 + '%';
           // div.style.height = 90 + '%';
          };
          reader.readAsDataURL(this.currentFile);
        }
      }
    }

    onsubmit() {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to Add Nominee!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, forward it!"
      }).then((res) => {
        if (res.isConfirmed) {
      if(this.model.id===null){
        this.model.id=0;
      }
      //this.model.CustomersAccountId = this.NomineeForm.value.CustomersAccountId;
      this.model.relationId = this.NomineeForm.value.relationId;
      this.nomineeSvc.save(this.model).subscribe(res => {
      if (this.isEdit){
        this.shared.ShowAlert("Success", "Updated Successfully", "success");
      }
      else{
        this.shared.ShowAlert("Success", "Save Successfully", "success");
      }
        this.isEdit = false;
        this.showSaveForm = 'saveList';
        this.getNomineeList();
      })
     }
     else if (res.isDismissed) {
      this.router.navigate(['/customer/nominee-list']);
    }
  });
  
  } 

    
     refresh() {
      this.isEdit = false;
      this.model={
        id: null,
        Name: null,
        CustomersAccountId :null,
        CustomersAccount: null,   
        DateOfBirth: null,
        Nid: null,
        BirthCertificateNo: null,
        Passport: null,
        ETin: null,
        SharingRate: null, 
        baseString : null, 
        relation:  null,
        relationId : null
      }
    }

}
