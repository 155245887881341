
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { ExciseDutyService } from '../service/excise-duty.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';


@Component({
  selector: "app-excise-duty",
  templateUrl: "./excise-duty.component.html",
  styleUrls: ["./excise-duty.component.scss"],
})
export class ExciseDutyComponent implements OnInit {
  ExciseDutyDetailslist: any[] = [];
  ExciseDutyList: any[] = [];
  transactionlist: any[] = [];
  dlist: any = {};
  isEdit: boolean = false;
  frmGroup: FormGroup;
  TaxkeyId: any = 0;
  amount: number;
  taxPercent: number;
  type: number | null = 1;
  TaxDetailId: number;
  tblDetails: string;
  currentPage = 1;
  itemsPerPage = 5;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  dutyAmount: number;
  maxAmountRange: number;
  minAmountRange: number;
  taxes: {
    amount: number;
    taxPercent: number;
    type: number;
    TaxDetailId: number;
    dutyAmount: number;
    maxAmountRange: number;
    minAmountRange: number;
  }[] = [];
  editingIndex: number = -1;
  showSaveForm: string = "";
  productTypeId: number = 0;
  productBuilderTypes = ProductBuilderTypeEnum;
  constructor(
    private formBuilder: FormBuilder,
    private _service: ExciseDutyService,
    private shared: SharedService,
    private router: Router,
    private _productService: ProductBuilderService,
    private modalService: NgbModal
  ) {
    this.getAllExciseDutyList();
    this.getExciseDutyDetailsList();
    this.getTransactiontypeList();
  }

  ngOnInit(): void {
    this.populateForm();
    this.reset();
    this.showSaveForm = "saveList";
    this.getTransactiontypeList();
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getAllExciseDutyList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getAllExciseDutyList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getAllExciseDutyList();
  }

  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      exciseDutyCode: [""],
      exciseDutyName: [""],
      remarks: [""],
      status: [""],
      transectionTypeId: [""],
      productTypeId:[0]
    });
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.getCodeByType(2);
    this.reset();
  }

  getTransactiontypeList() {
    this._service.gettansactionList().subscribe((res) => {
      this.transactionlist = res;
    });
  }

  getExciseDutyList() {
    this._service.getExciseDutyList().subscribe((res) => {

      this.ExciseDutyList = res;
    });
  }

  getAllExciseDutyList() {
  
    this._service
      .getListWithSerchAndPagination(
        this.currentPage,
        this.itemsPerPage,
        this.searchControl
      )
      .subscribe((res: any) => {
        this.ExciseDutyList = [];
        res.list.forEach((sD) => {
          this.ExciseDutyList.push(sD);
        });
        this.totalItems = res.totalItems;
        this.currentItems = res.currentItems;
      });
  }

  addTax() {
    console.log(this.taxes)
    if (this.frmGroup.valid) {
      if (this.editingIndex === -1) {
        this.taxes.push({
          amount: this.amount,
          taxPercent: this.taxPercent,
          type: this.type,
          TaxDetailId: this.TaxDetailId,
          dutyAmount: this.dutyAmount,
          minAmountRange: this.minAmountRange,
          maxAmountRange: this.maxAmountRange,
        });
      } else {
        alert(this.editingIndex)
        this.taxes[this.editingIndex] = {
          amount: this.amount,
          taxPercent: this.taxPercent,
          type: this.type,
          TaxDetailId: this.TaxDetailId,
          dutyAmount: this.dutyAmount,
          minAmountRange: this.minAmountRange,
          maxAmountRange: this.maxAmountRange,
        };
        this.editingIndex = -1;
      }
      this.amount = null;
      this.taxPercent = null;
      this.type = null;
      this.dutyAmount = null;
      this.minAmountRange = null;
      this.maxAmountRange = null;
    }
  }

  editTax(index: number) {
    this.editingIndex = index;
    this.amount = this.taxes[index].amount;
    this.taxPercent = this.taxes[index].taxPercent;
    this.type = this.taxes[index].type;
    this.TaxDetailId = this.taxes[index].TaxDetailId;
    this.dutyAmount = this.taxes[index].dutyAmount;
    this.minAmountRange = this.taxes[index].minAmountRange;
    this.maxAmountRange = this.taxes[index].maxAmountRange;
  }

  deleteTax(index: number) {
    this.taxes.splice(index, 1);
    if (index === this.editingIndex) {
      this.editingIndex = -1;
      this.amount = null;
      this.taxPercent = null;
      this.type = null;
      this.dutyAmount = null;
      this.minAmountRange = null;
      this.maxAmountRange = null;
    }
  }

  prepareDataForAPI() {
    const formData = this.frmGroup.value;
    formData.exciseDutyDetails = this.taxes;
    if (formData.status === "1") {
      formData.status = true;
    } else {
      formData.status = false;
    }
    return formData;
  }
  onsubmit() {
    if (this.frmGroup.valid) {
      const keydetails = this.prepareDataForAPI();
      this._service.saveExciseDuty(keydetails).subscribe(
        (res: any) => {
          if (res === null) {
            this.shared.ShowAlert(
              "Error",
              "No Information Given for Details.",
              "error"
            );
          } else {
            this.shared.ShowAlert("Success", "Save Successfully", "success");
            this.getAllExciseDutyList();
            this.isEdit = false;
            this.showSaveForm = "saveList";
          }
        },
        (error) => {
          this.shared.ShowAlert("Error", "Failed to save data.", "error");
        }
      );
    }
  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.taxes = [];
  }

  edit(item: any) {
    console.log(item);
    const statusString = item.exciseDuty.status === false ? "0" : "1";
    this.isEdit = true;
    this.TaxkeyId = item.exciseDuty.id;
    this.showSaveForm = "saveForm";
    this.frmGroup.patchValue({
      id: item.exciseDuty.id,
      exciseDutyName: item.exciseDuty.exciseDutyName,
      exciseDutyCode: item.exciseDuty.exciseDutyCode,
      remarks: item.exciseDuty.remarks,
      transectionTypeId: item.exciseDuty.transectionTypeId,
      status: statusString,
    });
    this.Updatedetails(this.TaxkeyId);
  }

  Updatedetails(id: number) {
    this.isEdit = true;
    this._service.GetDetailsByMasterId(id).subscribe((res) => {
      this.dlist = res;
      this.taxes = [];
      this.dlist.forEach((item: any) => {
        this.taxes.push({
          amount: item.amount,
          taxPercent: item.taxPercent,
          type: item.type,
          TaxDetailId: item.id,
          dutyAmount: item.dutyAmount,
          minAmountRange: item.minAmountRange,
          maxAmountRange: item.maxAmountRange,
        });
      });
    });
  }

  getCodeByType(type: number) {
    this._productService
      .getProductMasterDateCodeByType(type)
      .subscribe((res) => {
        this.frmGroup.patchValue({
          exciseDutyCode: res["code"],
        });
      });
  }

  delete(id: any) {
    this._service.delete(id).subscribe((res) => {
      this.shared.ShowAlert("Alert", "Data deleted successfully.", "warning");
      this.getAllExciseDutyList();
    });
  }

  getExciseDutyDetailsList() {
    this._service.getExciseDutyDetailsList().subscribe((res) => {
      this.ExciseDutyDetailslist = res;
    });
  }
}
