import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl = environment.baseUrl+'/api/LoanLimit/';

@Injectable({
  providedIn: 'root'
})
export class LoanLimitService {

  constructor(private http: HttpClient) { }

  
  getLoanLimitList(): Observable<any> {
    return this.http.get(apiUrl + 'GetAllLoanLimtList');
   }

   getLoanLimitDetailsById(limitId:number): Observable<any>{
    return this.http.get(apiUrl + 'GetLoanLimtDetailsByLimitId/'+limitId);
   }

   saveLoanLimit(model: any){
    return this.http.post(apiUrl +'SaveLoanLimit' , model);
  }

  getLoanLimitInfoByProductId(id): Observable<any>{
    return this.http.get(apiUrl + 'GetLoanLimtDetailsByProductId/' + id);
   }

}
