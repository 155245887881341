<div class="card">

    <form [formGroup]="frmGroupTerms">
        <div class="card-body mt-3">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="sortorder" class="col-sm-4 col-form-label">Loan Product</label>
                  <div class="col-sm-8">
                    <select class="form-control" [(ngModel)]="selectedProductId" name="selectedProductId">
                      <option value="">Select</option>
                      <option *ngFor="let item of productList" [value]="item.id">
                        {{ item.name }} - {{ item.code }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">Expire  Date</label>
                  <div class="col-sm-8">
                      <input type="date" class="form-control" [(ngModel)]="expireDate" name="expireDate" placeholder="Select From Date">
                  </div>
                </div>
              </div>
              
              
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label for="" class="col-sm-2 col-form-label">Conditions</label>
                  <div class="col-sm-10">
                      <textarea type="text" col="3" class="form-control" [(ngModel)]="conditions" name="conditions"></textarea>
                     
                  </div>
                </div>
              </div>
             
              
              <div class="col-md-12">
              <button class="btn " 
              style="background-color: #616D89;color:white;font-weight: bold;float: right;" (click)="onSubmit()">Save</button>
            </div>
            </div>
          
          </div>
    </form>
  
   
  </div>
  
