import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { UserRoutingModule } from './user-routing.module';
import { UserListComponent } from './user-list/user-list.component';
import { RoleComponent } from './user-management/user-component/role/role.component';
import { UserRoleAssignComponent } from './user-management/user-component/user-role-assign/user-role-assign.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from 'app/app.module';
import { HttpClient } from '@microsoft/signalr';
import { NgxPaginationModule } from 'ngx-pagination';
import { RoleCreateComponent } from './user-management/user-component/role-create/role-create.component';


@NgModule({
  declarations: [
    UserListComponent,
    RoleComponent,
    UserRoleAssignComponent,
    RoleCreateComponent,
   

  ],
  imports: [
    CommonModule,FormsModule,UserRoutingModule,ReactiveFormsModule, NgxPaginationModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ]
})
export class UserModule { }
