import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { TransectionTypeService } from '../service/transection-type.service';

@Component({
  selector: 'app-transection-type',
  templateUrl: './transection-type.component.html',
  styleUrls: ['./transection-type.component.scss']
})
export class TransectionTypeComponent implements OnInit {

  trnsTypeList: any[] = [];
  isEdit: boolean = false;
  trnsTypeFromGrp: FormGroup;
  trnsTypeModel: any = {};
  
  currentPage = 1;
  itemsPerPage = 5;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";

  constructor(private formBuilder: FormBuilder,
    private shared: SharedService,
    private _trnsTypeService: TransectionTypeService,

  ) { }



  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getTransactionList();
  }
  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getTransactionList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getTransactionList();
  }
  ngOnInit(): void {
    this.trnsTypeForm();
    this.getTransactionList();
    this.showSaveForm = 'saveList';
    //this.getTrnsTypeList();
  }

  showSaveForm: string = '';

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();
  }

  trnsTypeForm() {
    this.trnsTypeFromGrp = this.formBuilder.group({
      id: [0],
      typeName: [''],
      details: [''],
      status: ['']
    })
  }

  refresh() {
    this.isEdit = false;
    this.trnsTypeForm();
    this.trnsTypeModel = {};
  }

  getTrnsTypeList() {
    this._trnsTypeService.getTransectionTypeList().subscribe(res => {
      console.log(res);
      this.trnsTypeList = res;
    })
  }
  getTransactionList() {
    this._trnsTypeService.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.trnsTypeList = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }
  onSubmit() {
    if (this.isEdit) {
      this.trnsTypeModel.id = this.trnsTypeFromGrp.value.id;
    }
    this.trnsTypeModel.typeName = this.trnsTypeFromGrp.value.typeName;
    this.trnsTypeModel.details = this.trnsTypeFromGrp.value.details;
    this.trnsTypeModel.status = (this.trnsTypeFromGrp.value.status === "true") ? true : false;

    //console.log(this.accuredModel)
    this._trnsTypeService.saveTransectionType(this.trnsTypeModel).subscribe(res => {
      //alert('Save successfully');
      this.shared.ShowAlert("Success", "Save Successfully", "success");
      this.getTransactionList();
      this.refresh();
      this.showSaveForm = 'saveList';
    })
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    //alert(item.id)
    this.trnsTypeFromGrp.patchValue({
      id: item.id,
      typeName: item.typeName,
      details: item.details,
      status: item.status
    })
  }

  delete(id: any) {
    this._trnsTypeService.deleteTransectionType(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getTransactionList();
      this.showSaveForm = 'saveList';
    })
  }

}
