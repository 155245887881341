import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { AccountingreportService } from '../accountingreport.service';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { Observable } from 'rxjs';
import { Location } from "@angular/common";
@Component({
  selector: "app-chart-of-account",
  templateUrl: "./chart-of-account.component.html",
  styleUrls: ["./chart-of-account.component.scss"],
})
export class ChartOfAccountComponent implements OnInit {
  Sbulist: any = [];
  projectlist: any = [];
  showList: string = "subGroupList";
  subGroupList: any[] = [];

  currentPage = 1;
  itemsPerPage = 10;
  currentItems = 0;
  totalItems = 0;
  searchControl = "";
  subGroupTotalItems = 0;
  subGroupCurrentItems = 0;
  accountNatureViewModels: any[];
  accountsGroupViewModels: any[];
  accountsLedgerViewModels: any[];
  constructor(
    private pdfService: PdfService,
    private _ledgerService: LedgerService,
    private accountingreportService: AccountingreportService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.showList = "subGroupList";
    this.GetSubGroupList();
    this.ChartOfAccountData();
    // this.OpenPDF()
  }

  goBack() {
    this.location.back();
  }
  OpenPDF() {
    this.pdfService.chartofAccountspdf(1, 2).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  GetSubGroupList() {
    this._ledgerService.getSubGrpLedgerList().subscribe((res: any) => {
      this.subGroupList = res;
    });
  }
  ChartOfAccountData() {
    // Modify parameters as needed
    this._ledgerService.GetCoaTree(/* sbuId, projectId */).subscribe((data) => {
      console.log(data);
      this.accountNatureViewModels = data.accountNatureViewModels;
      this.accountsGroupViewModels = data.accountsGroupViewModels;
      this.accountsLedgerViewModels = data.accountsLedgerViewModels;
    });
  }

  filteredAccountsGroupByNId(nId: number): any[] {
    return this.accountsGroupViewModels
      .filter((g) => g.natureId === nId && g.parentGroupId === null)
      .sort((a, b) => a.groupCode - b.groupCode);
  }

  filteredSubAccountsGroupByPGId(parentGroupId: number): any[] {
    return this.accountsGroupViewModels
      .filter((g) => g.parentGroupId === parentGroupId)
      .sort((a, b) => a.groupCode - b.groupCode);
  }

  filteredLedgerByGId(gId: number): any[] {
    return this.accountsLedgerViewModels
      .filter((l) => l.groupId === gId)
      .sort((a, b) => a.ledgerCode - b.ledgerCode);
  }
  toggleCollapse(item: any): void {
    item.collapsed = !item.collapsed;
  }
}
