<div class="card" style="width: 100%;">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Interest Calculation Type List</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-info"
          
          style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;"> <a routerLink="/products/interest-calculation-type" style="color: white;font-weight: 700;">New Entry</a></button>
           
        
          <hr>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control"
              style="width:16%">
              <option value="2">2</option>
              <option value="3">3</option>
              <option selected value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="form-group col-md-6" style="text-align: right">
            <input type="text" class="form-control" [(ngModel)]="searchControl" placeholder="Search"
              (keyup)="onSearch()">
          </div>
        </div>
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th style="width: 10%;">SL No.</th>
              <th>Interest Calculation Name</th>
              <th>Detail </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
              <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
              <td>{{item.instCalType.code}} - {{item.instCalType.name}}
              </td>
              <td>
                <ul>
                  <li *ngFor="let detail of item.instCalTypeDetail">{{detail.amount}} - {{detail.taxPercent}} %
                    {{detail.type == '1' ? 'Up to' : 'Extra to'}}</li>
                </ul>
              </td>
              <td>
                <button class="btn btn-primary" (click)="edit(item)">
                  <i class="fa fa-edit 3x"></i>
                </button>
                <button class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.taxKey.id)">
                  <i class="fa fa-trash-o 3x"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <span>Showing {{currentItems}} of {{totalItems}} entries</span>
          </div>
          <div class="col-md-6" style="text-align: right">
            <pagination-controls (pageChange)="onPageChange($event)">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>





 