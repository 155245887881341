import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';
import { SharedService } from 'app/services/shared.service';
import Swal from 'sweetalert2';
import { Location } from "@angular/common";
@Component({
  selector: "app-clcalculation-info",
  templateUrl: "./clcalculation-info.component.html",
  styleUrls: ["./clcalculation-info.component.scss"],
})
export class ClcalculationInfoComponent implements OnInit {
  List: any[] = [];
  constructor(
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
   private location: Location,
    private shared: SharedService
  ) {}

  ngOnInit(): void {
    this.getClList();
  }

  getClList() {
    this._serviceDashboard.getClProcessListByClTypeWise().subscribe((res) => {
      this.List = res;
      this.changeDetectorRef.detectChanges();
    });
  }
  goBack() {
    this.location.back();
  }
}
