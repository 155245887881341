<div class="card" style="width: 100%;">
  <div class="card-body">
    <div asp-validation-summary="All" class="text-danger"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group row">
          <div class="col-sm-2">
            <h5>Loan Code:</h5>
          </div>
          <div class="col-sm-4">
            <h5 class="card-title" style="color: black">{{ leadCode }}</h5>
          </div>
          <div class="col-sm-6">



            <button class="btn btn-secondary pull-right ml-2" title="Go Back" (click)="goBack()">
              <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
            <button class="btn btn-primary" style="float: right;" (click)="RejectLoanByID()">Reject Loan
              </button>
            <button class="btn   mr-2" style="background-color: #c7ccd8;color:black;float: right;"
              (click)="loanDisburse()">Disburse
              Loan</button>

            <!-- <button class="btn" 
              style="background-color: #616D89; float: right;color:white;" 
              [disabled]="!loanRegisterId" 
              (click)="forwardto(leadCode)">
             Submit
             </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="card">
  <div class="card-body">
    <div class="">
      <h5 class="card-title" style="color: black">Customer Details</h5>
      <hr>
      <form [formGroup]="frmGroup">
        <div asp-validation-summary="All" class="text-danger"></div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label for="NameEnglish" class="col-sm-4 col-form-label">Customer Code</label>
              <div class="col-sm-8">
                <input type="hidden" class="form-control" formControlName="id">
                <input type="hidden" class="form-control" formControlName="leadId">
                <input type="text" class="form-control uppercase-input" readonly formControlName="customerRCode"
                  placeholder="customerRCode">
              </div>
            </div>

          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="NameBangla" class="col-sm-4 col-form-label">Customer Name</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" readonly formControlName="customerNameEng"
                  placeholder="customer Name">
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="NameBangla" class="col-sm-4 col-form-label">Customer Name Ban</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" [readonly]="isAuto" formControlName="customerNameBan" placeholder="customer Name Eng">
                </div>
              </div>
            </div> -->
          <div class="col-md-6">
            <div class="form-group row">
              <label for="dateOfBirth" class="col-sm-4 col-form-label">Date Of Birth</label>
              <div class="col-sm-8">
                <input type="text" class="form-control datepicker" readonly formControlName="dateOfBirth"
                  placeholder="dd/mm/yyyy">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="gender" class="col-sm-4 col-form-label">Gender</label>
              <div class="col-sm-8">


                <select class="form-control" formControlName="GenderId" readonly placeholder="Gender"
                  (change)="onGenderStatusChange($event.target.value)">
                  <option value="select">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="mobileNoOffice" class="col-sm-4 col-form-label">Mobile No.</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="mobileNo" placeholder="" readonly>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="mobileNoHome" class="col-sm-4 col-form-label">Email</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" readonly formControlName="email" placeholder="">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="Nationality" class="col-sm-4 col-form-label">NID No</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="nidNo" placeholder="" readonly>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="Religion" class="col-sm-4 col-form-label">Father Name</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="fatherNameEng" placeholder="" readonly>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Father Name Ban</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="fatherNameBan" placeholder="">
                </div>
              </div>
            </div> -->
          <div class="col-md-6">
            <div class="form-group row">
              <label for="Religion" class="col-sm-4 col-form-label">Mother Name</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="motherNameEng" placeholder="" readonly>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">motherNameBan</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="motherNameBan" placeholder="">
                </div>
              </div>
            </div> -->
          <div class="col-md-6">
            <div class="form-group row">
              <label for="Religion" class="col-sm-4 col-form-label">Marital Status</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="maritalStatus" placeholder="" readonly>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="Religion" class="col-sm-4 col-form-label">Spouse Name</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="spouseNameEng" placeholder="" readonly>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Spouse Name Ban</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="spouseNameBan" placeholder="">
                </div>
              </div>
            </div> -->
          <div class="col-md-6">
            <div class="form-group row">
              <label for="Religion" class="col-sm-4 col-form-label">Spouse Mobile No</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="spouseMobileNo" placeholder="" readonly>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">District Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="districtId" placeholder="">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Upazila Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="upazilaId" placeholder="">
                </div>
              </div>
            </div> -->
          <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">yearly Income</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="fatherNameEng" placeholder="">
                </div>
              </div>
            </div> -->

        </div>
        <!-- <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit"
            (click)="SavePersonalInfo()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
            <span>Save</span>
          </button> -->
      </form>
    </div>
  </div>
</div>


<div class="card">
  <div class="card-body">
    <div class="">


      <h3 class="card-title" style="color: black;font-size:15px">Product Info</h3>
      <hr>
      <div class="row">
        <!-- <div class="col-md-6">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Loan Code</label>
            <div class="col-sm-8">
              <input class="form-control" value="{{productDetails?.productInfo?.code}}" readonly>
            </div>

          </div>

        </div> -->
        <div class="col-md-6">
          <div class="form-group row">
            <label for="roleName" class="col-sm-4 col-form-label">Loan Type</label>
            <div class="col-sm-8">
              <input class="form-control" value="{{productDetails?.productInfo?.loanType?.typeName}}" readonly>
            </div>

          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group row">
            <label for="" class="col-sm-4 col-form-label">Loan Name</label>
            <div class="col-sm-8">
              <input class="form-control" value="{{ productDetails?.productInfo?.name }}" readonly>
            </div>

          </div>
        </div>
        <!-- <div class="col-md-6">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Version</label>
            <div class="col-sm-8">
              <input class="form-control" value="{{productDetails?.productInfo?.version }}" readonly>
            </div>

          </div>
        </div> -->

        <!-- <div class="col-md-6">
          <div class="form-group row">
            <label for="roleName" class="col-sm-4 col-form-label">Person Amount</label>
            <div class="col-sm-8">
              <input class="form-control" value="{{loanLimitDetails?.personAmount}}" readonly>
            </div>

          </div>
        </div> -->
        <!-- <div class="col-md-6">
          <div class="form-group row">
            <label for="loanDuration" class="col-sm-4 col-form-label">Loan Duration</label>
            <div class="col-sm-8">
              <input class="form-control" value="{{productDetails?.productInfo?.loanDuration?.monthName}}"
                (keyup)="calculateInstallmentSize()" readonly>
            </div>

          </div>
        </div> -->
      </div>

      <form [formGroup]="frmGroupLoanReg">





        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">


              <label for="interestRate" class="col-sm-4 col-form-label">Interest Rate</label>
              <div class="col-sm-8">

                <input class="form-control" value="{{productDetails?.productInfo?.interestRate}}"
                  formControlName="interestRate" readonly>
              </div>



            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="roleName" class="col-sm-4 col-form-label">Loan Amount</label>
              <div class="col-sm-8">
                <input type="number" class="form-control" readonly formControlName="loanAmount" [(ngModel)]="loanAmount"
                  placeholder="Type here....">
                <!-- <div *ngIf="!isLoanAmountValid()" style="color:red;">Loan amount cannot exceed product amount.</div> -->
              </div>
            </div>
          </div>

          <!-- <div class="col-md-6">
            <div class="form-group row">
              <label for="roleName" class="col-sm-4 col-form-label">Frequency<span
                  style="color:red; font-size: 15px; ">*</span></label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="frequency" placeholder="Type here...."
                  (keyup)="getFrequencyValue($event)" (keyup)="calculateInstallmentSize()">
              </div>
            </div>
          </div> -->

          <div class="col-md-6">
            <div class="form-group row">
              <label for="roleName" class="col-sm-4 col-form-label">Installment Size</label>
              <div class="col-sm-8">
                <input type="number" class="form-control" formControlName="installmentSize" value="{{installmentSize}}"
                  readonly>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-6">
            <div class="form-group row">
              <label for="roleName" class="col-sm-4 col-form-label">Loan Nature<span
                  style="color:red; font-size: 15px; ">*</span></label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="loanNature" placeholder="Type here....">
              </div>
            </div>
          </div> -->


          <!-- <div class="col-md-6">
            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Disburse Linking Account</label>
              <div class="col-sm-8">
                <select class="form-control" formControlName="customerAccountId">
                  <option value="0">Select</option>
                  <option *ngFor="let item of customerAccountList" [value]="item.id">{{item?.customerAccountNo}}
                  </option>
                </select>

              </div>
            </div>
          </div> -->


        </div>


      </form>
    </div>
  </div>
</div>