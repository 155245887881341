<section>
  <div class="card" style="width: 100%;">
    <div class="card-header row">
      <div class="col-10">
        <h4 class="card-title" style="color: black">Account Statement </h4>
      </div>
    
      <div class="col-2">
        <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
    
          <i class="icon-arrow-left" style="font-size: 17px;"> </i>
        </button>
      </div>
    </div>
    
    
    <hr>
    <div class="card-body">
      <div class="container">

        

        <div class="form-group row" *ngIf="SearchAccNo">
          <div class="col-4" *ngIf="userTypeId==3">
            <label>Account No</label>

            <div class="col-12" style="margin-left: -16px;">
              <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="accountNo" placeholder="Type Account No..."
                  (keyup.enter)="loadUserDataByAccountNoAndDate()">

              </div>
            </div>
          </div>
          <div class="col-4" *ngIf="userTypeId==1">
            <label> Account No</label>

            <div class="col-12" style="margin-left: -16px;">
              <div class="input-group">
                <select class="form-control" [(ngModel)]="accountNo" (keyup.enter)="loadUserDataByAccountNoAndDate()">
                  <option value="">Select</option>
                  <ng-container *ngFor="let item of AccountNoList">
                    <option [value]="item.customerAccount?.customerAccountNo">
                      {{item.customerAccount?.customerAccountNo}} - {{item.customerAccount?.accountType}}</option>
                  </ng-container>
                </select>

              </div>
            </div>
          </div>


          <div class="col-4">
            <label>From Date</label>
            <div class="col-12" style="margin-left: -16px;">

              <div class="input-group">
                <input type="date" class="form-control" [(ngModel)]="startDate" placeholder="Select From Date">

              </div>
            </div>
          </div>

          <div class="col-4">
            <label>To Date</label>
            <div class="col-12" style="margin-left: -16px;">

              <div class="input-group">
                <input type="date" class="form-control" [(ngModel)]="EndDate" placeholder="Select To Date">
              </div>

            </div>
          </div>

          <div class="col-6" *ngIf="generateAccNo">
            <label class="col-sm-4 mt-3"></label>

            <div class="col-8">

              <button class="btn btn-primary" (click)="loadUserDataByAccountNoAndDateStatement()">Generate</button>
              <button *ngIf="pdfView" class="btn btn-success" style="margin-left: 5px;"
                (click)="OpenPDF()">Print</button>

            </div>
          </div>

          <!-- <div class="col-6">
            <label class="col-sm-4 mt-3"></label>
          
            <div class="col-8">
          
              <button class="btn btn-primary" (click)="loadUserDataByAccountNoAndDate()">Generate</button>
              <button *ngIf="pdfView" class="btn btn-success" style="margin-left: 5px;" (click)="OpenPDF()">Print</button>
          
            </div>
          </div> -->


        </div>
        <hr>

        <div *ngIf="noDataAvailable">

          <p style="text-align: center;font-size: 25px;font-weight: 700;">No data available</p>
        </div>
        <div *ngIf="!noDataAvailable">



          <!-- <div class="customer-info" style="color: black;font-size: 20px;" *ngIf="accountNo !== null">

            <span>Account Info:</span> {{transactionList.length > 0 ? transactionList[0].customerAccountNo + ' - ' +
            transactionList[0].customerName : ''}}

          </div> -->
          <br>
          <div class="table-container" style="max-height: 800px; overflow-y: auto;">

            <!-- Assuming this template is part of your Angular component's HTML file -->

            <div class="row" style="font-size:16px;padding-left:50px;">
              <table>
                <tr>
                  <td>
                    Name <span style="padding-left: 62px;">: {{ bsicAccountInfo?.customerName }}</span> <br />
                    Customer Id <span style="padding-left: 12px;">: {{ bsicAccountInfo?.customerId }}</span> <br />
                    Address <span style="padding-left: 47px;">: {{ bsicAccountInfo?.address }}</span> <br />
                    City <span style="padding-left: 81px;">: {{ bsicAccountInfo?.city }}</span> <br />
                    Phone <span style="padding-left: 59px;">: {{ bsicAccountInfo?.phone }}</span> <br />
                    Period <span style="padding-left: 60px;">: {{ startDate | date: 'dd-MMM-yyyy' }} to {{ EndDate |
                      date:
                      'dd-MMM-yyyy' }}</span> <br />

                  </td>
                  <td style="padding-left:117px;">
                    A/C No <span style="padding-left: 104px;">: {{ bsicAccountInfo?.accNo }}</span> <br />
                    A/C Type <span style="padding-left: 89px;">: {{ bsicAccountInfo?.acType }}</span> <br />
                    A/C Open Date <span style="padding-left: 40px;">: {{ bsicAccountInfo?.accOpenDate | date:
                      'dd-MMM-yyyy'
                      }}</span> <br />
                    A/C Status <span style="padding-left: 77px;">: {{ bsicAccountInfo?.acStatus }}</span>
                    <br />


                    <ng-container *ngIf="bsicAccountInfo?.acType === 'Loan'; else elseBlock">
                      <span>Loan Amount</span>
                      <span style="padding-left: 55px;">: {{ bsicAccountInfo?.balance }}</span> <br />

                      <span>Outstanding</span><span style="padding-left: 64px;">: {{ lastBalance}}</span> <br />
                    </ng-container>
                    <ng-template #elseBlock>
                      <span>Open Balance</span>
                      <span style="padding-left: 52px;">: {{ bsicAccountInfo?.balance }}</span> <br />
                      <span>Current Balance</span><span style="padding-left: 35px;">: {{ lastBalance}}</span> <br />
                    </ng-template>

                  </td>
                </tr>
              </table>

              <div class="col-sm-2" style="float:left"></div>
              <div class="col-sm-10"></div>

            </div>

            <br />

            <table class="table" style="font-size:16px;color:#888888">
              <thead style="border-bottom:groove;">
                <tr>
                  <th>Date</th>
                  <th>Narration</th>
                  <th>Transaction No</th>
                  <th class="text-right">Debit</th>
                  <th class="text-right">Credit</th>
                  <th class="text-right">Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of transactionList">
                  <td>{{ item.transactioN_DATE | date: 'dd-MM-yyyy' }}</td>
                  <td>{{ item.remarks }}</td>
                  <td>{{ item.transactionType }}</td>
                  <td class="text-right">
                    <span *ngIf="item.debit > 0">{{ item.debit }}</span>
                  </td>
                  <td class="text-right">
                    <span *ngIf="item.credit > 0">{{ item.credit }}</span>
                  </td>
                  <td class="text-right">{{ item.balance }}</td>
                </tr>
              </tbody>
            </table>


            <!-- <table id="example" class="table table-striped table-bordered scrollable-table"
              style="width: 100% !important;">
              <thead>
                <tr>
                  <th>SL No.</th>
                  <th>Account Info </th>
                   <th>Transaction No </th>
                  <th>Transaction Date </th>
                  <th>Transaction Type</th>
                  <th>Currency</th>
                  <th>Amount</th>
                  <th>Balance</th>

                  <th>Receiver</th>
                  <th>Sender</th>


            
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of transactionList; let i = index;">
                  <td>{{i + 1}}</td>

                  <td>{{item.customerAccountNo}} - {{item.customerName}}
              </td>
                  <td>{{item.transactionNo}}</td>
                  <td>{{formatDate(item.transactionDate)}}</td>
                  
                  <td>
                    {{item.transactionType===0 ? 'Account':
                    item.transactionType===1?'Payment':item.transactionType===2?'Add
                    Money':item.transactionType===3?'Fund Transfer':''}}
                  

                  </td>
                  <td>{{item.currency}}</td>
                  <td>{{item.transactionType===1?'-':item.transactionType===2?'+':item.transactionType===3?'-':''}}
                    {{item.amount | number}}</td>
                  <td>{{item.balance | number}}</td>

                  <td>
                    {{item.receiverAccountNo}}
                    {{item.receiverName}}

                  </td>
                  <td>
                    {{item.senderAccountNo}}
                    {{item.senderName}}

                  </td>


                  <td>
                    <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit"></i></button>
                    <button [disabled]="isEdit" class="btn btn-danger"  (click)="delete(item.Id)"><i class="fa fa-trash-o "></i></button>
                 
                </td>
                </tr>
              </tbody>
            </table> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>