import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
@Component({
  selector: "app-whitelist-entry",
  templateUrl: "./whitelist-entry.component.html",
  styleUrls: ["./whitelist-entry.component.scss"],
})
export class WhitelistEntryComponent implements OnInit {
  constructor(private location: Location) {}

  ngOnInit(): void {}
  goBack() {
    this.location.back();
  }
}
