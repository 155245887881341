import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { PaymentCancelledComponent } from './payment-cancelled/payment-cancelled.component';
import { TransactionLogListComponent } from './transaction-log-list/transaction-log-list.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    PaymentSuccessComponent,
    PaymentFailedComponent,
    PaymentCancelledComponent,
    TransactionLogListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PaymentRoutingModule
  ]
})
export class PaymentModule { }
