
<div class="card">
    <div class="card-body">
      <div class="">
        <!-- <h5 class="card-title" style="color: rgb(78, 77, 77);">Customer Details</h5>
        <hr> -->
        <form [formGroup]="frmGroup">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="NameEnglish" class="col-sm-4 col-form-label">Customer Code</label>
                <div class="col-sm-8">
                  <input type="hidden" class="form-control" formControlName="id">
                 
                  <input type="text" class="form-control uppercase-input"  formControlName="customerRCode" placeholder="Customer Code" readonly>
                </div>
              </div>
  
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="NameBangla" class="col-sm-4 col-form-label">Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control"  formControlName="customerNameEng" placeholder="Customer Name" readonly>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="NameBangla" class="col-sm-4 col-form-label">Name Bangla</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control"  formControlName="customerNameBan" placeholder="Name Bangla">
                </div>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="form-group row">
                <label for="dateOfBirth" class="col-sm-4 col-form-label">Date of Birth</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control"  formControlName="DateOfBirth" readonly>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="gender" class="col-sm-4 col-form-label">Gender</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control"  formControlName="GenderName" readonly>
                  <input type="hidden" class="form-control"  formControlName="GenderId" readonly>
                  <!-- <input type="hidden" name="id" formControlName="id">
                  <select (change)="onGenderStatusChange($event.target.value)" class="form-control"formControlName="GenderId">
                    <option value="">Select</option>
                    <ng-container *ngFor="let item of GenderList">
                      <option [value]="item.id">{{ item.name }}</option>
                    </ng-container>
                  </select> -->
                </div>
              </div>

              <!-- <div class="form-group row">
                <label for="gender" class="col-sm-4 col-form-label">Gender</label>
                <div class="col-sm-8">
                  
                
                  <select class="form-control" formControlName="GenderId" placeholder="Gender" (change)="onGenderStatusChange($event.target.value)">
                    <option value="select">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div> -->
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Father Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="fatherNameEng" placeholder="" readonly>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Mother Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="motherNameEng" placeholder="" readonly>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Nationality" class="col-sm-4 col-form-label">NID No</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="nidNo" placeholder="" readonly>
                </div>
              </div>
            </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <label for="tin" class="col-sm-4 col-form-label">TIN No</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="tin" placeholder="" >
                  </div>
                </div>
              </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="mobileNoOffice" class="col-sm-4 col-form-label">Mobile (Office)</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="mobileNo" placeholder="" readonly >
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="mobileNoHome" class="col-sm-4 col-form-label">Email</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control"  formControlName="email" placeholder="" readonly>
                </div>
              </div>
            </div>
            
             
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Father Name (Bangla)</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="fatherNameBan" placeholder="">
                </div>
              </div>
            </div> -->
            
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Mother Name (Bangla)</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="motherNameBan" placeholder="">
                </div>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Marital Status</label>
                <div class="col-sm-8">

                  <select class="form-control" formControlName="maritalStatus" [(ngModel)]="selectedMaritalStatus" (change)="getmaritalStatus($event.target.value)" >
                    <option value="0">Select</option>
                    <option value="Married">Married</option>
                    <option value="Unmarried">Unmarried</option>
                  </select>
                  <!-- <input type="text" class="form-control" formControlName="maritalStatus" placeholder=""> -->
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row" *ngIf="selectedMaritalStatus === 'Married'">
                <label for="Religion" class="col-sm-4 col-form-label">Spouse Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="spouseNameEng" placeholder="">
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Spouse Name (Bangla)</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="spouseNameBan" placeholder="">
                </div>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="form-group row" *ngIf="selectedMaritalStatus === 'Married'">
                <label for="Religion" class="col-sm-4 col-form-label">Spouse Mobile No</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="spouseMobileNo" placeholder="">
                </div>
              </div>
            </div>
            
          
          </div>
          <button data-toggle="tooltip" title="Save" type="submit"
            (click)="SavePersonalInfo()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
            <span>Update</span>
          </button>
        </form>
      </div>
    </div>
  </div>

