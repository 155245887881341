<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="card" style="width: 100%;" >
                <div class="card-body">
                    <div class="container">
                        <h5 class="card-title" style="color: black">Customer Account List</h5>
                        <!-- <button type="button" data-toggle="tooltip" title="Add new"  class="btn btn-secondary"  style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button> -->
                        <hr>
                        <table id="example" class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SL No</th>
                                    <th>Account Title</th>
                                    <th>Account Type</th>
                                    <th>Customer AccountNo</th>
                                    <th>Opening Balance</th>
                                    <th>Monthly Income</th>
                                    <!-- <th>Action</th> -->
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of list; let i = index">
                                <tr>
                                    <td>{{i + 1}}</td>
                                    <td>{{item.accountTilte}}</td>
                                    <td>{{item.accountType}}</td>
                                    <td>{{item.customerAccountNo}}</td>
                                    <td>{{item.openingBalance}}</td>
                                    <td>{{item.monthlyIncome}}</td>
                                    <!-- <td>
                                        <button class="btn btn-primary" (click)="edit(item.id)"><i class="fa fa-edit 3x"></i></button>
                                        <button class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>