import { ChangeDetectorRef, Component, OnInit } from "@angular/core";

import { DatePipe,Location } from "@angular/common";
import Swal from "sweetalert2";
//import { LayoutService } from "app/shared/services/layout.service";
//import { date } from "ngx-custom-validators/src/app/date/validator";
//import { PdfService } from "app/pdf-reporting/pdf.service";
import { CustomerService } from "../service/customer.service";
import swal from "sweetalert";
import { LoanProductService } from "app/product/service/loan-product.service";
import { GuarantordetailsService } from "app/loan-application/guarantordetails.service";
import { Guarantordetails } from "app/loan-application/model/guarantordetails";

@Component({
  selector: "app-customer-inquery",
  templateUrl: "./customer-inquery.component.html",
  styleUrls: ["./customer-inquery.component.scss"],
})
export class CustomerInqueryComponent implements OnInit {
  customername: string = "";
  mobileNumber: string = "";
  accountNo: string = "";
  genderId: number = 0;
  guarantorId: number = 0;
  guarantordNid: string = "";
  guarantorMobileNo: string = "";

  nidNo: string = "";
  typeId: number = 0;
  customerList: any[] = [];
  loanList: any[] = [];
  GuarantorList: any[] = [];
  transactionList: any[] = [];
  genderList: any;
  filterList: any;

  // ---------------------------------------

  sBirthDate: Date;
  eBirthDate: Date;

  sCreateDate: Date;
  eCreateDate: Date;

  sModifiedDate: Date;
  eModifiedDate: Date;

  sDisburseDate: Date;
  eDisburseDate: Date;

  sApplyDate: Date;
  eApplyDate: Date;

  fromDate: Date;
  toDate: Date;
  //--------------------------------------------
  OtherInfoTable: boolean = true;
  LoanInfoTable: boolean = false;
  GuarantorInfoTable: boolean = false;
  TransactionInfoTable: boolean = false;

  //loanSearch: boolean = false;
  otherSearch: boolean = true;
  parameterSearch: boolean = false;

  showMobileNumberField: boolean = false;
  showGenderField: boolean = false;
  showNidField: boolean = false;
  showBirthField: boolean = false;
  showModifedField: boolean = false;
  showCreatedField: boolean = false;
  showGuarantorField: boolean = false;
  showAccountField: boolean = false;
  showLoanField: boolean = false;
  showTransactionField: boolean = false;

  showDisburseField: boolean = false; //12
  showApplyField: boolean = false; //13

  //currentUser: string = '';

  noDataAvailable: boolean = false;

  showFilterTypeField: boolean = false;
  selectedFilterType: number = 0;

  ProductId: number;
  productList: any[] = [];

  constructor(
    private customerService: CustomerService,
    //private pdfService: PdfService,
    //private layoutService: LayoutService,
    private changeDef: ChangeDetectorRef,
    private _loanProductService: LoanProductService,
    private _guarantorservice: GuarantordetailsService,
    private datePipe: DatePipe,
    private location: Location
  ) {}

  ngOnInit(): void {
    // this.layoutService.getCurrentUserName().subscribe(res => {
    //   this.currentUser = res;
    // });

    this.loadGenderList();
    this.loadFilterList();
    this.getGuarantorList();
    this.getLoanProductBuilderInfoList();
    //this.SearchByParameter();
  }
  goBack() {
    this.location.back();
  }
  loadGenderList() {
    this.customerService.GetGenderList().subscribe((res) => {
      this.genderList = res;
    });
    this.changeDef.detectChanges();
  }

  loadFilterList() {
    this.customerService.GetfilterList().subscribe((res) => {
      //this.filterList = (res);
      this.filterList = res.sort((a, b) => {
        return a.status - b.status;
      });
      this.filterList = res.sort((a, b) => {});
    });
    this.changeDef.detectChanges();
  }

  getLoanProductBuilderInfoList() {
    this._loanProductService.getLoanProductList().subscribe((res) => {
      this.productList = res;
      this.ProductId = 0;
      this.changeDef.detectChanges();
    });
  }

  getGuarantorList(): void {
    this._guarantorservice
      .GetAllGuarantorList()
      .subscribe((Guarantor: Guarantordetails[]) => {
        this.GuarantorList = Guarantor;
        this.changeDef.detectChanges();
      });
  }

  toggleFilterTypeField(): void {
    this.showFilterTypeField = !this.showFilterTypeField;
  }

  onFilterTypeChange(type: number): void {
    this.otherSearch = false;
    this.parameterSearch = true;
    this.selectedFilterType = type;
    switch (this.selectedFilterType.toString()) {
      case "1":
        this.showMobileNumberField = true;
        this.showGuarantorField = false;
        this.showLoanField = false;
        this.showTransactionField = false;
        this.showDisburseField = false;
        this.showApplyField = false;

        break;
      case "3":
        this.showNidField = true;
        this.showTransactionField = false;
        this.showGuarantorField = false;
        this.showLoanField = false;
        this.showTransactionField = false;
        this.showDisburseField = false;
        this.showApplyField = false;
        break;

      case "4":
        this.showGenderField = true;
        this.showTransactionField = false;
        this.showGuarantorField = false;
        this.showLoanField = false;
        this.showTransactionField = false;
        this.showDisburseField = false;
        this.showApplyField = false;
        break;

      case "5":
        this.showBirthField = true;
        this.showTransactionField = false;
        this.showGuarantorField = false;
        this.showGuarantorField = false;
        this.showLoanField = false;
        this.showTransactionField = false;
        this.showDisburseField = false;
        this.showApplyField = false;
        break;

      case "6":
        this.showCreatedField = true;
        this.showTransactionField = false;
        this.showGuarantorField = false;

        break;

      case "7":
        this.showModifedField = true;
        this.showTransactionField = false;
        break;

      case "8": //Guarantor
        this.showGuarantorField = true;
        this.showTransactionField = false;
        this.showMobileNumberField = false;
        this.showNidField = false;
        this.showGenderField = false;
        this.showBirthField = false;
        this.showCreatedField = false;
        this.showModifedField = false;
        this.showApplyField = false;
        this.showDisburseField = false;
        this.showLoanField = false;
        this.showDisburseField = false;
        this.showApplyField = false;

        break;
      case "9": //Account No
        this.showAccountField = true;
        this.showTransactionField = false;
        this.showGuarantorField = false;
        this.showLoanField = false;

        this.showMobileNumberField = false;
        this.showNidField = false;
        this.showGenderField = false;
        this.showBirthField = false;
        this.showDisburseField = false;
        this.showApplyField = false;

        break;
      case "10": //loan type
        this.showLoanField = true;
        this.showTransactionField = false;
        this.showMobileNumberField = false;
        this.showNidField = false;
        this.showGenderField = false;
        this.showBirthField = false;
        this.showAccountField = false;

        break;
      case "11":
        this.showTransactionField = true;
        this.showGuarantorField = false;
        this.showLoanField = false;
        this.showDisburseField = false;
        this.showApplyField = false;
        this.showMobileNumberField = false;
        this.showNidField = false;
        this.showGenderField = false;
        this.showBirthField = false;
        this.showAccountField = false;
        break;

      case "12":
        this.showDisburseField = true;
        this.showLoanField = true;
        this.showTransactionField = false;
        this.showGuarantorField = false;
        this.showMobileNumberField = false;
        this.showNidField = false;
        this.showGenderField = false;
        this.showBirthField = false;

        break;
      case "13":
        this.showApplyField = true;
        this.showLoanField = true;
        this.showTransactionField = false;
        this.showGuarantorField = false;
        this.showMobileNumberField = false;
        this.showNidField = false;
        this.showGenderField = false;
        this.showBirthField = false;

        break;
    }
  }

  deleteFilterTypeFields(type: number): void {
    this.selectedFilterType = type;
    switch (this.selectedFilterType.toString()) {
      case "1":
        this.showMobileNumberField = false;
        break;
      case "3":
        this.showNidField = false;
        break;
      case "4":
        this.showGenderField = false;
        break;
      case "5":
        this.showBirthField = false;
        break;
      case "6":
        this.showCreatedField = false;
        break;
      case "7":
        this.showModifedField = false;
        break;
      case "8":
        this.showGuarantorField = false;
        break;
      case "9":
        this.showAccountField = false;
        break;
      case "10":
        this.showLoanField = false;
        break;
      case "11":
        this.showTransactionField = false;
        break;

      case "12":
        this.showDisburseField = false;
        break;
      case "13":
        this.showApplyField = false;
        break;
    }
  }

  SearchByParameter() {
    this.typeId;
    if (
      this.typeId == 1 ||
      this.typeId == 3 ||
      this.typeId == 4 ||
      this.typeId == 5 ||
      this.typeId == 6 ||
      this.typeId == 7 ||
      //this.typeId == 10 ||
      this.typeId == 12
    ) {
      this.loadCustomerDataByName();
      // this.otherSearch = true;
      this.OtherInfoTable = true;
      this.LoanInfoTable = false;
      this.GuarantorInfoTable = false;
      this.TransactionInfoTable = false;
    } else if (
      this.typeId == 9 ||
      this.typeId == 10 ||
      this.typeId == 12 ||
      this.typeId == 13
    ) {
      this.loadLoanRelatedData();
      this.LoanInfoTable = true;
      this.OtherInfoTable = false;
      this.GuarantorInfoTable = false;
      this.TransactionInfoTable = false;
    } else if (
      //Guarantor
      this.typeId == 8
    ) {
      this.loadGuarantorData();
      //this.otherSearch = true;
      this.GuarantorInfoTable = true;
      this.LoanInfoTable = false;
      this.OtherInfoTable = false;
      this.TransactionInfoTable = false;
    } else if (this.typeId == 11) {
      this.loadTransactionInfoByDate();
      this.LoanInfoTable = false;
      this.OtherInfoTable = false;
      this.GuarantorInfoTable = false;
      this.TransactionInfoTable = true;
    }
  }

  loadCustomerDataByName() {
    if (this.customername == null) {
      swal("Warning", "Please enter any value for search!", "warning");
    }
    const defaultDate = "0001-01-01";
    this.customerService
      .getCustomerInfoBySearch(
        this.customername,
        this.genderId,
        this.typeId,
        this.mobileNumber,
        this.nidNo,
        this.sBirthDate ?? defaultDate,
        this.eBirthDate ?? defaultDate,
        this.sCreateDate ?? defaultDate,
        this.eCreateDate ?? defaultDate,
        this.sModifiedDate ?? defaultDate,
        this.eModifiedDate ?? defaultDate
      )
      .subscribe((res) => {
        if (res != null) {
          this.customerList = res;
          this.noDataAvailable = this.customerList.length === 0;
        } else {
          swal("Warning", "Invalid Account No", "warning");
          this.noDataAvailable = true;
        }
      });
  }

  loadTransactionInfoByDate() {
    if (this.fromDate === undefined) {
      //swal("Warning", "Please select from date..", "warning");
      return false;
    }

    if (this.toDate === undefined) {
      swal("Warning", "Please select To date..", "warning");
      return false;
    }

    this.customerService
      .getTransactionInfoByDateSearch(
        this.accountNo,
        //this.fromDate ?? defaultDate,
        this.fromDate,
        this.toDate
      )
      .subscribe((res) => {
        if (res != null) {
          this.transactionList = res;
          this.noDataAvailable = this.transactionList.length === 0;
        } else {
          swal("Warning", "Invalid Account No", "warning");
          this.noDataAvailable = true;
        }
      });
  }

  loadLoanRelatedData() {
    const defaultDate = "0001-01-01";

    this.customerService
      .getAllLoanRelatedInfoBySearch(
        this.customername,
        this.accountNo,
        this.ProductId,
        this.typeId,
        this.sCreateDate ?? defaultDate,
        this.eCreateDate ?? defaultDate,
        this.sDisburseDate ?? defaultDate,
        this.eDisburseDate ?? defaultDate,
        this.sApplyDate ?? defaultDate,
        this.eApplyDate ?? defaultDate
      )
      .subscribe((res) => {
        if (res != null) {
          this.loanList = res;
          this.noDataAvailable = this.loanList.length === 0;
        } else {
          swal("Warning", "Invalid Account No", "warning");
          this.noDataAvailable = true;
        }
      });
  }

  loadGuarantorData() {
    if (this.guarantordNid === "") {
      this.guarantordNid = "nid";
    } else {
      this.guarantordNid = this.guarantordNid;
    }

    if (this.guarantorMobileNo === "") {
      this.guarantorMobileNo = "mobile";
    } else {
      this.guarantorMobileNo = this.guarantorMobileNo;
    }

    this.customerService;
    this.customerService
      .getAllGuarantorBySearch(
        this.guarantordNid,
        this.guarantorMobileNo
        // this.eCreateDate ?? defaultDate,
        // this.sDisburseDate ?? defaultDate,
        // this.eDisburseDate ?? defaultDate,
      )
      .subscribe((res) => {
        if (res != null) {
          this.GuarantorList = res;
          this.noDataAvailable = this.GuarantorList.length === 0;
        } else {
          swal("Warning", "Invalid Account No", "warning");
          this.noDataAvailable = true;
        }
      });
  }

  // onNIDgetGuarantor(event: any) {
  //   const nidVal = event.target.value;
  //   const mobileNo = null;
  //     this._guarantorservice.getLoanGuarantorByNidOrMobile(nidVal, mobileNo).subscribe(
  //       (res: any) => {
  //         if (res && res.id) {

  //         }

  //       })
  // }

  formatDate(transactionDate: any): string {
    const dateObject = new Date(transactionDate);
    if (!transactionDate || isNaN(dateObject.getTime())) {
      return "";
    } else {
      return this.datePipe.transform(dateObject, "dd-MM-yyyy") || "";
    }
  }
}
