import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { AccuredService } from '../service/accured.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';

@Component({
  selector: 'app-accured',
  templateUrl: './accured.component.html',
  styleUrls: ['./accured.component.scss']
})
export class AccuredComponent implements OnInit {

  accuredList:any []=[];
  isEdit: boolean = false;
  accuredFromGrp: FormGroup;
  productBuilderTypes = ProductBuilderTypeEnum;
  productTypeId: number=0;
  accuredModel:any={};

  constructor(private formBuilder: FormBuilder,
              private shared:SharedService,
              private _accuredService:AccuredService) { }

  ngOnInit(): void {
    this.accuredForm();
    this.getAccuredList();
    this.showSaveForm='saveList';
  }

  accuredForm(){
    this.accuredFromGrp=this.formBuilder.group({
      id:[0],
      name:['',Validators.required],
      remarks:[''],
      status:['',Validators.required],
      productTypeId:[0]
    })
  }

  refresh() {
    this.isEdit = false;
    this.accuredForm();
    this.accuredModel = {};
  }

  showSaveForm:string='';

  onClickShowSaveForm(value:string){
    this.showSaveForm=value;
    this.refresh();
  }

  getAccuredList() {
    this._accuredService.getAccuredList().subscribe(res => {
      //console.log(res);
      this.accuredList = res;
    })
  }

  onSubmit() {
    if (this.isEdit) {
      this.accuredModel.id = this.accuredFromGrp.value.id;
    }
    this.accuredModel.name = this.accuredFromGrp.value.name;
    this.accuredModel.remarks = this.accuredFromGrp.value.remarks;
    this.accuredModel.status = (this.accuredFromGrp.value.status==="true")?true:false;
    this.accuredModel.productTypeId = this.accuredFromGrp.value.productTypeId;

    this._accuredService.saveAccured(this.accuredModel).subscribe(res => {
      //alert('Save successfully');
      this.shared.ShowAlert("Success","Save Successfully","success");
      this.getAccuredList();      
      this.showSaveForm='saveList';
      this.refresh();
    })
  }

  edit(item:any){
    this.isEdit=true;
    this.showSaveForm='saveForm';
    //alert(item.id)
    this.accuredFromGrp.patchValue({
      id:item.id,
      name:item.name,
      remarks:item.remarks,
      status:item.status,
      productTypeId:item.productBuilderTypes
    })
  }

  delete(id:any){
    this._accuredService.deleteAccured(id).subscribe(res=>{
      this.shared.ShowAlert('Alert','Data deleted successfully.','warning');
      this.getAccuredList();
      this.showSaveForm='saveList';
    })
  }

}
