export class LoanLimtModel {
    id:number;
    loanLimitMasterId: number;
    loanProductId: number;
    amount: number;
    activeDate: string;
    expireDate: string;
    status: number;
    loanRegisterId: number;
    productAmount: number;
    personAmount: number;
    maxAmount: number;
    minAmount: number;
    creditMaxScore: number;
    creditMinScore: number;

    
    constructor() {
        this.loanLimitMasterId=null;
        this.loanProductId=null;
        this.amount=0;
        this.activeDate='';
        this.expireDate='';
        this.status=null;
        this.loanRegisterId=null;
        this.personAmount=0;
        this.productAmount=0;
        this.maxAmount = 0;
        this.minAmount = 0;
        this.creditMaxScore = 0;
        this.creditMinScore = 0;
    }
}
