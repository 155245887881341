import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CashtransactionListComponent } from './cashtransaction-list/cashtransaction-list.component';
import { CustomercreateComponent } from './customercreate/customercreate.component';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { DeclarationEditComponent } from './declaration-edit/declaration-edit.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { NomineeListComponent } from './nominee-list/nominee-list.component';
import { NomineeEditComponent } from './nominee-edit/nominee-edit.component';
import { CustomerAccountListComponent } from './customer-account-list/customer-account-list.component';
import { CustomerEditForAdminComponent } from './customer-edit-for-admin/customer-edit-for-admin.component';
import { WhitelistEntryComponent } from './whitelist-entry/whitelist-entry.component';
import { WhitelistCustomerComponent } from './whitelist-customer/whitelist-customer.component';
import { WhitelistExcelUploadComponent } from './whitelist-excel-upload/whitelist-excel-upload.component';
import { WhitelistInsertComponent } from './whitelist-insert/whitelist-insert.component';
import { CustomerInqueryComponent } from './customer-inquery/customer-inquery.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';

// { path: 'score-create/:id', component: ScoreCreateComponent,data: {title: 'score-create'},canActivate:[AuthGuard]},
 
const routes: Routes = [
  {
    path: "list",
    component: CustomerListComponent,
    data: {
      title: "List",
    },
    canActivate:[AuthGuard]
  },

  {
    path: "cashtransaction-list",
    component: CashtransactionListComponent,
    data: {
      title: "cashtransaction-list",
    },
    canActivate:[AuthGuard]
  }
  ,

  {
    path: "customer-edit",
    //path: 'customer-edit/:customersId',
    component: CustomerEditComponent,
    data: {
      title: "customer-edit",
    },
    canActivate:[AuthGuard]
  },

  {
    path: "customer-edit/:id",
    component: CustomerEditComponent,
    data: {
      title: "customer-edit",
    },
    canActivate:[AuthGuard]
  },

  {
    path: "customer-admin-edit/:id",
    component: CustomerEditForAdminComponent,
    data:{
      title: "customer-admin-edit"
    }
  },

  {
    path: "declaration-edit",
    component: DeclarationEditComponent,
    data: {
      title: "declaration-edit",
    },
    canActivate:[AuthGuard]
  },

  // {path:'customerCr/:parameter/:parameter1',component:CustomercreateComponent,data:{title:'Customer Create'}}

  {
    path: "nominee-list",
     
    component: NomineeListComponent,
    data: {
      title: "nominee-list",
    },
    canActivate:[AuthGuard]
  },
  {
    path: "nominee-edit",
     
    component: NomineeEditComponent,
    data: {
      title: "nominee-edit",
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: "customer-account-list",
     
    component: CustomerAccountListComponent,
    data: {
      title: "Customer Account List",
    },
   
  }

  ,
  {
    path: "whitelist-entry",

    component: WhitelistEntryComponent,
    data: {
      title: "whitelist-entry",
    },

  }
  ,
  {
    path: "whitelist-customer",

    component: WhitelistCustomerComponent,
    data: {
      title: "whitelist-customer",
    },

  }
  ,
  {
    path: "whitelist-excel-upload",

    component: WhitelistExcelUploadComponent,
    data: {
      title: "whitelist-excel-upload",
    },

  }
  ,
  {
    path: "whitelist-insert",

    component: WhitelistInsertComponent,
    data: {
      title: "whitelist-insert",
    },

  }
  ,
  {
    path: "customer-inquery",

    component: CustomerInqueryComponent,
    data: {
      title: "customer-inquery",
    },

  }
  ,
  {
    path:"terms-condition",

    component: TermsConditionComponent,
    data: {
      title: "terms-condition",
    },

  }



];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule { }
