import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';
import { th } from 'date-fns/locale';

const apiUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class NomineeService {
  constructor(private http: HttpClient) { }

  getNomineeList(): Observable<any>{

    return this.http.get(apiUrl + '/api/CustomerAccount/GetNomineeInfo');
  }
  getRelationList():Observable<any>{
    return this.http.get(apiUrl + '/api/Relation/GetRelationList');
  }
  getRelationByYid(id: number){
    return this.http.get(apiUrl + '/api/Relation/GetRelationById?id=' +id);
  }
  GetRelationById(id: number){
    return this.http.get(apiUrl + '/api/Relation/GetRelationById/' +id);
  }
  save(model : any){
    
    return this.http.post(apiUrl + '/api/CustomerAccount/SaveNominee', model);
  }
  deleteNominee(id:number) {

    return this.http.get(apiUrl + '/api/CustomerAccount/Delete?id=' + id);
  }
  
}
