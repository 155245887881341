<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h5 class="card-title" style="color: black">Loan Limit List</h5>
                </div>
                <div class="col" style="text-align: right;">
                    <button type="button" data-toggle="tooltip" [routerLink]="['/products/loan-limit-entry']" class="btn btn-info"
                        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Go to Entry</button>
                </div>
            </div>
            <hr>
            <div class="table-container" style="max-height: 700px; overflow-y: auto;">
                <table id="example" class="table table-striped table-bordered scrollable-table" style="width:100%">
                    <thead>
                        <tr>
                            <th>SL No.</th>
                            <th>Product Name</th>
                            <th>Active Date</th>
                            <th>Expire Date</th>
                            <th>Person Amount </th>
                            <th>Product Amount </th>
                            <th>Max Amount </th>
                            <th>Min Amount</th>
                            
                            <th>Action</th>
                            <!-- <th style="width: 20%;">Action</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of loanLimitList; let i = index;">
                            <td>{{i + 1}}</td>
                            <td>{{item.loanProduct.name}}</td>
                            <td>{{item.activeDate | date:'yyyy-MM-dd'}}</td>
                            <td>{{item.expireDate | date:'yyyy-MM-dd'}}</td>
                            <td>{{item.amount}}</td>
                            <td>{{item.productAmount}}</td>
                            <td>{{item.maxAmount}}</td>
                            <td>{{item.minAmount}}</td>
                            
                            <td>
                                <button class="btn btn-primary" (click)="edit(item.id)"><i class="fa fa-edit 3x"></i></button>
                            </td>
                            <!-- <td style="width: 20%;">
                                <button class="btn btn-info mb-1" style="margin-left: 5px;"
                                (click)="openLoanLimitDetailsModal(productBuilderDetails)" (click)="getLoanLimitDetails(item.id)"><i
                                    class="fa fa-list"></i></button>
                                
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>



