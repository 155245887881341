
<div class="card-body">
  <h5 class="card-title" style="color: black">Customer Document</h5>
  <hr>
  <div class="col-md-12">
    <form [formGroup]="frmGroup" enctype="multipart/form-data">
      <div asp-validation-summary="All" class="text-danger"></div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label for="typeName" class="col-sm-4 col-form-label">Document Type</label>
            <div class="col-sm-8">
              <select class="form-control" id="documentsTypeId" formControlName="documentsTypeId" [(ngModel)]="documentInfo.documentsTypeId" required>
                <option value="">Select</option>
                <ng-container *ngFor="let item of LeadDocumentTypeList">
                  <option [value]="item.id">{{ item.typeName }}</option>
                </ng-container>
              </select>
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label for="remarks" class="col-sm-4 col-form-label">Description</label>
            <div class="col-sm-8">
              <textarea type="text" class="form-control" id="remarks" formControlName="remarks" [(ngModel)]="documentInfo.remarks"
                required></textarea>
            </div>
          </div>

        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <div class="form-group row">
            <label for="itemCode" class="col-sm-4 col-form-label">Image/File</label>
            <div class="col-sm-8">
              <label for="fileInput" class="upload-label">
                <img style="max-width: 150px; max-height: 200px;" [src]="getUploadImage()" alt="Upload Icon"
                  class="upload-icon">
              </label>
              <br>
              <span   id="fileNameSpan" ></span>
              <input id="fileInput" type="file" (change)="onFileSelected($event)"
                accept=".pdf,.docx,.jpeg,.jpg,.png,.xlsx,.xls" style="display: none;" />
            </div>
          </div>

        </div>
      </div>
      <hr />
      <button (click)="documentSubmit()" type="submit" value="Submit" class="btn btn-success"
        style="float:right; margin-top:5px;">Submit</button>
    </form>
  </div>
</div>
  
<div class="card" style="width: 100%;">
  <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Customer Document List</h5>
        <hr>
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th  style="width: 20%;">Image/File</th>
              <th style="width: 20%;">Document Type </th>
              <th style="width: 20%;">File Name</th>
              <th  style="width: 20%; text-align: center;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let document of documentList">
              <td style="width: 20%;">               
                <img [src]="getPreviewUrl(document.fileUrl)" (click)="fullPreviewImage(document.fileUrl)" style="width: 40%; height: 30%;" alt="Preview" *ngIf="document.fileType === 'image/jpeg' ||  document.fileType === 'image/png'"/>
                <img [src]="defaultDocxImage" style="width: 40%; height: 30%;" alt="Default Docx" *ngIf="document.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" />
                <img [src]="defaultPdfImage"  style="width: 40%; height: 30%;" alt="Default PDF" *ngIf="document.fileType === 'application/pdf'" />
                <img [src]="defaultExcelImage"  style="width: 40%; height: 30%;" alt="Default Excel" *ngIf="document.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" />
                <img src="assets/path/upload_image.png" style="width: 40%; height: 30%;" alt="Default File" *ngIf="!getPreviewImage()" />
              
              
              </td>
              <td style="width: 20%;">{{ document.documentsType?.typeName }}</td>
              <td style="width: 20%;">{{ document.fileName }}</td>
              <td style="width: 20%; text-align: center;">
                <button data-toogle="tooltip" title="Preview" class="btn btn-info" style="margin-right: 3px;" (click)="fullPreviewImage(document.fileUrl)" *ngIf="document.fileType === 'image/jpeg' ||  document.fileType === 'image/png'"><i class="icon-size-fullscreen"></i></button>
                <button data-toogle="tooltip" title="Download" class="btn btn-primary" (click)="downloadFile(document)"><i class="fa fa-download"></i></button>
              </td>
            </tr>
           </tbody>
      </table>
      </div>  
    </div>
</div>  
