<section>
  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Add Interest Capitalize Calculation Rule</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
          (click)="onClickShowSaveForm('saveList')"
          style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
        <hr>
        <form [formGroup]="FromGrp">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="code" class="col-sm-4 col-form-label">Rule Code</label>
                <div class="col-sm-8">
                  <input type="text" formControlName="code" class="form-control" readonly>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="details" class="col-sm-4 col-form-label">Details</label>
                <div class="col-sm-8">
                  <input type="text" formControlName="details" class="form-control" placeholder="Type here...">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="sortorder" class="col-sm-4 col-form-label">Product Type</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="productTypeId">
                    <ng-container *ngFor="let type of productBuilderTypes | keyvalue">
                      <option *ngIf="type.key!=='0' && type.key!=='1' && type.key!=='2'" [value]="type.value">{{ type.key }}</option>
                  </ng-container>
                  </select>
                </div>
              </div>
                  </div>
          <div class="col-md-6">

          <button [disabled]="" data-toggle="tooltip" title="Save" type="submit" value="Submit" (click)="onSubmit()"
            class="btn btn-success" style="float:right; margin-top:5px;">
            <span>{{ isEdit ? 'Update' : 'Save' }}</span>
          </button>
          <button type="button" data-toggle="tooltip" title="Refresh" (click)="refresh()" class="btn btn-secondary"
            style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
          </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Interest Capitalize Calculation Rule List</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
          (click)="onClickShowSaveForm('saveForm')"
          style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
        <hr>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control" style="width:16%">
              <option value="2">2</option>
              <option value="3">3</option>
              <option selected value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="form-group col-md-6" style="text-align: right">
            <input type="text" class="form-control" [(ngModel)]="searchControl" placeholder="Search" (keyup)="onSearch()">
          </div>
        </div>
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th style="width: 5px;">SL No.</th>
              <th>Rule Code</th>
              <th>Details</th>
              <th>Type</th>
              <th style="text-align: center;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
            *ngFor="let item of capitalizationList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
            <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
              <td>{{item.code}}</td>
              <td>{{item.details}}</td>
              <td>{{productBuilderTypes[item.productBuilderType]}} Product</td>
              <td>
                <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;"
                  (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <span>Showing {{currentItems}} of {{totalItems}} entries</span>
          </div>
          <div class="col-md-6" style="text-align: right">
            <pagination-controls (pageChange)="onPageChange($event)">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>