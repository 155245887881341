import { Component, OnInit } from '@angular/core';
import { Balancetype } from '../model/balancetype';
import { BalancetypeService } from '../service/balancetype.service';
import { SharedService } from 'app/services/shared.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';

@Component({
  selector: 'app-balancetype',
  templateUrl: './balancetype.component.html',
  styleUrls: ['./balancetype.component.scss']
})
export class BalancetypeComponent implements OnInit {
  list: Balancetype[] = [];
  model = new Balancetype();
  isEdit: boolean = false;

  productBuilderTypes = ProductBuilderTypeEnum;
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = '';

  constructor(
    private _service: BalancetypeService,
    private shared: SharedService
  ) 
  {

  }

  ngOnInit(): void {
    this.getBalanceList();
    this.showSaveForm = 'saveList';
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getBalanceList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getBalanceList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getBalanceList();
  }
  
  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();
  }
  getBalanceTypeList() {
    this._service.getlist().subscribe(res => {
      this.list = res;
    })
  }

  getBalanceList() {
    this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      
      this.list = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  refresh() {
    this.isEdit = false;
    this.model.name = '';
    this.model.remarks = '';
    this.model.productTypeId = 0;
  }

  onsubmit() {
    if (this.model.id === null) {
      this.model.id = 0;
    }
    this._service.saveBalancetype(this.model).subscribe(res => {
      if (this.isEdit) {
        this.shared.ShowAlert("Success", "Update Successfully", "success");      
      }
      else {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
      }
      this.isEdit = false;
      this.showSaveForm = 'saveList';
      this.getBalanceList();

    })
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.model = {
      id: item.id,
      name: item.name,
      remarks: item.remarks,
      status: item.status,
      productTypeId:item.productBuilderType
    };
  }

  delete(Id: any) {
    this._service.delete(Id).subscribe(res => {
      this.shared.ShowAlert("Success", "Data deleted successfully", "success");
      this.getBalanceList();
    })
  }
}
