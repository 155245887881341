import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/internal/Observable';
const apiUrl = environment.baseUrl + '/api/LoanRegisters/';

@Injectable({
  providedIn: 'root'
})
export class GuarantordetailsService {

  constructor(private http: HttpClient) { }


  //Guarantor


  // getLoanGurantorList():Observable<any>{
  //   return this.http.get(apiUrl + 'GetAllLoanGuarantor')
  // }

  getLoanGuarantorByNidOrMobile(nid:any, mobile:any){
    return this.http.get(apiUrl + 'GetGuarantorByNidOrMobile/'+ nid + '/'+ mobile);

  }

  getGurantorByClientId(clientId:any){
    return this.http.get(apiUrl + 'GetGurantorByClientId/'+ clientId);

  }

  saveLoanGurantor(model: any){

    return this.http.post(apiUrl +'SaveLoanGuarantor' , model);
 }  

  deleteLoanGurantor(id: any){
    return this.http.delete(apiUrl + 'DeleteLoanGuarantor/'+ id );
 }

  GetGuarantorList(leadId: number){
    return this.http.get(apiUrl + 'GetLoanGuarantorByLeadId/'+ leadId );
 }


//  GetLeadGenerationByLeadNoforLoanGuarantor(customersId:number): Observable<any> {
//     return this.http.get(apiUrl + 'GetLeadGenerationByLeadNoforLoanGuarantor?customersId=' + customersId );
//    }

GetAllGuarantorList(){
    return this.http.get(apiUrl + 'GetAllLoanGuarantorList' );
 }
  
}
