import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { ExpenseDetailsService } from '../expense-details.service';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { CustomerService } from 'app/customer/customer.service';
import swal from 'sweetalert';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-bankaccount-details',
  templateUrl: './bankaccount-details.component.html',
  styleUrls: ['./bankaccount-details.component.scss']
})
export class BankaccountDetailsComponent implements OnInit {
  @Output() bankaccountUpdateSuccess: EventEmitter<void> = new EventEmitter<void>();

  frmGroupBankAcc: FormGroup;
  clientInfoId: any = 0;
  leadId: any = 0;
  model: any = {};
  isEditBank: boolean = false;
  bankAccModel: any = {};
  Banklist: any = [];
  customerDetailsInfos: any;
  profileUpdate: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private shared: SharedService,
    private _expenseService: ExpenseDetailsService,
    private _leadGennarationService: LeadGenerationServiceService,
    private changeDetectorRef: ChangeDetectorRef,
    private customerService: CustomerService,
  ) { }

  ngOnInit(): void {
    this.GetCustomerDetailsForEdit();
    this.bankAccForm();
    this.getLeadInfo();
   
   this.activeRoute.paramMap.subscribe((params) => {
     console.log("params");
     console.log(params);

     if (params.get("id") == null) {
       this.customerService
         .GetCustomerDetailsForEdit()
         .subscribe((data: any) => {
           this.clientInfoId = data.customerDetailsInfos.customerId;
           this.getBankAccountList(this.clientInfoId);
         });
     } else {
       this.clientInfoId = parseInt(params.get("id"), 10);
       this.getBankAccountList(this.clientInfoId);
     }
   });
  }


  resetBankFrm() {
    this.bankAccModel = {};
    this.bankAccForm();
    this.getBankAccountList(this.clientInfoId);
  }

  getLeadInfo() {
    this.activeRoute.paramMap.subscribe(params => {
      const leadGNId = +params.get('leadId');
      this.leadId = +params.get('leadId');
      if (leadGNId != null&& this.leadId > 0) {
        this._leadGennarationService.searchLeadCodebyId(leadGNId).subscribe(
          (res: any) => {
            this.clientInfoId=res.customerId;
            this.getBankAccountList(this.clientInfoId);
          },
          (error: any) => {
           this.shared.ShowAlert('Error', 'Failed to retrieve.', 'error');
          }
        );
      }
    });
  }

  bankAccForm() {
    this.frmGroupBankAcc = this.formBuilder.group({
      id: [0],
      bankName: [''],
      branchName: [''],
      accountNo: [''],
      accountType: [''],
      avgBalance: ['',[Validators.required, Validators.pattern('^[0-9,.]*$')]]
    });
  }

  PostBankAccount() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save Bank Account Details!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save Account Details!"
    }).then((res) => {
      if (res.isConfirmed) {

    if (this.isEditBank) {
      this.bankAccModel.bankAccountId = this.frmGroupBankAcc.value.id;
    }

    this.bankAccModel.bankAccountId = this.frmGroupBankAcc.value.id;
    this.bankAccModel.bankName = this.frmGroupBankAcc.value.bankName;
    this.bankAccModel.branchName = this.frmGroupBankAcc.value.branchName;
    this.bankAccModel.accountNo = this.frmGroupBankAcc.value.accountNo;
    this.bankAccModel.accountType = this.frmGroupBankAcc.value.accountType;
    this.bankAccModel.avgBalance = this.frmGroupBankAcc.value.avgBalance.replace(/,/g, '');
    this.bankAccModel.customerId = this.clientInfoId;
    if (this.bankAccModel.bankAccountId) {
      this._expenseService.PostBankAccount(this.bankAccModel).subscribe(res => {
        this.shared.ShowAlert('Thank you...', 'You updated succesfully!', 'success');

        this.resetBankFrm();
        this.getProfileUpdateInfo();
     
     
        this.isEditBank = false;
      })
   
    } 
    else {

      this._expenseService.PostBankAccount(this.bankAccModel).subscribe(res => {
        this.shared.ShowAlert('Thank you...', 'You save succesfully!', 'success');
        this.bankaccountUpdateSuccess.emit();
        this.resetBankFrm();
  
      })
    
    }

    this.changeDetectorRef.detectChanges();
  }
  else if (res.isDismissed) {
    this.router.navigate(['/customer/customer-edit']);
  }
});

}


  getBankAccountList(clientInfoId:number) {
    this._expenseService.GetBankAccountDetailsById(clientInfoId).subscribe(res => {
      this.Banklist = res;
    })
  }
  editBankAccount(item: any) {
    this.isEditBank = true;

    this.frmGroupBankAcc.patchValue({

      id: item.id,
      bankName: item.bankName,
      branchName: item.branchName,
      accountNo: item.accountNo,
      accountType: item.accountType,
      avgBalance: item.avgBalance.toLocaleString()

    });
  }

  DeleteBankAcc(id: any) {

    this._expenseService.DeleteBankAcc(id).subscribe(res => {
      this.shared.ShowAlert('Thank you...', 'Delete succesfully!', 'success');
      this.getBankAccountList(this.clientInfoId);
    })
  }
  GetCustomerDetailsForEdit() {
 
    this.customerService.GetCustomerDetailsForEdit().subscribe((data:any)  => {
     
        this.customerDetailsInfos = data;
        this.clientInfoId = data.customerDetailsInfos.customerId;
      this.getBankAccountList(this.clientInfoId);
      this.changeDetectorRef.detectChanges();
      })
  }



  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe(res => {
      this.profileUpdate = res;
    })
    this.changeDetectorRef.detectChanges();
  }

  formatNumber(value: string): string {

    const formattedValue = value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedValue;
  }


}
