import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { ExpenseDetailsService } from '../expense-details.service';
import { IncomeDetails } from '../model/income-details';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { CustomerService } from 'app/customer/customer.service';
import swal from 'sweetalert';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-income-details',
  templateUrl: './income-details.component.html',
  styleUrls: ['./income-details.component.scss']
})
export class IncomeDetailsComponent implements OnInit {

  @Output() incomeUpdateSuccess: EventEmitter<void> = new EventEmitter<void>();

  isIncomeEdit: boolean = false;
  frmGroupIncome: FormGroup;
  frmGroupInshow: FormGroup;
  model: any = {};
  clientInfoId: any = 0;
  leadId: any = 0;
  //IncomeList: Object = [];
  IncomeList: any = [];
  
  isAuto: boolean = false;
  customersId: any = 0;
  customerDetailsInfos: any;
  profileUpdate: any;
 
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private shared: SharedService,
    private datePipe: DatePipe,
    private _expenseService: ExpenseDetailsService,
    private _leadGennarationService: LeadGenerationServiceService,
    private changeDetectorRef: ChangeDetectorRef,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    //private route: ActivatedRoute,

    

  ) {
    
      this.frmGroupIncome = this.formBuilder.group({
        id: [0],
        monthlyIncomeAmount: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
        

        jointApplicantIncomeAmount: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
        rentalIncomeAmount: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
        interestIncomeAmount: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
        otherIncomeAmount: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
        totalAmount: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]]
      });
}

  ngOnInit(): void {
    this.GetCustomerDetails();
 this.getLeadInfo();

   this.getIncomeList(this.clientInfoId);


    this.activeRoute.paramMap.subscribe(params => {
      console.log("params")
      console.log(params)

      if (params.get('id') == null) {
        this.customerService.GetCustomerDetailsForEdit().subscribe((data: any) => {
          this.clientInfoId = data.customerDetailsInfos.customerId;
          this.getIncomeList(this.customersId); 
        })
      } 
      else {
     
        this.clientInfoId = parseInt(params.get("id"), 10);
        this.getIncomeList(this.clientInfoId);

      }
    });

  }

  // LoadIncomeInfo(customersId:number){

  //   this._expenseService.GetLeadGenerationByLeadNoforIncome(customersId).subscribe(res =>{
  //     alert(customersId);

  //     if(res != null){


  //       this.isAuto=true;

  //       this.frmGroupIncome.patchValue({

  //         id: res.clientInfo?.id,

  //         leadId: res.id,

  //         monthlyIncomeAmount: res.monthlyIncomeAmount,
  //         jointApplicantIncomeAmount: res.jointApplicantIncomeAmount,
  //         rentalIncomeAmount: res.rentalIncomeAmount,
  //         interestIncomeAmount: res.interestIncomeAmount,
  //         otherIncomeAmount: res.otherIncomeAmount,
  //         totalAmount: res.totalAmount,


  //       });
  //     }
  //     else{
  //       this.frmGroupIncome = this.formBuilder.group({
  //         id: 0,
  //         leadId: 0,
  //         monthlyIncomeAmount:'',
  //         jointApplicantIncomeAmount: '',
  //         rentalIncomeAmount: '',
  //         interestIncomeAmount: '',
  //         otherIncomeAmount: '',
  //         totalAmount: '',


  //       });
  //     }
  //   })
  // }

  refreshIncome() {
    this.IncomeForm();
    this.model = {};
    this.getIncomeList(this.clientInfoId);
    this.isIncomeEdit = false;
  }

  getLeadInfo() {
    this.activeRoute.paramMap.subscribe(params => {
      const leadGNId = +params.get('leadId');
      this.leadId = +params.get('leadId');
      if (leadGNId != null&& this.leadId > 0) {
        this._leadGennarationService.searchLeadCodebyId(leadGNId).subscribe(
          (res: any) => {
            //console.log(res);
            //this.leadCode = res.leadCode;
            this.clientInfoId = res.customerId;
            this.getIncomeList(this.clientInfoId);
          },
          (error: any) => {
            this.shared.ShowAlert('Error', 'Failed to retrieve.', 'error');
          }
        );
      }
    });
  }

  IncomeForm() {
    this.frmGroupInshow = this.formBuilder.group({
      id: [0],
      monthlyIncomeAmount: [''],
      jointApplicantIncomeAmount: [''],
      rentalIncomeAmount: [''],
      interestIncomeAmount: [''],
      otherIncomeAmount: [''],
      totalAmount: ['']
    });

    this.frmGroupIncome = this.formBuilder.group({
      id: [0],
      monthlyIncomeAmount: [''],
      jointApplicantIncomeAmount: [''],
      rentalIncomeAmount: [''],
      interestIncomeAmount: [''],
      otherIncomeAmount: [''],
      totalAmount: ['']
    });
  }

  UpdateIncome(id: number) {
    this.isIncomeEdit = true;
    this._expenseService.GetIncomeDetailsById(id).subscribe(
      (incomeinfo: IncomeDetails) => {
        this.frmGroupIncome.patchValue({
          id: incomeinfo.id,
          monthlyIncomeAmount: incomeinfo.monthlyIncomeAmount.toLocaleString(),
          rentalIncomeAmount: incomeinfo.rentalIncomeAmount.toLocaleString(),
          jointApplicantIncomeAmount: incomeinfo.jointApplicantIncomeAmount.toLocaleString(),
          interestIncomeAmount: incomeinfo.interestIncomeAmount.toLocaleString(),
          otherIncomeAmount: incomeinfo.otherIncomeAmount.toLocaleString(),
          totalAmount: incomeinfo.totalAmount.toLocaleString()
        });
        this.getIncomeList(this.clientInfoId);
      },
      error => {
        // Handle error
      }
    );
  }

  getIncomeList(customerId: number) {

    this._expenseService.GetAllIncomeinfoByClientId(this.clientInfoId).subscribe(res => {
      this.IncomeList = res;
      (incomeinfo: IncomeDetails) => {
        this.frmGroupIncome.patchValue({
          id: incomeinfo.id,
          customerId: this.clientInfoId,
          monthlyIncomeAmount: incomeinfo.monthlyIncomeAmount,
          rentalIncomeAmount: incomeinfo.rentalIncomeAmount,
          jointApplicantIncomeAmount: incomeinfo.jointApplicantIncomeAmount,
          interestIncomeAmount: incomeinfo.interestIncomeAmount,
          otherIncomeAmount: incomeinfo.otherIncomeAmount,
          totalAmount: incomeinfo.totalAmount
        });
      }
    })
    this.changeDetectorRef.detectChanges();
  }


  onIncomeSubmit() {

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save Income Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save Income Details!"
    }).then((res) => {
      if (res.isConfirmed) {

    if (this.clientInfoId > 0) {
      this.model.id = this.frmGroupIncome.value.id;
      this.model.monthlyIncomeAmount = this.frmGroupIncome.value.monthlyIncomeAmount.replace(/,/g, '');
      this.model.jointApplicantIncomeAmount = this.frmGroupIncome.value.jointApplicantIncomeAmount.replace(/,/g, '');
      this.model.rentalIncomeAmount = this.frmGroupIncome.value.rentalIncomeAmount.replace(/,/g, '');
      this.model.interestIncomeAmount = this.frmGroupIncome.value.interestIncomeAmount.replace(/,/g, '');
      this.model.otherIncomeAmount = this.frmGroupIncome.value.otherIncomeAmount.replace(/,/g, '');
      this.model.totalAmount = this.frmGroupIncome.value.totalAmount.replace(/,/g, '');
      this.model.customerId = this.clientInfoId;




      // this.model.rentAndUtilityAmount = this.frmGroupIncome.value.rentAndUtilityAmount;
      // this.model.foodAndClothingAmount = this.frmGroupIncome.value.foodAndClothingAmount;
      // this.model.educationAmount = this.frmGroupIncome.value.educationAmount;
      // this.model.loanRepaymentAmount = this.frmGroupIncome.value.loanRepaymentAmount;
      // this.model.miscellaneousAmount = this.frmGroupIncome.value.miscellaneousAmount;
      // this.model.expenseTotalAmount = this.frmGroupIncome.value.expenseTotalAmount;
      // this.model.customerId = this.clientInfoId;


    


      this._expenseService.saveIncome(this.model).subscribe(res => {
        this.shared.ShowAlert('Thank you...', 'You save succesfully!', 'success');
        this.refreshIncome();
        this.incomeUpdateSuccess.emit();
        this.getProfileUpdateInfo();
      })
    }
    else {
      this.shared.ShowAlert('Warning...', 'Please Create Client First!', 'warning');
      this.refreshIncome();
    }

  }
  else if (res.isDismissed) {
    this.router.navigate(['/customer/customer-edit']);
  }
});

}

  formatNumber(value: string): string {

    const formattedValue = value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedValue;
  }

  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe(res => {
      this.profileUpdate = res;
    })
    this.changeDetectorRef.detectChanges();
  }


  GetCustomerDetails() {

    this.customerService.GetCustomerDetailsForEdit().subscribe((data: any) => {
      this.customerDetailsInfos = data;
      this.clientInfoId = data.customerDetailsInfos.customerId;
      this.getIncomeList(this.clientInfoId);
      this.changeDetectorRef.detectChanges();
    })
  }

}
