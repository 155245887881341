import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TaxKeyService } from '../service/tax-key.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { keydetail } from '../model/tax-key-detail';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';

@Component({
  selector: 'app-tax-key',
  templateUrl: './tax-key.component.html',
  styleUrls: ['./tax-key.component.scss']
})
export class TaxKeyComponent implements OnInit {
  list: any[] = [];
  dlist: any = {};

  transactionlist: any[] = [];
  isEdit: boolean = false;
  frmGroup: FormGroup;
  model: any = {};
  TaxkeyId: any = 0;

  amount: number;
  taxPercent: number;
  type: number | null = 1;
  TaxDetailId: number;
  productTypeId: number = 0;
  productBuilderTypes = ProductBuilderTypeEnum;

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  taxes: { amount: number; taxPercent: number, type: number, TaxDetailId: number }[] = [];
  editingIndex: number = -1;
  showSaveForm: string = '';

  constructor(private _taxKeyService: TaxKeyService,
    private formBuilder: FormBuilder,
    private shared: SharedService,private _productService: ProductBuilderService,
    private router: Router,
    private modalService: NgbModal

  ) {
    this.getTransactiontypeList();
  }

  ngOnInit(): void {
    this.showSaveForm = 'saveList';
    this.populateForm();
    this.getAlltaxkeyList();
    this.refresh();

  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getAlltaxkeyList();
  }

  getCodeByType(type: number) {
    this._productService.getProductMasterDateCodeByType(type).subscribe(res => {
      this.frmGroup.patchValue({
        taxCode: res['code']
      })
    })
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getAlltaxkeyList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getAlltaxkeyList();
  }
  
  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      taxKeyName: [''],
      taxCode: [''],
      remarks: [''],
      status: [''],
      transectionTypeId: [''],
      productTypeId:[0]
    })
  }

  getTaxList() {
    this._taxKeyService.getList().subscribe(res => {
      this.list = res;
    })
  }

  Updatedetails(id: number) {
    this.isEdit = true;
    this._taxKeyService.GetDetailsByMasterId(id).subscribe(res => {
      this.dlist = res;
      this.taxes = [];

      this.dlist.forEach((item: any) => {
        this.taxes.push({
          amount: item.amount,
          taxPercent: item.taxPercent,
          type: item.type,
          TaxDetailId: item.id
        });
      });
    })

  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.getCodeByType(3);
    this.refresh();
  }

  refresh() {
    this.isEdit = false;
    this.populateForm();
    this.taxes = [];
  }

  edit(item: any) {
    const statusString = item.taxKey.status === false ? '0' : '1';
    this.isEdit = true;
    this.TaxkeyId = item.taxKey.id
    this.showSaveForm = 'saveForm';
    this.frmGroup.patchValue({
      id: item.taxKey.id,
      taxKeyName: item.taxKey.taxKeyName,
      taxCode: item.taxKey.taxCode,
      remarks: item.taxKey.remarks,
      transectionTypeId: item.taxKey.transectionTypeId,
      status: statusString
    });
    this.Updatedetails(this.TaxkeyId);
  }

  delete(id: any) {
    this._taxKeyService.deletetaxkey(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getAlltaxkeyList();
    })
  }

  addTax() {
    if (this.frmGroup.valid) {
      if (this.editingIndex === -1) {
        this.taxes.push({
          amount: this.amount,
          taxPercent: this.taxPercent,
          type: this.type,
          TaxDetailId: this.TaxDetailId

        });
      } else {
        this.taxes[this.editingIndex] = {
          amount: this.amount,
          taxPercent: this.taxPercent,
          type: this.type,
          TaxDetailId: this.TaxDetailId

        };
        this.editingIndex = -1;
      }
      this.amount = null;
      this.taxPercent = null;
      this.type = 0;
    }
  }

  editTax(index: number) {
    this.editingIndex = index;
    this.amount = this.taxes[index].amount;
    this.taxPercent = this.taxes[index].taxPercent;
    this.type = this.taxes[index].type;
    this.TaxDetailId = this.taxes[index].TaxDetailId;

  }

  deleteTax(index: number) {
    this.taxes.splice(index, 1);
    if (index === this.editingIndex) {
      this.editingIndex = -1;
      this.amount = null;
      this.taxPercent = null;
      this.type = null;
    }
  }

  getTransactiontypeList() {
    this._taxKeyService.gettansactionList().subscribe(res => {
      this.transactionlist = res;

    })
  }

  getAlltaxkeyList() {
    this._taxKeyService.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = [];
      res.list.forEach(sD => {
        this.list.push(sD);
      })
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  prepareDataForAPI() {
    const formData = this.frmGroup.value;
    formData.taxDetails = this.taxes;
    if (formData.status === "1") {
      formData.status = true;
    }
    else {
      formData.status = false;
    }
    return formData;
  }

  onSubmit() {
    if (this.frmGroup.valid) {
      const keydetails = this.prepareDataForAPI();
      this._taxKeyService.savetaxkey(keydetails).subscribe(
        (res: any) => {
          if (res === null) {
            this.shared.ShowAlert("Error", "No Information Given for Details.", "error");
          }
          else {
            this.shared.ShowAlert("Success", "Save Successfully", "success");
            this.getAlltaxkeyList();
            this.isEdit = false;
            this.showSaveForm = 'saveList';
          }
        },
        error => {
          //console.log(error);
          this.shared.ShowAlert("Error", "Failed to save data.", "error");
        }
      );
    }
  }
}
