import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loan-settlement-report',
  templateUrl: './loan-settlement-report.component.html',
  styleUrls: ['./loan-settlement-report.component.scss']
})
export class LoanSettlementReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
