import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { SharedService } from 'app/services/shared.service';
import { Router } from '@angular/router';
import { CustomerService } from 'app/customer/service/customer.service';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { LayoutService } from 'app/shared/services/layout.service';
import swal from 'sweetalert';
import Swal from 'sweetalert2'
import { Location } from "@angular/common";
@Component({
  selector: "app-customer-loan-create",
  templateUrl: "./customer-loan-create.component.html",
  styleUrls: ["./customer-loan-create.component.scss"],
})
export class CustomerLoanCreateComponent implements OnInit {
  frmGroup: FormGroup;
  model: any = {};
  lgList: any[] = [];
  currentUser: any;
  customerList: any[] = [];
  productList: any[] = [];

  selectedCustomerId: string;
  selectedProductId: string;
  selectedLoanType: string;

  profileUpdate: number;
  constructor(
    private _service: LeadGenerationServiceService,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private router: Router,
    private customerService: CustomerService,
    private _loanProductService: LoanProductService,
    private layoutService: LayoutService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
  ) {
    this.getCustomerList();
    //this.getLoanProductBuilderInfoList();
  }

  ngOnInit(): void {
    this.searchForm();
    this.getExistingCustomerLoan();
    this.getCustomerList();
    this.getNanoLoanProductInfoList();
    this.getProfileUpdateInfo();
    //this.getLoanProductBuilderInfoList();

    // this.layoutService.getCurrentUserName().subscribe(res=>{
    //   this.currentUser = res;
    //   }
    // );
    this.layoutService.CurrentUserInfo().subscribe((res) => {
      this.currentUser = res;
    });
  }

  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe((res) => {
      this.profileUpdate = res;
      if (res < 100) {
        swal(
          "Warning",
          "You must update all info your Profile ,before loan apply.",
          "warning"
        );
        this.router.navigate(["/customer/customer-edit"]);
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  searchForm() {
    this.frmGroup = this.formBuilder.group({
      searchValue: ["", Validators.required],
    });
  }

  //lgData : string;
  getExistingCustomerLoan() {
    this._service.GetExistingLeadGeneration().subscribe((res) => {
      this.lgList = res.filter((item) => item.isComplete === 0);
    });
    this.changeDetectorRef.detectChanges();
  }

  getCustomerList() {
    this.customerService.getlist().subscribe((res) => {
      this.customerList = res;
      this.selectedCustomerId = this.customerList[0].id;
      this.changeDetectorRef.detectChanges();
    });
  }

  // getLoanProductBuilderInfoList(loanTypeId:number) {
  //   this._loanProductService.getLoanProductListByLoanTypeId(loanTypeId).subscribe(res => {
  //     this.productList= res;
  //     this.selectedProductId = '';
  //     this.changeDetectorRef.detectChanges();
  //   })
  // }

  getNanoLoanProductInfoList() {
    this._loanProductService.getNanoLoanProduct().subscribe((res) => {
      this.productList = res;
      this.selectedProductId = "";
      this.changeDetectorRef.detectChanges();
    });
  }

  //selectedOption: string;
  newLead: string;
  selectedOption = "New";

  // onRadioChange(value: string) {
  //  // this.lgData='dataNotFound';
  //   if(value==="New"){
  //   }
  //   this.searchForm();
  //   this.selectedOption = "New";

  // }

  CheckLaunderingCustomerByCustomerId() {

       this._service
         .getMoneyLaunderingCustomerByCustomerId(this.selectedCustomerId)
         .subscribe((res) => {
           if(res === null){
            console.log("MoneyLaundering");
            console.log(res)
             this.NewLoanGenerate('New');
          }
          else{
             swal(
               "Warning",
                 "You are not eligible for loan apply.Please contact with bank manager.",
               "warning"
             );
          }
         });
    this.changeDetectorRef.detectChanges();
  }

  NewLoanGenerate(value: string) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to apply new loan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, apply!",
    }).then((res) => {
      if (res.isConfirmed) {
        var product = this.productList.filter(
          (x) => x.id == this.selectedProductId
        )[0];
        // var productInfo = this.productList.filter(x=>x.id==this.selectedProductId)[0];
        //var productId='';
        // var tenureId='';

        // if(this.selectedLoanType === '3')
        // {
        //  productId=product.loanProductId;
        //  tenureId=product.durationId;
        // }
        // else
        // {
        //   productId=this.selectedProductId;
        //   tenureId=productInfo.loanDurationId;
        // }

        var productId = product.loanProductId;
        var tenureId = product.durationId;

        this._service
          .getSaveLeadGenerationNew(
            this.selectedCustomerId,
            productId,
            tenureId
          )
          .subscribe(
            (response: any) => {
              const leadId = response.leadId;
              const autoCode = response.autoCode;

              const customersId = this.selectedCustomerId;

              // const tenureId = this.selectedProductId;

              // swal('Auto Code Generated', `The auto code is: ${autoCode}`, 'success');
              // this.router.navigate(['/loan-application/loan-application/' + leadId + '/' + customersId + '/' + productId+'/'+tenureId]);

              if (response.status === "Appiled") {
                swal(
                  "Warning",
                  response.message +
                    ". Please check in existing applied loan list.",
                  "warning"
                );
              } else {
                swal(
                  "Auto Code Generated",
                  `The auto code is: ${autoCode}`,
                  "success"
                );
                this.router.navigate([
                  "/loan-application/loan-application/" +
                    leadId +
                    "/" +
                    customersId +
                    "/" +
                    productId +
                    "/" +
                    tenureId,
                ]);
              }

              this.changeDetectorRef.detectChanges();
            },
            (error) => {
              swal("Error", "Failed to generate the auto code", "error");
            }
          );
      } else if (res.isDismissed) {
        this.router.navigate(["/loan-application/customer-loan"]);
      }
    });

    //this.lgData='dataNotFound';
    // if(value==="New"){
    //   swal({
    //     title: 'Are you sure?',
    //     text: 'Do you want to apply a new Loan ?',
    //     icon: 'success',
    //     //showCancelButton: true,
    //     //confirmButtonText: 'Yes Create',
    //     //cancelButtonText: 'Cancel'
    //   }).then(result => {
    //     if (result === true) {
    //       this._service.getSaveLeadGeneration(this.selectedCustomerId, this.selectedProductId).subscribe(
    //         (response: any) => {
    //           const leadId = response.leadId;
    //           const autoCode = response.autoCode;

    //           const customersId = this.selectedCustomerId;
    //           const loanProductsId = this.selectedProductId;

    //           swal('Auto Code Generated', `The auto code is: ${autoCode}`, 'success');
    //          this.router.navigate(['/loan-application/loan-application/'+ leadId + '/'+ customersId + '/'+  loanProductsId]);
    //           this.changeDetectorRef.detectChanges();
    //         },
    //         error => {
    //           swal('Error', 'Failed to generate the auto code', 'error');
    //         }
    //       );
    //     }
    //   });
    // }
    this.searchForm();
    this.selectedOption = value;
  }

  goLoanForm(
    leadId: any,
    customersId: any,
    loanProductsId: any,
    tenureId: any
  ) {
    this.router.navigate([
      "/loan-application/loan-application/" +
        leadId +
        "/" +
        customersId +
        "/" +
        loanProductsId +
        "/" +
        tenureId,
    ]);
  }

  goBack() {
    this.location.back();
  }
}
