
    <div class="card" style="width: 100%;">
      <div class="card-body">
        <div class="">
          <h5 class="card-title" style="color: black">Business standing</h5>
          <hr>
          <form>
            <div asp-validation-summary="All" class="text-danger"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Job Position
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Nature of business
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Ownership in business
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Item manufactured
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Age of business</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Annual Sales Tk </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Has sufficient stock in shop ?
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Sales proceed percentage
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="Type here....">
                  </div>
                </div>

              </div>
            </div>
            <button title="Save" type="submit" value="Submit" class="btn btn-success"
              style="float:right; margin-top:5px;">
              <!-- <span>{{ isEdit ? 'Update' : 'Save' }}</span> -->
              <span>Save</span>
            </button>
            <button type="button" title="Refresh" class="btn btn-secondary"
              style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>

          </form>
        </div>
      </div>
    </div>
