<ng-container *ngIf="!saveSuccess">
<div class="card" style="width: 100%;">
  <div class="card-body">
    <div class="">
      <!-- <h5 class="card-title" [ngStyle]="{'color': isDataNull=== true ? 'rgb(78, 77, 77)' : 'rgb(78, 77, 77)'}">
        {{ isDataNull === true ? 'Address Details (Incomplete)' : 'Address Details' }}
      </h5> -->



      
      <form [formGroup]="frmGroupAddress">
        <div asp-validation-summary="All" class="text-danger"></div>
        <div class="row">

          <div class="col-md-6">
            <div class="form-group row">
              <label for="" style=" color: rgb(133, 133, 133);" class="col-sm-12 col-form-label">Present Address</label>
              <!-- <h5 style="font-size: 16px; padding-bottom: 10px; color: rgb(133, 133, 133);">Present Address</h5> -->
              
            </div>
            <hr style="margin-top: -10px;">
            
              
             <div class="form-group row">
              <input type="hidden"  formControlName="presentId"    [(ngModel)]="presentAddressData.preAddressId">
              <input type="hidden"  formControlName="customersId"    [(ngModel)]="presentAddressData.customersId">

             
              <label for="RoadNo" class="col-sm-4 col-form-label">Road Number</label>
              <div class="col-sm-8">
                <input type="text" [(ngModel)]="presentAddressData.preRoadNo" class="form-control" formControlName="RoadNo" placeholder="roadNumber.." >
              </div>
            </div>

            <div class="form-group row">
              <label for="HouseNo" class="col-sm-4 col-form-label">House No./Village</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="HouseNo"  [(ngModel)]="presentAddressData.preHouseNo"
                  placeholder="house No./Village..">
              </div>
            </div>

            <div class="form-group row">
              <label for="blockSector" class="col-sm-4 col-form-label">Block Sector</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="blockSector" [(ngModel)]="presentAddressData.preblockSector" placeholder="blockSector...">
              </div>
            </div>

            <div class="form-group row">
              <label for="postCode" class="col-sm-4 col-form-label">Post Code</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="postCode" [(ngModel)]="presentAddressData.prePostCode" placeholder="postCode...">
              </div>
            </div>

            <div class="form-group row">
              <label for="postOffice" class="col-sm-4 col-form-label">PostOffice</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="postOffice" [(ngModel)]="presentAddressData.prePostOffice" placeholder="postOffice...">
              </div>
            </div>

            <div class="form-group row">
              <label for="DivisionsId" class="col-sm-4 col-form-label">Division Name</label>
              <div class="col-sm-8">
               
                <!-- <select (change)="onDivisionChange($event.target.value)" class="form-control"  [(ngModel)]="presentAddressData.preDivisionsId"
                  [value]="customerDetailsInfos?.customerDetailsInfos.addressDivisionName" formControlName="DivisionsId"
                  id="DivisionsId"> -->

                <select (change)="onDivisionChange($event.target.value)" class="form-control"  [(ngModel)]="presentAddressData.preDivisionsId"
                  [value]="presentAddressData.preDivisionsId" formControlName="DivisionsId"
                  id="DivisionsId">
                  <option value="0">Select</option>
                  <ng-container *ngFor="let item of DivisionList">
                    <option [value]="item.id">{{ item.divisionName }}</option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label for="DistrictsId" class="col-sm-4 col-form-label">District Name</label>
              <div class="col-sm-8">

                <!-- <select (change)="onDistrictChange($event.target.value)" class="form-control" [(ngModel)]="presentAddressData.PreDistrictsId"
                  [value]="customerDetailsInfos?.customerDetailsInfos.addressDistrictName" formControlName="DistrictsId"
                  id="DistrictsId"> -->

                <select (change)="onDistrictChange($event.target.value)" class="form-control" [(ngModel)]="presentAddressData.preDistrictsId"
                  [value]="presentAddressData.preDistrictsId" formControlName="DistrictsId"
                  id="DistrictsId">
                  <option value="0">Select</option>
                  <ng-container *ngFor="let item of DistrictByDivList">
                    <option [value]="item.id">{{ item.districtName }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
              <!-- <div class="form-group row">
              <label for="DistrictsId" class="col-sm-4 col-form-label">District Name</label>
              <div class="col-sm-8">

                <input type="text" class="form-control" formControlName="DistrictsId" [(ngModel)]="presentAddressData.districtName"
                placeholder="District Name......."  (keyup.enter)="getDistrictList()">
                <input type="hidden" class="form-control" name="DistrictsId" formControlName="DistrictsId">
              </div>
            </div>  -->

            <div class="form-group row">
              <label for="upazilaId" class="col-sm-4 col-form-label">Thana Name</label>
              <div class="col-sm-8">


                <!-- <select class="form-control" formControlName="upazilaId" id="upazilaId"  [(ngModel)]="presentAddressData.upazilaId"
                  [value]="customerDetailsInfos?.customerDetailsInfos.addressUpazilaName">
                   -->

                <select class="form-control" formControlName="upazilaId" id="upazilaId"  [(ngModel)]="presentAddressData.preUpazilaId"
                  [value]="presentAddressData.preUpazilaId">
                  <option value="0">Select</option>
                  <ng-container *ngFor="let item of ThanaByDisList">
                    <option [value]="item.id">{{ item.upazilaName }}</option>
                  </ng-container>
                </select>
              </div>
            </div>

          </div>



          <div class="col-md-6">
            <div class="form-group row">
              <label style=" color: rgb(133, 133, 133);" class="col-sm-6 col-form-label">Permanent Address</label>
              <div class="col-sm-6">
                <input type="hidden" name="permanentId" formControlName="permanentId"    [(ngModel)]="permanentAddressData.parAddressId">
                <input type="checkbox" id="same"   (change)="getSameAddress($event)">
                <label for="Address" style="padding-left: 4px; font-size: 11px !important;"> Same as Present Address</label>
                
              </div>
             
            </div>
            <hr style="margin-top: -10px;">

            <div class="form-group row">
              <label for="permanentRoadNo" class="col-sm-4 col-form-label">Road Number</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="permanentRoadNo" placeholder="roadNumber.." [(ngModel)]="permanentAddressData.parRoadNo">
              </div>
            </div>

            <div class="form-group row">
              <label for="permanentHouseNo" class="col-sm-4 col-form-label">House No./Village</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="permanentHouseNo" [(ngModel)]="permanentAddressData.parHouseNo"
                  placeholder="house No./Village..">
              </div>
            </div>

            <div class="form-group row">
              <label for="permanentBlockSector" class="col-sm-4 col-form-label">Block Sector</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="permanentBlockSector" [(ngModel)]="permanentAddressData.parblockSector" placeholder="blockSector...">
              </div>
            </div>

            <div class="form-group row">
              <label for="permanentPostCode" class="col-sm-4 col-form-label">Post Code</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="permanentPostCode" [(ngModel)]="permanentAddressData.parPostCode" placeholder="postCode...">
              </div>
            </div>

            <div class="form-group row">
              <label for="permanentPostOffice" class="col-sm-4 col-form-label">PostOffice</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="permanentPostOffice" [(ngModel)]="permanentAddressData.parPostOffice" placeholder="postOffice...">
              </div>
            </div>

            <div class="form-group row">
              <label for="permanentDivisionsId" class="col-sm-4 col-form-label">Division Name</label>
              <div class="col-sm-8">  

                <select (change)="onDivisionChange2($event.target.value)" class="form-control" [(ngModel)]="permanentAddressData.parDivisionsId"
                  [value]="permanentAddressData.parDivisionsId" formControlName="permanentDivisionsId"
                  id="permanentDivisionsId">
                  <option value="0">Select</option>
                  <ng-container *ngFor="let item of DivisionList2">
                    <option [value]="item.id">{{ item.divisionName }}</option>
                  </ng-container>
                </select>

              </div>
            </div>

            <div class="form-group row">
              <label for="permanentDistrictsId" class="col-sm-4 col-form-label">District Name</label>
              <div class="col-sm-8">

                <select (change)="onDistrictChange2($event.target.value)" class="form-control" [(ngModel)]="permanentAddressData.parDistrictsId"
                  [value]="permanentAddressData.parDistrictsId" formControlName="permanentDistrictsId"
                  id="permanentDistrictsId">
                  <option value="0">Select</option>
                  <ng-container *ngFor="let item of DistrictByDivList2">
                    <option [value]="item.id">{{ item.districtName }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
  

            <div class="form-group row">
              <label for="permanentUpazilaId" class="col-sm-4 col-form-label">Thana Name</label>
              <div class="col-sm-8"> 
                <select class="form-control" formControlName="permanentUpazilaId" [(ngModel)]="permanentAddressData.parUpazilaId"
                id="permanentUpazilaId" [value]="permanentAddressData.parUpazilaId">
                  <option value="0">Select</option>
                  <ng-container *ngFor="let item of ThanaByDisList2">
                    <option [value]="item.id">{{ item.upazilaName }}</option>
                  </ng-container>
                </select>
              </div>
            </div>

          </div> 


          <div class="col-md-6">
            <div class="form-group row">
              <label for="PresentAddress" class="col-sm-4 col-form-label">Present Address</label>
              <div class="col-sm-8">
                <!-- <input type="text" class="form-control" formControlName="prCountry" placeholder="prCountry.."> -->
                <textarea class="form-control" formControlName="PresentAddress" [(ngModel)]="presentAddressData.presentAddress"
                  [value]="customerDetailsInfos?.customerDetailsInfos.presentAddress"></textarea>
              </div>
            </div>
          </div>
           

          <div class="col-md-6">
            <div class="form-group row">
              <label for="PermamentAddress" class="col-sm-4 col-form-label">Permanent Address</label>
              <div class="col-sm-8">
                <!-- <input type="text" class="form-control" formControlName="prNo" placeholder="prNo.."> -->
                <textarea class="form-control" formControlName="PermamentAddress" [(ngModel)]="permanentAddressData.permamentAddress"
                  [value]="customerDetailsInfos?.customerDetailsInfos.permamentAddress"></textarea>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-6">

            </div>
            <div class="col-md-6">

            </div>
          </div>
        </div>

        <!-- <div class="row mt-2">
             
            
            <div class="col-md-6">
              <div class="form-group row">
                <label for="type" class="col-sm-4 col-form-label">Type</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="type" placeholder="type.."[value]="customerDetailsInfos?.customerDetailsInfos.type">
                </div>
              </div>
            </div>  
            
          </div>-->


        <button (click)="onAddresssubmit()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
          <span>Save</span></button>

        <button type="button" data-toggle="tooltip" title="Refresh" (click)="Addressreset()" class="btn btn-secondary"
          style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>

      </form>
    </div>
  </div>
</div>
</ng-container>
<ng-container *ngIf="saveSuccess">
  <app-contact-info (contactUpdateSuccess)="getProfileUpdateInfo()"></app-contact-info>
</ng-container>