import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
const apiUrl = environment.baseUrl+'/api/ProductBuilder/';
@Injectable({
  providedIn: "root",
})
export class ProductBuilderService {
  constructor(private http: HttpClient) {}
  getDPSProductList(): Observable<any> {
    return this.http.get(apiUrl + "GetDPSFromProductBuilders");
  }

  getList(): Observable<any> {
    return this.http.get(apiUrl + "GetProductBuilders");
  }

  getIncomeSourceList(): Observable<any> {
    return this.http.get(apiUrl + "GetIncomeSource");
  }
  save(model: any) {
    return this.http.post(apiUrl + "SaveProductBuilders", model);
  }

  getLoanTypeById(id: any) {
    return this.http.get(apiUrl + "GetProductBuildersById/" + id);
  }

  getLedgerByProductId(id: any) {
    return this.http.get(apiUrl + "GetLedgerListByProductId/" + id);
  }

  // getLedgerByProductId(id:any):Observable<any>{
  //   return this.http.get(apiUrl+'GetLedgerListByProductId/' +id);
  // }

  delete(id: any) {
    return this.http.delete(apiUrl + "DeleteProductBuilder/" + id);
  }
  deActive(id: any, s: boolean) {
    return this.http.delete(
      apiUrl + "DeActiveProductBuilder?id=" + id + "&status=" + s
    );
  }
  getProductMasterDateCodeByType(type: number) {
    return this.http.get(apiUrl + "GetProductMasterDataCodeByType/" + type);
  }

  getProductBuilderDetailsById(id: number): Observable<any> {
    return this.http.get(apiUrl + "GetProductBuilderDetailsById/" + id);
  }

  GetMaturityDate(pId: number) {
    return this.http.get(apiUrl + "GetMaturityDateByProductId?pId=" + pId);
  }

  getTenureList(productId: number): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/LoanProductBuilder/GetAllLoanDurationListByProductId?productId=" + productId
    );
  }
  GetAllPendingProductByRole(): Observable<any> {
    return this.http.get(apiUrl + "GetAllPendingProductByRole");
  }
  AproveLoanProductByUserRole(productId: number): Observable<any> {
    return this.http.get(
      apiUrl + "AproveLoanProductByUserRole?productId=" + productId
    );
  }
}