<div class="card" style="width: 100%;">
    <div class="card-body">
        <div asp-validation-summary="All" class="text-danger"></div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group row">
                    <div class="col-sm-2">


                        <ng-container *ngIf="currentUser?.userName === 'opus.admin'; else showLoanCode">
                            <h5> Lead Code:</h5>
                        </ng-container>
                        <ng-template #showLoanCode>
                            <h5>Loan Code:</h5>
                        </ng-template>



                    </div>
                    <div class="col-sm-6">
                        <h5 class="card-title" style="color: black">{{ leadCode }}</h5>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn" style="background-color: #616D89;color:white;float: right;"
                            (click)="forwardto(leadCode)">Submit</button>


              
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" style="display: flex; justify-content: space-between;margin-right: 0%;margin-left: 0%;">

    <button *ngFor="let item of ParentList; let i = index" class="btnMenu" style="margin-right: 5px;margin-left: 5px; width: 180px; height: 50px; padding: 10px;"
    [ngClass]="{'active': activeButtonparentId === item.id}" (click)="childloadOnParentId(item.id)">{{item.name}}
</button>

</div>



<div class="row" *ngIf="mainTab == '1'">
    <div class="col-md-2">
      

        <div style="display: flex; flex-direction: column; margin-top: 14px;">

            
            <button  *ngFor="let item of Childist; let i = index" class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
            [ngClass]="{'active': activeButton === item.url}" (click)="setActiveButton(item.url)">{{item.name}}
            
        </button>
               

        </div>
    
    </div>


    <!-- <div class="col-md-10" style="padding-left: 48px;">
        <ng-container *ngIf="activeTab === '1-2'">
        
           
            {{Childist.url}}
        
        </ng-container>
    </div> -->



    <div class="col-md-10" style="padding-left: 48px;">
        <ng-container #childContainer >
            
        </ng-container>
        
        <ng-container *ngIf="activeTab === '1-1'">
            <app-basic-info-self></app-basic-info-self>
        </ng-container>
        <ng-container *ngIf="activeTab === '1-2'">
            <app-contact-info></app-contact-info>
          </ng-container>
   


        <ng-container *ngIf="activeTab === '1-3-1'">
            <app-address-info></app-address-info>
        </ng-container>
        <ng-container *ngIf="activeTab === '1-3-2'">
            <app-professional-info></app-professional-info>
        </ng-container>
        <ng-container *ngIf="activeTab === '1-4'">
            <app-income-details></app-income-details>
        </ng-container>
        <ng-container *ngIf="activeTab === '1-5'">
            <app-expense-details></app-expense-details>
        </ng-container>

        <ng-container *ngIf="activeTab === '1-6'">
            <app-bankaccount-details></app-bankaccount-details>
        </ng-container>

        <ng-container *ngIf="activeTab === '1-7'">
            <app-reference-details></app-reference-details>
        </ng-container>
        <ng-container *ngIf="activeTab === '2-1'">
            <app-loan-register></app-loan-register>
        </ng-container>
        <ng-container *ngIf="activeTab === '3-1'">
            <app-gurantor-info></app-gurantor-info>
        </ng-container>

        <ng-container *ngIf="activeTab === '5-1'">
            <app-loan-document></app-loan-document>
        </ng-container>

    </div>

</div>







<div class="row" *ngIf="mainTab == '2'">
    <div class="col-md-2">
        <div style="display: flex; flex-direction: column; margin-top: 14px;">
         
            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
                [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('2-1')">Loan Register</button>

        
        </div>
    </div>
    <div class="col-md-10" style="padding-left: 48px;">
        <ng-container *ngIf="activeTab === '2-1'">
            <app-loan-register></app-loan-register>
        </ng-container>
     
    </div>
</div>


<div class="row" *ngIf="mainTab == '3'">
    <div class="col-md-2">
        <div style="display: flex; flex-direction: column; margin-top: 14px;">
          
            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
                [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('3-1')">Loan Guarantor</button>

       
        </div>
    </div>
    <div class="col-md-10" style="padding-left: 48px;">
        <ng-container *ngIf="activeTab === '3-1'">
            <app-gurantor-info></app-gurantor-info>
        </ng-container>

     

    </div>
</div>


<div class="row" *ngIf="mainTab == '4'">
    <div class="col-md-2">
        <div style="display: flex; flex-direction: column; margin-top: 14px;">
        
            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
                [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('4-1')">Security Type
            </button>

        </div>
    </div>
    <div class="col-md-10" style="padding-left: 48px;">
        <ng-container *ngIf="activeTab === '4-1'">
            <app-security-type></app-security-type>
        </ng-container>

    </div>
</div>

<div class="row" *ngIf="mainTab == '5'">
    <div class="col-md-2">
        <div style="display: flex; flex-direction: column; margin-top: 14px;">
       
            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
                [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('5-1')">Document
            </button>

        </div>
    </div>
    <div class="col-md-10" style="padding-left: 48px;">
        <ng-container *ngIf="activeTab === '5-1'">
            <app-loan-document></app-loan-document>
        </ng-container>

    </div>
</div>