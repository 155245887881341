import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DpsAccountRoutingModule } from './dps-account-routing.module';
import { DpsCreateComponent } from './dps-create/dps-create.component';
//import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { DpsListComponent } from './dps-list/dps-list.component';
import { DpsCollectionComponent } from './dps-collection/dps-collection.component';
import { DpsEncashmentComponent } from './dps-encashment/dps-encashment.component';
import { DpsEncashmentListComponent } from './dps-encashment-list/dps-encashment-list.component';
import { DpsImmatureEncashmentComponent } from './dps-immature-encashment/dps-immature-encashment.component';


@NgModule({
  declarations: [
    DpsCreateComponent,
    DpsListComponent,
    DpsCollectionComponent,
    DpsEncashmentComponent,
    DpsEncashmentListComponent,
    DpsImmatureEncashmentComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DpsAccountRoutingModule,
    NgbNavModule
  ]
})
export class DpsAccountModule { }
