<div class="card" style="margin-top: 0;">
  <div class="card-header" >
        <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">Nominee List</h5>
       
    </div>
  <div class="card-body" style="padding-top: 0;">
    <div class="container">
      
      <div class="card" *ngIf="showSaveForm==='saveForm'">
        <div class="col-12 pull-right">
          <button type="button" title="Add new" class="col-2 pull-right btn btn-secondary"
            (click)="onClickShowSaveForm('saveList')" style="float:right;">List View</button>
        </div>
        <div class="card-body">
          <form [formGroup]="NomineeForm">
            <div class="row">
              <div class="col-md-6">
                <!-- <div class="form-group row">
                  <label for="" class="col-sm-4 ">Customers Account </label>
                  <div class="col-sm-8"> 
                      <input type="text" readonly class="form-control" formControlName="CustomersAccountId" [(ngModel)]="model.CustomersAccountId"
                      placeholder="Customers Account">
                    <input type="hidden" readonly class="form-control" name="CustomersAccountId" [(ngModel)]="model.CustomersAccountId"
                      formControlName="CustomersAccountId">

                  </div>
                </div> -->

                <div class="form-group row">
                  <label for="" class="col-sm-4 ">Name</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="Name" [(ngModel)]="model.Name"
                      placeholder="Name">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="" class="col-sm-4 ">Date Of Birth</label>
                  <div class="col-sm-8">
                    <input type="date" class="form-control" formControlName="DateOfBirth" [(ngModel)]="model.DateOfBirth"
                      placeholder="Name">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">Nominee's Share <span style="color: red;"> *</span></label>
                  <div class="col-sm-8">
                    <input type="number" class="form-control" min="1" max="100" formControlName="SharingRate"   [(ngModel)]="model.SharingRate" (keypress)="onKeyPress($event)" placeholder="Nominee's Share...." readonly>
                    <!-- <input type="number" class="form-control" min="1" max="100" formControlName="SharingRate" [(ngModel)]="model.SharingRate"
                      placeholder="Share">  -->
                  </div>
                </div>
                 
                
                <div class="form-group row">
                  <label for="selectedIdentifier" class="col-sm-4 col-form-label">Nominee's Identifier <span style="color: red;"> *</span></label>                         
               
                   <div class="col-sm-8">
                    <select class="form-control" [(ngModel)]="selectedIdentifier" (change)="getNomineeIdentifier($event.target.value)" >
                       
                      <option value="1" selected>NID</option>
                      <option value="2">BirthId</option>
                      <option value="3">PassportNo</option>
                      <option value="4">ETin</option>
                    </select>
                   </div>                     
                </div>  
                <div class="form-group row" *ngIf="selectedIdentifier === '1'">                                                
                  <label for="" class="col-sm-4 col-form-label">Nominee's NID</label>
                  <div class="col-sm-8">                     
                    <input type="text" class="form-control" formControlName="Nid" [(ngModel)]="model.Nid"
                    placeholder="Nominee NID should be 10/13/17 digit....">
                  </div>
                </div>                     
                                     
                <div class="form-group row" *ngIf="selectedIdentifier === '2'">                           
                  <label for="" class="col-sm-4 col-form-label">Nominee's BirthId</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control"  [(ngModel)]="model.BirthCertificateNo" formControlName="BirthCertificateNo" placeholder="Nominee BirthIdentity....">                    
                  </div>
                </div>                       
               
                <div class="form-group row" *ngIf="selectedIdentifier === '3'">                           
                  <label for="" class="col-sm-4 col-form-label">Nominee's PassportNo</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control"  [(ngModel)]="model.Passport" formControlName="Passport" placeholder="Nominee Passport....">              
                  </div>
                </div>                       
               
                <div class="form-group row" *ngIf="selectedIdentifier === '4'">                           
                  <label for="" class="col-sm-4 col-form-label">Nominee's E-Tin</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control"  [(ngModel)]="model.ETin" formControlName="ETin" placeholder="Nominee ETin....">
                  </div> 
                </div>
                  
              </div>
              <div class="col-md-6">
                
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">Relation with Nominee</label>
                  <div class="col-sm-8">
                    <select class="form-control" formControlName="relationId" (change)="getRelation($event.target.value)">
                      <option value="">Select</option>
                        <option *ngFor="let item of relationList" [value]="item.id">{{item.name}}</option> 
                      
                    </select>
                  </div>
                </div> 

                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">NOMINEE'S  Picture <span style="color: red;"> *</span></label>
                  <div class="col-md-8" style="padding-right: 0px;">

                    <!-- <label for="image">Nominee Picture</label> -->

                    <input type="file" accept="image/*" id="image" (change)="selectNidBackPic($event)"
                        style="display: none;" #fileInputBack />

                    <div style="height: 125px; width: 150px; cursor: pointer;"
                        (click)="fileInputBack.click()">
                        <img src="{{ backPreview ? backPreview : 'assets/img/gallery/upload_image.png' }}"
                            id="image" style="width: 100%; height: 100%;" class="proPreview"
                            title="Click here to upload photo" />
                    </div>


                </div>
                </div>

              </div>
            </div>
            <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-success" style="float:right; margin-top:5px;">Save </button>
            </form>
        </div>
      </div>
      <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
        <div class="card-body">
          <div class="container">
            <!-- <div style="margin-bottom: 20px;">
               <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-success" (click)="onClickShowSaveForm('saveForm')" style="float:right;  margin-bottom: 20px; margin-right:5px;margin-left: 8px;">Add New</button> 
            </div>  -->
            
            
            
            <table id="example" class="table table-striped table-bordered table-sm scrollable-table" style="width:100%">
              <thead>
                  <tr>
                    <th>SL No.</th>
                    <th>Image</th>
                      <th>Name</th>
                      <th>nomineeDOB</th>
                      <th>nominee NID</th>                    
                      <th>nominee BirthId</th> 
                      <th>Relation</th>
                      <th>Share</th>
                      <th>Action</th>
                  </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of nominee; let i = index;">
                  <!-- <td>{{i + 1}}</td> -->
                  <td>{{i + 1}}</td>
                  <td><img class="avatar" src="data:image/jpeg;base64,{{item.baseString}}" alt="avatar" height="35" width="35" /></td>
                  <td>{{item.name}}</td>
                  <td>{{item.dateOfBirth}}</td>
                  <td>{{item.nid}}</td>
                  <td>{{item.birthCertificateNo}}</td>
                  <td>{{item?.relation?.name}}</td>
                  <td>{{item.sharingRate}}</td>
              
    
                  <td> 
                    <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                      <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;" (click)="deleteNm(item.id)"><i class="fa fa-trash-o 3x"></i></button>
                       
                  </td> 
                
                </tr>
               </tbody>
          </table>
             
          </div>
        </div>
      </div>
    </div>
  </div>
</div>