import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'app/services/shared.service';

import { CustomerService } from "../service/customer.service";
import { LayoutService } from 'app/shared/services/layout.service';
import { LeadGenerationServiceService } from 'app/loan-application/lead-generation-service.service';
import { LoanapplicationApprovalService } from 'app/loanapplication-approval/loanapplication-approval.service';
import { NgModule } from '@angular/core';
import { LoanApplicationComponent } from 'app/loan-application/loan-application/loan-application.component';
import Stepper from 'bs-stepper';
//import { LoanApplicationComponent } from './loan-application.component'; // Assuming this is what's declared

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit {
  //activeTab:string = '1-1';
  //mainTab:string = '1';

  activeTab: string = '1-1';
  mainTab: string = '';
  profileUpdate:any;

  customerDetailsInfos: any;

  //selectedOption: string = '1';

 activeButton = 1;
 activeSubButton = 1;
 activeFormbody = 1;

  // activeButton = 0;
  // activeSubButton = 0;
  // activeFormbody = 0;

  isApprover: boolean=true;

  currentUser:any;

  private stepper: Stepper;

  onSubmit() {
    return false;
  }
  next() {
    this.stepper.next();
  }
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal,
    private shared:SharedService,
    private customerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private layoutService: LayoutService,
    private changeDef:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // this.stepper = new Stepper(document.querySelector('#stepper1'), {
    //   linear: false,
    //   animation: true
    // })
   
    this.getProfileUpdateInfo();
    this.layoutService.getCurrentUserName().subscribe(res=>{
      this.currentUser = res;
    
      }
    );
  
  }
 
 
  
  setActiveButton(buttonNumber): void {
    ////this.activeButton = buttonNumber;
    this.activeButton = parseInt(buttonNumber, 10); 
    this.activeSubButton = 1;

   this.activeFormbody = buttonNumber;
    this.activeTab = buttonNumber;
   ///this.changeDetectorRef.detectChanges();
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe(res => {
      this.profileUpdate = res;
    })
    this.changeDetectorRef.detectChanges();
  }

}
