<div class="card">

  <div class="card-header d-flex flex-row align-items-center">
    <div class="col-md-9">
      <h6 class="font-weight-bold text-primary">Loan Payment Details</h6>
    </div>
    <div class="col-md-2" style="text-align: right;">
      <button type="button" data-toggle="tooltip" title="Go to dashboard" class="btn btn-secondary"
        (click)="goDashboard()">Dashboard</button>
    </div>
      <div class="col-1">
        <button class="btn btn-secondary" title="Go Back" (click)="goBack()">
      
          <i class="icon-arrow-left" style="font-size: 17px;"> </i>
        </button>
      </div>

  </div>
  <hr>
  <div class="card-body">
    <div class="">

      <form [formGroup]="frmGroup">
        <div asp-validation-summary="All" class="text-danger"></div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label for="sortorder" class="col-sm-4 col-form-label">Loan Account</label>
              <div class="col-sm-8">
                <select class="form-control" (change)="getCustomerLeadList($event.target.value)">
                  <option value="">Select</option>
                  <option *ngFor="let item of List" [value]="item.leadGenerationId">
                    {{ item.customerAccountNo }} - {{ item.accountType}}
                  </option>
                </select>
              </div>
            </div>
          </div>


          <!--  <div class="col-md-6">
              <div class="form-group row">
                <label for="leadCode" class="col-sm-4 col-form-label">Lead Code</label>
                <div class="col-sm-8">
                  
                  <input type="text" class="form-control" readonly formControlName="leadCode" placeholder="">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="ProductBuilder" class="col-sm-4 col-form-label">Loan Type</label>
                <div class="col-sm-8">
                  
                  <input type="text" class="form-control" readonly formControlName="productBuilder" placeholder="">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="accountNo" class="col-sm-4 col-form-label">Customer Account</label>
                <div class="col-sm-8">
                  
                  <input type="text" class="form-control" readonly formControlName="accountNo" placeholder="">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="outstanding" class="col-sm-4 col-form-label">Customer Name</label>
                <div class="col-sm-8">
                
                  <input type="text" class="form-control" readonly formControlName="customername">
               
                </div>
              </div>
            </div> -->
          <div class="col-md-6">
            <div class="form-group row">
              <label for="outstanding" class="col-sm-4 col-form-label">Out Standing</label>
              <div class="col-sm-8">
                <input type="hidden" class="form-control" formControlName="leadId">
                <input type="hidden" class="form-control" formControlName="customername">
                <input type="hidden" class="form-control" formControlName="outstanding">
                <input type="text" class="form-control" readonly style="text-align: right;"
                  [value]="frmGroup.get('finalOutstanding').value | number:'1.0-0'">
              </div>
            </div>
          </div>



          <div class="col-md-6">
            <div class="form-group row">
              <label for="outstanding" class="col-sm-4 col-form-label">Monthly EMI</label>
              <div class="col-sm-8">

                <input type="text" class="form-control" readonly style="text-align: right;"
                  [value]="frmGroup.get('emiAmount').value  | number:'1.0-0'">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row" *ngIf="frmGroup.get('penaltyAmount').value > 0">
              <label for="outstanding" class="col-sm-4 col-form-label">Penalty Amount</label>
              <div class="col-sm-8">

                <input type="text" class="form-control" readonly style="text-align: right;"
                  [value]="frmGroup.get('penaltyAmount').value">
              </div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="frmGroup.get('finalOverDue').value > 0">
            <div class="form-group row">
              <label for="outstanding" class="col-sm-4 col-form-label">Overdue Amount</label>
              <div class="col-sm-8">

                <input type="text" class="form-control" readonly style="text-align: right;"
                  [value]="frmGroup.get('finalOverDue').value  | number:'1.0-0'">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="collectionamount" class="col-sm-4 col-form-label">Payment Amount</label>
              <div class="col-sm-8">
                <!-- <input type="text" class="form-control" formControlName="collectionAmount" placeholder="0" style="text-align: right;"  [value]="frmGroup.get('collectionAmount').value | number:'1.0-0'"> -->



                <input type="text" class="form-control" formControlName="collectionAmount" placeholder="0"
                  style="text-align: right"
                  (input)="frmGroup.get('collectionAmount').setValue(formatNumber($event.target.value))">


                <div *ngIf="frmGroup.get('collectionAmount').hasError('pattern')" class="text-danger">
                  Only numeric values are allowed.
                </div>
              </div>
            </div>
          </div>



        </div>








        <button class="btn btn-primary ml-2" (click)="CollentionPDF()" type="button" title="Disburse Loan"
          style="float:right; margin-top:5px;"> Payment PDF </button>

        <button class="btn btn-primary ml-2" (click)="SchedulePDF()" type="button" title="Disburse Loan"
          style="float:right; margin-top:5px;"> Schedule PDF</button>

        <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit"
          (click)="Savecollectionamount()" value="Submit" class="btn btn-primary" style="float:right; margin-top:5px;">
          <span>Loan Payment</span>
        </button>





      </form>
    </div>
  </div>
</div>