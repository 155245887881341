
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { AccountingreportService } from '../accountingreport.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-group-summary",
  templateUrl: "./group-summary.component.html",
  styleUrls: ["./group-summary.component.scss"],
})
export class GroupSummaryComponent implements OnInit {
  fromDate: Date = new Date();
  toDate: Date = new Date();
  reportBy: number;

  groupList: any[] = [];
  AccountGroupList: any[] = [];
  ledgerList: any[] = [];
  SubLedgerList: any[] = [];

  mgId: number;
  gId: number;
  LId: number;
  subLedgerId: number;

  constructor(
    private pdfService: PdfService,
    private accountReportService: AccountingreportService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.onMGNameSelected();
  }

  // onReportByChange() {

  // }

  onMGNameSelected() {
    this.accountReportService.GetMainGroupInfo().subscribe((response: any) => {
      this.groupList = response;
    });
  }

  onGroupChange(Id: any): void {
    if (Id > 0 || Id != null || Id != "") {
      this.GetAccountGroupList(Id);
    }
  }

  GetAccountGroupList(Id: number): void {
    this.accountReportService
      .GetSubGroupByMgId(Id)
      .subscribe((response: any) => {
        this.AccountGroupList = response;

        this.changeDetectorRef.detectChanges();
      });
  }

  onAccountGroupChange(Id: any): void {
    if (Id > 0 || Id != null || Id != "") {
      this.GetLedgerGroupList(Id);
    }
  }

  GetLedgerGroupList(Id: number): void {
    this.accountReportService.GetLedgerByGId(Id).subscribe((response: any) => {
      this.ledgerList = response;

      this.changeDetectorRef.detectChanges();
    });
  }

  onLedgerChange(Id: any): void {
    if (Id > 0 || Id != null || Id != "") {
      this.GetSubLedgerList(Id);
    }
  }

  GetSubLedgerList(Id: number): void {
    this.accountReportService
      .GetSubLedgerByGId(Id)
      .subscribe((response: any) => {
        this.SubLedgerList = response;

        this.changeDetectorRef.detectChanges();
      });
  }

  OpenPDF() {
    this.pdfService
      .GroupSummaryReport(
        this.fromDate,
        this.toDate,
        1,
        2,
        this.reportBy,
        this.mgId,
        this.gId,
        this.LId,
        this.subLedgerId
      )
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }
  goBack() {
    this.location.back();
  }
}
