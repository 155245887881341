<section>
  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Tax Add</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
          (click)="onClickShowSaveForm('saveList')"
          style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
        <hr>
        <form [formGroup]="frmGroup">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Tax Key Code</label>
                <div class="col-sm-8">
                  <input type="text" oninput="this.value = this.value.toUpperCase()" formControlName="taxCode" class="form-control" readonly>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Tax Key Name</label>
                <div class="col-sm-8">
                  <input type="text" formControlName="taxKeyName" class="form-control" placeholder="Type here...">
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="shortName" class="col-sm-4 col-form-label">Remarks</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="remarks" placeholder="">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="sortorder" class="col-sm-4 col-form-label">Transaction Type</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="transectionTypeId">
                    <option value="">Select</option>
                    <option *ngFor="let item of transactionlist" [value]="item.id">{{item.typeName}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="sortorder" class="col-sm-4 col-form-label">Status</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="status">
                    <option value="">Select</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
            
            <div class="col-md-6">
              <div class="form-group row">
                <label for="sortorder" class="col-sm-4 col-form-label">Product Type</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="productTypeId">
                    <ng-container *ngFor="let type of productBuilderTypes | keyvalue">
                      <option *ngIf="type.key!=='0' && type.key!=='1' && type.key!=='2'" [value]="type.value">{{ type.key }}</option>
                  </ng-container>
                  </select>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Tax Details</h5>
        <hr>
        <div class="row">
          <div class="col-md-5">
            <div class="form-group row">
              <label for="amount" class="col-sm-4 col-form-label">Amount</label>
              <div class="col-sm-8">
                <input type="number" min="0" class="form-control" [(ngModel)]="amount" placeholder="Type here...">
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group row">
              <label for="taxPercent" class="col-sm-4 col-form-label">Percent %</label>
              <div class="col-sm-8">

                <input type="number" min="0" class="form-control" [(ngModel)]="taxPercent" placeholder="Type here...">
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <button type="button" (click)="addTax()" data-toggle="tooltip" title="Add new"
              class="btn btn-primary">Add</button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <div class="form-group row">
              <label for="percent" class="col-sm-4 col-form-label">Type</label>
              <div class="col-sm-8">
                <select class="form-control" [(ngModel)]="type">
                  <option value="1">Up to</option>
                  <option value="0">Extra</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="container">
        <table class="table">
          <thead>
            <tr>
              <th>Sl.</th>
              <th>Amount</th>
              <th>Percent %</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tax of taxes; let i = index">
              <td>{{ i+1 }}</td>
              <td style="text-align: center;">{{ tax.amount }}</td>
              <td style="text-align: center;">{{ tax.taxPercent }}</td>
              <td>{{tax.type === 1 ? 'Up to' : 'Extra to'}}
              </td>
              <td>
                <button type="button" class="btn btn-info  mr-2" (click)="editTax(i)">Edit</button>
                <button type="button" class="btn btn-danger" (click)="deleteTax(i)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="form-group">
      <button type="button" data-toggle="tooltip" title="Submit" (click)="onSubmit()" class="btn btn-secondary"
        style="float:right; margin-top:-31px;margin-right:38px;margin-left: 8px;">Submit</button>

    </div>
  </div>


  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Tax List</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
          (click)="onClickShowSaveForm('saveForm')"
          style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
        <hr>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control"
              style="width:16%">
              <option value="5">5</option>
              <option selected value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="form-group col-md-6" style="text-align: right">
            <input type="text" class="form-control" [(ngModel)]="searchControl" placeholder="Search"
              (keyup)="onSearch()">
          </div>
        </div>
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th style="width: 10%;">SL No.</th>
              <th>Tax Key </th>
              <th>Type</th>
              <th>Detail </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of list | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
              <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
              <td>{{item.taxKey.taxCode}} - {{item.taxKey.taxKeyName}}</td>
              <td>{{productBuilderTypes[item.taxKey.productBuilderType]}} Product</td>
              <td>
                <ul>
                  <li *ngFor="let detail of item.taxDetails">
                    {{detail.amount}} - {{detail.taxPercent}} % {{detail.type == '1' ? 'Up to' : 'Extra to'}}</li>
                </ul>
              </td>
              <!-- <td>
                    <ng-container *ngFor="let detail of item.taxDetails">
                     {{detail.taxPercent}}
                      <br>
                    </ng-container>
                  </td> -->
              <td>
                <button class="btn btn-primary" (click)="edit(item)">
                  <i class="fa fa-edit 3x"></i>
                </button>
                <button class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.taxKey.id)">
                  <i class="fa fa-trash-o 3x"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <span>Showing {{currentItems}} of {{totalItems}} entries</span>
          </div>
          <div class="col-md-6" style="text-align: right">
            <pagination-controls (pageChange)="onPageChange($event)">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>