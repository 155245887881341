import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const urls = environment.baseUrl + '/api/LoanApproval/';
const url2 = environment.baseUrl;

@Injectable({

  providedIn: 'root'

})
export class LoanapplicationApprovalService {

  constructor(private http:HttpClient) { }

  getStatusList(): Observable<any>
  {
    return this.http.get(urls + 'GetStatusInfoList');
  }


  getLeadInformationData(leadNo: string)
  {
    return this.http.get(urls + 'SelfCustomerLoanData/'+ leadNo );
  }


  ApproverForwardLeadByCode(leadCode: any,statusId:number)
  {
    return this.http.get(urls + 'ForwardLeadByCode/' + leadCode + '/' + statusId );
  }

  ForwardLeadByCodeForApproval(leadCode: string){
    return this.http.get(urls + 'LoanApplicationForwardLeadByCode/' + leadCode);
  }


  getLoanregisterbyLeadId(leadId: number){

    return this.http.get(url2 + '/api/LoanApplication/GetLoanRegistersByLeadId/' + leadId);
  }
  getLoanLeadAllInfoById(leadId: number){

    return this.http.get(url2 + '/api/LoanApplication/GetLoanLeadAllInfoById/' + leadId);
  }
  GetLoanMenuSetUpByLoanProductId(ProductId: any){
    return this.http.get(urls + '/api/LoanApplication/GetLoanMenuSetUpByLoanProductId/' + ProductId);
  }
  GetLoanMenuSetupforlist(): Observable<any>
  {
    return this.http.get(url2 + '/api/LoanMenuSetup/GetLoanMenuSetupforlist');
  }
  GetLoanMenuSetupforlistChild(parentId : any): Observable<any>
  {
    //alert(13)
    return this.http.get(url2 + '/api/LoanMenuSetup/GetLoanMenuSetupforlistChild/'+ parentId);
  }
}
