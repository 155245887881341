import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-vehicle-loan',
  templateUrl: './vehicle-loan.component.html',
  styleUrls: ['./vehicle-loan.component.scss']
})
export class VehicleLoanComponent implements OnInit {
  isVehicleLoanInfoEdit: boolean = false;
  model: any = {};
  frmGroupVehicleLoan: FormGroup;
  clientInfoId: any = 0;
  loanRegId: any = 0;
  leadId: any = 0;
  loanRegVicales: any = 0;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private shared: SharedService,
  ) { }

  ngOnInit(): void {
  }


  VehicleLoanreset() {

    this.VehicleLoanpopulateForm();
    this.model = {};
  }
  VehicleLoanpopulateForm() {
    this.frmGroupVehicleLoan = this.formBuilder.group({
      id: [0],
      brand: ['', Validators.required],
      vehiclesModel: ['', Validators.required],
      manufacturingYear: ['', Validators.required],
      engineNo: ['', Validators.required],
      chassisNo: ['', Validators.required],
      cc: ['', Validators.required],
      vendor: [null],
      contactPerson: [null],
      address: [null],
      telephone: [null],
      mobile: ['', Validators.required],
    })
  }

  GetVehicleLoanInfoByRegId(id: number) {
    this.isVehicleLoanInfoEdit = true;
    // this._serviceLoanReg.GetVehicleLoanInfoByRegId(id).subscribe(
    //   (loanRegister: LoanRegister) => {
    //     this.loanRegVicales=loanRegister.id ;
    //     if (loanRegister && loanRegister.id > 0) {
    //       this.frmGroupVehicleLoan.patchValue({
    //         id: loanRegister.id,
    //         brand: loanRegister.brand,
    //         vehiclesModel: loanRegister.vehiclesModel,
    //         manufacturingYear: loanRegister.manufacturingYear,
    //         engineNo: loanRegister.engineNo,
    //         chassisNo: loanRegister.chassisNo,
    //         cc: loanRegister.cc,
    //         vendor: loanRegister.vendor,
    //         contactPerson: loanRegister.contactPerson,
    //         address: loanRegister.address,
    //         telephone: loanRegister.telephone,
    //         mobile: loanRegister.mobile,
    //       });
    //     } else {
    //       this.VehicleLoanpopulateForm();
    //     }
    //   }
    // );
  }
  onVehicleLoanSubmit() {
    if (this.clientInfoId > 0) {
      if (this.loanRegId > 0) {
        this.model.id = this.frmGroupVehicleLoan.value.id;
        this.model.brand = this.frmGroupVehicleLoan.value.brand;
        this.model.vehiclesModel = this.frmGroupVehicleLoan.value.vehiclesModel;
        this.model.manufacturingYear = this.frmGroupVehicleLoan.value.manufacturingYear;
        this.model.engineNo = this.frmGroupVehicleLoan.value.engineNo;
        this.model.chassisNo = this.frmGroupVehicleLoan.value.chassisNo;
        this.model.cc = this.frmGroupVehicleLoan.value.cc;
        this.model.vendor = this.frmGroupVehicleLoan.value.vendor;
        this.model.contactPerson = this.frmGroupVehicleLoan.value.contactPerson;
        this.model.address = this.frmGroupVehicleLoan.value.address;
        this.model.telephone = this.frmGroupVehicleLoan.value.telephone;
        this.model.mobile = this.frmGroupVehicleLoan.value.mobile;
        this.model.loanRegisterMasterId = this.loanRegId;
        this.model.leadId = this.leadId;
        // this._serviceLoanReg.saveVehicleLoan(this.model).subscribe(res => {
        //   if (this.model.id > 0) {
        //     this.shared.ShowAlert('Thank you...', 'Updated successfully!', 'success');
        //   } else {
        //     this.shared.ShowAlert('Thank you...', 'Saved successfully!', 'success');
        //   }
        //   //this.getLeadInfo();
        //   this.loanRegVicales = res; 
        // }, error => {
        //   if (this.model.id > 0) {
        //     this.shared.ShowAlert('Error...', 'An error occurred while updating the vehicle loan.', 'error');
        //   } else {
        //     this.shared.ShowAlert('Error...', 'An error occurred while saving the vehicle loan.', 'error');
        //   }

        //   //this.getLeadInfo();
        // });
      } else {
        this.shared.ShowAlert('Warning...', 'Please Register Loan First!', 'warning');
        //this.getLeadInfo();
      }
    } else {
      this.shared.ShowAlert('Warning...', 'First Create a Client application!', 'warning');
     // this.getLeadInfo();
    }
  }
}
