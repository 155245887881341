import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ChargeService } from "../service/charge.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "app/services/shared.service";
import { ProductBuilderService } from "app/product/service/product-builder.service";
import Swal from "sweetalert2";
import { ProductBuilderTypeEnum } from "../model/productBuilderTypeEnum";
@Component({
  selector: "app-charge",
  templateUrl: "./charge.component.html",
  styleUrls: ["./charge.component.scss"],
})
export class ChargeComponent implements OnInit {
  list: any[] = [];
  isEdit: boolean = false;
  frmGroup: FormGroup;
  productTypeId: number=0;
  productBuilderTypes = ProductBuilderTypeEnum;
  model: any = {};

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = "";
  showAmount: string = "Show";
  showPercentage: string = "Hide";

  constructor(
    private _service: ChargeService,
    private _productService: ProductBuilderService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private shared: SharedService
  ) {
    this.getAllChargeList();
  }

  ngOnInit(): void {
    this.populateForm();

    this.showSaveForm = "saveList";
    // this.showAmount='Show';
    // this.showPercentage='Hide';
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getAllChargeList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getAllChargeList();
  }

  onSearch() {
    this.currentPage = 1;
    this.getAllChargeList();
  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.getCodeByType(5);
    this.reset();
  }

  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      code: [""],
      name: [""],
      amount: [""],
      remarks: ["Yearly Charge"],
      type: ["1"],
      status: [""],
      productTypeId:[0]
    });
  }

  GetAllChargeList() {
    this._service.GetAllChargeList().subscribe((res) => {
      this.list = res;
    });
  }

  getCodeByType(type: number) {
    this._productService
      .getProductMasterDateCodeByType(type)
      .subscribe((res) => {
        this.frmGroup.patchValue({
          code: res["code"],
        });
      });
  }

  getAllChargeList() {
    this._service
      .getListWithSerchAndPagination(
        this.currentPage,
        this.itemsPerPage,
        this.searchControl
      )
      .subscribe((res: any) => {
        this.list = res.data;
        this.totalItems = res.totalItems;
        this.currentItems = res.currentItems;
      });
  }

  // onsubmit() {
  //   if (this.isEdit) {
  //     this.model.id = this.frmGroup.value.id;
  //   }
  //   else {
  //     this.model.id = 0;
  //   }
  //   this.model.code = this.frmGroup.value.code;
  //   this.model.name = this.frmGroup.value.name;
  //   this.model.amount = this.frmGroup.value.amount;
  //   this.model.remarks = this.frmGroup.value.remarks;
  //   this.model.status = (this.frmGroup.value.status == "true") ? true : false;
  //   this._service.SaveCharge(this.model).subscribe(res => {
  //     if (this.model.id) {
  //       this.shared.ShowAlert("Success", "Updated Successfully", "success");
  //     }
  //     else {
  //       this.shared.ShowAlert("Success", "Save Successfully", "success");
  //     }
  //     this.isEdit = false;
  //     this.showSaveForm = 'saveList';
  //     this.getAllChargeList();

  //   })
  // }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = "saveForm";
    this.frmGroup.patchValue({
      id: item.id,
      code: item.code,
      name: item.name,
      amount: item.amount,
      remarks: item.remarks,
      type: item.type,
      status: item.status,
      productTypeId:item.productBuilderType
    });
  }
  delete(id: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete charge information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this._service.DeleteCharge(id).subscribe(
          (res) => {
            this.shared.ShowAlert(
              "Alert",
              "Data deleted successfully.",
              "warning"
            );
            this.getAllChargeList();
            this.isEdit = false;
            this.showSaveForm = "saveList";
          },
          (error) => {
            this.shared.ShowAlert(
              "Warning",
              "Something went wrong!",
              "warning"
            );
          }
        );
      } else if (result.isDismissed) {
        this.shared.ShowAlert("Warning", "Operation cancelled!", "warning");
      }
    });
  }
  onTypeChange(typeId: number) {
   
    if (typeId == 1) {
      this.showAmount = "Show";
      this.showPercentage = "Hide";
    } else {
      this.showAmount = "Hide";
      this.showPercentage = "Show";
    }
  }

  onsubmit() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save charge information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, save it!",
    }).then((res) => {
      if (res.isConfirmed) {
        if (this.isEdit) {
          this.model.id = this.frmGroup.value.id;
        } else {
          this.model.id = 0;
        }
        this.model.code = this.frmGroup.value.code;
        this.model.name = this.frmGroup.value.name;
        this.model.amount = this.frmGroup.value.amount;
        this.model.remarks = this.frmGroup.value.remarks;
        this.model.type = this.frmGroup.value.type;
        this.model.productTypeId = this.frmGroup.value.productTypeId;
        this.model.status = this.frmGroup.value.status == "true" ? true : false;
        this._service.SaveCharge(this.model).subscribe((res) => {
          if (this.model.id) {
            this.shared.ShowAlert("Success", "Updated Successfully", "success");
          } else {
            this.shared.ShowAlert("Success", "Save Successfully", "success");
          }
          this.isEdit = false;
          this.showSaveForm = "saveList";
          this.getAllChargeList();
        });
      } else if (res.isDismissed) {
        this.shared.ShowAlert("Warning", "Something Wrong!", "warning");
      }
    });
  }
}
