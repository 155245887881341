import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from 'app/customer/customer.service';

import { SharedService } from 'app/services/shared.service';
import { Customer } from '../model/customer';
import { ActivatedRoute } from '@angular/router';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { id } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-basic-info-self',
  templateUrl: './basic-info-self.component.html',
  styleUrls: ['./basic-info-self.component.scss']
})
export class BasicInfoSelfComponent implements OnInit {
  frmGroup: FormGroup;
  generatecustomercode: any[] = [];
  model: any = {};
  clientInfoId: any = 0;
  //leadId: any = 0;
  leadCode: any;
  isClientInfoEdit: boolean = false;
  isAuto: boolean=false;
  
  loanId:number = 0;
  leadId:number = 0;
  customerDetailsInfos: any;
  customersId: any = 0;
  loanProductsId: any = 0;
  leadNo:string = "";
  profileUpdate: any;
  constructor(
    private customerService: CustomerService,
    private shared: SharedService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    // private activeRoute: ActivatedRoute,
    private _leadGennarationService: LeadGenerationServiceService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    
  ) { 
    this.frmGroup = this.formBuilder.group({
      id: 0,
      leadId: 0,
      customerRCode: '',
      customerNameEng: '',
      customerNameBan: '',
      dateOfBirth: '',
      GenderId: ['select'],
      mobileNo: '',
      email: '',
      nidNo: '',
      fatherNameEng: '',
      fatherNameBan:'',
      motherNameEng:'',
      motherNameBan:'',
      maritalStatus:'',
      spouseNameEng:'',
      spouseNameBan:'',
      spouseMobileNo:'',
      districtId:'',
      upazilaId:'',
    });
   
  }

  ngOnInit(): void {
    // this.GetCustomerGenerateCode();
    // this.ClientspopulateForm();
    // this.getLeadInfo();
     //this.GetCustomerDetailsForEdit();
    this.route.paramMap.subscribe(params => {
      this.leadId = parseInt(params.get('leadId'), 10);
      this.customersId = parseInt(params.get('customersId'), 10);
      this.loanProductsId = parseInt(params.get('loanProductsId'), 10);
      this.leadNo = params.get('leadNo');
      
    });

    this.LoadPersonalInfo(this.leadId,this.customersId,this.loanProductsId);
    this.getProfileUpdateInfo();
  }



  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe(res => {
      this.profileUpdate = res;
    })
    this.changeDetectorRef.detectChanges();
  }



  
  LoadPersonalInfo(leadId: number,customersId:number,loanProductsId:number){
   
    this.customerService.GetLeadGenerationByLeadNoforBasic(leadId,customersId,loanProductsId).subscribe(res =>{
      
      if(res != null){
        this.isAuto=true;
        this.frmGroup.patchValue({
          id: res.clientInfo?.id,
          leadId: res.id,
          customerRCode: res.customer?.customerRCode,
          customerNameEng: res.customer?.customerNameEng,
          customerNameBan: res.customer?.customerNameBan,
          dateOfBirth: this.datePipe.transform(res.customer?.dateOfBirth, 'dd-MMM-yyyy'),
          GenderId: res.customer?.genderId,
          mobileNo: res.customer?.mobileNo,
          email: res.customer?.email,
          nidNo: res.customer?.nidNo,
          fatherNameEng: res.customer?.fatherNameEng,
          fatherNameBan: res.customer?.fatherNameBan,
          motherNameEng: res.customer?.motherNameEng,
          motherNameBan: res.customer?.motherNameBan,
          maritalStatus: res.customer?.maritalStatus,
          spouseNameEng: res.customer?.spouseNameEng,
          spouseNameBan: res.customer?.spouseNameBan,
          spouseMobileNo: res.customer?.spouseMobileNo,
          districtId: res.customer?.district?.districtName,
          upazilaId: res.customer?.upazila?.upazilaName,

        });
      }
      else{
        this.frmGroup = this.formBuilder.group({
          id: 0,
          leadId: 0,
          customerRCode:'',
          customerNameEng: '',
          NameBangla: '',
          dateOfBirth: '',
          GenderId: 'select',
          email: '',
          mobileNoHome: '',
          nidNo: '',
          fatherNameEng: '',
          fatherNameBan:'',
      motherNameEng:'',
      motherNameBan:'',
      maritalStatus:'',
      spouseNameEng:'',
      spouseNameBan:'',
      spouseMobileNo:'',
      districtId:'',
      upazilaId:'',
        });
      }
    })
  }
  gender: string = 'select';
  onGenderStatusChange(value: string) {
    this.gender = value;
  }

 
  // GetCustomerDetailsForEdit() {
 
  //   this.customerService.GetCustomerDetailsForEdit().subscribe(data  => {
  //     // console.log("basic");
  //     // console.log(data);
  //       this.customerDetailsInfos = data;
       

  //    // this.changeDef.detectChanges();
  //     })
  // }


  
  
}
