
<div class="row">
    <div class="col-6">
      <h6 class="m-0 font-weight-bold text-primary">MIS-Report</h6>
    </div>
    <div class="col-6">
      <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
    
        <i class="icon-arrow-left" style="font-size: 17px;"> </i>
      </button>
    </div>
  </div>
    
  
  <hr>
  
      <div class="col-12 row">
  
        <div class="col-lg-3 col-md-6 col-12 col-sm-12">
          <div class="card cuscard " (click)="changeReport(1)" style="cursor: pointer; height: 100px;">
            <div class="card-content">
              <div class="card-body py-0">
                <div class="media pb-1 align-self-center">
                  <div class="black customIcon">
                    <i class="fa fa-print"></i>
                   
                  </div>
                  <div class="black ml-2 mt-2">
                    <span>Loan Disbursement</span>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 col-sm-12">
          <div class="card  cuscard" (click)="changeReport(2)" style="cursor: pointer; height: 100px;">
            <div class="card-content">
              <div class="card-body py-0">
                <div class="media pb-1 align-self-center">
                  <div class="black customIcon">
                    
                    <i class="fa fa-print"></i>
                  </div>
                  <div class="black ml-2 mt-3">
                    <span>Outstanding Report</span>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
  
        <div class="col-lg-3 col-md-6 col-12 col-sm-12">
          <div class="card cuscard" (click)="changeReport(3)" style="cursor: pointer; height: 100px;">
            <div class="card-content ">
              <div class="card-body py-0">
                <div class="media pb-1">
                  <div class="black customIcon">
                    <i class="fa fa-print"></i>
                  </div>
                  <div class="black ml-2 mt-3">
                    <span>Overdue Report</span>
                  </div>
    
                </div>
              </div>
            
            </div>
          </div>
        </div>
        
        <div class="col-lg-3 col-md-6 col-12 col-sm-12">
          <div class="card  cuscard" (click)="changeReport(4)"  style="cursor: pointer; height: 100px;">
            <div class="card-content">
              <div class="card-body py-0">
                <div class="media pb-1 align-self-center">
                  <div class="black  customIcon">
                    <i class="fa fa-print"></i>
                  
                  </div>
                  <div class="black ml-2 mt-3">
                    <span>Collection Report</span>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
  
  <hr>
      <!-- ------------------------------------------------------------- -->
     
      <div class="col-12 row">
        <div class="col-lg-3 col-md-6 col-12 col-sm-12">
          <div class="card cuscard " (click)="changeReport(5)" style="cursor: pointer; height: 100px;">
            <div class="card-content">
              <div class="card-body py-0">
                <div class="media pb-1 align-self-center">
                  <div class="black customIcon">
                    <i class="fa fa-print"></i>
                  
                  </div>
                  <div class="black ml-2 mt-3">
                    <span>Settlement Report</span>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 col-sm-12">
            <div class="card cuscard"  (click)="changeReport(6)" style="cursor: pointer; height: 100px;">
              <div class="card-content">
                <div class="card-body py-0">
                  <div class="media pb-1 align-self-center">
                    <div class="black customIcon">
                      
                      <i class="fa fa-print"></i>
      
                    </div>
                    <div class="black ml-2 mt-3">
                      <span>Interest Income Report</span>
      
                    </div>
      
                  </div>
                </div>
              
              </div>
            </div>
        </div>


        <!-- <div class="col-lg-3 col-md-3 col-12 col-sm-12">
          <a routerLink="/accounting-reports/profit-loss-report">
            <div class="card cuscard"  style="cursor: pointer; height: 100px;">
              <div class="card-content">
                <div class="card-body py-0">
                  <div class="media pb-1 align-self-center">
                    <div class="black customIcon">
                      
                      <i class="fa fa-print"></i>
      
                    </div>
                    <div class="black ml-2 mt-3">
                      <span>Profit & Loss</span>
      
                    </div>
      
                  </div>
                </div>
              
              </div>
            </div>
                      
          </a>
         
        </div>
        <div class="col-lg-3 col-md-3 col-12 col-sm-12">
          <a routerLink="/accounting-reports/balannce-sheet-report">
            <div class="card cuscard"  style="cursor: pointer; height: 100px;">
              <div class="card-content">
                <div class="card-body py-0">
                  <div class="media pb-1 align-self-center">
                    <div class="black customIcon">
                     
                      <i class="fa fa-print"></i>
                     
                    </div>
                    <div class="black ml-2 mt-3">
                      <span>Balance Sheet </span>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
                      
          </a>
         
        </div> -->
      
  
        
      
      </div>
     
  
  <hr>
  
   <!-- ------------------------------------------------------------- -->
     
   <!-- <!-- <div class="col-12 row">
      
    <div class="col-lg-3 col-md-6 col-12 col-sm-12">
      <a routerLink="/accounting-reports/group-summary">
        <div class="card cuscard"  style="cursor: pointer; height: 100px;">
          <div class="card-content">
            <div class="card-body py-0">
              <div class="media pb-1 align-self-center">
                <div class="black customIcon">
                 
                  <i class="fa fa-print"></i>
                 
                </div>
                <div class="black ml-2 mt-2">
                  <span>Group Summary</span>
                </div>
              </div>
            </div>
           
          </div>
        </div>
                  
      </a>
     
    </div>
    <div class="col-lg-3 col-md-6 col-12 col-sm-12">
      <a routerLink="/accounting-reports/sub-ledger-book">
        <div class="card cuscard"  style="cursor: pointer; height: 100px;">
          <div class="card-content">
            <div class="card-body py-0">
              <div class="media pb-1 align-self-center">
                <div class="black customIcon">
                 
                  <i class="fa fa-print"></i>
                 
                </div>
                <div class="black ml-2 mt-2">
                  <span>Sub-ledger-book</span>
                </div>
              </div>
            </div>
           
          </div>
        </div>
                  
      </a>
     
    </div>
  <div class="col-lg-3 col-md-6 col-12 col-sm-12">
    <a routerLink="/accounting-reports/income-expense-report">
      <div class="card cuscard" style="cursor: pointer; height: 100px;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1 align-self-center">
              <div class="black customIcon">
  
                <i class="fa fa-print"></i>
  
              </div>
              <div class="black ml-2 mt-2">
                <span>Income-Expense-Report</span>
              </div>
            </div>
          </div>
  
        </div>
      </div>
  
    </a>
  
  </div>
  </div> 
  
  
  <hr> -->
  
  