import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadGenerationServiceService } from 'app/loan-application/lead-generation-service.service';
import { SharedService } from 'app/services/shared.service';
import { CustomerService } from '../customer.service';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from 'app/shared/services/layout.service';
import Stepper from 'bs-stepper';

@Component({
  selector: 'app-customer-edit-for-admin',
  templateUrl: './customer-edit-for-admin.component.html',
  styleUrls: ['./customer-edit-for-admin.component.scss']
})
export class CustomerEditForAdminComponent implements OnInit {

  activeTab: string = '1-1';
  mainTab: string = '';
  profileUpdate:any;

  customerDetailsInfos: any;

  //selectedOption: string = '1';

 activeButton = 1;
 activeSubButton = 1;
 activeFormbody = 1;

  // activeButton = 0;
  // activeSubButton = 0;
  // activeFormbody = 0;

  isApprover: boolean=true;

  currentUser:any;
  id:number;

  private stepper: Stepper;

  onSubmit() {
    return false;
  }
  next() {
    this.stepper.next();
  }
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal,
    private shared:SharedService,
    private customerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private layoutService: LayoutService,
    private changeDef:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // this.stepper = new Stepper(document.querySelector('#stepper1'), {
    //   linear: false,
    //   animation: true
    // })
    this.activeRoute.params.subscribe(params=>{
      this.id=params['id'];
    })
   
    this.getProfileUpdateInfo(this.id);
    
    this.layoutService.getCustomerListForAdmin(this.id).subscribe(res=>{
      this.currentUser = res;
    
      }
    );
  
  }
 
 
  
  setActiveButton(buttonNumber): void {
    ////this.activeButton = buttonNumber;
    this.activeButton = parseInt(buttonNumber, 10); 
    this.activeSubButton = 1;

   this.activeFormbody = buttonNumber;
    this.activeTab = buttonNumber;
   this.changeDetectorRef.detectChanges();
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  getProfileUpdateInfo(id : number) {
    this.customerService.getProfileUpdatePercentageByAdmin(id).subscribe(res => {
      this.profileUpdate = res;
    })
    this.changeDetectorRef.detectChanges();
  }

}
