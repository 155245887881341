import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../customer.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-whitelist-excel-upload',
  templateUrl: './whitelist-excel-upload.component.html',
  styleUrls: ['./whitelist-excel-upload.component.scss']
})
export class WhitelistExcelUploadComponent implements OnInit {

  constructor(private customerService: CustomerService,
    private pdfService: PdfService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private shared: SharedService,
    private http: HttpClient 
    ) { }

  ngOnInit(): void {
  }

  public showTable: boolean = false;
  public tableData: any[] = [];

  onFileChange(event: any): void {
    const file = event.target.files[0];

    if (file.name !== 'WhiteListCustomerFormat.xlsx') {
      alert('Wrong file name. Please rename the file to LeadReceivers.xlsx and try again.');
      return;
    }

    this.showTable = true;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
   
    };

    reader.readAsArrayBuffer(file);
  }

  formatDate(date: string): string {
    const formattedDate = new Date(date);
    // Implement your date formatting logic here
    return formattedDate.toISOString();
  }
  

  fileForamtExcel() {
    this.pdfService.DownloadWhiteListCustomerFormat().subscribe(res => {
      const blob: Blob = res.body as Blob;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'WhiteListCustomerFormat.xlsx';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  }



  onSubmit(): void {
    const formData = new FormData();
    const fileInput = document.getElementById('file-input') as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      formData.append('formFile', file);

      this.customerService.UploadWhiteListCustomer(formData).subscribe(
        (res) => {
          console.log("uyiuyiyu")
          console.log(res)
          this.shared.ShowAlert('Success', 'Save Successful', 'success');
          this.router.navigate(['/customer/whitelist-customer']);
          // if (res === 1){
          //   this.shared.ShowAlert('Success', 'Save Successful', 'success');
          //   this.router.navigate(['/customer/whitelist-customer']);
          // }
         
        },
        (error) => {
          console.error('Error uploading file:', error);
        }
      );
    } else {
      // No file selected, show an alert or handle accordingly
    }
  }





  // onsubmit() {

  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "Do you want to Create Account!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, Create Account!"
  //   }).then((res) => {
  //     if (res.isConfirmed) {
  //       this.customerService.UploadWhiteListCustomer().subscribe(res => {

  //         this.shared.ShowAlert("Success", "Save Successful", "success");
  //         this.router.navigate(['/customer/whitelist-customer']);
  //       })
 
  //     }
  //     else if (res.isDismissed) {
  //       this.router.navigate(['/customer/whitelist-excel-upload']);
  //     }

  //   }
  // )}

  


}
