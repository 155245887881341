import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from "@angular/common";
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { LoanapplicationApprovalService } from 'app/loanapplication-approval/loanapplication-approval.service';
import { environment } from 'environments/environment';
const apiUrlDoc = environment.baseUrl + '/api/LoanApplication/';

@Component({
  selector: 'app-loan-application-full-view',
  templateUrl: './loan-application-full-view.component.html',
  styleUrls: ['./loan-application-full-view.component.scss']
})
export class LoanApplicationFullViewComponent implements OnInit {
  leadId: number;
  loanInfo: any;
  cusProf1: boolean;
  cusProf2: boolean;
  cusProf3: boolean;
  constructor(
    private activeRoute: ActivatedRoute,
    private pdfService: PdfService,
    private loanService: LoanapplicationApprovalService,
    private location: Location) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(params => {
      this.leadId = parseInt(params.get('id'));
    });
    this.loanService.getLoanLeadAllInfoById(this.leadId).subscribe((res:any)=>{
 
      this.loanInfo=res;
      if(res.customerProfessionalInfo!=null && res.customerProfessionalInfo?.professionaltypeId==1){
        this.cusProf1=true;
        this.cusProf2=false;
        this.cusProf3=false;
      }
      else if(res.customerProfessionalInfo!=null && res.customerProfessionalInfo?.professionaltypeId==2){
        this.cusProf2=true;
        this.cusProf1=false;
        this.cusProf3=false;
      }
      else if(res.customerProfessionalInfo!=null && res.customerProfessionalInfo?.professionaltypeId==3){
        this.cusProf3=true;
        this.cusProf1=false;
        this.cusProf2=false;
      }
    })
  }

  goDetails() {
    this.pdfService.OpenLoanApplicationFullView(this.leadId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }
  downloadFile(id: number) {
    const fileUrl = `${apiUrlDoc}download?id=${id}`;
    window.open(fileUrl, '_blank');
  }
  getPreviewUrl(fileUrl: string): string {
    return `${apiUrlDoc}Preview?fileUrl=${fileUrl}`;
  }
  fullPreviewImage(fileUrl: string) {
    const previewUrl = this.getPreviewUrl(fileUrl);
    // Open the preview in a new tab or show it in a modal, depending on your requirements
    window.open(previewUrl, '_blank');
  }
  goBack() {
    this.location.back();
  }
}
