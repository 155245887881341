import { Component, OnInit, ViewChild } from '@angular/core';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { LoanLimitService } from '../service/loan-limit.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { LoanLimtModel } from '../model/loan-limt-model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbDatepickerI18n, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'app/services/shared.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-loan-limit-entry',
  templateUrl: './loan-limit-entry.component.html',
  styleUrls: ['./loan-limit-entry.component.scss']
})
export class LoanLimitEntryComponent implements OnInit {
  productList: any[] = [];
  showEntry = false;
  frmGroup: FormGroup;
  submitted = false;
  model: any = {};
  activeDate:any;
  closeResult: string;
  isEdit: boolean = false;
  llModel: LoanLimtModel = new LoanLimtModel();
  @ViewChild('limitForm') limitForm: NgForm;
  ngForm: FormGroup;
  id:number;
 
   
  constructor(private _loanProductService: LoanProductService,
    private _loanLimitService:LoanLimitService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private datePipe: DatePipe,


    ) { }

  ngOnInit(): void {
    this.getAllLoanProduct();
    this.buildForm();
    this.getLoanLimitForEdit();

    this.activeRoute.params.subscribe(params=>{
      this.id = params['id'];
    })
  }

  isDisabled(date: NgbDateStruct, current: {month: number}) {
    return date.month !== current.month;
  }
 
  getAllLoanProduct(){
    this._loanProductService.getLoanProductList().subscribe(res => {
      this.productList = res;
    })
  }

  onsubmit(){
    
      this.llModel.id = this.ngForm.value.id;
      this._loanLimitService.saveLoanLimit(this.llModel).subscribe((response) => {
        this.router.navigate(['/products/loan-limit-list']);
      }, (error) => {
        console.log(error);
      });
    
  }


  reset() {
    this.isEdit = false;
    this.model = {};
  }

  buildForm(){
    this.ngForm = this.formBuilder.group({
      id:[0],
      loanProductId:[0, Validators.required],
      amount:  [0, Validators.required],
      activeDate: ['', Validators.required],
      expireDate: ['', Validators.required],
      productAmount:  [0, Validators.required],
      personAmount:  [0, Validators.required],
      maxAmount:  [0, Validators.required],
      minAmount:  [0, Validators.required],
      creditMaxScore: [0, Validators.required],
      creditMinScore:  [0, Validators.required],
    })
  }

  getLoanLimitForEdit(){
    this.activeRoute.paramMap.subscribe(params=>{
      const SID = +params.get('id');
      if(SID != null){
        this.isEdit = true;
        this._loanLimitService.getLoanLimitDetailsById(SID).subscribe((res:any)=>{
          //alert(res.id);
          this.ngForm.patchValue({
            id: res.id,
            loanProductId: res.loanProductId,
            amount: res.amount,
            activeDate: this.datePipe.transform(res.activeDate, 'yyyy-MM-dd'),
            expireDate: this.datePipe.transform(res.expireDate, 'yyyy-MM-dd'),
            productAmount: res.productAmount,
            personAmount: res.personAmount,
            maxAmount: res.maxAmount,
            minAmount: res.minAmount,
            creditMaxScore: res.creditMaxScore,
            creditMinScore: res.creditMinScore
          })
        }, (error:any)=>{
          this.shared.ShowAlert("Error", "Failed to retrieve", "error");
        })
      }
    })
  }
}
