

<section>
  <div class="card" style="width: 100%;">
  <div class="card-body">
    <div class="row">

<div class="col-6">
  <h6 class="m-0 font-weight-bold text-primary">Customer List</h6>
</div>
<div class="col-6">
  <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
  </button>
</div>
    </div>

    <hr>
      <div class="container">
        <!-- <h5 class="card-title" style="color: black">Customer List</h5>
        <hr> -->

        <div class="row" style="margin-bottom: 15px;">
          <div class="col-md-6" style="text-align: left">
            <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control"
              style="width:16%">
              <option value="5" >5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="form-group col-md-6" style="text-align: right">
            <input type="text" class="form-control" [(ngModel)]="searchControl" placeholder="Search"
              (keyup)="onSearch()">
          </div>
        </div>

        <table id="example" class="table table-striped table-bordered table-sm scrollable-table" style="width:100%">
          <thead>
              <tr>
                <th>SL No.</th>
                <th>Image</th>
                  <!-- <th> Name (Bangla)</th> -->
                  <th> Name</th>
                  <th>Email </th>
                  <th>Phone Number</th>
                  <th>Father Name</th>
                  <th>Mother Name</th>
                  <th>Action</th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of customerList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
              <td>{{i + 1}}</td>
              <!-- <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td> -->
              <td><img class="avatar" src="data:image/jpeg;base64,{{item.profileImage}}" alt="avatar" height="35" width="35" /></td>
              <!-- <td>{{item.customerNameBan}}</td> -->
              <td>{{item.customerNameEng}}</td>
              <td>{{item.email}}</td>
              <td>{{item.mobileNo}}</td>
              <td>{{item.fatherNameEng}}</td>
              <td>{{item.motherNameEng}}</td>
              <td>
                  <button class="btn btn-primary btn-sm mb-1" title="Info" (click)="openCustomerDetailsModal(customerDetails)" (click)="GetCustomerDetails(item.customerId)"><i class="fa fa-info-circle"></i></button>
                  <button [disabled]="isEdit" title="PDF" class="btn btn-success btn-sm mb-1"  (click)="OpenPDF(item.customerId)"><i class="fa fa-file-pdf-o"></i></button>
                  <button class="btn btn-danger btn-sm mb-1" title="Delete" (click)="DeleteCustomerById(item.id)">
                    <i class="fa fa-trash-o 3x"></i>
                  </button>
                  <button class="btn btn-info btn-sm mb-1" title="Loan Details" (click)="openLoanDetailsModel(loanIfoDetails)" (click)="getCustomerLoan(item.customerId)">
                    <i class="fa fa-file-pdf-o"></i>
                  </button>
                  <button class="btn btn-secondary btn-sm mb-1" title="Edit" (click)="Edit(item.customerId)"><i class="fa fa-edit 3x"></i></button>
                  
              </td>
              <!-- <td>
                <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-info-circle"></i> Details</button>
                <button [disabled]="isEdit" class="btn btn-danger" (click)="downloadPDF(item.Id)"><i class="fa fa-file-pdf-o"></i> PDF</button>
            </td> -->
            
            
            </tr>
           </tbody>
      </table>

      <div class="row">
        <div class="col-md-6" style="text-align: left">
          <span>Showing {{currentItems}} of {{totalItems}} entries</span>
        </div>
        <div class="col-md-6" style="text-align: right">
          <pagination-controls (pageChange)="onPageChange($event)">
          </pagination-controls>
        </div>
      </div>

      </div>  
    </div>
</div>   
</section>

<ng-template #customerDetails let-c="close" let-d="dismiss">
  <div class="modal-dialog">

    <div class="modal-content" style="margin-left: -50%; width: 200%;">
      <div class="modal-header">
        <h4 class="modal-title">Customer Details</h4>
        <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Customer Details Section -->
      <div class="col-md-12 col-sm-12 p-4" style="border: 1px solid; height: 600px; overflow-y: scroll;">
       
          <h5 class="card-title" style="color: black">Customer Details</h5>
          <hr>
        
        <div class="row">
        <div class="col-md-6 col-sm-6">
         
    
          
            <div class="row">
              <label for="NameEnglish" class="col-sm-4 col-form-label">Customer Code</label>
              <div class="col-sm-8">
                <label for="" class="">: {{customerDetailsInfos?.customerDetailsInfos?.customerRCode}}</label>
              </div>
            </div>
    
            <div class="row">
              <label for="NameBangla" class="col-sm-4 col-form-label">Customer Name</label>
              <div class="col-sm-8">
                <label for="" class="">: {{customerDetailsInfos?.customerDetailsInfos?.customerNameEng}}</label>
              </div>
            </div>
    
            <div class="row">
              <label for="" class="col-sm-4 col-form-label">Father Name </label>
              <div class="col-sm-8">
                <label for="" class="">: {{customerDetailsInfos?.customerDetailsInfos?.fatherNameEng}}</label>
              </div>
            </div>
    
            <div class="row">
              <label for="" class="col-sm-4 col-form-label">Mother Name </label>
              <div class="col-sm-8">
                <label for="" class="">: {{customerDetailsInfos?.customerDetailsInfos?.motherNameEng}}</label>
              </div>
            </div>
    
            <div class="row">
              <label for="dateOfBirth" class="col-sm-4 col-form-label">Date Of Birth</label>
              <div class="col-sm-8">
                <label for="" class="">:  {{customerDetailsInfos?.customerDetailsInfos?.dateOfBirth | date: 'dd/MM/yyyy'}}</label>
              </div>
            </div>
    
            <div class="row">
              <label for="gender" class="col-sm-4 col-form-label">Gender</label>
              <div class="col-sm-8">
                <label for="" class="">:  {{customerDetailsInfos?.customerDetailsInfos?.genderName}}</label>
              </div>
            </div>
    
            <div class="row">
              <label for="mobileNoOffice" class="col-sm-4 col-form-label">Mobile (Office)</label>
              <div class="col-sm-8">
                <label for="" class="">:  {{customerDetailsInfos?.customerDetailsInfos?.mobileNo}}</label>
              </div>
            </div>        
         
        </div>
        <div class="col-md-6 col-sm-6">
    
          
    
            <div class="row">
              <label for="Nationality" class="col-sm-4 col-form-label">NID No</label>
              <div class="col-sm-8">
                <label for="" class="">:  {{customerDetailsInfos?.customerDetailsInfos?.nidNo}}</label>
              </div>
            </div>
    
            <div class="row">
              <label for="mobileNoHome" class="col-sm-4 col-form-label">Email</label>
              <div class="col-sm-8">
                <label for="" class="">:  {{customerDetailsInfos?.customerDetailsInfos?.email}}</label>
              </div>
            </div>
    
    
            <div class="row">
              <label for="Religion" class="col-sm-4 col-form-label">Marital Status</label>
              <div class="col-sm-8">
                <label for="" class="">: {{customerDetailsInfos?.customerDetailsInfos?.maritalStatus}}</label>
              </div>
            </div>
    
            <div class="row">
              <label for="Religion" class="col-sm-4 col-form-label">Spouse Name</label>
              <div class="col-sm-8">
                <label for="" class="">:  {{customerDetailsInfos?.customerDetailsInfos?.spouseNameEng }}</label>
              </div>
            </div>
    
            <div class="row">
              <label for="Religion" class="col-sm-4 col-form-label">Spouse Mobile No</label>
              <div class="col-sm-8">
                <label for="" class="">:  {{customerDetailsInfos?.customerDetailsInfos?.spouseMobileNo}}</label>
              </div>
            </div>
    
            <!-- <div class="row">
              <label for="Religion" class="col-sm-4 col-form-label">District Name</label>
              <div class="col-sm-8">
                <label for="" class="">:  {{customerDetailsInfos?.customerDetailsInfos?.districtsName}}</label>
              </div>
            </div>        
    
            <div class="row">
              <label for="Religion" class="col-sm-4 col-form-label">Upazila Name</label>
              <div class="col-sm-8">
                <label for="" class="">:  {{customerDetailsInfos?.customerDetailsInfos?.upazilaName}}</label>
              </div>
            </div> -->
          </div>
        
      </div>
      <hr>

    <div class="row mt-2">

      <div class="col-md-12 col-sm-12">
        <h5 class="card-title" style="color: black"> Contact Details</h5>
        <hr>
      </div>

        <div class="col-md-6">
          <div class="row">
            <label for="desicode" class="col-sm-4 col-form-label">Contact Person </label>
            <div class="col-sm-8">
              <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.name}}</label>
            </div>
          </div>

           <!-- <div class="row">
            <label for="name" class="col-sm-4 col-form-label">Relation</label>
            <div class="col-sm-8">
              <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.relation}}</label>
            </div>
          </div> 

          <div class="row">
            <label for="sortorder" class="col-sm-4 col-form-label">Designation</label>
            <div class="col-sm-8">
              <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.designation}}</label>
            </div>
          </div>

          <div class="row">
            <label for="organization" class="col-sm-4 col-form-label">Organization</label>
            <div class="col-sm-8">
              <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.organization}}</label>
            </div>
          </div> -->

          <div class="row">
            <label for="contact" class="col-sm-4 col-form-label">Mobile</label>
            <div class="col-sm-8">
              <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.contact}}</label>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <label for="email" class="col-sm-4 col-form-label">Email</label>
            <div class="col-sm-8">
              <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.contactEmail}}</label>
            </div>
          </div>
        <div class="row">
          <label for="homeAddress" class="col-sm-4 col-form-label"> Address</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.homeAddress}}</label>
          </div>
        </div>
       

          <!-- <div class="row">
            <label for="occupation" class="col-sm-4 col-form-label">Occupation</label>
            <div class="col-sm-8">
              <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.occupation}}</label>
            </div>
          </div>

          <div class="row">
            <label for="officeAddress" class="col-sm-4 col-form-label">Office Address</label>
            <div class="col-sm-8">
              <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.officeAddress}}</label>
            </div>
          </div>

           -->
        </div>

    </div>
    <hr>
    <div class="row mt-2">

      <div class="col-md-12 col-sm-12">
        <h5 class="card-title" style="color: black">Address Details</h5>
        <hr>
      </div>

      <div class="col-md-12">
        <div class="row">
          <label for="DivisionsId" class="col-sm-4 col-form-label">Division </label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.addressDivisionName}}</label>
          </div>
        </div>

        <div class="row">
          <label for="DistrictsId" class="col-sm-4 col-form-label">District </label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.addressDistrictName}}</label>
          </div>
        </div>

        <!-- <div class="row">
          <label for="UpazilaId" class="col-sm-4 col-form-label">Country Name</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.addressCountryName}}</label>
          </div>
        </div> -->
        <div class="row">
          <label for="UpazilaId" class="col-sm-4 col-form-label">Upazila </label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.addressUpazilaName}}</label>
          </div>
        </div>
        <div class="row">
          <label for="type" class="col-sm-4 col-form-label">Type</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.type}}</label>
          </div>
        </div>

        <div class="row">
          <label for="PresentAddress" class="col-sm-4 col-form-label">Present Address</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.presentAddress}}</label>
          </div>
        </div>

        <div class="row">
          <label for="PermamentAddress" class="col-sm-4 col-form-label">Permanent Address</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.permamentAddress}}</label>
          </div>
        </div>
      </div>

    </div>
    <hr>
    <div class="row mt-2">
    <div class="col-md-12">
      <h5 style="color: black;">
        Profession Information</h5>
        <hr>
      </div>
     
     
        
      
      <!-- <div class="col-md-6 col-sm-6 ">
        <h5 style="color:black;">Previous Employer Information</h5>
        <hr>
      </div> -->
  
      <div class="col-md-6">
        <!-- <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label"> Employer's Name</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.presentEmployersName}}</label>
          </div>
        </div> -->

        <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label"> Company Name</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.presentcompany}}</label>
          </div>
        </div>

        <!-- <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label"> Business Nature</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.presentbusinessnature}}</label>
          </div>
        </div> -->

        <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label"> Designation</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.presentDesignation}}</label>
          </div>
        </div>

        <!-- <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label">Length of Service</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.presentlengthofservice}}</label>
          </div>
        </div> -->

        <!-- <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label">Office Telephone no.</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.presentofficeTel}}</label>
          </div>
        </div> -->

        
      </div>

      <div class="col-md-6">
        <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label">Office Address
          </label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.presentofficeaddress}}</label>
          </div>
        </div>
        <!-- <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label">Employer's Name </label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.prevEmployersName}}</label>
          </div>
        </div>

        <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label">Designation </label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.prevDesignation}}</label>
          </div>
        </div>

        <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label">Office Telephone No </label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.prevofficeTel}}</label>
          </div>
        </div>

        <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label">Office Address </label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.prevAddress}}</label>
          </div>
        </div>

        <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label">Length of Service</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.prevlengthofservice}}</label>
          </div>
        </div>

        <div class="row">
          <label for="roleName" class="col-sm-4 col-form-label">Total experience</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.totalexperience}}</label>
          </div>
        </div> -->
      </div>

    </div>
    <hr>
    <div class="col-md-12 col-sm-12 ">
      <h5 style="color:black;">Income Details</h5>
      <hr>
    </div>
    <div class="" *ngFor="let item of customerDetailsInfos?.incomeDetails; let i = index;"> 
  
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <h5 class="card-title" style="color: black"> {{i +1}} : Income info </h5>
            </div>
          </div>
  
          <hr>
  
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row mt-2">
            <div class="col-md-6">
              <div class="form-group">
                <label class=" col-form-label">
                  <i class="fa fa-money ic" aria-hidden="true"></i>
                  Monthly Amount : <span class="ic"> {{item.monthlyIncomeAmount}}</span></label>
  
              </div>
  
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">
                  <i class="fa fa-money ic" aria-hidden="true"></i>
                  Joint Applicant Amount
                  : <span class="ic"> {{item.jointApplicantIncomeAmount}}</span>
  
                </label>
  
              </div>
  
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">
                  <i class="fa fa-money ic" aria-hidden="true"></i>
                  Rental Amount
                  : <span class="ic"> {{item.rentalIncomeAmount}}</span>
                </label>
  
              </div>
  
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">
                  <i class="fa fa-money ic" aria-hidden="true"></i>
                  Interest Amount
                  : <span class="ic"> {{item.interestIncomeAmount}}</span>
  
                </label>
  
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">
                  <i class="fa fa-money ic" aria-hidden="true"></i>
                  Other's Income Amount
                  : <span class="ic"> {{item.otherIncomeAmount}}</span>
                </label>
  
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">
                  <i class="fa fa-money ic" aria-hidden="true"></i>
                  Total Income Amount
                  : <span class="ic"> {{item.totalAmount}}</span>
                </label>
  
              </div>
            </div>
          </div>
  
  
        </div>
      </div>
  
    </div>
    <hr>

    <div class="row mt-2">

      <div class="col-md-12 col-sm-12">
        <h5 style="color: black;">Expense Details</h5>
        <hr>
      </div>

      <div class="" *ngFor="let item of customerDetailsInfos?.expenseDetails; let i = index;">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <h5 class="card-title" style="color: black"> {{i +1}} : Expense Details </h5>
              </div>   
            </div>
    
            <hr>
    
            <div asp-validation-summary="All" class="text-danger"></div>
            <div class="row mt-2">
              <div class="col-md-6">
                <div class="form-group">
                  <label class=" col-form-label">
                    <i class="fa fa-money ic" aria-hidden="true"></i>
                    Rent And Utility Amount : <span class="ic"> {{item.rentAndUtilityAmount}}</span></label>
    
                </div>
    
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">
                    <i class="fa fa-money ic" aria-hidden="true"></i>
                    Food And Clothing Amount
                    : <span class="ic"> {{item.foodAndClothingAmount}}</span>
    
                  </label>
    
                </div>
    
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">
                    <i class="fa fa-money ic" aria-hidden="true"></i>
                    Education Amount
                    : <span class="ic"> {{item.educationAmount}}</span>
                  </label>
    
                </div>
    
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">
                    <i class="fa fa-money ic" aria-hidden="true"></i>
                    Loan Repayment Amount
                    : <span class="ic"> {{item.loanRepaymentAmount}}</span>
    
                  </label>
    
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">
                    <i class="fa fa-money ic" aria-hidden="true"></i>
                    Miscellaneous Amount
                    : <span class="ic"> {{item.miscellaneousAmount}}</span>
                  </label>
    
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">
                    <i class="fa fa-money ic" aria-hidden="true"></i>
                    Expense Total Amount
                    : <span class="ic"> {{item.totalAmount}}</span>
                  </label>
    
                </div>
              </div>
            </div>
    
    
          </div>
        </div>
    
      </div>

    </div>
    <hr>

    <div class="row mt-2">

      <div class="col-md-12 col-sm-12">
        <h5 style="color: black;">Bank Account Details</h5>
        <hr>
      </div>

      <div class="col-md-6">
        <div class="row">
          <label for="bankName" class="col-sm-4 col-form-label">Bank Name</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.bankName}}</label>
          </div>
        </div>

        <div class="row">
          <label for="branchName" class="col-sm-4 col-form-label">Branch Name</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.branchName}}</label>
          </div>
        </div>

        <div class="row">
          <label for="accountNo" class="col-sm-4 col-form-label">Account No.</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.accountNo}}</label>
          </div>
        </div>

        
      </div>

      <div class="col-md-6">
       
        <div class="row">
          <label for="accountType" class="col-sm-4 col-form-label">Account Type</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.accountType}}</label>
          </div>
        </div>
      
        <div class="row">
          <label for="avgBalance" class="col-sm-4 col-form-label">Average Balance</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.avgBalance}}</label>
          </div>
        </div>
      </div>
    </div>
    <hr>

    <div class="row mt-2">

      <div class="col-md-12 col-sm-12">
        <h5 class="card-title" style="color: black">Reference</h5>
        <hr>
      </div>

      <div class="col-md-6">
        <div class="row">
          <label for="name" class="col-sm-4 col-form-label">Name</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.name}}</label>
          </div>
        </div>

        <div class="row">
          <label for="relationship" class="col-sm-4 col-form-label">Relationship</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.relationship}}</label>
          </div>
        </div>

        <div class="row">
          <label for="residenceAddress" class="col-sm-4 col-form-label">Residence Address </label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.residenceAddress}}</label>
          </div>
        </div>

        <div class="row">
          <label for="residentialStatus" class="col-sm-4 col-form-label">Residential Status </label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.residentialStatus}}</label>
          </div>
        </div>

        <div class="row">
          <label for="companyName" class="col-sm-4 col-form-label">Company Name</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.companyName}}</label>
          </div>
        </div>

        <div class="row">
          <label for="refDesignation" class="col-sm-4 col-form-label">Ref Designation</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.refDesignation}}</label>
          </div>
        </div>

        
      </div>


      <div class="col-md-6">
       
      
        <div class="row">
          <label for="companyDesignation" class="col-sm-4 col-form-label"> Designation</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.companyDesignation}}</label>
          </div>
        </div>
      
        <div class="row">
          <label for="companyAddress" class="col-sm-4 col-form-label"> Address</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.companyAddress}}</label>
          </div>
        </div>
      
        <div class="row">
          <label for="companyPhoneResidence" class="col-sm-4 col-form-label"> Phone Residence</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.companyPhoneResidence}}</label>
          </div>
        </div>
      
        <div class="row">
          <label for="companyPhoneOffice" class="col-sm-4 col-form-label"> Phone Office</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.companyPhoneOffice}}</label>
          </div>
        </div>
      
        <div class="row">
          <label for="companyMobile" class="col-sm-4 col-form-label"> Mobile</label>
          <div class="col-sm-8">
            <label for="" class="">:{{customerDetailsInfos?.customerDetailsInfos?.companyMobile}}</label>
          </div>
        </div>
      </div>

    </div>
      </div>
      

      <!-- End Customer Details Section -->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #loanIfoDetails let-c="close" let-d="dismiss">
  <div class="modal-dialog">

    <div class="modal-content" style="margin-left: -54%; width: 208%;">
      <div class="modal-header">
        <h4 class="modal-title">Loan Details</h4>
        <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>


      <table class="table text-center" style="color: #868585; font-size: 14px; margin-top: 40px;">
        <thead style="background-color: #f8f9fb;">
            <tr>
                <th>Loan Type</th>
                <th>Account Name</th>
                <th>Account No</th>
                <th>Loan Create Date</th>
                <th>Loan Amount</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of loanDetails">
              <td>{{item?.leadGeneration?.loanProduct?.name}}</td>
              <td>{{ item?.accountTilte }}</td>
              <td>{{ item?.customerAccountNo }}</td>
              <td>{{ item?.createdAt | date: 'yyyy-MM-dd' }}</td>
              <td>{{ item?.openingBalance }}</td>
                <td>
                    <button class="btn btn-success ml-2" (click)="CollentionPDF(item.leadGenerationId)" type="button" title="Loan Collection"
                    style="float:right; margin-top:5px;"> Collection </button>
                   
                    <button class="btn btn-success ml-2" (click)="SchedulePDF(item.leadGenerationId)" type="button" title="Loan Schedule"
                    style="float:right; margin-top:5px; width: 115px;"> Schedule </button>
                </td>
            </tr>
        </tbody>
    </table>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      </div>
    </div>
  </div>
</ng-template>
