<div class="card" style="width: 100%;">
  <div class="card-body">
    <h5 class="card-title" style="color: black">Loan Register Info</h5>
    <hr>
    <h3 class="card-title" style="color: black;font-size:15px">Product Info</h3>
    <hr>
    <div class="col-md-12 ">

      <div class="row">
        <div class="col-md-6">
          <div class="form-group row" style="display: none;">
            <label class="col-sm-4 col-form-label">Loan Code</label>
            <div class="col-sm-8">
              <input class="form-control" value="{{productDetails?.productInfo?.code}}" readonly>
            </div>

          </div>

        </div>
        <div class="col-md-6">
          <div class="form-group row" style="display: none;">
            <label class="col-sm-4 col-form-label">Version</label>
            <div class="col-sm-8">
              <input class="form-control" value="{{productDetails?.productInfo?.version }}" readonly>
            </div>

          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row" style="display: none;">
            <label for="roleName" class="col-sm-4 col-form-label">Type</label>
            <div class="col-sm-8">
              <input class="form-control" value="{{productDetails?.productInfo?.loanType?.typeName}}" readonly>
            </div>

          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row" style="display: none;">
            <label for="roleName" class="col-sm-4 col-form-label">Person Amount</label>
            <div class="col-sm-8">
              <input class="form-control" value="{{loanLimitDetails?.personAmount}}" readonly>
            </div>

          </div>
        </div>


        <form [formGroup]="frmGroupLoanReg">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Account Title</label>
                <div class="col-sm-8">
                  <input class="form-control" value="{{  productDetails?.productInfo?.name  }}" readonly>
                </div>

              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Loan Tenure</label>
                <div class="col-sm-8">
                  <input class="form-control" value="{{durationName}}" readonly>
                </div>
            
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="interestRate" class="col-sm-4 col-form-label">Interest Rate</label>


                <div class="col-sm-8">
                  <input class="form-control" value="{{productDetails?.productInfo?.interestRate}}"
                    formControlName="interestRate" readonly>
                </div>
              </div>
            </div>
          </div>


          <hr>

          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row" style="display: none;">
                <label for="roleName" class="col-sm-4 col-form-label">Frequency<span
                    style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="frequency" placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row" style="display: none;">
                <label for="roleName" class="col-sm-4 col-form-label">Loan Nature<span
                    style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="loanNature" placeholder="Type here....">
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Loan Amount<span
                    style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                 
                  <span>Amount Must Be Between BDT {{creditScoreInfo.minAmount}} to BDT
                    {{creditScoreInfo.maxAmount}}</span>
                  <input type="text" class="form-control" formControlName="loanAmount" placeholder="0"
                    style="text-align: right;"
                    (input)="frmGroupLoanReg.get('loanAmount').setValue(formatNumber($event.target.value))"
                    (keyup)="getLoanAmountValue($event)" (keyup)="calculateLoanDetails()">
                  <div *ngIf="!isValidAmount" style="color:red;">{{msgLoanAmt}}</div>



                </div>
              </div>
            </div>
            <div class="col-md-6" style="margin-top: 42px;">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Interest Amount</label>
                <div class="col-sm-8">
                  <input type="number" style="text-align: right;" class="form-control" formControlName="interestAmount"
                    value="{{interestAmount}}" readonly>

                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Monthly Installment Size</label>
                <div class="col-sm-8">
                  <input type="number" style="text-align: right;" class="form-control" formControlName="installmentSize"
                    value="{{installmentSize}}" readonly>
                </div>
              </div>
            </div>


      <div class="col-md-6">
        <div class="form-group row">
          <label for="" class="col-sm-4 col-form-label">First Installment Date <span
              style="color:red; font-size: 15px; ">*</span></label>
          <div class="col-sm-8">
            <input type="date" class="form-control" formControlName="firstInstallmentDate">
            <div *ngIf="frmGroupLoanReg.get('firstInstallmentDate').hasError('invalidDate')" class="text-danger">
              Invalid date. Please select a date not in the past and within the next two months.
            </div>
          </div>
        </div>
      </div>


            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Select Account Type <span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <label class="radio-inline mr-4">
                    <input type="radio" formControlName="accountTypes" value="disburse" style="cursor :pointer;"> Disburse Linking Account
                  </label>

                  <label class="radio-inline">
                    <input type="radio" formControlName="accountTypes" value="mfs" style="cursor: pointer;"> MFS Account
                  </label>
                </div>
              </div>
            </div>









            <div class="col-md-6" *ngIf="frmGroupLoanReg.controls['accountTypes'].value === 'disburse'">
              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Disburse Linking Account <span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="disburselinkingAccountId">
                    <option value="0">Select</option>
                    <option *ngFor="let item of customerAccountList" [value]="item.id">{{item?.customerAccountNo}}
                    </option>
                  </select>

                </div>
              </div>
            </div>


            <div class="col-md-6" *ngIf="frmGroupLoanReg.controls['accountTypes'].value === 'mfs'">
              <div class="form-group row">

                <label for="" class="col-sm-4 col-form-label">MFS Account <span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="ledgerRelationId">
                    <option value="">Select</option>
                    <ng-container *ngFor="let item of mfsAccountList">
                      <option [value]="item.ledgerRelationId">{{item.accountName}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

            </div>


            <div class="col-md-6" *ngIf="frmGroupLoanReg.controls['accountTypes'].value === 'mfs'">

              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Account No <span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="mfsNumber">
                </div>
              </div>
            </div>

            <div class="col-md-6">
            
              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Processing Fee<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input style="text-align: right;" type="text" class="form-control" value="{{processingFee}}" readonly>
                </div>
              </div>
            </div>



            <div class="col-md-6 d-none" *ngFor="let item of lstCharge">
              <div class="form-group row" *ngIf="item.id !== 10 && item.id !=11">
                <label for="roleName" class="col-sm-4 col-form-label">{{item.name}}</label>
                <div class="col-sm-8">
                  <input type="number" style="text-align: right;" class="form-control" formControlName="loanCharge"
                    value="{{item.amount}}" readonly>
                    <!-- <span>Percentage %</span> -->
                </div>
              </div>
              <!-- <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">{{item.name}}<span
                    style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="number" style="text-align: right;" class="form-control" formControlName="loanCharge" value="{{item.amount}}"
                    readonly>
                </div>
              </div> -->
            </div>

          </div>

          <div class="row">
<div class="col-md-9 col-sm-6"></div>
              <div class="col-md-3 col-sm-6">
                  <button data-toggle="tooltip" title="Save" type="submit" (click)="onLoanRegSubmit()"
                  [disabled]="!isLoanAmountValid()" value="Submit" class="btn btn-success mr-1 mb-1">
                  <span>
                    {{isLoanRegInfoEdit? 'Update' : 'Save' }}
                  </span>
                </button>
                  
                  <button data-toggle="tooltip" title="Save" type="submit" (click)="getLoanSchedulePreview()"
                    (click)="openLoanScheduleModal(loanScheduleModal)" [disabled]="!isLoanAmountValid()" value="Submit" class="btn btn-info mr-1 mb-1">
                      Schedule
                  </button>
                
              </div>

          </div>

          

        </form>
      </div>


    </div>
  </div>
</div>

<ng-template #loanScheduleModal let-c="close" let-d="dismiss">
  <div class="modal-dialog">
      <div class="modal-content" style="margin-left: -50%; width: 200%;">
          <div class="modal-header">
              <h4 class="modal-title">Loan Schedule Preview</h4>
              <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <table class="table-bordered" >
            <thead>
              <tr style="text-align: center;">
                <th>#</th>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of loanScheduleModel" style="text-align: center;">
                <td>{{item.sl}}</td>
                <td>{{item.emiDate | date: 'dd/MM/yyyy'}}</td>
                <td>{{item.emiAmount | number }}</td>
              </tr>
            </tbody>
        </table>
          

          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
          </div>
      </div>
  </div>
</ng-template>




<!-- <div class="col-md-6">
          <div class="form-group row">
            <label for="" class="col-sm-4 col-form-label">Account Title</label>
            <div class="col-sm-8">
              <input class="form-control" value="{{ productDetails?.productInfo?.name }}" readonly>
            </div>

          </div>
        </div> 
        <div class="col-md-6">
          <div class="form-group row">
            <label for="loanDuration" class="col-sm-4 col-form-label">Loan Duration</label>
            <div class="col-sm-8">
              <select class="form-control" formControlName="tenureId" [ngClass]="{
                'is-invalid': frmGroupLoanReg.get('tenureId').invalid && (frmGroupLoanReg.get('tenureId').dirty || frmGroupLoanReg.get('tenureId').touched || submitted),
                  'is-valid': frmGroupLoanReg.get('tenureId').valid && (frmGroupLoanReg.get('tenureId').dirty || frmGroupLoanReg.get('tenureId').touched || submitted)}">
                <option value="">Select</option>
                <option *ngFor="let item of lstLoanDuration" [value]="item.id">{{item?.monthName}}
                </option>
              </select>
              <span class="text-danger"
                  *ngIf="frmGroupLoanReg.get('tenureId').invalid && (frmGroupLoanReg.get('tenureId').dirty || frmGroupLoanReg.get('tenureId').touched || submitted) && frmGroupLoanReg.get('tenureId').errors?.required">
                  Select Tenure
              </span>
            </div>

          </div>
        </div> -->

<!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Other Fee<span
                    style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="number" style="text-align: right;" class="form-control" formControlName="otherFee" value="{{otherFee}}"
                    readonly>
                </div>
              </div>
            </div> -->

<!-- <div *ngIf="frmGroupLoanReg.get('loanAmount').invalid && (frmGroupLoanReg.get('loanAmount').dirty || frmGroupLoanReg.get('loanAmount').touched)" class="text-danger">
                      <div *ngIf="frmGroupLoanReg.get('loanAmount').hasError('required')">
                        Loan amount is required
                      </div>
                      <div *ngIf="frmGroupLoanReg.get('loanAmount').hasError('min')">
                        Loan amount must be greater than {{creditScoreInfo.minAmount}}
                      </div>
                      <div *ngIf="frmGroupLoanReg.get('loanAmount').hasError('max')">
                        Loan amount can't exceed {{creditScoreInfo.maxAmount}}
                      </div>
                    </div> -->
<!-- <span *ngIf="frmGroupLoanReg.get('loanAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>  -->