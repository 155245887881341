import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupComponent } from './group/group.component';
import { LedgerComponent } from './ledger/ledger.component';
import { CashPostingComponent } from './cash-posting/cash-posting.component';
import { AccountRoutingModule } from './account-routing.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SubGroupComponent } from './sub-group/sub-group.component';
import { AccountBalanceComponent } from './account-balance/account-balance.component';
import { VoucherMasterComponent } from './voucher-master/voucher-master.component';
import { CashApprovalComponent } from './cash-approval/cash-approval.component';
import { CashApproveComponent } from './cash-approve/cash-approve.component';
import { CashReceivedComponent } from './cash-received/cash-received.component';
import { FundTransferComponent } from './fund-transfer/fund-transfer.component';
import { LedgerListComponent } from './ledger-list/ledger-list.component';
import { GlGenerateComponent } from './gl-generate/gl-generate.component';
import { BusinessDateComponent } from './business-date/business-date.component';
import { HolidayComponent } from './holiday/holiday.component';
import { AddMoneyComponent } from './add-money/add-money.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TransectionLimitComponent } from './transection-limit/transection-limit.component';
import { OtherBankComponent } from './other-bank/other-bank.component';
import { NpsbComponent } from './npsb/npsb.component';
import { OtherAccountComponent } from './other-account/other-account.component';
import { OwnAccountFundTransferComponent } from './own-account-fund-transfer/own-account-fund-transfer.component';
import { BeftnComponent } from './beftn/beftn.component';
import { BKashComponent } from './b-kash/b-kash.component';
import { NagadComponent } from './nagad/nagad.component';
import { MobileRechargeComponent } from './mobile-recharge/mobile-recharge.component';
import { WasaComponent } from './wasa/wasa.component';
import { BrebComponent } from './breb/breb.component';
import { DpdcComponent } from './dpdc/dpdc.component';
import { DescoComponent } from './desco/desco.component';
import { NescoComponent } from './nesco/nesco.component';
import { BgdclComponent } from './bgdcl/bgdcl.component';
import { RocketComponent } from './rocket/rocket.component';
import { BanglaQrComponent } from './bangla-qr/bangla-qr.component';
import { UpayComponent } from './upay/upay.component';
import { FundTransferListComponent } from './fund-transfer-list/fund-transfer-list.component';
import { PaymentVoucherComponent } from './payment-voucher/payment-voucher.component';
import { ReceiveVoucherComponent } from './receive-voucher/receive-voucher.component';
import { JournalVoucherComponent } from './journal-voucher/journal-voucher.component';
import { ContraVoucherComponent } from './contra-voucher/contra-voucher.component';
import {TreeTableModule} from 'primeng/treetable';
import { ButtonModule } from 'primeng/button'; 
import { InputTextModule } from 'primeng/inputtext';
import { TreeTableTestComponent } from './tree-table-test/tree-table-test.component';




@NgModule({
  declarations: [
    GroupComponent,
    SubGroupComponent,
    LedgerComponent,
    CashPostingComponent,
    AccountBalanceComponent,
    VoucherMasterComponent,
    CashApprovalComponent,
    CashApproveComponent,
    CashReceivedComponent,
    FundTransferComponent,
    LedgerListComponent,
    GlGenerateComponent,
    BusinessDateComponent,
    HolidayComponent,
    AddMoneyComponent,
    TransectionLimitComponent,
    OtherBankComponent,
    NpsbComponent,
    MobileRechargeComponent,
    BKashComponent,
    OtherAccountComponent,
     WasaComponent,
    BrebComponent ,
    DpdcComponent ,
   DescoComponent ,
   NescoComponent ,
    BgdclComponent,
    RocketComponent ,
    
    OwnAccountFundTransferComponent,
       BeftnComponent,
       BKashComponent,
       NagadComponent,
    BanglaQrComponent,
    UpayComponent,
    FundTransferListComponent,
    PaymentVoucherComponent,
    ReceiveVoucherComponent,
    JournalVoucherComponent,
    ContraVoucherComponent,
    TreeTableTestComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccountRoutingModule,
    NgxPaginationModule,
    TreeTableModule,
    ButtonModule, 
    InputTextModule, 
  ]
})
export class AccountModule { }
