import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { ExpenseDetailsService } from '../expense-details.service';
import { IncomeDetails } from '../model/income-details';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { CustomerService } from 'app/customer/customer.service';
import swal from 'sweetalert';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-expense-details',
  templateUrl: './expense-details.component.html',
  styleUrls: ['./expense-details.component.scss']
})
export class ExpenseDetailsComponent implements OnInit {

  @Output() expenseUpdateSuccess: EventEmitter<void> = new EventEmitter<void>();


 // @Output() expenseUpdateSuccess:EventEmitter<void>=new EventEmitter<void>();
  customersId: any = 0;
  isAuto: boolean=false;
  model: any = {};
  frmGroupEx: FormGroup;
  frmGroupExshow: FormGroup;
  ExpenseList: any = [];
  isExpenseEdit: boolean = false;
  clientInfoId: any = 0;
  leadId: any = 0;
  customerDetailsInfos: any;
  profileUpdate: any;
  id:number;

  constructor(    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private _expenseService: ExpenseDetailsService,
    private _leadGennarationService: LeadGenerationServiceService,
    private datePipe: DatePipe,
    private shared: SharedService,
    private customerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    ) { }

  ngOnInit(): void {
    this.GetCustomerDetailsForEdit();
    //this.getExpenseList();
    this.ExpenseForm();
    this.getLeadInfo();
   
    this.activeRoute.params.subscribe(params=>{
      this.id=params['id'];
    })
    this.GetCustomerDetailsForAdminEdit(this.id);

    // this.activeRoute.paramMap.subscribe(params => {
    //   this.customersId = parseInt(params.get('customersId'), 10);
      
    //   this.LoadExpenseInfo(this.customersId);
    // });

  
  }

  LoadExpenseInfo(customersId:number){
   
    this._expenseService.GetLeadGenerationByLeadNoforExpense(customersId).subscribe(res =>{
      if(res != null){
        this.isAuto=true;
        
        this.frmGroupEx.patchValue({
   
          id: res.clientInfo?.id,
          
          leadId: res.id,
         
          rentAndUtilityAmount: res.rentAndUtilityAmount,
          foodAndClothingAmount: res.foodAndClothingAmount,
          educationAmount: res.educationAmount,
          loanRepaymentAmount: res.loanRepaymentAmount,
          miscellaneousAmount: res.miscellaneousAmount,
          expenseTotalAmount: res.totalAmount,
          
          
         
        });
      }
      else{
        this.frmGroupEx = this.formBuilder.group({
          id: 0,
          leadId: 0,
          rentAndUtilityAmount:'',
          foodAndClothingAmount: '',
          educationAmount: '',
          loanRepaymentAmount: '',
          miscellaneousAmount: '',
          expenseTotalAmount: '',
          totalAmount:'',
         
        });
      }
    })
  }


  refreshExpense() {
    this.ExpenseForm();
    this.model = {};
    this.getExpenseList(this.customersId);
    this.isExpenseEdit = false;
  }

  getLeadInfo() {
    this.activeRoute.paramMap.subscribe(params => {
      const leadGNId = +params.get('leadId');
      this.leadId = +params.get('leadId');
      if (leadGNId != null&& this.leadId > 0) {
        this._leadGennarationService.searchLeadCodebyId(leadGNId).subscribe(
          (res: any) => {
          
            this.clientInfoId=res.customerId;
            this.getExpenseList(this.clientInfoId);
          },
          (error: any) => {
           this.shared.ShowAlert('Error', 'Failed to retrieve.', 'error');
          }
        );
      }
    });
  }

  getExpenseList(clientInfoId:number) {
    this._expenseService.GetAllExpenseinfoByClientId(clientInfoId).subscribe(res => {
      this.ExpenseList = res;
      this.changeDetectorRef.detectChanges();

    })
  }

  ExpenseForm() {
    this.frmGroupEx = this.formBuilder.group({
      expenseId: [0],
      rentAndUtilityAmount: ['',[Validators.required, Validators.pattern('^[0-9,.]*$')]],
      foodAndClothingAmount: ['',[Validators.required, Validators.pattern('^[0-9,.]*$')]],
      educationAmount: ['',[Validators.required, Validators.pattern('^[0-9,.]*$')]],
      loanRepaymentAmount: ['',[Validators.required, Validators.pattern('^[0-9,.]*$')]],
      miscellaneousAmount: ['',[Validators.required, Validators.pattern('^[0-9,.]*$')]],
      expenseTotalAmount: ['',[Validators.required, Validators.pattern('^[0-9,.]*$')]],
     
    })
  }

  onExpenseSubmit() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save Expense Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save Expense Details!"
    }).then((res) => {
      if (res.isConfirmed) {

    if (this.customersId > 0) {

      this.model.id = this.frmGroupEx.value.expenseId;
      this.model.rentAndUtilityAmount = this.frmGroupEx.value.rentAndUtilityAmount.replace(/,/g, '');
      this.model.foodAndClothingAmount = this.frmGroupEx.value.foodAndClothingAmount.replace(/,/g, '');
      this.model.educationAmount = this.frmGroupEx.value.educationAmount.replace(/,/g, '');
      this.model.loanRepaymentAmount = this.frmGroupEx.value.loanRepaymentAmount.replace(/,/g, '');
      this.model.miscellaneousAmount = this.frmGroupEx.value.miscellaneousAmount.replace(/,/g, '');
      this.model.expenseTotalAmount = this.frmGroupEx.value.expenseTotalAmount.replace(/,/g, '');
      this.model.customerId = this.customersId;
      this._expenseService.saveExpense(this.model).subscribe(res => {
        if (this.model.id > 0) {
          this.shared.ShowAlert('Thank you...', 'You Updated succesfully!', 'success');
        } else {
          this.shared.ShowAlert('Thank you...', 'You Saved succesfully!', 'success');
          this.getProfileUpdateInfo();
          this.expenseUpdateSuccess.emit();
        }
        this.refreshExpense();
      })
    } 
    else {
      this.shared.ShowAlert('Warning...', 'Please Create Client First!', 'warning');
      this.refreshExpense();
    }

  }
  else if (res.isDismissed) {
    this.router.navigate(['/customer/customer-edit']);
  }
});

}

  formatNumber(value: string): string {

    const formattedValue = value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedValue;
  }

  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe(res => {
      this.profileUpdate = res;
    })
    this.changeDetectorRef.detectChanges();
  }

  UpdateExpence(id: number) {
    this.isExpenseEdit = true;
    this._expenseService.GetExpenseinfoById(id).subscribe(
     
      (incomeinfo: IncomeDetails) => {
      
        this.frmGroupEx.patchValue({
          expenseId: incomeinfo.id,
          rentAndUtilityAmount: incomeinfo.rentAndUtilityAmount.toLocaleString(),
          foodAndClothingAmount: incomeinfo.foodAndClothingAmount.toLocaleString(),
          educationAmount: incomeinfo.educationAmount.toLocaleString(),
          loanRepaymentAmount: incomeinfo.loanRepaymentAmount.toLocaleString(),
          miscellaneousAmount: incomeinfo.miscellaneousAmount.toLocaleString(),
          expenseTotalAmount: incomeinfo.totalAmount.toLocaleString()
        });

      },
      error => {
        
      }
    );
  }
  GetCustomerDetailsForEdit() {

    this.customerService.GetCustomerDetailsForEdit().subscribe((data:any) => {

      this.customerDetailsInfos = data;
      this.customersId = data.customerDetailsInfos.customerId;
      this.getExpenseList(this.customersId);
      this.changeDetectorRef.detectChanges();
    })
  }

  GetCustomerDetailsForAdminEdit(id:number) {

    this.customerService.GetCustomerDetailsForAdminEdit(id).subscribe((data:any) => {

      this.customerDetailsInfos = data;
      this.customersId = data.customerDetailsInfos.customerId;
      this.getExpenseList(this.customersId);
      this.changeDetectorRef.detectChanges();
    })
  }

}
