import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loan-interest-income',
  templateUrl: './loan-interest-income.component.html',
  styleUrls: ['./loan-interest-income.component.scss']
})
export class LoanInterestIncomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
