import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DistrictService } from 'app/master-data/basic/basic-service/district.service';
import { DivisionService } from 'app/master-data/basic/basic-service/division.service';
import { UpazilaService } from 'app/master-data/basic/basic-service/upazila.service';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { SharedService } from 'app/services/shared.service';
import { GuarantordetailsService } from '../guarantordetails.service';
import { Division } from 'app/master-data/basic/basic-model/division';
import { Upazila } from 'app/master-data/basic/basic-model/upazila';
import { CustomerService } from 'app/customer/customer.service';
import { District } from 'app/master-data/basic/basic-model/district';
import { Guarantordetails } from '../model/guarantordetails';
import swal from 'sweetalert';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-gurantor-info',
  templateUrl: './gurantor-info.component.html',
  styleUrls: ['./gurantor-info.component.scss']
})
export class GurantorInfoComponent implements OnInit {

  model: any = {};
  loanGuarantorId: any;
  frmLoanGurantor: FormGroup;
  customerId: any = 0;
  leadId: any = 0;
  loanGuarantorModel: any = {};
  districtLists: District[] = [];
  ThanaList: any[] = [];
  GuarantorList: any[] = [];
  isEdit: boolean = false;
  isAuto: boolean = false;
  customersId: any = 0;
  isEditGurantor: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private shared: SharedService,
    private _loanProductService: ProductBuilderService,
    private _serviceDivisionInfo: DivisionService,
    private _serviceThana: UpazilaService,
    private _serviceDistrict: DistrictService,
    private _guarantorservice: GuarantordetailsService,
    private customerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.LoanGurantorForm();
    this.getDistrictList();
    this.route.paramMap.subscribe(params => {
      this.leadId = parseInt(params.get('leadId'), 10);


    });
    this.LoadClient(this.leadId);

    this.getGuarantorList(this.leadId)
    // this.route.paramMap.subscribe(params => {
    //   this.customersId = parseInt(params.get('customersId'), 10);


    // });

    // this.LoadContactInfo(this.customersId);
  }
  // LoadContactInfo(customersId:number){

  //   this._guarantorservice.GetLeadGenerationByLeadNoforLoanGuarantor(customersId).subscribe(res =>{
  //     if(res != null){
  //       this.isAuto=true;

  //       this.frmLoanGurantor.patchValue({

  //         id: res.clientInfo?.id,

  //         leadId: res.id,

  //         name: res.name,
  //         fathersName: res.fathersName,
  //         mothersName: res.mothersName,
  //         organization: res.organization,
  //         spouseName: res.spouseName,
  //         dob: res.dob,
  //         relationwithApplicant: res.relationwithApplicant,
  //         presentResidentialAddress: res.presentResidentialAddress,
  //         permanentAddress: res.permanentAddress,
  //         telephone: res.telephone,

  //       });
  //     }
  //     else{
  //       this.frmLoanGurantor = this.formBuilder.group({
  //         id: 0,
  //         leadId: 0,
  //         name:'',
  //         fathersName: '',
  //         mothersName: '',
  //         spouseName: '',
  //         dob: '',
  //         relationwithApplicant: '',
  //         presentResidentialAddress: '',
  //         permanentAddress: '',
  //         telephone: '',
  //     //     fatherNameBan:'',
  //     // motherNameEng:'',
  //     // motherNameBan:'',
  //     // maritalStatus:'',
  //     // spouseNameEng:'',
  //     // spouseNameBan:'',
  //     // spouseMobileNo:'',
  //     // districtId:'',
  //     // upazilaId:'',
  //       });
  //     }
  //   })
  // }
  LoadClient(leadId: number) {
    this.customerService.GetLeadGenerationByLeadNo(leadId).subscribe(res => {

      this.customerId = res.customer?.id;
      this.getGuarantorList(this.leadId);
      this.changeDetectorRef.detectChanges();
    })
  }

  formatNumber(value: string): string {

    const formattedValue = value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedValue;
  }
  

  getGuarantorList(leadId: number): void {

    this._guarantorservice.GetGuarantorList(leadId).subscribe(
      (Guarantor: Guarantordetails[]) => {
        // console.log(Guarantor)
        this.GuarantorList = Guarantor;
        this.changeDetectorRef.detectChanges();
      },
      (error: any) => {
        console.error('Error loading District:', error);
      }
    );
  }



  guarantorType: string = '';

  onGuarantorTypeChange(value: string) {
    this.guarantorType = value;

  }
  getGurantorByClientId(customerId) {

    if (customerId > 0) {
      this._guarantorservice.getGurantorByClientId(customerId).subscribe(
        (res: any) => {
          // console.log(res);
          if (res && res.id) {
            alert(res.id);
            this.frmLoanGurantor.patchValue({
              id: res.id,
              name: res.name,
              fathersName: res.fathersName,
              mothersName: res.mothersName,
              spouseName: res.spouseName,
              dob: res.dob,
              relationwithApplicant: res.relationwithApplicant,
              presentResidentialAddress: res.presentResidentialAddress,
              permanentAddress: res.permanentAddress,
              telephone: res.telephone,
              mobile: res.mobile,
              fax: res.fax,
              email: res.email,
              tinNo: res.tinNo,
              occupation: res.occupation,
              nidNo: res.nidNo,
              employersName: res.employersName,
              employersDesignation: res.employersDesignation,
              businessAddress: res.businessAddress,
              netMonthlyIncome: res.netMonthlyIncome,
              commentsSource: res.commentsSource,
              commentsBSMM: res.commentsBSMM,
              commentsBM: res.commentsBM,
              commentsAVC: res.commentsAVC,
              status: res.status,
              type: res.type,
              customerId: res.customerId,
              professionOfBG: res.professionOfBG,
              age: res.age,
              maritalStatus: res.maritalStatus,
              presentAddress: res.presentAddress,
              years: res.years,
              residentialStaus: res.residentialStaus,
              districtId: res.districtId,
              thanaId: res.thanaId,
              lastTaxReturnYear: res.lastTaxReturnYear,
              nameOfEnterprise: res.nameOfEnterprise,
              establishDate: res.establishDate,
              businessSales: res.businessSales,
              concernStock: res.concernStock,
              enterpriseAge: res.enterpriseAge,
              legalEntity: res.legalEntity,
              enterpriseNature: res.enterpriseNature,
              businessLocation: res.businessLocation,
              tradeLicenceNo: res.tradeLicenceNo,
              segmentEnterprise: res.segmentEnterprise,
              subSectorEnterprise: res.subSectorEnterprise,
              clusterName: res.clusterName,
              initialEquityAbsoluteFigure: res.initialEquityAbsoluteFigure,
              presentEquityAbsoluteFigure: res.presentEquityAbsoluteFigure,
              monthlyExpense: res.monthlyExpense,
              relationshipWithEBL: res.relationshipWithEBL,
              personalWorth: res.personalWorth,
              eduQualification: res.eduQualification,
              organizationName: res.organizationName,
              organizationAddress: res.organizationAddress,
              guaranteeAmount: res.guaranteeAmount,
              leadId: this.leadId
            })
          }
          else {
            this.LoanGurantorForm();
          }
        })

    }
  }

  onNIDgetGuarantor(event: any) {
    const nidVal = event.target.value;
    const mobileNo = null;
   
    if (nidVal.length == 10 || nidVal.length == 13 || nidVal.length == 17) {

      this._guarantorservice.getLoanGuarantorByNidOrMobile(nidVal, mobileNo).subscribe(
        (res: any) => {
          //console.log(res);
          if (res && res.id) {
            //alert(res.id);  
            this.frmLoanGurantor.patchValue({
              id: res.id,
              name: res.name,
              fathersName: res.fathersName,
              mothersName: res.mothersName,
              spouseName: res.spouseName,
              dob: res.dob,
              relationwithApplicant: res.relationwithApplicant,
              presentResidentialAddress: res.presentResidentialAddress,
              permanentAddress: res.permanentAddress,
              telephone: res.telephone,
              mobile: res.mobile,
              fax: res.fax,
              email: res.email,
              tinNo: res.tinNo,
              occupation: res.occupation,
              nidNo: res.nidNo,
              employersName: res.employersName,
              employersDesignation: res.employersDesignation,
              businessAddress: res.businessAddress,
              netMonthlyIncome: res.netMonthlyIncome,
              commentsSource: res.commentsSource,
              commentsBSMM: res.commentsBSMM,
              commentsBM: res.commentsBM,
              commentsAVC: res.commentsAVC,
              status: res.status,
              type: res.type,
              customerId: res.customerId,
              professionOfBG: res.professionOfBG,
              age: res.age,
              maritalStatus: res.maritalStatus,
              presentAddress: res.presentAddress,
              years: res.years,
              residentialStaus: res.residentialStaus,
              districtId: res.districtId,
              thanaId: res.thanaId,
              lastTaxReturnYear: res.lastTaxReturnYear,
              nameOfEnterprise: res.nameOfEnterprise,
              establishDate: res.establishDate,
              businessSales: res.businessSales,
              concernStock: res.concernStock,
              enterpriseAge: res.enterpriseAge,
              legalEntity: res.legalEntity,
              enterpriseNature: res.enterpriseNature,
              businessLocation: res.businessLocation,
              tradeLicenceNo: res.tradeLicenceNo,
              segmentEnterprise: res.segmentEnterprise,
              subSectorEnterprise: res.subSectorEnterprise,
              clusterName: res.clusterName,
              initialEquityAbsoluteFigure: res.initialEquityAbsoluteFigure,
              presentEquityAbsoluteFigure: res.presentEquityAbsoluteFigure,
              monthlyExpense: res.monthlyExpense,
              relationshipWithEBL: res.relationshipWithEBL,
              personalWorth: res.personalWorth,
              eduQualification: res.eduQualification,
              organizationName: res.organizationName,
              organizationAddress: res.organizationAddress,
              guaranteeAmount: res.guaranteeAmount,
              leadId: this.leadId
            })
          }
          else {
            this.shared.ShowAlert('Warning...!', 'Not found this NID Number Guarantor data.', 'warning');
            this.LoanGurantorForm();
          }
        })

    }
  }

  onMobileGetGuarantor(event: any) {
    const nidVal = null;
    const mobileNo = event.target.value;


    if (mobileNo.length == 11 || mobileNo.length == 14) {

      this._guarantorservice.getLoanGuarantorByNidOrMobile(nidVal, mobileNo).subscribe((res: any) => {
        //console.log(res);
        if (res && res.id) {
          this.frmLoanGurantor.patchValue({
            id: res.id,
            name: res.name,
            fathersName: res.fathersName,
            mothersName: res.mothersName,
            spouseName: res.spouseName,
            dob: res.dob,
            relationwithApplicant: res.relationwithApplicant,
            presentResidentialAddress: res.presentResidentialAddress,
            permanentAddress: res.permanentAddress,
            telephone: res.telephone,
            mobile: res.mobile,
            fax: res.fax,
            email: res.email,
            tinNo: res.tinNo,
            occupation: res.occupation,
            nidNo: res.nidNo,
            employersName: res.employersName,
            employersDesignation: res.employersDesignation,
            businessAddress: res.businessAddress,
            netMonthlyIncome: res.netMonthlyIncome,
            commentsSource: res.commentsSource,
            commentsBSMM: res.commentsBSMM,
            commentsBM: res.commentsBM,
            commentsAVC: res.commentsAVC,
            status: res.status,
            type: res.type,
            customerId: res.customerId,
            professionOfBG: res.professionOfBG,
            age: res.age,
            maritalStatus: res.maritalStatus,
            presentAddress: res.presentAddress,
            years: res.years,
            residentialStaus: res.residentialStaus,
            districtId: res.districtId,
            thanaId: res.thanaId,
            lastTaxReturnYear: res.lastTaxReturnYear,
            nameOfEnterprise: res.nameOfEnterprise,
            establishDate: res.establishDate,
            businessSales: res.businessSales,
            concernStock: res.concernStock,
            enterpriseAge: res.enterpriseAge,
            legalEntity: res.legalEntity,
            enterpriseNature: res.enterpriseNature,
            businessLocation: res.businessLocation,
            tradeLicenceNo: res.tradeLicenceNo,
            segmentEnterprise: res.segmentEnterprise,
            subSectorEnterprise: res.subSectorEnterprise,
            clusterName: res.clusterName,
            initialEquityAbsoluteFigure: res.initialEquityAbsoluteFigure,
            presentEquityAbsoluteFigure: res.presentEquityAbsoluteFigure,
            monthlyExpense: res.monthlyExpense,
            relationshipWithEBL: res.relationshipWithEBL,
            personalWorth: res.personalWorth,
            eduQualification: res.eduQualification,
            organizationName: res.organizationName,
            organizationAddress: res.organizationAddress,
            guaranteeAmount: res.guaranteeAmount,
            leadId: this.leadId
          })
        } else {
          this.shared.ShowAlert('Warning...!', 'Not fund this Mobile Number Guarantor data.', 'warning');
          this.LoanGurantorForm();
        }

      })
    }

  }

  resetLoanGurantorFrm() {
    this.loanGuarantorModel = {};
    this.LoanGurantorForm();
  }
  LoanGurantorForm() {
    this.frmLoanGurantor = this.formBuilder.group({
      id: [0],
      name: [''],
      fathersName: [''],
      mothersName: [''],
      spouseName: [''],
      dob: [''],
      relationwithApplicant: [''],
      presentResidentialAddress: [''],
      permanentAddress: [''],
      telephone: [''],
      mobile: [''],
      fax: [''],
      email: [''],
      tinNo: [''],
      occupation: [''],
      nidNo: [''],
      employersName: [''],
      employersDesignation: [''],
      businessAddress: [''],
     netMonthlyIncome: ['0', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      //netMonthlyIncome: [0],
      commentsSource: [''],
      commentsBSMM: [''],
      commentsBM: [''],
      commentsAVC: [''],
      status: [1],
      type: [''],
      customerId: [''],
      professionOfBG: [''],
      age: [0],
      maritalStatus: [''],
      presentAddress: [''],
      years: [''],
      residentialStaus: [''],
      districtId: [''],
      thanaId: [''],
      lastTaxReturnYear: [''],
      nameOfEnterprise: [''],
      establishDate: [''],
      businessSales: [''],
      concernStock: [''],
      enterpriseAge: [0],
      legalEntity: [''],
      enterpriseNature: [''],
      businessLocation: [''],
      tradeLicenceNo: [''],
      segmentEnterprise: [''],
      subSectorEnterprise: [''],
      clusterName: [''],

      initialEquityAbsoluteFigure: [0, [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      presentEquityAbsoluteFigure: [0, [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      monthlyExpense: [0, [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      relationshipWithEBL: [''],
      personalWorth: [0, [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      eduQualification: [''],
      organizationName: [''],
      organizationAddress: [''],
      guaranteeAmount: [0, [Validators.required, Validators.pattern('^[0-9,.]*$')]],

      // initialEquityAbsoluteFigure: [0],
      // presentEquityAbsoluteFigure: [0],
      // monthlyExpense: [0],
      // relationshipWithEBL: [''],
      // personalWorth: [0],
      // eduQualification: [''],
      // organizationName: [''],
      // organizationAddress: [''],
      // guaranteeAmount: [0],


      leadId: this.leadId
    });
  }

  saveLoanGurantor() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save Guarantor!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save it!"
    }).then((res) => {
      if (res.isConfirmed) {

    if (this.customerId > 0) {
      this.loanGuarantorModel.Id = this.frmLoanGurantor.value.id;
      this.loanGuarantorModel.name = this.frmLoanGurantor.value.name;
      this.loanGuarantorModel.fathersName = this.frmLoanGurantor.value.fathersName;
      this.loanGuarantorModel.mothersName = this.frmLoanGurantor.value.mothersName;
      this.loanGuarantorModel.spouseName = this.frmLoanGurantor.value.spouseName;
      this.loanGuarantorModel.dob = this.frmLoanGurantor.value.dob;

      this.loanGuarantorModel.relationwithApplicant = this.frmLoanGurantor.value.relationwithApplicant;
      this.loanGuarantorModel.presentResidentialAddress = this.frmLoanGurantor.value.presentResidentialAddress;
      this.loanGuarantorModel.permanentAddress = this.frmLoanGurantor.value.permanentAddress;
      this.loanGuarantorModel.telephone = this.frmLoanGurantor.value.telephone;
      this.loanGuarantorModel.mobile = this.frmLoanGurantor.value.mobile;
      this.loanGuarantorModel.fax = this.frmLoanGurantor.value.fax;
      this.loanGuarantorModel.email = this.frmLoanGurantor.value.email;
      this.loanGuarantorModel.tinNo = this.frmLoanGurantor.value.tinNo;
      this.loanGuarantorModel.occupation = this.frmLoanGurantor.value.occupation;
      this.loanGuarantorModel.nidNo = this.frmLoanGurantor.value.nidNo;
      this.loanGuarantorModel.employersName = this.frmLoanGurantor.value.employersName;
      this.loanGuarantorModel.employersDesignation = this.frmLoanGurantor.value.employersDesignation;

      this.loanGuarantorModel.businessAddress = this.frmLoanGurantor.value.businessAddress;
      this.loanGuarantorModel.netMonthlyIncome = this.frmLoanGurantor.value.netMonthlyIncome.replace(/,/g, '');
      //this.loanGuarantorModel.netMonthlyIncome = this.frmLoanGurantor.value.netMonthlyIncome;

      this.loanGuarantorModel.commentsSource = this.frmLoanGurantor.value.commentsSource;
      this.loanGuarantorModel.commentsBSMM = this.frmLoanGurantor.value.commentsBSMM;
      this.loanGuarantorModel.commentsBM = this.frmLoanGurantor.value.commentsBM;
      this.loanGuarantorModel.commentsAVC = this.frmLoanGurantor.value.commentsAVC;
      this.loanGuarantorModel.status = this.frmLoanGurantor.value.status;
      this.loanGuarantorModel.type = this.frmLoanGurantor.value.type;
      this.loanGuarantorModel.customerId = this.customerId;

      this.loanGuarantorModel.professionOfBG = this.frmLoanGurantor.value.professionOfBG;
      this.loanGuarantorModel.age = this.frmLoanGurantor.value.age;
      this.loanGuarantorModel.maritalStatus = this.frmLoanGurantor.value.maritalStatus;
      this.loanGuarantorModel.presentAddress = this.frmLoanGurantor.value.presentAddress;
      this.loanGuarantorModel.years = this.frmLoanGurantor.value.years;
      this.loanGuarantorModel.residentialStaus = this.frmLoanGurantor.value.residentialStaus;
      this.loanGuarantorModel.districtId = this.frmLoanGurantor.value.districtId;
      this.loanGuarantorModel.thanaId = this.frmLoanGurantor.value.thanaId;
      this.loanGuarantorModel.lastTaxReturnYear = this.frmLoanGurantor.value.lastTaxReturnYear;
      this.loanGuarantorModel.nameOfEnterprise = this.frmLoanGurantor.value.nameOfEnterprise;
      this.loanGuarantorModel.establishDate = this.frmLoanGurantor.value.establishDate;
      this.loanGuarantorModel.businessSales = this.frmLoanGurantor.value.businessSales;
      this.loanGuarantorModel.concernStock = this.frmLoanGurantor.value.concernStock;
      this.loanGuarantorModel.enterpriseAge = this.frmLoanGurantor.value.enterpriseAge;
      this.loanGuarantorModel.legalEntity = this.frmLoanGurantor.value.legalEntity;
      this.loanGuarantorModel.enterpriseNature = this.frmLoanGurantor.value.enterpriseNature;
      this.loanGuarantorModel.businessLocation = this.frmLoanGurantor.value.businessLocation;
      this.loanGuarantorModel.tradeLicenceNo = this.frmLoanGurantor.value.tradeLicenceNo;
      this.loanGuarantorModel.segmentEnterprise = this.frmLoanGurantor.value.segmentEnterprise;
      this.loanGuarantorModel.subSectorEnterprise = this.frmLoanGurantor.value.subSectorEnterprise;
      this.loanGuarantorModel.clusterName = this.frmLoanGurantor.value.clusterName;
      this.loanGuarantorModel.eduQualification = this.frmLoanGurantor.value.eduQualification;
      this.loanGuarantorModel.organizationName = this.frmLoanGurantor.value.organizationName;
      this.loanGuarantorModel.organizationAddress = this.frmLoanGurantor.value.organizationAddress;


      //this.loanGuarantorModel.initialEquityAbsoluteFigure = this.frmLoanGurantor.value.initialEquityAbsoluteFigure.replace(/,/g, '');
      //this.loanGuarantorModel.presentEquityAbsoluteFigure = this.frmLoanGurantor.value.presentEquityAbsoluteFigure.replace(/,/g, '');
      //this.loanGuarantorModel.initialEquityAbsoluteFigure = this.frmLoanGurantor.value.initialEquityAbsoluteFigure;
      //this.loanGuarantorModel.presentEquityAbsoluteFigure = this.frmLoanGurantor.value.presentEquityAbsoluteFigure;
      //this.loanGuarantorModel.monthlyExpense = this.frmLoanGurantor.value.monthlyExpense.replace(/,/g, '');
      //this.loanGuarantorModel.monthlyExpense = this.frmLoanGurantor.value.monthlyExpense;
      //this.loanGuarantorModel.relationshipWithEBL = this.frmLoanGurantor.value.relationshipWithEBL;
      //this.loanGuarantorModel.personalWorth = this.frmLoanGurantor.value.personalWorth.replace(/,/g, '');
      //this.loanGuarantorModel.personalWorth = this.frmLoanGurantor.value.personalWorth;
      // this.loanGuarantorModel.guaranteeAmount = this.frmLoanGurantor.value.guaranteeAmount.replace(/,/g, '');
      //this.loanGuarantorModel.guaranteeAmount = this.frmLoanGurantor.value.guaranteeAmount;
      const netMonthlyIncome = this.frmLoanGurantor.value.netMonthlyIncome;
      const initialEquityAbsoluteFigure = this.frmLoanGurantor.value.initialEquityAbsoluteFigure;
      const monthlyExpense = this.frmLoanGurantor.value.monthlyExpense;
      const personalWorth = this.frmLoanGurantor.value.personalWorth;
      const guaranteeAmount = this.frmLoanGurantor.value.guaranteeAmount;

      // Check if the values are strings before using replace
      if (typeof netMonthlyIncome === 'string') {
        this.loanGuarantorModel.netMonthlyIncome = netMonthlyIncome.replace(/,/g, '');
      }

      if (typeof initialEquityAbsoluteFigure === 'string') {
        this.loanGuarantorModel.initialEquityAbsoluteFigure = initialEquityAbsoluteFigure.replace(/,/g, '');
      }

      if (typeof monthlyExpense === 'string') {
        this.loanGuarantorModel.monthlyExpense = monthlyExpense.replace(/,/g, '');
      }

      if (typeof personalWorth === 'string') {
        this.loanGuarantorModel.personalWorth = personalWorth.replace(/,/g, '');
      }

      if (typeof guaranteeAmount === 'string') {
        this.loanGuarantorModel.guaranteeAmount = guaranteeAmount.replace(/,/g, '');
      }

      this.loanGuarantorModel.leadId = this.leadId;
      this._guarantorservice.saveLoanGurantor(this.loanGuarantorModel).subscribe(res => {

        this.loanGuarantorId = res;
        this.shared.ShowAlert('Success', 'Loan Guarantor Save Successfully', 'success');

      })
    }
    else {
      this.shared.ShowAlert('Warning...!', 'First have to created a client', 'warning');
      this.resetLoanGurantorFrm();
      this.getGuarantorList(this.leadId);
    }
  }
  else if (res.isDismissed) {
    this.router.navigate(['/loan-application/loan-application/' + this.leadId + '/' + this.customerId + '/' + 1]);
  }
});

}




  getDistrictList() {
    this._serviceDistrict.getlist().subscribe(response => {
      if (response !== null) {
        this.districtLists = response;
        this.changeDetectorRef.detectChanges();
      }

    })
  }



  getThanaInfoList(districtId: number): void {

    this._serviceThana.getUpazilaByDistrictId(districtId).subscribe(
      (thana: Upazila[]) => {

        this.ThanaList = thana;
        this.changeDetectorRef.detectChanges();
      },
      (error: any) => {
        console.error('Error loading District:', error);
      }
    );
  }


  onDistrictChange(districtId: any): void {
    if (districtId > 0 || districtId != null || districtId != "") {
      this.getThanaInfoList(districtId);
      this.changeDetectorRef.detectChanges();
    }
  }

  editGuarantor(item: any) {
    this.isEditGurantor = true;
    this.frmLoanGurantor.patchValue({

      id: item.id,
      name: item.name,
      fathersName: item.fathersName,
      mothersName: item.mothersName,
      spouseName: item.spouseName,
      dob: item.dob,
      relationwithApplicant: item.relationwithApplicant,
      presentResidentialAddress: item.presentResidentialAddress,
      permanentAddress: item.permanentAddress,
      telephone: item.telephone,
      mobile: item.mobile,
      fax: item.fax,
      email: item.email,
      tinNo: item.tinNo,
      occupation: item.occupation,
      nidNo: item.nidNo,
      employersName: item.employersName,
      employersDesignation: item.employersDesignation,
      businessAddress: item.businessAddress,
      netMonthlyIncome: item.netMonthlyIncome,
      commentsSource: item.commentsSource,
      commentsBSMM: item.commentsBSMM,
      commentsBM: item.commentsBM,
      commentsAVC: item.commentsAVC,
      status: item.status,
      type: item.type,
      professionOfBG: item.professionOfBG,
      age: item.age,
      maritalStatus: item.maritalStatus,
      presentAddress: item.presentAddress,
      years: item.years,
      residentialStaus: item.residentialStaus,
      districtId: item.districtId,
      thanaId: item.thanaId,
      lastTaxReturnYear: item.lastTaxReturnYear,
      nameOfEnterprise: item.nameOfEnterprise,
      establishDate: item.establishDate,
      businessSales: item.businessSales,
      concernStock: item.concernStock,
      enterpriseAge: item.enterpriseAge,
      legalEntity: item.legalEntity,
      enterpriseNature: item.enterpriseNature,
      businessLocation: item.businessLocation,
      tradeLicenceNo: item.tradeLicenceNo,
      segmentEnterprise: item.segmentEnterprise,
      subSectorEnterprise: item.subSectorEnterprise,
      clusterName: item.clusterName,
      initialEquityAbsoluteFigure: item.initialEquityAbsoluteFigure,
      presentEquityAbsoluteFigure: item.presentEquityAbsoluteFigure,
      monthlyExpense: item.monthlyExpense,
      relationshipWithEBL: item.relationshipWithEBL,
      personalWorth: item.personalWorth,
      eduQualification: item.eduQualification,
      organizationName: item.organizationName,
      organizationAddress: item.organizationAddress,
      guaranteeAmount: item.guaranteeAmount,

    });
  }

  DeleteGuarantor(id: any) {

    this._guarantorservice.deleteLoanGurantor(id).subscribe(res => {
      this.shared.ShowAlert('Thank you...', 'Delete succesfully!', 'success');
      this.getGuarantorList(this.leadId);
    })
  }
}
