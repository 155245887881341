import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { SharedService } from 'app/services/shared.service';
import { CustomerService } from 'app/customer/customer.service';
import { DatePipe } from '@angular/common';
import swal from 'sweetalert';
import Swal from 'sweetalert2'



@Component({
  selector: "app-contact-info",
  templateUrl: "./contact-info.component.html",
  styleUrls: ["./contact-info.component.scss"],
})
export class ContactInfoComponent implements OnInit {
  frmGroupcontact: FormGroup;
  model: any = {};
  customersId: any = 0;
  leadId: any = 0;
  clientInfoId: any = 0;
  customerDetailsInfos: any;
  isAuto: boolean = false;
  isDataNull: boolean = false;
  profileUpdate: any;
  saveSuccess: boolean = false;
  @Output() contactUpdateSuccess: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private _leadGennarationService: LeadGenerationServiceService,
    private shared: SharedService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.GetCustomerDetailsForEdit();
    this.getLeadInfo();
    this.ContactpopulateForm();
    this.Contactreset();
    this.route.paramMap.subscribe((params) => {
      if (params.get("id") == null) {
        this.customerService
          .GetCustomerDetailsForEdit()
          .subscribe((data: any) => {
            this.customersId = data.customerDetailsInfos.customerId;
            this.LoadContactInfo(this.customersId);
          });
      } else {
        this.customersId = parseInt(params.get("id"), 10);
        this.LoadContactInfo(this.customersId);
      }
    });
  }

  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe((res) => {
      this.profileUpdate = res;
    });
  }

  LoadContactInfo(customersId: number) {
    this.customerService
      .GetLeadGenerationByLeadNoforContact(customersId)
      .subscribe((res) => {
        if (res != null) {
          this.isAuto = true;

          this.frmGroupcontact.patchValue({
            id: res.id,
            customerId: res.customerID,
            // leadId: res.id,

            contact: res.contact,
            relation: res.relation,
            //customerNameBan: res.customer?.customerNameBan,
            //dateOfBirth: this.datePipe.transform(res.customer?.dateOfBirth, 'dd-MMM-yyyy'),
            designation: res.designation,
            organization: res.organization,
            name: res.name,
            email: res.email,
            homeAddress: res.homeAddress,
            occupation: res.occupation,
            officeAddress: res.officeAddress,

            // motherNameBan: res.customer?.motherNameBan,
            // maritalStatus: res.customer?.maritalStatus,
            // spouseNameEng: res.customer?.spouseNameEng,
            // spouseNameBan: res.customer?.spouseNameBan,
            // spouseMobileNo: res.customer?.spouseMobileNo,
            // districtId: res.customer?.district?.districtName,
            // upazilaId: res.customer?.upazila?.upazilaName,
          });
        } else {
          this.isDataNull = true;
          this.frmGroupcontact = this.formBuilder.group({
            id: 0,
            customerId: 0,
            name: "",
            contact: "",
            relation: "",
            designation: "",
            organization: "",
            email: "",
            homeAddress: "",
            occupation: "",
            officeAddress: "",
            //     fatherNameBan:'',
            // motherNameEng:'',
            // motherNameBan:'',
            // maritalStatus:'',
            // spouseNameEng:'',
            // spouseNameBan:'',
            // spouseMobileNo:'',
            // districtId:'',
            // upazilaId:'',
          });
        }
      });
  }
  getLeadInfo() {
    this.activeRoute.paramMap.subscribe((params) => {
      const leadGNId = +params.get("leadId");
      this.leadId = +params.get("leadId");
      if (leadGNId != null && this.leadId > 0) {
        this._leadGennarationService.searchLeadCodebyId(leadGNId).subscribe(
          (res: any) => {
            this.clientInfoId = res.customerId;
          },
          (error: any) => {
            this.shared.ShowAlert("Error", "Failed to retrieve.", "error");
          }
        );
      }
    });
  }

  ContactpopulateForm() {
    this.frmGroupcontact = this.formBuilder.group({
      id: [0],
      customerId: [0],
      name: ["", Validators.required],
      relation: ["", Validators.required],
      designation: ["", Validators.required],
      organization: [""],
      contact: [""],
      email: [""],
      occupation: [""],
      officeAddress: [""],
      homeAddress: [""],
    });
  }

  onContactsubmit() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save this Contact Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save Contact!",
    }).then((res) => {
      if (res.isConfirmed) {
        
        if (this.customersId > 0) {
          this.model.id = this.frmGroupcontact.value.id;
          this.model.name = this.frmGroupcontact.value.name;
          this.model.email = this.frmGroupcontact.value.email;
          this.model.homeAddress = this.frmGroupcontact.value.homeAddress;
          this.model.contact = this.frmGroupcontact.value.contact;

          //this.model.relation = this.frmGroupcontact.value.relation;
          //this.model.designation = this.frmGroupcontact.value.designation;
          //this.model.organization = this.frmGroupcontact.value.organization;
          //this.model.occupation = this.frmGroupcontact.value.occupation;
          //this.model.officeAddress = this.frmGroupcontact.value.officeAddress;
          this.model.customerID = this.customersId;
          this.customerService.Contactsave(this.model).subscribe((res) => {
            //this.clientInfoId = this.model.clientInfoID;
            this.shared.ShowAlert(
              "Thank you...",
              "You save succesfully!",
              "success"
            );
            this.LoadContactInfo(this.customersId);
            this.contactUpdateSuccess.emit();
            this.getProfileUpdateInfo();
            this.isDataNull = false;

            this.saveSuccess = true;
          });
        } else {
          this.shared.ShowAlert(
            "Warning...",
            "First Create a Client application!",
            "warning"
          );
          this.LoadContactInfo(this.customersId);
        }
      } else if (res.isDismissed) {
        this.router.navigate(["/customer/customer-edit"]);
      }
    });
  }

  Contactreset() {
    this.ContactpopulateForm();
    this.model = {};
  }
  GetCustomerDetailsForEdit() {
    this.customerService.GetCustomerDetailsForEdit().subscribe((data: any) => {
      this.customerDetailsInfos = data;
      this.clientInfoId = data.customerDetailsInfos.customerId;

      //this.changeDef.detectChanges();
    });
  }
}
