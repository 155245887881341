import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { CustomerService } from "../service/customer.service";
import { Customer } from "../model/customer.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "app/services/shared.service";
import swal from "sweetalert";
import { ActivatedRoute, Router } from "@angular/router";
import { PdfService } from "app/pdf-reporting/pdf.service";
import { DashboardCustomService } from "app/dashboard/dashboard-custom.service";
import { ConsoleService } from "@ng-select/ng-select/lib/console.service";
import { Location } from "@angular/common";
@Component({
  selector: "app-customer-list",
  templateUrl: "./customer-list.component.html",
  styleUrls: ["./customer-list.component.scss"],
})
export class CustomerListComponent implements OnInit {
  customers: Customer[] = [];
  customerList: any = [];
  customerDetailsInfos: any;
  currentPage = 1;
  itemsPerPage = 10;
  searchControl = "";
  totalItems = 0;
  currentItems = 0;
  closeResult: string;
  id: number;
  customerId: any;
  loanDetails: any = [];

  constructor(
    private customerService: CustomerService,
    private modalService: NgbModal,
    private shared: SharedService,
    private changeDef: ChangeDetectorRef,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private pdfService: PdfService,
    private _serviceDashboard: DashboardCustomService,
    private location: Location
  ) {
    //this.getCustomerList();
    //this.getCustomerListForPagination();
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      this.id = params["id"];
    });

    //this.getCustomerList();
    this.getCustomerListForPagination();
    this.customerEditListTag();
  }
  // getCustomerList() {
  //   this.customerService.getlist().subscribe((res) => {
  //     this.customers = res;
  //     this.changeDef.detectChanges();
  //   });
  // }
  goBack() {
    this.location.back();
  }
  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getCustomerListForPagination();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getCustomerListForPagination();
    this.changeDef.detectChanges();
  }

  onSearch() {
    this.currentPage = 1;
    this.getCustomerListForPagination();
    this.changeDef.detectChanges();
  }

  getCustomerListForPagination() {
    this.customerService
      .getCustomerListBySearchAndPagination(
        this.currentPage,
        this.itemsPerPage,
        this.searchControl
      )
      .subscribe((res: any) => {
        this.customerList = res.data;
        this.totalItems = res.totalItems;
        this.currentItems = res.currentItems;
        this.changeDef.detectChanges();
      });
  }

  ///////////////////////////////////////////////////////////

  openCustomerDetailsModal(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  openLoanDetailsModel(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  getCustomerLoan(id: any) {
    //alert(id)
    this.customerService.GetLoanDetails(id).subscribe((res) => {
      this.loanDetails = res;
    });
  }

  GetCustomerDetails(id: any) {
    this.customerService.GetCustomerDetails(id).subscribe((data) => {
      this.customerDetailsInfos = data;
      this.changeDef.detectChanges();
    });
  }

  CollentionPDF(leadId: number) {
    this.pdfService.OpenCollectionPdf(leadId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  SchedulePDF(leadId: number) {
    this.pdfService.OpenSchedulePdf(leadId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  async DeleteCustomerById(Id: any) {
    const userConfirmed = await this.showConfirmationDialog();

    if (userConfirmed) {
      this.customerService.DeleteCustomerById(Id).subscribe(
        (res) => {
          this.shared.ShowAlert(
            "Success",
            "Data deleted successfully",
            "success"
          );
          //this.getCustomerList();
          this.getCustomerListForPagination();
        },
        (error) => {
          if (error.status === 500) {
            this.shared.ShowAlert("Error", "Already Have a loan.", "error");
          }
        }
      );
    }
  }

  async showConfirmationDialog() {
    return new Promise<boolean>((resolve) => {
      const userConfirmed = window.confirm(
        "Do you want to delete this customer?"
      );
      resolve(userConfirmed);
    });
  }

  customerEditListTag() {
    this.activeRoute.paramMap.subscribe((params) => {
      const SID = +params.get("id");
    });
  }

  Edit(id: any) {
    this.customerService.GetCustomerDetails(id).subscribe((res) => {
      this.router.navigate(["/customer/customer-admin-edit", id]);
    });
  }

  OpenPDF(customerId: any) {
    this.pdfService.customerDetailsReport(customerId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }
}
