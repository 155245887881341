
<section>
    <div class="card" style="width: 100%;"*ngIf="showSaveForm==='saveForm'">
        <div class="card-body">
            <div class="container">
              <div class="row">
                <div class="col">
                    <h5 class="card-title" style="color: black">Commission</h5>
                    <button type="button" data-toggle="tooltip" title="Add new"  class="btn btn-secondary" (click)="onClickShowSaveForm('saveList')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>

                </div>
              
              </div>
              <hr>
                <form [formGroup]="frmGroup">
                    <div asp-validation-summary="All" class="text-danger"></div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="code" class="col-sm-4 col-form-label">Commission Code</label>
                                <div class="col-sm-8">
                                  <input type="text" class="form-control" formControlName="code" readonly>
                                </div>
                            </div>        
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                              <label for="name" class="col-sm-4 col-form-label">Commission Name</label>
                              <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="name" placeholder="Name">
                              </div>
                          </div>        
                      </div> 
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                              <label for="amount" class="col-sm-4 col-form-label">Amount</label>
                              <div class="col-sm-8">
                                <input type="number" min="0" class="form-control" formControlName="amount" placeholder="Amount">
                              </div>
                          </div>        
                      </div> 
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label for="status" class="col-sm-4 col-form-label"> Status</label>
                          <div class="col-sm-8">
                            <select class="form-control" formControlName="status">
                              <option value="">Select</option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </div>
                        </div>       
                      </div> 
                  </div>
                  <div class="row">
                    
            <div class="col-md-6">
              <div class="form-group row">
                <label for="sortorder" class="col-sm-4 col-form-label">Product Type</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="productTypeId">
                    <ng-container *ngFor="let type of productBuilderTypes | keyvalue">
                      <option *ngIf="type.key!=='0' && type.key!=='1' && type.key!=='2'" [value]="type.value">{{ type.key }}</option>
                  </ng-container>
                  </select>
                </div>
              </div>
                  </div>
                  <div class="col-md-6">
                    <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit" (click)="onsubmit()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
                    <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                    </button>
                    <button type="button" data-toggle="tooltip" title="Refresh"  (click)="reset()"  class="btn btn-secondary" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
      </div>
      </div>
                </form>
            </div>
        </div>
      </div>
      <div class="card" style="width: 100%;"*ngIf="showSaveForm==='saveList'">
        <div class="card-body">
            <div class="container">
              <h5 class="card-title" style="color: black">Commission List</h5>
              <button type="button" data-toggle="tooltip" title="Add new"  class="btn btn-secondary" (click)="onClickShowSaveForm('saveForm')"style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
              <hr>
              <div class="row">
                <div class="col-md-6" style="text-align: left">
                  <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control" style="width:16%">
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option selected value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div class="form-group col-md-6" style="text-align: right">
                  <input type="text" class="form-control" [(ngModel)]="searchControl" placeholder="Search" (keyup)="onSearch()">
                </div>
              </div>
              <table id="example" class="table table-striped table-bordered" style="width:100%">
                <thead>
                    <tr>
                      <th>SL No.</th>
                        <th>Commission Code</th>
                        <th>Commission Name</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th style="width: 25%;text-align: center;">Action</th>
                    </tr>
                </thead>
                <tbody>
                  <tr
                  *ngFor="let item of list | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
                  <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td>{{item.code}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.amount}}</td>
                    <td>{{productBuilderTypes[item.productBuilderType]}} Product</td>
                    <td>{{item.status=== true ? "Active" : "Inactive"}}</td>
                    <td style="width: 25%;">
                        <button class="btn btn-primary" (click)="edit(item)" ><i class="fa fa-edit 3x"></i></button>
                        <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.id)" ><i class="fa fa-trash-o 3x"></i></button>
                     
                    </td>
                  </tr>
                 </tbody>
            </table>
            <div class="row">
              <div class="col-md-6" style="text-align: left">
                <span>Showing {{currentItems}} of {{totalItems}} entries</span>
              </div>
              <div class="col-md-6" style="text-align: right">
                <pagination-controls (pageChange)="onPageChange($event)">
                </pagination-controls>
              </div>
            </div>
            </div>  
          </div>
      </div>  
    </section>


