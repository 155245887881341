import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'app/customer/customer.service';
import { SharedService } from 'app/services/shared.service';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { LoanapplicationApprovalService } from 'app/loanapplication-approval/loanapplication-approval.service';
import { LayoutService } from 'app/shared/services/layout.service';
import swal from 'sweetalert';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-loan-application',
  templateUrl: './loan-application.component.html',
  styleUrls: ['./loan-application.component.scss']
})
export class LoanApplicationComponent implements OnInit {
  activeTab:string = '1-1';
  mainTab:string = '1';

  leadCode: any;
  leadId: any = 0;
  loanRegInfoId: any;


  //selectedOption: string = '1';
  activeButton = 1;
  activeSubButton = 1;
  activeFormbody = 1;

  loanId:number = 0;
  leadNo:string = "";
  isApprover: boolean=true;
  clientInfoId: any=0;
  currentUser:any;
  loanRegisterId:number;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal,
    private shared:SharedService,
    private _serviceClientInfo: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private _leadGennarationService: LeadGenerationServiceService,
    private loanService: LoanapplicationApprovalService,
    private layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
    this.getLeadInfo();
    //this.loanRegisterId === null
    this.activeRoute.paramMap.subscribe(params => {
        this.leadId=parseInt(params.get('leadId')); 
    });

    this.layoutService.getCurrentUserName().subscribe(res=>{
      this.currentUser = res;   
      // console.log('userName');
      // console.log(res); 
      }
    );
    //this.getLoanRegisterId(this.leadId);
  }

  getLeadInfo() {
    this.activeRoute.paramMap.subscribe(params => {
      const leadGNId = +params.get('leadId');
      this.leadId = +params.get('leadId');
      if (leadGNId != null) {
        this._leadGennarationService.searchLeadCodebyId(leadGNId).subscribe(
          (res: any) => {
            this.leadCode = res.leadCode;
            this.getLoanRegisterId(this.leadId);
          },
          (error: any) => {
           this.shared.ShowAlert('Error', 'Failed to retrieve.', 'error');
          }
        );
      }
    });
  }

  
  setMainTab(number:string):void{
    this.mainTab = number;
    this.activeButton = parseInt(number, 10); 
    
    if(number == '1'){
      this.activeTab = '1-1';
    }
    if(number == '2'){
      this.activeTab = '2-1';
    }
    if(number == '3'){
      this.activeTab = '3-1';
    }
    if(number == '4'){
      this.activeTab = '4-1';
    }

    if(number == '5'){
      this.activeTab = '5-1';
    }
    if(number == '6'){
      this.activeTab = '6-1';
    }
    if(number == '7'){
      this.activeTab = '7-1';
    }

    this.changeDetectorRef.detectChanges();
  }

  setActiveButton(buttonNumber): void {
   
    ////this.activeButton = buttonNumber;
    this.activeButton = parseInt(buttonNumber, 10); 
    this.activeSubButton = 1;
    this.activeFormbody = buttonNumber;

    this.activeTab = buttonNumber;

   this.changeDetectorRef.detectChanges();
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  // forwardto(leadCode){
  //   this.loanService.ForwardLeadByCodeForApproval(leadCode).subscribe((res:any) => {
  //     if(res.status==="forwarded"){
  //       this.shared.ShowAlert("Success","Forwarded Successfully","success");
  //       this.LoadPdfByCode(leadCode);
  //       this.router.navigate(['/loan-application/lead-generation']);
  //     }
  //     else if(res.status==="fail"){
  //       this.shared.ShowAlert("Success","Unable to Forwarded","warning");
  //     }
  //     else{
  //       this.shared.ShowAlert("Success","Unable to Forwarded","warning");

  //     }
  //   })
  // }

  getLoanRegisterId(leadId:number){

    this.loanService.getLoanregisterbyLeadId(leadId).subscribe((res:any)=>{
 
      this.loanRegisterId=res;
      this.changeDetectorRef.detectChanges();
    })

  }


  forwardto(leadCode) {

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to send waiting application!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, forward it!"
         }).then((res) => {
        if (res.isConfirmed) {
  
   //alert(this.loanRegisterId)
        if (this.loanRegisterId === null || this.loanRegisterId === undefined || this.loanRegisterId === 0 ) {
          this.shared.ShowAlert("Error", "You must add  loan register first", "warning");
          return;
        }
        else{
          this.loanService.ForwardLeadByCodeForApproval(leadCode).subscribe((res: any) => {
            if (res.status === "forwarded") {
              this.shared.ShowAlert("Success", "Forwarded Successfully", "success");
              this.LoadPdfByCode(leadCode);

              //this.router.navigate(['/loan-application/lead-generation']);
              this.router.navigate(['/loan-application/customer-loan']);

            } else if (res.status === "fail") {
              this.shared.ShowAlert("Error", "Unable to Forwarded", "warning");
            } else {
              this.shared.ShowAlert("Error", "Unable to Forwarded", "warning");
            }
          });
        }
  }
  else if (res.isDismissed) {
    //this.shared.ShowAlert("Error", "Unable to Forwarded", "warning");
   // this.router.navigate(['/loan-application/loan-application/' + this.leadId + '/' + this.customerId + '/' + 1]);
  }
});

}
  


  
  LoadPdfByCode(leadCode: string) {
    // this._serviceClientInfo.LoadPdfByCode(leadCode).subscribe(
    //   (response) => {
    //     const pdfBlob = new Blob([response], { type: 'application/pdf' });
    //     const pdfDataUrl = URL.createObjectURL(pdfBlob);
    //   },
    //   (error) => {
    //     console.error('Error fetching PDF:', error);
    //   }
    // );
  }


 

}
