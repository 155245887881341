<div class="row">
    <div class="card" style="width: 100%;">
        <div class="row card-header py-3 d-flex flex-row align-items-center">
           
            <div class="col-6">
                <h6 class="m-0 font-weight-bold text-primary">Running Loan CL</h6>
            </div>
            <div class="col-6">
                <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
            
                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div class="container">
                <table id="example" class="table table-striped table-bordered" style="width:100%;color: #9E9E9E!important;">
                   
                    <thead>
                        <tr>
                            <th>SL No.</th>
                            <th>Account No </th>
                            <th>Account Title </th>
                            <th>Loan Type </th>
                            <th>Loan Amount</th>
                            <th>Overdue</th>
                            <th>POA</th>
                            <th>CL Classification </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr *ngFor="let item of List; let i = index">
                            <td>{{i+1}}</td>
                            <td>{{item.accountNo}}</td>
                            <td>
                                <span>{{item?.accountTitle}}</span><br>
                                <!-- <span>Mobile No.: {{item?.customer?.mobileNo}}</span> -->
                            </td>
                            <td>{{item.tenureName}}</td>
                            <td>{{item?.outStanding}}</td>
                            <td>
                            {{item?.overDue}}
                            </td>
                            <td>
                              <ng-container *ngIf="item?.aging !==0; else noAging">
                                {{item?.aging}} Month
                              </ng-container>
                              <ng-template #noAging>

                              </ng-template>
                            </td>
                            <td>
                                
                                {{item?.typeName}}


                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>