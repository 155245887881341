<div class="card" style="width: 100%;">
    <div class="card-header row py-3 d-flex flex-row align-items-center">
        <div class="col-md-9">
            <h6 class="m-0 font-weight-bold text-primary">Loan  List</h6>
        </div>
        <div class="col-md-2" style="text-align: right;">
          
        </div>




        <div class="col-1">
            <button class="btn btn-secondary" title="Go Back" (click)="goBack()">

                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
        </div>



        <hr>

    </div>

    <div class="card-body">
        <div class="container">

            <table id="example" class="table table-striped table-bordered" style="width:100%">
                <thead>
                    <tr>
                        <th>SL No.</th>
                        <th>Loan Code</th>
                        <th>Customer</th>
                        <th>Loan Product</th>
                        <th>Status</th>
                        <th style="width: 15%;text-align: center;">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of LeadList; let i = index;">
                        <td>{{i + 1}}</td>
                        <td>{{item.leadCode}}</td>
                        <td><span>Name:{{item.customer?.customerNameEng}}</span> <br>
                            <span>Mobile No:{{item.customer?.mobileNo}} </span><br>
                           
                        </td>
                       
                        <td>{{item.loanProduct?.name}} <br> {{item.tenure?.monthName}}</td>
                        <td>
                            <div class="badge badge-pill" *ngIf="item.statusInfoId==1"
                                [ngClass]="{'bg-light-primary': item.statusInfoId == 1}">
                                Apply </div>

                            <div class="badge badge-pill" *ngIf="item.statusInfoId==5"
                                [ngClass]="{'bg-light-primary': item.statusInfoId == 5}">
                                Rejected </div>

                                <div class="badge badge-pill" *ngIf="item.statusInfoId==3" [ngClass]="{'bg-light-primary': item.statusInfoId == 3}">
                                    Approved </div>

                                <div class="badge badge-pill" *ngIf="item.statusInfoId==7" [ngClass]="{'bg-light-primary': item.statusInfoId == 7}">
                                    Disburse </div>

                                    <div class="badge badge-pill" *ngIf="item.statusInfoId==8" [ngClass]="{'bg-light-primary': item.statusInfoId == 8}">
                                        Full-Paid </div>
                                  <div class="badge badge-pill" *ngIf="item.statusInfoId==9" [ngClass]="{'bg-light-primary': item.statusInfoId == 9}">
                                            Reconciliation </div>
                        </td>
                        <!-- <td style="width: 21%;text-align: -webkit-center;">

                          

                            <button class="btn btn-sm btn-info" type="button" title="Click here to go to form"
                                (click)="goLoanForm(item.id,item.customerId,item.loanProductId,item.tenureId)"
                                *ngIf="type==1"> Go to Form </button>

                            <button class="btn btn-sm btn-info" type="button" title="Click here to complete process"
                                (click)="goLoanView(item.leadCode)" *ngIf="type==3" style="height: 30px;width: 60px;">
                                View </button>

                            
                        </td> -->
                        <td>
                            <button class="btn btn-danger btn-sm mb-1" title="Delete" (click)="DeleteCustomerById(item.id)">
                                <i class="fa fa-trash-o 3x"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

    </div>
</div>