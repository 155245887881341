<div class="card" style="width: 100%;">
    <div class="card-body">
      <!-- <h5 class="card-title" style="color: black">Expense Details </h5> -->

      <h5 class="card-title" [ngStyle]="{'color': ExpenseList.length === 0 ? 'red' : 'black'}" >
        {{ ExpenseList.length === 0 ? 'Expense Details (Incomplete)' : 'Expense Details' }}
      </h5>


      <hr>

      <form [formGroup]="frmGroupEx">
        <div asp-validation-summary="All" class="text-danger"></div>
        <div class="row mt-2">
          <div class="col-md-6">
            <div class="form-group row">
              <label for="rentAndUtilityAmount" class="col-sm-4 col-form-label">Rent & Utility Amount</label>
              <div class="col-sm-8">
                <input type="text" style="text-align: right;" class="form-control" formControlName="rentAndUtilityAmount"
                (input)="frmGroupEx.get('rentAndUtilityAmount').setValue(formatNumber($event.target.value))" placeholder="0">
                    <input type="hidden" class="form-control" formControlName="expenseId">
                    <span *ngIf="frmGroupEx.get('rentAndUtilityAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
              </div>
            </div>

          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="foodAndClothingAmount" class="col-sm-4 col-form-label">Food And Clothing Amount</label>
              <div class="col-sm-8">
                <input type="text" style="text-align: right;" class="form-control" formControlName="foodAndClothingAmount"
                 (input)="frmGroupEx.get('foodAndClothingAmount').setValue(formatNumber($event.target.value))" placeholder="0">
                 <span *ngIf="frmGroupEx.get('foodAndClothingAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="educationAmount" class="col-sm-4 col-form-label">Education Amount</label>
              <div class="col-sm-8">
                <input type="text" style="text-align: right;" class="form-control" formControlName="educationAmount"
                 (input)="frmGroupEx.get('educationAmount').setValue(formatNumber($event.target.value))" placeholder="0">
                 <span *ngIf="frmGroupEx.get('educationAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group row">
              <label for="loanRepaymentAmount" class="col-sm-4 col-form-label">Loan Repayment Amount</label>
              <div class="col-sm-8">
                <input type="text" style="text-align: right;" class="form-control" formControlName="loanRepaymentAmount"
                 (input)="frmGroupEx.get('loanRepaymentAmount').setValue(formatNumber($event.target.value))" placeholder="0">
                 <span *ngIf="frmGroupEx.get('loanRepaymentAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group row">
              <label for="miscellaneousAmount" class="col-sm-4 col-form-label">Miscellaneous Amount</label>
              <div class="col-sm-8">
                <input type="text" style="text-align: right;" class="form-control" formControlName="miscellaneousAmount"
                 (input)="frmGroupEx.get('miscellaneousAmount').setValue(formatNumber($event.target.value))" placeholder="0">
                 <span *ngIf="frmGroupEx.get('miscellaneousAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group row">
              <label for="expenseTotalAmount" class="col-sm-4 col-form-label">Total expense Amount</label>
              <div class="col-sm-8">
                <input type="text" style="text-align: right;" class="form-control" formControlName="expenseTotalAmount"
                 (input)="frmGroupEx.get('expenseTotalAmount').setValue(formatNumber($event.target.value))" placeholder="0">
                 <span *ngIf="frmGroupEx.get('expenseTotalAmount').hasError('pattern')" class="text-danger">  Only numeric values are allowed. </span>
              </div>
            </div>
          </div>

        </div>
        <hr>
        <div class="row" style="float: right;">
          <button title="Save" type="submit" value="Submit" (click)="onExpenseSubmit()" class="btn btn-success"
            style="float:right; margin-top:5px;">
            <span>{{ isExpenseEdit ? 'Update' : 'Save' }}</span>
          </button>
          <button type="button" (click)="refreshExpense()" title="Refresh" class="btn btn-secondary"
            style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
        </div>
      </form>

    </div>
  </div>
   <div class="" *ngFor="let item of ExpenseList; let i = index;">
  <!-- <div class="" *ngFor="let item of customerDetailsInfos.expenseDetails; let i = index;"> -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-8">
            <h5 class="card-title" style="color: black"> {{i +1}} : Expense Details </h5>
          </div>
          <div class="col-md-4">
            <button title="Update" type="submit" value="Submit" (click)="UpdateExpence(item.id)"
              class="btn btn-success" style="float:right; margin-top:5px;"> <i class="fa fa-edit 3x"></i>
            </button>
          </div>


        </div>

        <hr>

        <div asp-validation-summary="All" class="text-danger"></div>
        <div class="row mt-2">
          <div class="col-md-6">
            <div class="form-group">
              <label class=" col-form-label">
                <i class="fa fa-money ic" aria-hidden="true"></i>
                Rent And Utility Amount : <span class="ic"> {{item.rentAndUtilityAmount | number:'1.0-2'}}</span></label>

            </div>

          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label">
                <i class="fa fa-money ic" aria-hidden="true"></i>
                Food And Clothing Amount
                : <span class="ic"> {{item.foodAndClothingAmount | number:'1.0-2'}}</span>

              </label>

            </div>

          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label">
                <i class="fa fa-money ic" aria-hidden="true"></i>
                Education Amount
                : <span class="ic"> {{item.educationAmount | number:'1.0-2'}}</span>
              </label>

            </div>

          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label">
                <i class="fa fa-money ic" aria-hidden="true"></i>
                Loan Repayment Amount
                : <span class="ic"> {{item.loanRepaymentAmount | number:'1.0-2'}}</span>

              </label>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label">
                <i class="fa fa-money ic" aria-hidden="true"></i>
                Miscellaneous Amount
                : <span class="ic"> {{item.miscellaneousAmount | number:'1.0-2'}}</span>
              </label>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label">
                <i class="fa fa-money ic" aria-hidden="true"></i>
                Expense Total Amount
                : <span class="ic"> {{item.totalAmount | number:'1.0-2'}}</span>
              </label>

            </div>
          </div>
        </div>


      </div>
    </div>

  </div>