<!-- <div class="card" style="width: 100%;">
    <div class="card-body">
      <div class="">
        <h5 class="card-title" style="color: black">Applicant Details</h5>
        <hr>
        <form [formGroup]="frmGroup">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="CustomerRCode" class="col-sm-4 col-form-label">Customer Code</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" [value]="generatecustomercode" formControlName="CustomerRCode" placeholder="Code....">
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="dateOfBirth" class="col-sm-4 col-form-label">Date Of Birth</label>
                <div class="col-sm-8">
                  <input type="date" class="form-control" formControlName="DateOfBirth" dateOfBirth="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="CustomerNameEng" class="col-sm-4 col-form-label">Customer Name Eng</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="CustomerNameEng" placeholder="Customer Name Eng....">
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="CustomerNameBan" class="col-sm-4 col-form-label">Customer Name Ban</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="CustomerNameBan" placeholder="Customer Name Ban....">
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="GenderId" class="col-sm-4 col-form-label">Gender</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="GenderId" placeholder="Gender">
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="mobileNo2" class="col-sm-4 col-form-label">Telephone</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="mobileNo2" placeholder="Telephone....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="MobileNo" class="col-sm-4 col-form-label">Mobile</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="MobileNo" placeholder="Mobile....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Email" class="col-sm-4 col-form-label">Email</label>
                <div class="col-sm-8">
                  <input type="email" class="form-control" formControlName="Email" placeholder="Email....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="NidNo" class="col-sm-4 col-form-label">NID No</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="NidNo" placeholder="NID No....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="FatherNameBan" class="col-sm-4 col-form-label">Father Name Ban</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="FatherNameBan" placeholder="Father Name....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="FatherNameEng" class="col-sm-4 col-form-label">Father Name Eng</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="FatherNameEng"
                    placeholder="Father Mobile No....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="MotherNameBan" class="col-sm-4 col-form-label">Mother Name Ban</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="MotherNameBan" placeholder="Mother Name....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="MotherNameEng" class="col-sm-4 col-form-label">Mother Name Eng</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="MotherNameEng"
                    placeholder="Mother Mobile No....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Marital status</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="MaritalStatus"
                    (change)="onMaritalStatusChange($event.target.value)">
                    <option value="select">Select</option>
                    <option value="Married">Married</option>
                    <option value="Unmarried">Unmarried</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-6" *ngIf="marritalStatus === 'Married'">
              <div class="form-group row">
                <label for="spouseName" class="col-sm-4 col-form-label">Spouse Name Eng</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="SpouseNameEng" placeholder="Spouse Name....">
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="marritalStatus === 'Married'">
              <div class="form-group row">
                <label for="SpouseMobileNo" class="col-sm-4 col-form-label">Spouse Mobile No</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="SpouseMobileNo"
                    placeholder="Spouse Mobile No....">
                </div>
              </div>
            </div>


            <div class="col-md-6">
              <div class="form-group row">
                <label for="yearlyIncome" class="col-sm-4 col-form-label">Yearly Income</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="yearlyIncome"
                    placeholder="Yearly Income....">
                </div>
              </div>
            </div>


          </div>
         <button 
            (click)="onClientssubmit()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
            <span>{{ isClientInfoEdit ? 'Update' : 'Save' }}</span>
          </button>

        </form>
      </div>
    </div>
  </div> -->

  <div class="card">
    <div class="card-body">
      <div class="">
        <h5 class="card-title" style="color: rgb(78, 77, 77);">Customer Details</h5>
        <hr>
        <form [formGroup]="frmGroup">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="NameEnglish" class="col-sm-4 col-form-label">Customer Code</label>
                <div class="col-sm-8">
                  <input type="hidden" class="form-control" formControlName="id">
                  <input type="hidden" class="form-control" formControlName="leadId">
                  <input type="text" class="form-control uppercase-input" [readonly]="isAuto" formControlName="customerRCode" placeholder="customerRCode">
                </div>
              </div>
  
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="NameBangla" class="col-sm-4 col-form-label">Customer Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" [readonly]="isAuto" formControlName="customerNameEng" placeholder="customer Name Eng">
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="NameBangla" class="col-sm-4 col-form-label">Customer Name Ban</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" [readonly]="isAuto" formControlName="customerNameBan" placeholder="customer Name Eng">
                </div>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="form-group row">
                <label for="dateOfBirth" class="col-sm-4 col-form-label">Date Of Birth</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control datepicker" [readonly]="isAuto" formControlName="dateOfBirth" placeholder="dd/mm/yyyy">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="gender" class="col-sm-4 col-form-label">Gender</label>
                <div class="col-sm-8">
                  
                
                  <select class="form-control" formControlName="GenderId"  placeholder="Gender" (change)="onGenderStatusChange($event.target.value)">
                    <option  value="select">Select</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="mobileNoOffice" class="col-sm-4 col-form-label">Mobile (Office)</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="mobileNo" placeholder="" readonly>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="mobileNoHome" class="col-sm-4 col-form-label">Email</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" [readonly]="isAuto" formControlName="email" placeholder="">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Nationality" class="col-sm-4 col-form-label">NID No</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="nidNo" placeholder="" readonly>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Father Name Eng</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="fatherNameEng" placeholder="" readonly>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Father Name Ban</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="fatherNameBan" placeholder="">
                </div>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Mother Name </label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="motherNameEng" placeholder="" readonly>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">motherNameBan</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="motherNameBan" placeholder="">
                </div>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Marital Status</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="maritalStatus" placeholder="" readonly>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Spouse Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="spouseNameEng" placeholder="" readonly>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Spouse Name Ban</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="spouseNameBan" placeholder="">
                </div>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Spouse Mobile No</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="spouseMobileNo" placeholder="" readonly>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">District Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="districtId" placeholder="">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">Upazila Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="upazilaId" placeholder="">
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="Religion" class="col-sm-4 col-form-label">yearly Income</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="fatherNameEng" placeholder="">
                </div>
              </div>
            </div> -->
          
          </div>
          <!-- <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit"
            (click)="SavePersonalInfo()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
            <span>Save</span>
          </button> -->
        </form>
      </div>
    </div>
  </div>
