<section>
  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Excise Duty</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
          (click)="onClickShowSaveForm('saveList')"
          style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
        <hr>
        <form [formGroup]="frmGroup">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="desicode" class="col-sm-4 col-form-label">Excise Duty Code <span
                    style="color: red;">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="exciseDutyCode" readonly>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Transaction Type</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="transectionTypeId">
                    <option value="">Select</option>
                    <option *ngFor="let item of transactionlist" [value]="item.id">{{item.typeName}}</option>
                  </select>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Loan Product <span
                    style="color: red;">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="exciseDutyName"
                    placeholder="Excise Duty Name">

                </div>
              </div>

            </div> -->

          </div>

          <div class="row mt-2">
            
            <div class="col-md-6">
              <div class="form-group row">
                <label for="sortorder" class="col-sm-4 col-form-label">Product Type</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="productTypeId">
                    <ng-container *ngFor="let type of productBuilderTypes | keyvalue">
                      <option *ngIf="type.key!=='0' && type.key!=='1' && type.key!=='2'" [value]="type.value">{{ type.key }}</option>
                  </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="sortorder" class="col-sm-4 col-form-label">Status</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="status">
                    <option value="">Select</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
<div class="row">
  
  <div class="col-md-6">
    <div class="form-group row">
      <label for="sortorder" class="col-sm-4 col-form-label">Remarks<span style="color: red;">*</span></label>
      <div class="col-sm-8">

        <textarea id="basic-form-9" placeholder="....Remarks" rows="4" formControlName="remarks"
          class="form-control"></textarea>
      </div>
    </div>
  </div>
</div>
          <!-- <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" (click)="onsubmit()" type="submit"  value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
                    <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                    </button>
                    <button type="button" data-toggle="tooltip" title="Refresh"  (click)="reset()"  class="btn btn-secondary" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
        -->
        </form>
      </div>
    </div>
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Excise Duty Details</h5>
        <hr>
        





        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label for="desicode" class="col-sm-4 col-form-label">Minimum Amount<span style="color: red;">*</span></label>
              <div class="col-sm-8">
                <input type="text" class="form-control"  [(ngModel)]="minAmountRange" >
              </div>
            </div>
          </div>
        
        
          <div class="col-md-6">
            <div class="form-group row">
              <label for="name" class="col-sm-4 col-form-label">Maximum Amount <span style="color: red;">*</span></label>
              <div class="col-sm-8">
                <input type="text" class="form-control"  [(ngModel)]="maxAmountRange" placeholder="Excise Duty Amount">
        
              </div>
            </div>
        
          </div>
        
        </div>


        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label for="amount" class="col-sm-4 col-form-label">Excise Duty Amount</label>
              <div class="col-sm-8">
                <input type="number" min="0" class="form-control" [(ngModel)]="dutyAmount" placeholder="Type here...">
              </div>
            </div>
          </div>
          <!-- <div class="col-md-5">
                    <div class="form-group row">
                      <label for="taxPercent" class="col-sm-4 col-form-label">Percent %</label>
                      <div class="col-sm-8">
                        <input type="number" min="0" class="form-control" [(ngModel)]="taxPercent" placeholder="Type here...">
                      </div>
                    </div>
                  </div> -->
          <div class="col-md-2">
            <button type="button" (click)="addTax()" data-toggle="tooltip" title="Add new" class="btn btn-primary">Add
              More</button>
          </div>
        
        </div>
        <!-- <div class="row">
          <div class="col-md-5">
            <div class="form-group row">
              <label for="type" class="col-sm-4 col-form-label">Type</label>
              <div class="col-sm-8">
                <select class="form-control" [(ngModel)]="type">

                  <option value="1">Up to</option>
                  <option value="0">Extra</option>
                </select>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <hr>
      <div class="container">
        <table class="table">
          <thead>
            <tr>
              <th>Sl.</th>
              <th>Duty Amount</th>
              <th>Min. Amount</th>
              <th>Max. Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tax of taxes; let i = index">
              <td>{{ i+1 }}</td>
              <td style="text-align: center;">{{ tax.dutyAmount }}</td>
              <td style="text-align: center;">{{ tax.minAmountRange }}</td>
              <td>{{tax.maxAmountRange}}
              </td>
              <!-- <td style="text-align: center;">{{ tax.taxPercent }}</td>
              <td>{{tax.type === 1 ? 'Up to' : 'Extra to'}}
              </td> -->
              <td>
                <button type="button" class="btn btn-info  mr-2" (click)="editTax(i)">Edit</button>
                <button type="button" class="btn btn-danger" (click)="deleteTax(i)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="form-group">
      <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" (click)="onsubmit()" type="submit"
        value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
        <span>{{ isEdit ? 'Update' : 'Save' }}</span>
      </button>
      <button type="button" data-toggle="tooltip" title="Refresh" (click)="reset()" class="btn btn-secondary"
        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>

      <!-- <button type="button" data-toggle="tooltip" title="Submit" (click)="onSubmit()" class="btn btn-secondary" style="float:right; margin-top:-31px;margin-right:38px;margin-left: 8px;">Submit</button> -->

    </div>
  </div>

  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Excise Duty List</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
          (click)="onClickShowSaveForm('saveForm')"
          style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>

        <hr>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control"
              style="width:16%">
              <option value="2">2</option>
              <option value="3">3</option>
              <option selected value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="form-group col-md-6" style="text-align: right">
            <input type="text" class="form-control" [(ngModel)]="searchControl" placeholder="Search"
              (keyup)="onSearch()">
          </div>
        </div>
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th>SL No.</th>
              <th>Excise Duty Name</th>
              <!-- <th>Excise Duty Amount</th> -->
              <th>Highest Balance (Credit/Debit) </th>
              <th>Type</th>
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of ExciseDutyList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
              <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
              <td>{{item.exciseDuty.exciseDutyCode}} </td>
              <!-- - {{item.exciseDuty.exciseDutyName}} -->
              <td>
                <ul>
                  <li *ngFor="let detail of item.exciseDutyDetails">
                    <span>Excise Amount: {{detail.dutyAmount}}</span><br>
                    <span>Amount Range:  {{detail.minAmountRange}} -
                    {{detail.maxAmountRange}}</span>
                    
                   </li>


                  <!-- <li *ngFor="let detail of item.exciseDutyDetails">{{detail.amount}} - {{detail.taxPercent}} %
                    {{detail.type === '1' ? 'Up to' : 'Extra to'}}</li> -->




                </ul>
              </td>
              <td>{{productBuilderTypes[item.exciseDuty.productBuilderType]}} Product</td>
              <td>
                <button class="btn btn-primary" (click)="edit(item)">
                  <i class="fa fa-edit 3x"></i>
                </button>
                <button class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.exciseDuty.id)">
                  <i class="fa fa-trash-o 3x"></i>
                </button>
              </td>
            </tr>
          </tbody>

        </table>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <span>Showing {{currentItems}} of {{totalItems}} entries</span>
          </div>
          <div class="col-md-6" style="text-align: right">
            <pagination-controls (pageChange)="onPageChange($event)">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>