import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Nominee } from '../model/nominee';
import { CustomerService } from '../service/customer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'app/services/shared.service';
import { id } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-nominee-edit',
  templateUrl: './nominee-edit.component.html',
  styleUrls: ['./nominee-edit.component.scss']
})
export class NomineeEditComponent implements OnInit {

  nominee: Nominee[] = [];
  Id:number = 0;
  constructor(private customerService: CustomerService,
    private modalService: NgbModal,
    private shared: SharedService,
    private changeDef:ChangeDetectorRef
    )  { }

    ngOnInit(): void {
      this.getNomineeById(this.Id)
    }

    getNomineeById(Id : number) {
   alert(7)
      this.customerService.GetNomineeById(Id).subscribe(res => {
        console.log(res)
        this.nominee = res;
        this.changeDef.detectChanges();
      })
    }
  
    
  
  
       
    
}
