import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gurantor-business-standing',
  templateUrl: './gurantor-business-standing.component.html',
  styleUrls: ['./gurantor-business-standing.component.scss']
})
export class GurantorBusinessStandingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
}
