<div class="card">
    <div class="row card-header  d-flex flex-row align-items-center">
        <div class="col-6">
            <h5 class="card-title" style="color: #888888">Add White List Customer</h5>
        </div>
        <div class="col-6">
            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
    
                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
        </div>
    </div>
    <hr>
    <div class="card-body">
        <form #customerForm="ngForm">
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Mobile No <span style="color: red;">
                                    *</span></label>
                            <div class="col-sm-8">
                                <input class="form-control" type="text" placeholder="Enter Mobile No" name="MobileNo"
                                    name="MobileNo" [(ngModel)]="model.MobileNo" (keyup)="CheckWhiteList()">
                                    <div *ngIf="!isMobileNoValid" class="text-danger">Mobile number must be 11 digits.</div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label">Name <span style="color: red;">
                                *</span></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="CustomerNameEng"
                                    placeholder="Customer Name" [(ngModel)]="model.CustomerNameEng"
                                    #CustomerNameEng="ngModel">

                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Father Name</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="FatherNameEng" placeholder="Father Name"
                                    [(ngModel)]="model.FatherNameEng">

                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Mother Name</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="MotherNameEng" placeholder="Mother Name"
                                    [(ngModel)]="model.MotherNameEng">

                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Date Of Birth</label>
                            <div class="col-sm-8">
                                <input class="form-control" type="date" placeholder="yyyy-mm-dd" name="dp"
                                    name="DateOfBirth" [(ngModel)]="model.DateOfBirth">
                            </div>
                        </div>

                        
                    </div>

                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">NID </label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="NidNo" placeholder="NID" [(ngModel)]="model.NidNo">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">TIN </label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="tin" placeholder="TIN"
                                    [(ngModel)]="model.tin">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Gender</label>
                            <div class="col-sm-8">
                                <select class="form-control" name="GenderId" placeholder=""
                                    [(ngModel)]="model.GenderId">
                                    <option value="">Select</option>
                                    <option *ngFor="let item of genderList | async" [value]="item.id">
                                        {{item.name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Email<span style="color: red;">
                                    *</span></label>
                            <div class="col-sm-8">

                                <input class="form-control" type="text" placeholder="Email"
                                    name="Email" name="Email" [(ngModel)]="model.Email" required>

                            </div>
                        </div>


                            <div class="form-group row">
                                <label for="" class="col-sm-4 col-form-label">Credit Score<span style="color: red;">
                                        *</span></label>
                                <div class="col-sm-8">

                                    <input class="form-control" type="text" placeholder="Credit Score"
                                        name="creditScore" [(ngModel)]="model.creditScore" required>

                                </div>

                            </div>


                            <!-- <div class="form-group row">
                                        <label for="image" class="col-sm-4 col-form-label">Profile PICTURE <span
                                                style="color:red">*</span></label>
                                        <div class="col-sm-8">
                                            <input type="file" accept="image/*" id="image"
                                                (change)="selectProfilePicture($event)" style="display: none;"
                                                #fileInput />
                                            <div style="height: 180px; width: 180px; cursor: pointer;"
                                                (click)="fileInput.click()">

                                                <img src="{{ proPreview ? proPreview : 'assets/img/gallery/1Icon.png' }}"
                                                    id="imageProPreview" style="width: 100%; height: 100%;"
                                                    class="proPreview" title="Click here to upload photo" />


                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="form-group row pull-right">
                                        <button data-toggle="tooltip" title="Save" type="button"
                                         (click)="onsubmit(customerForm)" class="btn btn-success"
                                         [disabled]="!isSubmitButtonEnabled()"
                                            style="float:right; margin-top:5px;"> <span>{{ isEdit ? 'Update'
                                                : 'Save'
                                                }}</span></button>
                                    
                                    
                                    
                                        <button type="button" data-toggle="tooltip" title="Refresh" (click)="resetCust()" class="btn btn-secondary"
                                            style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
                                    </div>

                    </div>



     

                  


                </div>
                </div>



        </form>
    </div>
</div>