import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductBuilderService } from '../service/product-builder.service';
import { Balancetype } from 'app/master-data/products/model/balancetype';
import { Excise_duty } from 'app/master-data/products/model/Excise_Duty';
import { InterestCalculationFrequency } from 'app/master-data/products/model/interest-calculation-frequency';
import { DaySize } from 'app/master-data/products/model/day-size';
import { Accured } from 'app/master-data/products/model/accured';
import { CapitalizationRule } from 'app/master-data/products/model/capitalizationRule';
import { BalancetypeService } from 'app/master-data/products/service/balancetype.service';
import { ExciseDutyService } from 'app/master-data/products/service/excise-duty.service';
import { InterestCalculationService } from 'app/master-data/products/service/interest-calculation.service';
import { AccuredService } from 'app/master-data/products/service/accured.service';
import { DaySizeService } from 'app/master-data/products/service/day-size.service';
import { TaxKeyService } from 'app/master-data/products/service/tax-key.service';
import { CapitalizationRuleService } from 'app/master-data/products/service/capitalization-rule.service';
import { Commission } from 'app/master-data/products/model/commission';
import { CommissionService } from 'app/master-data/products/service/commission.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { CountryInfo } from 'app/master-data/basic/basic-model/country-info';
import { SlabService } from 'app/master-data/products/service/slab.service';
import { CapitalizeFrequency } from 'app/master-data/products/model/capitalize-frequency';
import { CapitalizeFrequencyService } from 'app/master-data/products/service/capitalize-frequency.service';
import { ChargeService } from 'app/master-data/products/service/charge.service';
import { Charge } from 'app/master-data/products/model/charge';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { GroupService } from 'app/accounting/service/group.service';
import { Location } from "@angular/common";
import Swal from 'sweetalert2';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UserRoleAssignService } from 'app/user/user-management/user-service/user-role-assign.service';
@Component({
  selector: "app-product-builder",
  templateUrl: "./product-builder.component.html",
  styleUrls: ["./product-builder.component.scss"],
})
export class ProductBuilderComponent implements OnInit {
  list: any[] = [];
  product: any;
  ledgerList: any = [];
  ledgerListAcc: any = [];
  ledgerIntProList: any = [];
  accGroupList: any = [];
  incomeExpenseGroupList: any;
  categoryList: any[] = [];
  productDetails: any;
  balanceTypeList: Balancetype[] = [];
  balanceType: any;
  exciseDutyList: Excise_duty[] = [];
  exciseDutyDetails: any[] = [];
  sortExcDutyDetails: any[] = [];
  exciseDuty: any;
  capRuleList: CapitalizationRule[] = [];
  capRuleSelectList: CapitalizationRule[] = [];
  capRule: any;
  capFrequencyList: CapitalizeFrequency[] = [];
  capFrequency: any;
  intCalculationTypeList: any[] = [];
  intCalTypeDetails: any[] = [];
  storeIntCalTypeDetails: any[] = [];
  storeIntCalType: any;
  intCalculationFreqList: [InterestCalculationFrequency][] = [];
  accuredList: Accured[] = [];
  daySizeList: DaySize[] = [];
  daySize: any;
  currencyList: CountryInfo[] = [];
  taxKeyList: any[] = [];
  taxKey: any;
  taxKeyDetails: any[] = [];
  allSlab: any[] = [];
  instCalTypeSlab: any[] = [];
  isEdit: boolean = false;
  intCalTypeDiv: boolean = true;
  intCalTypeMultipleDiv: boolean = false;
  chargeList: Charge[] = [];
  charge: any;
  selectedChargeList: Charge[] = [];
  commissionList: Commission[] = [];
  commission: any;
  selectedCommissionList: Commission[] = [];
  GropupNatureList: any[] = [];
  showEntry = false;
  frmGroup: FormGroup;
  model: any = {};
  addedApprovers2: any[] = [];
  allbankusers2: [];
  selectedUserId2: string;
  selectedUsername2: string;
  closeResult: string;
  constructor(
    private _service: ProductBuilderService,
    private _userRoleService:UserRoleAssignService,
    private _CommissionService: CommissionService,
    private _blanaceTypeService: BalancetypeService,
    private _exciseDutyService: ExciseDutyService,
    private _capitaliseRuleService: CapitalizationRuleService,
    private _intCalculationService: InterestCalculationService,
    private _accuredService: AccuredService,
    private _capitaliseFrequencyService: CapitalizeFrequencyService,
    private _daySizeService: DaySizeService,
    private _taxKeyService: TaxKeyService,
    private _countryService: CountryInfoService,
    private _slabService: SlabService,
    private _chargeService: ChargeService,
    private _groupService: GroupService,
    private _ledgerService: LedgerService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private changeref: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    private location: Location,
    private router: Router
  ) {
    this.getProductBuilderInfoList();
  }

  ngOnInit() {
    this.populateForm();
    //this. loadLedgerList();
    // this.formModel=new window.bootstrap.model(
    //   document.getElementById("taxModal")
    // );
    this.activeRoute.paramMap.subscribe((params) => {
      const ProductBuilderId = +params.get("id");
      if (ProductBuilderId > 0) {
        this.getProductBuilderById(ProductBuilderId);
      }
    });

    this.getGroupByNatureId(2);
    this.getIncomeExpenseGroup();
    this.loadIntProLedgerListByGroupId(2);
    this.getExciseDuty(1);
    this.getDaySize(1);
    this.getTaxKey(1);
    this.getInterestCalculationType(1);
    this.getInterestCalculationFrequency(1);
    this.getCapitalizationRule(1);
    this.getCapitalizationFrequency(1);
    this.getBalanceType(1);
    //this.getAccured();
    this.GetAllCommissionList(1);
    this.GetAllChargeList(1);
    this.getCountryList();
    //this.getSlabList();
    this.getSlabListByType(1);
    ///this.getGroupList();
    this.getAllProductCategory();
    this.getAllUserRole();
  }

  // GetGroupNatureList() {
  //   this._groupService.GetGroupNatureList().subscribe(res => {
  //     this.GropupNatureList = res;
  //   })
  // }

  onGroupChange(gId: number, typeId: number) {
    if (typeId == 3) {
      if (gId != 0 || gId != null) {
        this.getLedgerListByGroupIdForProfit(gId);
      }
    } else if (typeId == 2) {
      if (gId != 0 || gId != null) {
        this.loadIntProLedgerListByGroupId(gId);
      }
    } else if (typeId == 1) {
      if (gId != 0 || gId != null) {
        this.loadLedgerListByGroupId(gId);
      }
    }
  }

  loadLedgerListByGroupId(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerListAcc = res;
    });
  }
  getLedgerListByGroupIdForProfit(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerList = res;
    });
  }
  loadIntProLedgerListByGroupId(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerIntProList = res;
    });
  }

  getGroupByNatureId(natureId: number) {
    this._groupService.ChildAccountGroupByNature(2).subscribe((res) => {
      this.accGroupList = res;
    });
  }

  getIncomeExpenseGroup() {
    this._groupService.GetIncomeExpenseAccountGroup().subscribe((res) => {
      this.incomeExpenseGroupList = res;
      this.changeref.detectChanges();
    });
  }
  trackByFn(index, item) {
    return item.id;
  }
  // getGroupList() {

  //   this._groupService.GetAccountGroupListByNature(this.frmGroup.value.productType).subscribe(res => {
  //     this.accGroupList = res;
  //   });
  // }

  getProductDetails(id: any) {
    this._service.getProductBuilderDetailsById(id).subscribe(
      (data: any) => {
        this.productDetails = data;
        this.frmGroup = this.formBuilder.group({
          id: [id],
          Code: [data?.productInfo?.code],
          Name: [data?.productInfo?.name],
          Version: [data?.productInfo?.version],
          productCategoryId:[data?.productInfo?.productCategoryId],
          Description:[data?.productInfo?.description],
          InterestRateMin:[data?.productInfo?.interestRateMin],
          InterestRateMax:[data?.productInfo?.interestRateMax],
          interestRate:[data?.productInfo?.interestRate],
          Currency: [data?.productInfo?.currencyId],
          DaySizeId: [data?.productInfo?.daySizeId],
          taxKeyId: [data?.productInfo?.taxKeyId],
          exciseDutyId: [data?.productInfo?.exciseDutyId],
          InterestCalculationTypeId: [data?.productInfo?.interestCalculationTypeId>0?data?.productInfo?.interestCalculationTypeId:0],
          InterestCalculationTypeSlabId: [""],
          interestCalculationTypeSelect: [data?.productInfo?.interestCalculationTypeId>0?0:1],
          InterestCalculationFrequencyId: [data?.productInfo?.interestCalculationFrequencyId],
          CapitalizationRuleId: [""],
          capitalizationFrequencyId: [data?.productInfo?.capitalizationFrequencyId],
          BalanceTypeId: [data?.productInfo?.balanceTypeId],
          commissionId: [""],
          chargeId: [""],
          ledgerRelationId: [data?.productInfo?.accountGroupId],
          ledgerId: [data?.productInfo?.ledgerId],
          plInterestGroupId:[data?.productInfo?.plInterestLedger?.groupId],
          plInterestLedgerId:[data?.productInfo?.plInterestLedgerId],
          interestProvisionGroupId: [data?.productInfo?.interestProvisionLedger?.groupId],
          interestProvisionLedgerId: [data?.productInfo?.interestProvisionLedgerId],
          status:[data?.productInfo?.status== true || data?.productInfo?.status== 'true'|| data?.productInfo?.status== 'null'|| data?.productInfo?.status== null ? 1 : 0]
        });
        this.taxKeyDetails = [];
        this.taxKey = null;
        this.taxKeyList?.forEach(e => {
          if(e!=null && e!='null' && this.frmGroup.value.taxKeyId == e.taxKey.id){
            this.taxKey=e.taxDetails[0].taxKey;
            this.taxKeyDetails = e.taxDetails;
          }
        });
        this.daySize = null;
        this.daySizeList?.forEach(e => {
          if (this.frmGroup.value.DaySizeId == e.id) {
            this.daySize = e;
          }
        });
        this.exciseDuty = null;
        this.sortExcDutyDetails = [];
        this.exciseDutyDetails?.forEach(e => {
          if(e!=null && e!='null' && this.frmGroup.value.exciseDutyId == e.exciseDutyId){
            this.sortExcDutyDetails.push(e);
            this.exciseDuty = e.exciseDuty;
          }
        });
        this.storeIntCalTypeDetails = [];
        this.storeIntCalType = null;
        this.intCalTypeDetails?.forEach(e => {
          if(e!=null && e!='null' && this.frmGroup.value.InterestCalculationTypeId ==
          e.interestCalculationTypeId){
            this.storeIntCalTypeDetails.push(e);
              this.storeIntCalType = e.interestCalculationType;
          }
        });
        if(data?.productInfo?.interestCalculationTypeId==null){
          this.intCalTypeDiv=false;
          this.intCalTypeMultipleDiv=true;
        }
        data?.productCommission?.forEach(e => {
          if(e!=null && e!='null'){
            this.selectedCommissionList.push(
              e.commission
            );
          }
        });
        data?.productCharge?.forEach(e => {
          if(e!=null && e!='null'){
            this.selectedChargeList.push(
              e.charge
            );
          }
        });
        data?.productRules?.forEach(e => {
          if(e!=null && e!='null'){
            this.capRuleSelectList.push(
              e.rule
            );
          }
        });
        data?.productInstCalTypes?.forEach(e => {
          if(e!=null && e!='null'){
            this.instCalTypeSlab.push(
              e.slab
            );
          }
        });
        data?.approver?.forEach(e => {
          if(e!=null && e!='null'){
            const newObject: any = {
              order: e.order,
              roleId: e.roleId,
              roleName: e.role?.name,
            };
            this.addedApprovers2.push(newObject);
          }
        });
        if(data?.productInfo?.accountGroupId>0){
          this.onGroupChange(data?.productInfo?.accountGroupId,1);
        }
        if(data?.productInfo?.plInterestLedgerId>0){
          this.onGroupChange(data?.productInfo?.plInterestLedger?.groupId,3);
        }
        if(data?.productInfo?.interestProvisionLedgerId>0){
          this.onGroupChange(data?.productInfo?.interestProvisionLedger?.groupId,2);
        }
      },
      (error) => {
        console.error("Error fetching product details:", error);
      }
    );
  }
  editId:number=0;
  isReadOnly:boolean=false;
  getProductBuilderById(id: number) {
    this.isEdit = true;
    this.isReadOnly = true;
    this.editId=id;
    this.getProductDetails(id);
  }
  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      Code: [""],
      Name: [""],
      Version: [""],
      Description: [""],
      InterestRateMin: [""],
      InterestRateMax: [""],
      interestRate: [0],
      Currency: [""],
      DaySizeId: [""],
      taxKeyId: [""],
      exciseDutyId: [""],
      InterestCalculationTypeId: [0],
      InterestCalculationTypeSlabId: [""],
      interestCalculationTypeSelect: [0],
      InterestCalculationFrequencyId: [""],
      CapitalizationRuleId: [""],
      capitalizationFrequencyId: [""],
      BalanceTypeId: [""],
      inputorId: [""],
      authorizerId: [""],
      AccuredId: [""],
      status: [""],
      chargeId: [""],
      commissionId: [""],
      ledgerRelationId: [""],
      plInterestGroupId: [""],
      interestProvisionGroupId: [""],
      ledgerId: [""],
      plInterestLedgerId: [""],
      ipLedgerId: [""],
      interestProvisionLedgerId: [""],
      productCategoryId: [""],
      productType: [1],
    });
  }

  getProductBuilderInfoList() {
    this._service.getList().subscribe((res) => {
      this.list = res;
    });
  }

  onsubmit() {
    if (!this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    else{
      this.model.id =this.editId;
    }
    this.model.Code = this.frmGroup.value.Code;
    this.model.Name = this.frmGroup.value.Name;
    this.model.Version = this.frmGroup.value.Version;
    this.model.Description = this.frmGroup.value.Description;
    this.model.InterestRateMin = this.frmGroup.value.InterestRateMin;
    this.model.InterestRateMax = this.frmGroup.value.InterestRateMax;
    this.model.Currency = this.frmGroup.value.Currency;
    this.model.DaySizeId = this.frmGroup.value.DaySizeId;
    this.model.taxKeyId = this.frmGroup.value.taxKeyId;
    this.model.exciseDutyId = this.frmGroup.value.exciseDutyId;
    this.model.InterestCalculationTypeId =
      this.frmGroup.value.InterestCalculationTypeId;
    this.model.InterestCalculationFrequencyId =
      this.frmGroup.value.InterestCalculationFrequencyId;
    this.model.capitalizationFrequencyId = this.frmGroup.value.capitalizationFrequencyId,
    (this.model.BalanceTypeId = this.frmGroup.value.BalanceTypeId),
      (this.model.productCategoryId = this.frmGroup.value.productCategoryId),
      //this.model.inputorId = this.frmGroup.value.inputorId,
      // this.model.authorizerId = this.frmGroup.value.authorizerId,
      //this.model.AccuredId = this.frmGroup.value.AccuredId,
    this.model.status = this.frmGroup.value.status === 1 ? true : false;
    this.model.InterestCalculationTypeSlabId = this.instCalTypeSlab;
    this.model.selectedCapitalizationRuleId = this.capRuleSelectList;
    this.model.selectedChargeId = this.selectedChargeList;
    this.model.selectedCommissionId = this.selectedCommissionList;
    this.model.interestRate = this.frmGroup.value.interestRate;
    this.model.ledgerRelationId = this.frmGroup.value.ledgerRelationId;
    this.model.ledgerId = this.frmGroup.value.ledgerId;
    this.model.plInterestLedgerId = this.frmGroup.value.plInterestLedgerId;
    this.model.interestProvisionLedgerId =
    this.frmGroup.value.interestProvisionLedgerId;
    this.model.approvers = this.addedApprovers2;
    Swal.fire({
      title: 'Are you sure?',
      text: this.isEdit?'You want to update!':'You want to save!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.isEdit?'Update!':'Save!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this._service
        .save(this.model)
        .pipe(
          catchError((error) => {  
            if(error.error.message.indexOf("UNIQUE KEY") !== -1){
              Swal.fire({
                title: 'Failed?',
                text: 'Version are already exist.',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                //cancelButtonText: 'No'
              })
            }            
            return throwError(error);
          })
        )
        .subscribe((res) => {
          Swal.fire({
            title: 'Success?',
            text: this.isEdit?'Update success!':'Save success!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((e) =>{
            this.getProductBuilderInfoList();
            this.router.navigate(["/product/product-builder-list"]);
          })
        })
      }
    });
  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }
  ChangeApprover2(target) {
    this.selectedUserId2 = target.value;
    this.selectedUsername2 = target.selectedOptions[0].innerText;
  }
  AddApprover2() {
    const newObject: any = {
      order: this.addedApprovers2.length + 1,
      roleId: this.selectedUserId2,
      roleName: this.selectedUsername2,
    };
    this.addedApprovers2.push(newObject);
  }
  edit(item: any) {
    this.isEdit = true;
    // this.showArea = 2;
    this.frmGroup.patchValue({
      id: item.id,
      Code: item.code,
      Name: item.name,
      Version: item.version,
      Description: item.description,
      InterestRateMin: item.interestRateMin,
      InterestRateMax: item.interestRateMax,
      InterestRate: item.interestRate,
      Currency: item.currency,
      DaySizeId: item.daySizeId,
      taxKeyId: item.taxKeyId,
      exciseDutyId: item.exciseDutyId,
      InterestCalculationTypeId: item.interestCalculationTypeId,
      InterestCalculationFrequencyId: item.interestCalculationFrequencyId,
      CapitalizationRuleId: item.iapitalizationRuleId,
      BalanceTypeId: item.balanceTypeId,
      productCategoryId: item.productCategoryId,
      inputorId: item.inputorId,
      authorizerId: item.authorizerId,
      AccuredId: item.accuredId,
      status: item.status,
    });
  }

  getAllUserRole(){
    this._userRoleService.GetAllRole().subscribe((res) => {
      this.allbankusers2 = res;
    });
  }
  // showArea = 1;
  // areaView(areaNo): void {
  //   this.showArea = areaNo;
  //   this.getExciseDuty();
  //   this.getDaySize();
  //   this.getTaxKey();
  //   this.getInterestCalculationType();
  //   this.getInterestCalculationFrequency();
  //   this.getCapitalizationRule();
  //   this.getCapitalizationFrequency();
  //   this.getBalanceType();
  //   //this.getAccured();
  //   this.GetAllCommissionList();
  //   this.GetAllChargeList();
  //   this.getCountryList();
  //   this.getSlabList();
  //   ///this.getGroupList();
  //   this. getAllProductCategory();
  // }

  //   delete(id : any){
  //      this._service.delete(id).subscribe(res =>{
  //       this.goProductBuilderList();
  //     })
  //   }
  //   goProductBuilderList() : void{

  //     this.router.navigate(['/loan/loan-type-list'])
  //  }
  //#region
  //#region

  getAllProductCategory() {
    this._blanaceTypeService.getAllCategorylist().subscribe((res) => {
      this.categoryList = res;
    });
  }
  getSlabListByType(id:number) {
    this._slabService.getlistByProBuilderType(id).subscribe((res) => {
      this.allSlab = res;
    });
  }
  // getSlabList() {
  //   this._slabService.getlist().subscribe((res) => {
  //     this.allSlab = res;
  //   });
  // }
  getBalanceType(id:number) {
    //this._blanaceTypeService.getlist().subscribe((res) => {
    this._blanaceTypeService.getlistByProBulderType(id).subscribe((res) => {
      this.balanceTypeList = res;
    });
  }
  getExciseDuty(id:number) {
    //this._exciseDutyService.getExciseDutyList().subscribe((res) => {
    this._exciseDutyService.getExciseDutyListByType(id).subscribe((res) => {
      for (const exDuty of res) {
        this.exciseDutyList.push(exDuty["exciseDuty"]);
        if (exDuty["exciseDutyDetails"].length > 0) {
          for (const dtt of exDuty["exciseDutyDetails"]) {
            this.exciseDutyDetails.push(dtt);
          }
        }
      }
    });
  }
  onExciseDutyChange() {
    this.exciseDuty = null;
    this.sortExcDutyDetails = [];
    for (const dt of this.exciseDutyDetails) {
      if (this.frmGroup.value.exciseDutyId == dt.exciseDutyId) {
        this.sortExcDutyDetails.push(dt);
        this.exciseDuty = dt.exciseDuty;
      }
    }
  }
  getCapitalizationRule(id:number) {
    this._capitaliseRuleService.getlistByProBuilderType(id).subscribe((res) => {
      this.capRuleList = res;
    });
  }  
  onCapitalizeRuleChange() {
    this.capRule = null;
    this.capRule= this.capRuleList.find(
        (obj) => obj.id == this.frmGroup.value.CapitalizationRuleId
      )
  }
  addMultipleCapRuleMulti() {
    const check = this.capRuleSelectList.find(
      (obj) => obj.id == this.frmGroup.value.capitalizeRuleSlabId
    );
    if (this.frmGroup.value.CapitalizationRuleId > 0 && check == undefined) {
      this.capRuleSelectList.push(
        this.capRuleList.find(
          (obj) => obj.id == this.frmGroup.value.CapitalizationRuleId
        )
      );
    }
  }
  getCapitalizationFrequency(id:number) {
    this._capitaliseFrequencyService
      .getCapFreqListByProBuildeType(id)
      .subscribe((res) => {
        this.capFrequencyList = res;
      });
  }  
  onCalTypeSelect() {
    this.frmGroup.value.interestCalculationTypeId=0;
    if (this.frmGroup.value.interestCalculationTypeSelect == "0") {
      this.intCalTypeDiv = true;
      this.intCalTypeMultipleDiv = false;
    } else {
      this.intCalTypeDiv = false;
      this.intCalTypeMultipleDiv = true;
    }
  }
  addSlabForIntCalTypeMulti() {
    const check = this.instCalTypeSlab.find(
      (obj) => obj.id == this.frmGroup.value.InterestCalculationTypeSlabId
    );
    if (
      this.frmGroup.value.InterestCalculationTypeSlabId > 0 &&
      check == undefined
    ) {
      this.instCalTypeSlab.push(
        this.allSlab.find(
          (obj) => obj.id == this.frmGroup.value.InterestCalculationTypeSlabId
        )
      );
    }
  }
  getInterestCalculationType(id:number) {
    this.intCalculationTypeList = [];
    this.intCalTypeDetails = [];
    this._intCalculationService
      //.GetAllInterestCalculationType()
      .GetAllInterestCalculationTypeByProductType(id)
      .subscribe((res) => {
        for (const type of res) {
          this.intCalculationTypeList.push(type["instCalType"]);
          if (type["instCalTypeDetail"].length > 0) {
            for (const dtt of type["instCalTypeDetail"]) {
              this.intCalTypeDetails.push(dtt);
            }
          }
        }
      });
  }
  onCalTypeChange() {
    this.storeIntCalTypeDetails = [];
    this.storeIntCalType = null;
    for (const ty of this.intCalTypeDetails) {
      if (
        this.frmGroup.value.InterestCalculationTypeId ==
        ty.interestCalculationTypeId
      ) {
        this.storeIntCalTypeDetails.push(ty);
        this.storeIntCalType = ty.interestCalculationType;
      }
    }
  }
  onCalTypeSlabChange() {
    this.storeIntCalType = null;
    this.storeIntCalType= this.allSlab.find(
        (obj) => obj.id == this.frmGroup.value.InterestCalculationTypeSlabId
      )
  }
  getAccured() {
    this._accuredService.getAccuredList().subscribe((res) => {
      this.accuredList = res;
    });
  }
  GetAllCommissionList(id:number) {
    //this._CommissionService.GetAllCommissionList().subscribe((res) => {
    this._CommissionService.GetAllCommissionListByProBuilderType(id).subscribe((res) => {
      this.commissionList = res;
    });
  }
  onCommissionChange() {
    this.commission = null;
    this.commission= this.commissionList.find(
        (obj) => obj.id == this.frmGroup.value.commissionId
      )
  }
  addMultipleCommission() {
    const check = this.selectedCommissionList.find(
      (obj) => obj.id == this.frmGroup.value.commissionId
    );
    if (this.frmGroup.value.commissionId > 0 && check == undefined) {
      this.selectedCommissionList.push(
        this.commissionList.find(
          (obj) => obj.id == this.frmGroup.value.commissionId
        )
      );
    }
  }
  GetAllChargeList(id:number) {
    //this._chargeService.GetAllChargeList().subscribe((res) => {
    this._chargeService.GetAllChargeListByProBuilderType(id).subscribe((res) => {
      this.chargeList = res;
    });
  }
  onChargeChange() {
    this.charge = null;
    this.charge= this.chargeList.find(
        (obj) => obj.id == this.frmGroup.value.chargeId
      )
  }
  addMultipleCharge() {
    const check = this.selectedChargeList.find(
      (obj) => obj.id == this.frmGroup.value.chargeId
    );
    if (this.frmGroup.value.chargeId > 0 && check == undefined) {
      this.selectedChargeList.push(
        this.chargeList.find((obj) => obj.id == this.frmGroup.value.chargeId)
      );
    }
  }
  getInterestCalculationFrequency(id:number) {
    //this._intCalculationService.getFrequencyList().subscribe((res) => {
    this._intCalculationService.getFrequencyListByProductType(id).subscribe((res) => {
      this.intCalculationFreqList = res;
    });
  }
  getDaySize(id:number) {
    //this._daySizeService.getDaySizeList().subscribe((res) => {
    this._daySizeService.getDaySizeListByProductId(id).subscribe((res) => {
      this.daySizeList = res;
    });
  }
  getCountryList() {
    this._countryService.getlist().subscribe((res) => {
      this.currencyList = res;
    });
  }
  getTaxKey(id:number) {
    //this._taxKeyService.getList().subscribe((res) => {
    this._taxKeyService.getListByType(id).subscribe((res) => {
      this.taxKeyList = res;
    });
  }
  onTaxChange() {
    this.taxKeyDetails = [];
    this.taxKey = null;
    for (const dt of this.taxKeyList) {
      if (this.frmGroup.value.taxKeyId == dt.taxKey.id) {
        this.taxKeyDetails = dt.taxDetails;
        this.taxKey = dt.taxDetails[0].taxKey;
      }
    }
  }
  onDaySizeChange() {
    this.daySize = null;
    for (const dt of this.daySizeList) {
      if (this.frmGroup.value.DaySizeId == dt.id) {
        console.log(dt)
        this.daySize = dt;
      }
    }
  }
  //#endregion
  //#region Detail

  openModal(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  openBuilderDetailsModal(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }
  //#endregion
  goBack() {
    this.location.back();
  }
}