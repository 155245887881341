<div class="card">
    <div class="card-header">
        <h4>Transaction Log</h4>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>TRAN ID</th>
                        <th>TRAN DATE</th>
                        <th>STATUS</th>
                        <th>AMOUNT</th>
                        <th>CARD TYPE</th>
                        <th>CARD NO</th>
                        <th>ACCOUNT NO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of transactionLogs">
                        <td>{{ log.tran_id }}</td>
                        <td>{{ log.tran_date | date }}</td>
                        <td>{{ log.status }}</td>
                        <td style="text-align: right;">{{ log.amount }}</td>
                        <td>{{ log.card_type }}</td>
                        <td>{{ log.card_no }}</td>
                        <td>{{ log.value_a.split(',')[0] }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>