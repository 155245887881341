<div class="card" style="width: 100%;">
    <div class="card-body">
      <div class="">
        <h5 class="card-title" style="color: black">Home Loan Details</h5>
        <hr>
        <form [formGroup]="frmGroupHomeLoan">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Property Type<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="homeLoanPropertyId" id="homeLoanPropertyId">
                    <option value="">Select</option>
                    <option *ngFor="let item of propertytypeList" [value]="item.id">{{ item.name }}</option>
                  </select>
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Property Address<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="propertyAddress"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Developer Name<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="developerName" placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Developers Category<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="developersCategory"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Building Project Name<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="buildingProjectName"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Total Land Area (Katha)<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="totalLandArea" placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Proportionate Land Area(katha)<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="proportionateLandArea"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">property Agreement Amount<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="propertyOrDeedAgreementVal"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">No Of Car Parking<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="noOfCarParking"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Car Parking Price<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="carParkingPrice"
                    placeholder="Type here....">
                </div>
              </div>
            </div>


          </div>

          <button [disabled]="!frmGroupHomeLoan.valid" data-toggle="tooltip" title="Save" type="submit"
            (click)="onHomeLoanSubmit()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
            <span>{{ isHomeLoanInfoEdit ? 'Update' : 'Save' }}</span>
          </button>

          <!-- <button type="button" data-toggle="tooltip" title="Refresh"  (click)="HomeLoanreset()"  class="btn btn-secondary" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button> -->

        </form>
      </div>
    </div>
  </div>