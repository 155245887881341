
    <div class="card" style="width: 100%;">
      <div class="card-body">
        <div class="">
        <h5 class="card-title" [ngStyle]="{'color': Reflist.length === 0 ? 'red' : 'black'}">
          {{ Reflist.length === 0 ? 'Reference (Incomplete)' : 'Reference' }}
        </h5>
          <hr>
          <form [formGroup]="frmGroupReference">
            <div asp-validation-summary="All" class="text-danger"></div>
            <div class="row mt-2">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="name" class="col-sm-4 col-form-label">Name</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="name" placeholder="Type here...">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="relationship" class="col-sm-4 col-form-label">Relationship</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="relationship" placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="residenceAddress" class="col-sm-4 col-form-label">Residence Address </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="residenceAddress"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="residentialStatus" class="col-sm-4 col-form-label">Residential Status </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="residentialStatus"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="companyName" class="col-sm-4 col-form-label">Company Name</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="companyName" placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="refDesignation" class="col-sm-4 col-form-label">Ref Designation</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="refDesignation"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="companyDesignation" class="col-sm-4 col-form-label">Company Designation</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="companyDesignation"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="companyAddress" class="col-sm-4 col-form-label">Company Address</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="companyAddress"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="companyPhoneResidence" class="col-sm-4 col-form-label">Company Phone Residence</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="companyPhoneResidence"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="companyPhoneOffice" class="col-sm-4 col-form-label">Company Phone Office</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="companyPhoneOffice"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="companyMobile" class="col-sm-4 col-form-label">Company Mobile</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="companyMobile" placeholder="Type here....">
                  </div>
                </div>
              </div>

            </div>



            <div class="row" style="float: right;">
              <button (click)="onReference()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
                <span>Save</span></button>  
              <!-- <button [disabled]="!frmGroupReference.valid" data-toggle="tooltip" title="SaveReference" type="submit"
                (click)="onReference()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
                <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                </button> -->
                <button type="button"  title="Refresh"class="btn btn-secondary" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
            </div>


          </form>
        </div>
      </div>
    </div>
    <div class="card" style="width: 100%;">
      <div class="card-body">
        <div class="container ">
          <h5 class="card-title" style="color: black">Reference List</h5>
          <hr>
          <table id="example" class="table table-striped table-bordered table-responsive-lg" style="width:100%">
            <thead>
              <tr>
                <th>SL No.</th>
                <th>Reference Info</th>
                <th>Professional Info</th>
                <th style="width: 20%;">Action</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr *ngFor="let item of Reflist; let i = index;"> -->
                <tr *ngFor="let item of Reflist; let i = index;">
                <td>{{i + 1}}</td>
                <td>Name: {{item.name}}<br>
                  Relationship: {{item.relationship}}<br>
                  Address: {{item.residenceAddress}}<br>
                  Designation: {{item.companyDesignation}}
                </td>

                <td>Company Name: {{item.companyName}}<br>
                  Address: {{item.companyAddress}}<br>
                  Phone Residence: {{item.companyPhoneResidence}}<br>
                  Phone Office: {{item.companyPhoneOffice}}<br>
                  Mobile:{{item.companyMobile}}

                </td>

                <td>
                  <button class="btn-sm btn-primary" (click)="edit(item)"><i class="fa fa-edit"></i></button>
                  <button [disabled]="isEdit" class="btn-sm btn-danger" style="margin-left: 5px;"
                    (click)="delete(item.id)"><i class="fa fa-trash-o"></i></button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
