import { Component, OnInit } from '@angular/core';
import { CapitalizationRuleService } from '../service/capitalization-rule.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';

@Component({
  selector: 'app-capitalization-rule',
  templateUrl: './capitalization-rule.component.html',
  styleUrls: ['./capitalization-rule.component.scss']
})
export class CapitalizationRuleComponent implements OnInit {
  capitalizationList: any[] = [];
  isEdit: boolean = false;
  FromGrp: FormGroup;
  capitalizationModel: any = {};
  currentPage = 1;
  itemsPerPage = 5;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm:string='';
  productTypeId: number=0;
  productBuilderTypes = ProductBuilderTypeEnum;
  constructor(private _service: CapitalizationRuleService, private _productService: ProductBuilderService, private formBuilder: FormBuilder, private shared: SharedService,
    private router: Router) { }


  ngOnInit(): void {
    this.capitalizationForm();
    this.getcapitalizationList();
    this.showSaveForm = 'saveList';
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getFiscalYearList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getFiscalYearList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getFiscalYearList();
  }

  capitalizationForm() {
    this.FromGrp = this.formBuilder.group({
      id: [0],
      code: ['', Validators.required],
      details: [''],
      productTypeId:[0]
    })
  }

  getCodeByType(type: number) {
    this._productService.getProductMasterDateCodeByType(type).subscribe(res => {
      this.FromGrp.patchValue({
        code: res['code']
      })
    })
  }

  refresh() {
    this.isEdit = false;
    this.capitalizationForm();
    this.capitalizationModel = {};
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.getCodeByType(6);
    this.refresh();
  }

  getcapitalizationList() {
    this._service.getlist().subscribe(res => {
      this.capitalizationList = res;
    })
  }
  getFiscalYearList() {
    this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.capitalizationList = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  onSubmit() {
    if (this.isEdit) {
      this.capitalizationModel.Id = this.FromGrp.value.id;
    }
    this.capitalizationModel.code = this.FromGrp.value.code;
    this.capitalizationModel.details = this.FromGrp.value.details;
    this.capitalizationModel.productTypeId = this.FromGrp.value.productTypeId;

    this._service.SaveCapitalization(this.capitalizationModel).subscribe(res => {
      //alert('Save successfully');
      this.shared.ShowAlert("Success", "Save Successfully", "success");
      this.getcapitalizationList();
      this.isEdit = false;
      this.showSaveForm = 'saveList';
    })
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.FromGrp.patchValue({
      id: item.id,
      code: item.code,
      details: item.details,
      productTypeId:item.productBuilderType
    })
  }

  delete(id: any) {
    this._service.DeleteCapitalization(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getcapitalizationList();
      this.showSaveForm = 'saveList';
    })
  }
}
