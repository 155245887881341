import { Injectable } from '@angular/core';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class PdfService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  OpenVoucherPdf(voucherId: number) {
    return this.http.get(this.baseUrl + "/VoucherPdf?voucherId=" + voucherId, {
      observe: "response",
      responseType: "blob",
    });
  }

  CashTransactionPdf(accountNo: string, startDate: Date, EndDate: Date) {
    return this.http.get(
      this.baseUrl +
        "/AccountStatementPDF?accountNo=" +
        accountNo +
        "&startDate=" +
        startDate +
        "&EndDate=" +
        EndDate,
      { observe: "response", responseType: "blob" }
    );
  }

  OpenLedgerBookPdf(fromDate: string, toDate: string) {
    return this.http.get(
      this.baseUrl + "/LedgerBookPdf?fDate=" + fromDate + "&tDate=" + toDate,
      { observe: "response", responseType: "blob" }
    );
  }

  OpenKycPdf(id: number) {
    //alert(id)
    return this.http.get(this.baseUrl + "/KycPdf?id=" + id, {
      observe: "response",
      responseType: "blob",
    });
  }

  //

  OpenSchedulePdf(leadId: number) {
    return this.http.get(this.baseUrl + "/LoanSchedulePdf?leadId=" + leadId, {
      observe: "response",
      responseType: "blob",
    });
  }
  OpenLoanApplicationFullView(Id: number) {
    return this.http.get(this.baseUrl + "/LoanApplicationPdf?id=" + Id, {
      observe: "response",
      responseType: "blob",
    });
  }
  OpenCollectionPdf(leadId: number) {
    return this.http.get(this.baseUrl + "/LoanCollectionPdf?leadId=" + leadId, {
      observe: "response",
      responseType: "blob",
    });
  }

  DPSOpenSchedulePdf(accountId: number) {
    return this.http.get(
      this.baseUrl + "/DPSSchedulePdf?accountId=" + accountId,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }
  O;

  //

  TrialBalanceReport(
    fromDate: Date,
    toDate: Date,
    sbuId: number,
    projectId: number,
    reportBy: number
  ) {
    return this.http.get(
      this.baseUrl +
        "/TrialBalanceReportDatapdf?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&sbuId=" +
        sbuId +
        "&projectId=" +
        projectId +
        "&reportBy=" +
        reportBy,
      { observe: "response", responseType: "blob" }
    );
  }
  CashBookReport(
    fromDate: Date,
    toDate: Date,
    sbuId: number,
    projectId: number,
    reportBy: number
  ) {
    return this.http.get(
      this.baseUrl +
        "/CashBookReportDataViewpdf?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&sbuId=" +
        sbuId +
        "&projectId=" +
        projectId +
        "&reportBy=" +
        reportBy,
      { observe: "response", responseType: "blob" }
    );
  }
  BankBookReport(
    fromDate: Date,
    toDate: Date,
    sbuId: number,
    projectId: number,
    reportBy: number
  ) {
    return this.http.get(
      this.baseUrl +
        "/BankBookReportDataViewpdf?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&sbuId=" +
        sbuId +
        "&projectId=" +
        projectId +
        "&reportBy=" +
        reportBy,
      { observe: "response", responseType: "blob" }
    );
  }
  GroupSummaryReport(
    fromDate: Date,
    toDate: Date,
    sbuId: number,
    projectId: number,
    reportBy: number,
    mgId: number,
    gId: number,
    lId: number,
    slId: number
  ) {
    return this.http.get(
      this.baseUrl +
        "/GroupSummaryReportDatapdf?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&sbuId=" +
        sbuId +
        "&projectId=" +
        projectId +
        "&reportBy=" +
        reportBy +
        "&mgId=" +
        mgId +
        "&gId=" +
        gId +
        "&lid=" +
        lId +
        "&slId=" +
        slId,
      { observe: "response", responseType: "blob" }
    );
  }

  ProfitLossReport(
    fromDate: Date,
    toDate: Date,
    sbuId: number,
    projectId: number,
    reportBy: number
  ) {
    return this.http.get(
      this.baseUrl +
        "/PLReportDatapdf?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&sbuId=" +
        sbuId +
        "&projectId=" +
        projectId +
        "&reportBy=" +
        reportBy,
      { observe: "response", responseType: "blob" }
    );
  }

  BalanceSheetReport(
    fromDate: Date,
    toDate: Date,
    sbuId: number,
    projectId: number,
    reportBy: number
  ) {
    return this.http.get(
      this.baseUrl +
        "/BalanceSheetReportDatapdf?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&sbuId=" +
        sbuId +
        "&projectId=" +
        projectId +
        "&reportBy=" +
        reportBy,
      { observe: "response", responseType: "blob" }
    );
  }

  LoanOutstandingReport(leadId: number, asOnDate: Date, reportBy: number) {
    return this.http.get(
      this.baseUrl +
        "/LoanOutstandingReportDataViewpdf?leadId=" +
        leadId +
        "&asOnDate=" +
        asOnDate +
        "&reportBy=" +
        reportBy,
      { observe: "response", responseType: "blob" }
    );
  }
  LoanOutstandingExcelReport(leadId: number, asOnDate: Date, reportBy: number) {
    return this.http.get(
      this.baseUrl +
        "/LoanOutstandingReportExcel?leadId=" +
        leadId +
        "&asOnDate=" +
        asOnDate +
        "&reportBy=" +
        reportBy,
      { observe: "response", responseType: "blob" }
    );
  }
  
  LoanOutstandingData() {
    return this.http.get(
      this.baseUrl +"/LoanOutstandingReportData"
    );
  }

  GetDPSPayableList() {
    return this.http.get(
      this.baseUrl +"/GetDPSPayableList"
    );
  }

  LoanOutstandingDataByDateRange(startDate:any, EndDate:any) {
    return this.http.get(
      this.baseUrl +"/LoanOutstandingDataByDateRange?startDate=" +
      startDate +
      "&EndDate=" +
      EndDate
    );
  }

  LoanOverDueReport(asOnDate: Date, reportBy: number) {
    return this.http.get(
      this.baseUrl +
        "/LoanOverdueReportDataViewpdf?&asOnDate=" +
        asOnDate +
        "&reportBy=" +
        reportBy,
      { observe: "response", responseType: "blob" }
    );
  }

  LoanOverDueDashboard() {
    return this.http.get(
      this.baseUrl +
        "/LoanOverdueReportData"
    );
  }

  LedgerBookReportDataViewpdf(
    ledgerId: any,
    subledgerId: any,
    fromDate: Date,
    toDate: Date,
    sbuId: number,
    projectId: number
  ) {
    return this.http.get(
      this.baseUrl +
        "/LedgerBookReportDataViewpdf?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&sbuId=" +
        sbuId +
        "&projectId=" +
        projectId +
        "&ledgerId=" +
        ledgerId +
        "&subledgerId=" +
        subledgerId,
      { observe: "response", responseType: "blob" }
    );
  }

  SubLedgerBookReportDataViewpdf(
    ledgerId: any,
    subledgerId: any,
    fromDate: Date,
    toDate: Date,
    sbuId: number,
    projectId: number
  ) {
    return this.http.get(
      this.baseUrl +
        "/SubLedgerBookReportDataViewpdf?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&sbuId=" +
        sbuId +
        "&projectId=" +
        projectId +
        "&ledgerId=" +
        ledgerId +
        "&subledgerId=" +
        subledgerId,
      { observe: "response", responseType: "blob" }
    );
  }

  chartofAccountspdf(sbuId: number, projectId: number) {
    return this.http.get(
      this.baseUrl +
        "/chartofAccountspdf?sbuId=" +
        sbuId +
        "&projectId=" +
        projectId,
      { observe: "response", responseType: "blob" }
    );
  }

  CustomerstatementReportViewpdf(
    customerId: number,
    loanAccountId: number,
    fromDate: Date,
    toDate: Date,
    sbuId: number,
    projectId: number
  ) {
    return this.http.get(
      this.baseUrl +
        "/CustomerstatementReportViewpdf?customerId=" +
        customerId +
        "&loanAccountId=" +
        loanAccountId +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&sbuId=" +
        sbuId +
        "&projectId=" +
        projectId,
      { observe: "response", responseType: "blob" }
    );
  }

  AccountIncomeExpenseReport(
    fromDate: Date,
    toDate: Date,
    sbuId: number,
    projectId: number,
    reportBy: number,
    typeId: number
  ) {
    return this.http.get(
      this.baseUrl +
        "/AccountIncomeExpenseReportPdf?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&sbuId=" +
        sbuId +
        "&projectId=" +
        projectId +
        "&reportBy=" +
        reportBy +
        "&typeId=" +
        typeId,
      { observe: "response", responseType: "blob" }
    );
  }

  /////WhiteList

  DownloadWhiteListCustomerFormat() {
    return this.http.get(
      this.baseUrl + "/api/CustomerAccount/DownloadWhiteListCustomerFormat/",
      { observe: "response", responseType: "blob" }
    );
  }

  /////--------------No Use--------------////
  async generatePDF(htmlElement: HTMLElement, fileName: string) {
    const options = {
      filename: fileName,
      image: { type: "png", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(htmlElement).set(options).save();
  }

  generateDynamicPDF(fileName: string, dynamicDataContainer: any) {
    if (dynamicDataContainer) {
      const options = {
        filename: fileName,
        image: { type: "png", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      // Introduce a delay to ensure dynamic data is rendered
      setTimeout(() => {
        html2canvas(dynamicDataContainer.nativeElement).then((canvas) => {
          const dynamicDataImage = canvas.toDataURL("image/jpeg", 0.98);
          const pdf = new jsPDF("p", "mm", "a4");
          pdf.addImage(dynamicDataImage, "JPEG", 0, 0, 210, 297); // Adjust width and height as needed
          pdf.save(options.filename);
        });
      }, 1000); // Adjust the delay (milliseconds) as needed
    }
  }
  /////--------------No Use--------------////

  //   BalanceSheetReport(fromDate: Date , toDate: Date, sbuId:number,projectId:number,reportBy: number)
  // {
  //   return this.http.get(this.baseUrl + '/BalanceSheetReportDatapdf?fromDate=' + fromDate + '&toDate=' + toDate + '&sbuId=' + sbuId + '&projectId=' + projectId   + '&reportBy=' + reportBy, {observe: 'response', responseType: 'blob'});
  // }

  customerDetailsReport(customerId: any) {
    return this.http.get(
      this.baseUrl + "/CustomerDetailsByCustomerIdPdf?customerId=" + customerId,
      { observe: "response", responseType: "blob" }
    );
  }

  DayBookReport(fromDate: Date, toDate: Date, voucherTypeId: number) {
    return this.http.get(
      this.baseUrl +
        "/DayBookReportDataViewpdf?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&voucherTypeId=" +
        voucherTypeId,
      { observe: "response", responseType: "blob" }
    );
  }

  LoanDisbursementReport(fromDate: Date, toDate: Date) {
    return this.http.get(
      this.baseUrl +
        "/LoanDisbursementReportPdf?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate,
      { observe: "response", responseType: "blob" }
    );
  }
  LoanDisbursemetExcelReport(fromDate: Date, toDate: Date) {
    return this.http.get(
      this.baseUrl +
        "/LoanDisbursementReportExcel?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate,
      { observe: "response", responseType: "blob" }
    );
  }

  LoanCollectionPdfReport(fromDate:Date,toDate:Date){
    return this.http.get(
      this.baseUrl+"/LoanCollectionReportPdf?fromDate="+fromDate+"&toDate="+toDate,{observe:"response",responseType:"blob"}
    );
  }

  LoanCollectionExcelReport(fromDate:Date,toDate:Date){
    return this.http.get(
      this.baseUrl+"/LoanCollectionReportExcel?fromDate="+fromDate+"&toDate="+toDate,{observe:"response",responseType:"blob"}
    );
  }

  LoanCollectionReportData(fromDate:Date,toDate:Date):Observable<any> {
    return this.http.get(
      this.baseUrl+"/LoanCollectionReportData?fromDate="+fromDate+"&toDate="+toDate,{observe:"response",responseType:"json"}
    )
  }
}
