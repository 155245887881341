import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { Location } from "@angular/common";
import { SharedService } from 'app/services/shared.service';
import { CustomerService } from 'app/customer/service/customer.service';
import Swal from 'sweetalert2';
@Component({
  selector: "app-loan-list",
  templateUrl: "./loan-list.component.html",
  styleUrls: ["./loan-list.component.scss"],
})
export class LoanListComponent implements OnInit {
  LeadList: any = [];
  constructor(
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private shared: SharedService,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.getCustomerLeadList();
  }

  getCustomerLeadList() {
    this._serviceDashboard.getLoanListForTS().subscribe((res: any) => {
      this.LeadList = res;
      this.changeDetectorRef.detectChanges;
    });
  }

   DeleteCustomerById(Id: any) {
  
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete all information!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((res) => {
          if (res.isConfirmed) {
          this.customerService.DeleteCustomerAllInfoByCustId(Id).subscribe(
            (res) => {
              this.shared.ShowAlert("Success", "Data deleted successfully", "success");

              this.getCustomerLeadList();
            },
            (error) => {
              if (error.status === 500) {
                this.shared.ShowAlert("Error", "Something went wrong.", "error");
              }
            }
          )
          } 
          else if (res.isDismissed) {
            
          }
});
 }
  
  goBack() {
    this.location.back();
  }
}
