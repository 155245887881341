import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductRoutingModule } from './product-routing.module';
import { ProductBuilderComponent } from './product-builder/product-builder.component';
import { ReactiveFormsModule } from '@angular/forms'; 
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ProductBuilderEditComponent } from './product-builder/product-builder-edit/product-builder-edit.component';
import { LoanProductListComponent } from './loan-product/loan-product-list/loan-product-list.component';
import { LoanProductEntryComponent } from './loan-product/loan-product-entry/loan-product-entry.component';
import { LoanProductDetailsComponent } from './loan-product/loan-product-details/loan-product-details.component';
import { ProductBuilderListComponent } from './product-builder/product-builder-list/product-builder-list.component';
import { DecimalOnlyDirective } from './loan-product/decimal-only.directive';
import { PendingProductComponent } from './loan-product/pending-product/pending-product.component';
import { PendingRetailProductComponent } from './product-builder/pending-retail-product/pending-retail-product.component';

@NgModule({
  declarations: [
    ProductBuilderComponent,
    ProductBuilderEditComponent,
    LoanProductListComponent,
    LoanProductEntryComponent,
    LoanProductDetailsComponent,
    ProductBuilderListComponent,
    DecimalOnlyDirective,
    PendingProductComponent,
    PendingRetailProductComponent
  ],
  imports: [
    CommonModule,
    ProductRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ProductModule { }
