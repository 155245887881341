<div class="card" style="width: 100%;">
    <div class="card-body">
      <div class="">
        <h5 class="card-title" style="color: black">Vehicle Loan Details</h5>
        <hr>
        <form [formGroup]="frmGroupVehicleLoan">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Brand<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="brand" placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Vehicles Model<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="vehiclesModel" placeholder="Type here....">
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Manufacturing Year<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="manufacturingYear"
                    placeholder="Type here....">
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Engine No<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="engineNo" placeholder="Type here....">
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Chassis No<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="chassisNo" placeholder="Type here....">
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Vendor<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="vendor" placeholder="Type here....">
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Contact Person<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="contactPerson" placeholder="Type here....">
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">cc<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="cc" placeholder="Type here....">
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Address<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="address" placeholder="Type here....">
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Telephone<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="telephone" placeholder="Type here....">
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="roleName" class="col-sm-4 col-form-label">Mobile<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="mobile" placeholder="Type here....">
                </div>
              </div>

            </div>



          </div>
          <button [disabled]="!frmGroupVehicleLoan.valid" data-toggle="tooltip" title="Save" type="submit"
            (click)="onVehicleLoanSubmit()" value="Submit" class="btn btn-success"
            style="float:right; margin-top:5px;">
            <span>{{ isVehicleLoanInfoEdit ? 'Update' : 'Save' }}</span>
          </button>
          <!-- <button type="button" data-toggle="tooltip" title="Refresh"  (click)="VehicleLoanreset()"  class="btn btn-secondary" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button> -->

        </form>
      </div>
    </div>
  </div>