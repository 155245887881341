import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { LoanLimitService } from '../service/loan-limit.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loan-limit-list',
  templateUrl: './loan-limit-list.component.html',
  styleUrls: ['./loan-limit-list.component.scss']
})
export class LoanLimitListComponent implements OnInit {
  loanLimitList: any[] = [];
  loanLimitDetails: any;
  closeResult: string;
  isEdit: boolean = false;

  constructor(private _loanLimitService: LoanLimitService,
    private _loanProductService:LoanProductService,
    private modalService: NgbModal,
    private router: Router,

    ) { }

  ngOnInit(): void {
    this.getLoanLimitList();
  }

  getLoanLimitList() {
    this._loanLimitService.getLoanLimitList().subscribe(res => {
      this.loanLimitList = res;
    })
  }

  getLoanLimitDetails(id:any){
    this._loanLimitService.getLoanLimitDetailsById(id).subscribe(
      (data : any) => {
        this.loanLimitDetails = data;
      }
    );
  }

  openLoanLimitDetailsModal(content) {
    let options: any = {
      size: 'xl',
      centered: false
    }
      this.modalService.open(content, options).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed`;
      });
  }

  edit(id:number){
    this.router.navigate(['/products/loan-limit-entry/' + id]);
    this.isEdit = true;
  }
}
