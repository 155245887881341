<div class="card" >
    
        <!-- <div class="card" style="background-color:azure;">
            <h3 class="card-title" style="color: black;">Transaction Limit</h3>
        </div> -->

<div class="card-header" >
    <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">Transaction Limit</h5>
   
</div>
<br>

<form [formGroup]="frmGroup">
    <div class="col-md-12">
        <h5 style="font-size:18px;">Deposit Declearation</h5>
        <hr>
        <div class="form-group row">
           
            <div class="col-sm-12">
                <table class="table table-bordered">
                    <thead>
                        <tr style="background-color: rgb(241, 237, 237);color:#888888">
                            <td class="pl-2 p-2 m-2" >Method</td>
                            <td class="pl-1 p-0 m-0">Monthly potential total deposit number</td>
                            <td class="pl-1 p-0 m-0">Monthly potential total deposit amount</td>
                            <td class="pl-1 p-0 m-0">Single maximum deposit amount</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of transactionList ;let i = index" >
                           <ng-container *ngIf="item.type === 1">
                            <td class="pl-1 p-0 m-0">
                                {{item.methodType?.methodName}}
                                <input type="hidden" class="form-control" value="{{item.methodType?.id}}">
                            </td>
                            <td class="p-1 m-0">
                                <input type="number" class="form-control"  style="text-align:right;"
                                    (keyup)="updateDeclearationList(item.methodType?.id,1,$event)" value="{{item.monthlyTRCount}}" placeholder="Number ....">
                            </td>
                            <td class="p-1 m-0">
                                <input type="number" class="form-control"  style="text-align:right;"
                                    (keyup)="updateDeclearationList(item.methodType?.id,2,$event)" value="{{item.monthlyTRTotalAmount}}" placeholder="Amount ....">
                            </td>
                            <td class="p-1 m-0">
                                <input type="number" class="form-control"  style="text-align:right;"
                                    (keyup)="updateDeclearationList(item.methodType?.id,3,$event)" value="{{item.monthlyTRMaxAmount}}" placeholder="Maximum ....">
                            </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <h5 style="font-size:18px;">Withdrow Declearation</h5>
        <hr>
        <div class="form-group row">
         
            <div class="col-sm-12">
                <table class="table table-bordered">
                    <thead>
                      <tr style="background-color: rgb(241, 237, 237);color:#888888">
                            <td class="pl-2 p-2 m-2">Method</td>
                            <td class="pl-1 p-0 m-0 ">Monthly potential total deposit number</td>
                            <td class="pl-1 p-0 m-0 ">Monthly potential total deposit amount</td>
                            <td class="pl-1 p-0 m-0 ">Single maximum deposit amount</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of transactionList ;let i = index">
                            <ng-container *ngIf="item.type === 2">
                            <td class="pl-1 p-0 m-0">
                                {{item.methodType?.methodName}}
                                <input type="hidden" class="form-control" value="{{item.methodType?.id}}">
                            </td>
                            <td class="p-1 m-0">
                                <input type="number" (keyup)="updateDeclearationList1(item.methodType?.id,1,$event)" style="text-align:right;"
                                 value="{{item.monthlyTRCount}}"   class="form-control" placeholder="Number ....">
                            </td>
                            <td class="p-1 m-0">
                                <input type="number" (keyup)="updateDeclearationList1(item.methodType?.id,2,$event)"  style="text-align:right;"
                                   value="{{item.monthlyTRTotalAmount}}" class="form-control" placeholder="Amount ....">
                            </td>
                            <td class="p-1 m-0">
                                <input type="number" (keyup)="updateDeclearationList1(item.methodType?.id,3,$event)"  style="text-align:right;"
                                  value="{{item.monthlyTRMaxAmount}}"  class="form-control" placeholder="Maximum ....">
                            </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    
    <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit" (click)="onsubmit()"
        value="Submit" class="btn btn-primary" style="float:right; margin-top:5px;">
        <span>{{ isEdit ? 'Update' : 'Save' }}</span>
    </button>
    <button type="button" data-toggle="tooltip" title="Refresh" (click)="refresh()" class="btn btn-secondary"
        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;margin-bottom: 10%!important;">Refresh</button>
  </form>
</div>
