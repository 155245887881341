import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Slab } from '../model/slab';
import { SlabService } from 'app/master-data/products/service/slab.service';
import { SharedService } from 'app/services/shared.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';



@Component({
  selector: 'app-slab',
  templateUrl: './slab.component.html',
  styleUrls: ['./slab.component.scss']
})
export class SlabComponent implements OnInit {

  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: Slab[] = [];
  model = new Slab();
  productBuilderTypes = ProductBuilderTypeEnum;
  currentPage = 1;
  itemsPerPage = 5;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = '';

  constructor(
    private _service: SlabService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,
  ) {
    this.frmGroup = new FormGroup({
      id: new FormControl(),
      min: new FormControl(),
      max: new FormControl(),
      amountParcent: new FormControl(),
      remarks: new FormControl(),
      productTypeId:new FormControl()
    });
    this.getAllSlabList()
    //this.buildForm();
  }
  
  ngOnInit(): void {
    this.getAllSlabList();
    this.showSaveForm = 'saveList';
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getAllSlabList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getAllSlabList();
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value
    this.refresh();
  }

  refresh() {
    this.isEdit = false;
    this.frmGroup.patchValue({
      id: null,
      min: null,
      max: null,
      amountParcent: null,
      remarks: null,
      productTypeId:[0]
    })
  }

  getSlabList() {
    this._service.getlist().subscribe(res => {
      this.list = res;
    })
  }
  getAllSlabList() {
    this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  onsubmit() {
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    this.model.min = this.frmGroup.value.min;
    this.model.max = this.frmGroup.value.max;
    this.model.amountParcent = this.frmGroup.value.amountParcent;
    this.model.remarks = this.frmGroup.value.remarks;
    this.model.productTypeId = this.frmGroup.value.productTypeId;
    this._service.save(this.model).subscribe(res => {      
    if (this.isEdit) {
      this.shared.ShowAlert("Success", "Save Successfully", "success");
    }
    else{
      this.shared.ShowAlert("Success", "Updated Successfully", "success");
    }
    this.isEdit = false;
    this.showSaveForm = 'saveList';
    this.getSlabList();
    })
  }


  delete(id: any) {
    this._service.DeleteSlabById(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getAllSlabList();
    })

  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.frmGroup.patchValue({
      id: item.id,
      min: item.min,
      max: item.max,
      amountParcent: item.amountParcent,
      remarks: item.remarks,
      productTypeId:item.productBuilderType
    })
  }
}
