import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-security-type',
  templateUrl: './security-type.component.html',
  styleUrls: ['./security-type.component.scss']
})
export class SecurityTypeComponent implements OnInit {
  frmGroupSecurity: FormGroup;
  constructor() { }

  ngOnInit(): void {
  }
  onSecurity() {
    // if (this.clientInfoId > 0) {
    //   this.model.id = this.frmGroupSecurity.value.id;
    //   this.model.valuationofProperty = this.frmGroupSecurity.value.valuationofProperty;
    //   this.model.goodRightofTitleDeed = this.frmGroupSecurity.value.goodRightofTitleDeed;
    //   this.model.vettingReport = this.frmGroupSecurity.value.vettingReport;
    //   this.model.ownerofProperty = this.frmGroupSecurity.value.ownerofProperty;
    //   this.model.relationwithApplicant = this.frmGroupSecurity.value.relationwithApplicant;
    //   this.model.natureScheduleofProperty = this.frmGroupSecurity.value.natureScheduleofProperty;
    //   this.model.yearsofPurchase = this.frmGroupSecurity.value.yearsofPurchase;
    //   this.model.purchaseValue = this.frmGroupSecurity.value.purchaseValue;
    //   this.model.internalValue = this.frmGroupSecurity.value.internalValue;
    //   this.model.marketValue = this.frmGroupSecurity.value.marketValue;
    //   this.model.forcedSalesValue = this.frmGroupSecurity.value.forcedSalesValue;
    //   this.model.currentPropertyStatus = this.frmGroupSecurity.value.currentPropertyStatus;
    //   this.model.locationofCollateral = this.frmGroupSecurity.value.locationofCollateral;
    //   this.model.nameofDistrict = this.frmGroupSecurity.value.nameofDistrict;
    //   this.model.landOffice = this.frmGroupSecurity.value.landOffice;
    //   this.model.mouzaName = this.frmGroupSecurity.value.mouzaName;
    //   this.model.khatianNo = this.frmGroupSecurity.value.khatianNo;
    //   this.model.mutationkhatianNo = this.frmGroupSecurity.value.mutationkhatianNo;
    //   this.model.dagNo = this.frmGroupSecurity.value.dagNo;
    //   this.model.holdingNo = this.frmGroupSecurity.value.holdingNo;
    //   this.model.roadConnectivity = this.frmGroupSecurity.value.roadConnectivity;
    //   this.model.demarcationMode = this.frmGroupSecurity.value.demarcationMode;
    //   this.model.ownershipCategory = this.frmGroupSecurity.value.ownershipCategory;
    //   this.model.nearbyLandmark = this.frmGroupSecurity.value.nearbyLandmark;
    //   this.model.subregisteryOffice = this.frmGroupSecurity.value.subregisteryOffice;
    //   this.model.landArea = this.frmGroupSecurity.value.landArea;
    //   this.model.fdrValue = this.frmGroupSecurity.value.fdrValue;
    //   this.model.collateralAddress = this.frmGroupSecurity.value.collateralAddress;
    //   this.model.loanWithoutSecurity = this.frmGroupSecurity.value.loanWithoutSecurity;
    //   this.model.typeofSecurity = this.frmGroupSecurity.value.typeofSecurity;
    //   this.model.designation = this.frmGroupSecurity.value.designation;
    //   this.model.status = this.frmGroupSecurity.value.status;
    //   this.model.clientInfoId = this.clientInfoId;
    //   this.model.leadId = this.leadId;

    //   this._serviceClientInfo.saveSecurity(this.model).subscribe(res => {
    //     if (this.model.id > 0) {
    //       Swal.fire('Thank you...', 'Updated succesfully!', 'success');
    //     } else {
    //       Swal.fire('Thank you...', 'Saved succesfully!', 'success');
    //     }
    //     this.getLeadInfo();
    //   })
    // } else {
    //   Swal.fire('Warning...', 'First Create a Client application!', 'warning');
    //   this.getLeadInfo();
    // }
  }
}
