import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';

@Component({
  selector: 'app-loan-collection',
  templateUrl: './loan-collection.component.html',
  styleUrls: ['./loan-collection.component.scss']
})
export class LoanCollectionComponent implements OnInit {

  fromDate:Date=new Date();
  toDate:Date=new Date();
  collectionDataList:any[];
  constructor(private pdfService:PdfService,private location:Location,private datePipe:DatePipe) { }

  ngOnInit(): void {
  }
  OpenPdf(){
    this.pdfService.LoanCollectionPdfReport(this.fromDate,this.toDate).subscribe(x=>{
      let blob:Blob=x.body as Blob;
      let url =window.URL.createObjectURL(blob);

      window.open(url);
    })
  }

  OpenExcel(){
    this.pdfService.LoanCollectionExcelReport(this.fromDate,this.toDate).subscribe(x=>{
      let blob:Blob=x.body as Blob;
      let url=window.URL.createObjectURL(blob);

      window.open(url)
    })
  }

  goBack(){
    this.location.back()
  }

  GetLoanCollection(){
    this.pdfService.LoanCollectionReportData(this.fromDate,this.toDate).subscribe(res=>{
      this.collectionDataList=res.body
    })
  }

  formatDateString(dateString: string): string {
    const formattedDate = this.datePipe.transform(dateString, 'dd-MM-yyyy'); // Adjust the format as needed
    return formattedDate || dateString;
  }

  // commaseparatorAmount(amount: number): string {
  //   return this.decimalPipe.transform(amount, '1.2-2'); // Adjust the format as needed
  // }
}
