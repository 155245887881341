import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/internal/Observable';
const apiUrl = environment.baseUrl + '/api/LoanRegisters/';
@Injectable({
  providedIn: 'root'
})
export class ExpenseDetailsService {

  constructor(private http: HttpClient) { }

  GetAllExpenseinfoByClientId(id: any){
    return this.http.get(apiUrl + 'GetAllExpenseinfoByClientId/'+ id );
  }

  saveExpense(model:any){
    return this.http.post(apiUrl + 'SaveExpense',model);
  }

  GetExpenseinfoById(id: any){
    return this.http.get(apiUrl + 'GetExpenseinfoById/'+ id );
  }
  GetLeadGenerationByLeadNoforExpense(customersId:number): Observable<any> {
    return this.http.get(apiUrl + 'GetLeadGenerationByLeadNoforExpense?customersId=' + customersId );
   }
  //bankAccount

    getBankAccountList():Observable<any>{
      return this.http.get(apiUrl + 'GetBankAccountDetails');
    }


  GetBankAccountDetailsById(id: any) {
    return this.http.get(apiUrl + 'GetBankAccountDetailsByCustomerId/' + id);
  } 


    saveBankAcc(bankAccModel:any){
      return this.http.post(apiUrl + 'SaveBankAccount',bankAccModel);
    }
    PostBankAccount(bankAccModel:any){
      return this.http.post(apiUrl + 'PostBankAccount',bankAccModel);
    }
  
    DeleteBankAcc(id: any){
      return this.http.delete(apiUrl + 'DeleteBankAcc/'+ id );
    }

  //Reference
  getReferenceList(): Observable<any> {
    return this.http.get(apiUrl + 'GetReferenceDetail');
   }
 
   
  GetReferenceinfoByClientId(id: any): Observable<any> {
    return this.http.get(apiUrl + 'GetReferenceinfoByClientId/'+ id );
 }




   GetReferenceinfoById(id: any){
    return this.http.get(apiUrl + 'GetReferenceinfoById/'+ id );
  }
   saveReference(model: any){
    
     return this.http.post(apiUrl +'PostReferenceDetail' , model);
  }
  delete(id: any){
    return this.http.delete(apiUrl + 'ReferenceDelete/'+ id );
 }


 //Income
 getIncomeDetailsList():Observable<any>{
  return this.http.get(apiUrl + 'GetAllIncome');
}

saveIncomeExpense(model:any){
  return this.http.post(apiUrl + 'SaveIncomeExpense',model);
}

saveIncome(model:any){
  return this.http.post(apiUrl + 'SaveIncome',model);
}


GetIncomeinfoByClientId(id: any){
  return this.http.get(apiUrl + 'GetIncomeinfoByClientId/'+ id );
}
GetIncomeDetailsById(id: any){
  return this.http.get(apiUrl + 'GetIncomeDetailsById/'+ id );
}

 GetAllIncomeinfoByClientId(id: any){
  return this.http.get(apiUrl + 'GetAllIncomeinfoByClientId/'+ id );
 }

// GetAllIncomeinfoByClientId(customersId:number){
//   return this.http.get(apiUrl + 'GetAllIncomeinfoByClientId?id=' + customersId );
// }
GetLeadGenerationByLeadNoforIncome(customersId:number): Observable<any> {
  return this.http.get(apiUrl + 'GetLeadGenerationByLeadNoforIncome?customersId=' + customersId );
 }

}
