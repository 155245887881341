import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-trial-balance-report",
  templateUrl: "./trial-balance-report.component.html",
  styleUrls: ["./trial-balance-report.component.scss"],
})
export class TrialBalanceReportComponent implements OnInit {
  fromDate: Date = new Date();
  toDate: Date = new Date();
  reportBy: number;
  constructor(private pdfService: PdfService, private location: Location) {}

  ngOnInit(): void {}

  OpenPDF() {
    this.pdfService
      .TrialBalanceReport(this.fromDate, this.toDate, 1, 2, this.reportBy)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }
  goBack() {
    this.location.back();
  }
}
