
    <section>
        <div class="row">
          <div class="col-10">
            <h3>Loan Collection </h3>

          </div>
          <div class="col-2">
            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
              <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
          </div>
        </div>
      <div  class="search-bar mt-3">
        <form  action="javascript:;" class="ng-untouched ng-pristine ng-valid"
          [formGroup]="frmGroup">
          <div  class="page-search-input form-group position-relative row">
            <div class="col-sm-10">
                <input type="search" id="searchbar" placeholder="Search by loan code.."
                formControlName="searchValue" class="form-control rounded-right form-control-lg shadow pl-2">
            </div>
            <div class="col-sm-2">
                <button  style="background-color: #616D89;color:white;cursor: pointer;" type="button"
                [disabled]="!frmGroup.valid" (click)="search()"
                class="btn btn-primary search-btn rounded position-absolute">
                <span  class="d-none d-sm-block">Search</span>
                <i  class="ft-search d-block d-sm-none"></i>
              </button>
            </div>
           
           
          </div>
        </form>
      </div>
    </section>

    <div class="card">
        <div class="card-body">
          <div class="">
            <h5 class="card-title" style="color: black">Loan Details</h5>
            <hr>
            <form [formGroup]="frmGroupSearch">
              <div asp-validation-summary="All" class="text-danger"></div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="NameEnglish" class="col-sm-4 col-form-label">Customer Code<span style="color:red; font-size: 15px; ">*</span></label>
                    <div class="col-sm-8">
                        <input type="hidden" class="form-control" formControlName="customerId">
                      <input type="hidden" class="form-control" formControlName="leadId">
                      <input type="text" class="form-control uppercase-input" [readonly]="isAuto" formControlName="customerRCode" placeholder="Customer Code">
                    </div>
                  </div>
      
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="NameBangla" class="col-sm-4 col-form-label">Customer Name Eng<span style="color:red; font-size: 15px; ">*</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" [readonly]="isAuto" formControlName="customerNameEng" placeholder="Customer Name Eng">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="NameBangla" class="col-sm-4 col-form-label">Customer Name Ban<span style="color:red; font-size: 15px; ">*</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" [readonly]="isAuto" formControlName="customerNameBan" placeholder="Customer Name Ban">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="dateOfBirth" class="col-sm-4 col-form-label">Date of Birth<span style="color:red; font-size: 15px; ">*</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control datepicker" [readonly]="isAuto" formControlName="dateOfBirth" placeholder="dd/mm/yyyy">
                    </div>
                  </div>
                </div>
                
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="mobileNoOffice" class="col-sm-4 col-form-label">Mobile (Office)<span style="color:red; font-size: 15px; ">*</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" [readonly]="isAuto" formControlName="mobileNo" placeholder="">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="mobileNoHome" class="col-sm-4 col-form-label">Email<span style="color:red; font-size: 15px; ">*</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" [readonly]="isAuto" formControlName="email" placeholder="">
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                      <label for="disbursementDate" class="col-sm-4 col-form-label">Disbursement Date</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" [readonly]="isAuto" formControlName="disbursementDate" placeholder="">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="loanAmount" class="col-sm-4 col-form-label">Loan Amount</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" [readonly]="isAuto" formControlName="loanAmount" placeholder="">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="interestRate" class="col-sm-4 col-form-label">Interest Rate</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" [readonly]="isAuto" formControlName="interestRate" placeholder="">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="interestAmount" class="col-sm-4 col-form-label">Interest Amount</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" [readonly]="isAuto" formControlName="interestAmount" placeholder="">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="totalaount" class="col-sm-4 col-form-label">Total Amount</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" [readonly]="isAuto" formControlName="totalaount" placeholder="">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="scheduleCollection" class="col-sm-4 col-form-label">Collection Amount</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" formControlName="scheduleCollection" placeholder="">
                      </div>
                    </div>
                  </div>
               
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="outstanding" class="col-sm-4 col-form-label">Out Standing</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" [readonly]="isAuto" formControlName="outstanding" placeholder="">
                      </div>
                    </div>
                  </div>
              </div>
               <button [disabled]="!frmGroupSearch.valid" data-toggle="tooltip" title="Save" type="submit"
            (click)="SavePersonalInfo()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
            <span>Save</span>
          </button> 
            </form>
          </div>
        </div>
      </div>