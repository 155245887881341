<section>
    
    <div class="card" style="width: 100%;">
    <div class="row card-header  d-flex flex-row align-items-center">
        <div class="col-6">
            <h4 style="margin-top: 16px;color: #888888;margin-left: 18px;">Reporting section</h4>
        </div>
        <div class="col-6">
            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                <!-- <i class="ft-chevrons-left"> Back</i> -->
                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
        </div>
    </div>
    <hr>
       
        <div class="card-body">
            <div class="container">

                <div class="form-group row">

                    <div class="col-2 mt-1">
                        <h5 style="font-size: 16px;color: #888888;">Customer Name</h5>
                    </div>
                    <div class="col-4">
                        <div class="input-group-prepend">
                            <p class="input-group-text" style="border-right: none;">
                                <i class="fa fa-search"></i>
                            </p>
                            <input type="text" class="form-control" [(ngModel)]="customername"
                                (keyup.enter)="loadCustomerDataByName()" placeholder="Search by customer name ..."
                                style="border-left: none; margin-left: -6px;">
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="input-group-prepend" style="border-color:1px solid rgb(52, 168, 214) !important;">
                            <p class="input-group-text" style="background-color: white;"
                                (click)=" toggleFilterTypeField()">
                                <i class="ft-layers" style="font-size: 22px;cursor: pointer;"> Filter</i>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="form-group row" *ngIf="showFilterTypeField">
                    <label for="" class="col-sm-2 col-form-label">Filter Type </label>
                    <div class="col-sm-4">
                        <!-- (change)="SearchByParameter($event.target.value)" -->
                        <select class="form-control" [(ngModel)]="typeId"
                            (change)="onFilterTypeChange($event.target.value)"
                            (change)="SearchByParameter($event.target.value)">
                            <option value="0"> Select</option>
                            <option *ngFor="let item of filterList" [value]="item.id">{{item.typeName}}</option>
                        </select>
                    </div>
                </div>

                <div class="row" *ngIf="showMobileNumberField">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <label>Mobile No</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="mobileNumber"
                                placeholder="Select mobile number">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(1)"
                                style="margin-right: 10px;"><i class="fa fa-close"></i></button>
                        </div>

                    </div>
                </div>

                <div class="row" *ngIf="showNidField">
                    <div class="col-2"></div>
                    <div class="col-4">


                        <label>NID No</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="nidNo" placeholder="Select NID number">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(3)"><i
                                    class="fa fa-close"></i></button>
                        </div>

                    </div>
                </div>
                <div class="row" *ngIf="showGenderField">
                    <div class="col-2"></div>
                    <div class="col-4">


                        <label>Gender</label>
                        <div class="input-group">

                            <select class="form-control" [(ngModel)]="genderId">
                                <option value="0"> Select</option>
                                <option *ngFor="let item of genderList" [value]="item.id">{{item.name}}</option>
                            </select>

                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(4)"><i
                                    class="fa fa-close"></i></button>
                        </div>

                    </div>
                </div>
                <div class="row" *ngIf="showGuarantorField">
                    <div class="col-2 mt-4">Guarantor</div>

                    <div class="col-4">
                        <label>Guarantor Nid No</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="guarantordNid"
                                placeholder="Select From Date">

                        </div>
                    </div>
                    <div class="col-4">
                        <label>Guarantor Mobile No</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="guarantorMobileNo"
                                placeholder="Select mobile number">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(8)"><i
                                    class="fa fa-close"></i></button>
                        </div>
                    </div>
                    <!-- <div class="col-2"></div>
                    <div class="col-4">
                        <label>Guarantor</label>

                 
                        <div class="input-group">

                             <select class="form-control" [(ngModel)]="guarantorId">
                                <option value="0"> Select</option>
                                <option *ngFor="let item of GuarantorList" [value]="item.id">{{item.name}} - Mobile: {{item.mobile}} - NID: {{item.nidNo}}</option>
                            </select> 
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(8)"><i
                                    class="fa fa-close"></i></button>
                        </div>
                    </div> -->
                </div>
                <div class="row" *ngIf="showAccountField">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <label>Account No</label>
                        <div class="input-group">

                            <input type="text" class="form-control" [(ngModel)]="accountNo"
                                placeholder="Type Account No...." (keyup.enter)="loadUserDataByAccountNoCashPayment()">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(9)"><i
                                    class="fa fa-close"></i></button>
                        </div>
                    </div>

                </div>
                <div class="row" *ngIf="showLoanField">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <label>Loan Type</label>
                        <div class="input-group">
                            <select class="form-control" [(ngModel)]="ProductId" name="ProductId">
                                <option value="0">Select</option>
                                <option *ngFor="let item of productList" [value]="item.id">
                                    {{ item.loanType.typeName }} - {{ item.name }} - {{ item.code }}
                                </option>
                            </select>
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(10)"><i
                                    class="fa fa-close"></i></button>
                        </div>
                    </div>
                </div>
                <!-- <div class="row" *ngIf="showTransactionField">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <label>Customer Wise Transaction</label>
                        <div class="input-group">
                            <select class="form-control" [(ngModel)]="genderId">
                                <option value="0"> Select</option>
                                <option *ngFor="let item of genderList" [value]="item.id">{{item.name}}</option>
                            </select>
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(11)"><i
                                    class="fa fa-close"></i></button>
                        </div>
                    </div>
                </div> -->
                <!-- -----------------------------------------Date-------------------------------------------------------- -->
                <div class="row" *ngIf="showBirthField">
                    <div class="col-2 mt-4">Birth Date</div>
                    <div class="col-4">
                        <label>Start Date</label>
                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="sBirthDate"
                                placeholder="Select From Date">

                        </div>
                    </div>
                    <div class="col-4">
                        <label>End Date</label>
                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="eBirthDate"
                                placeholder="Select To Date">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(5)"><i
                                    class="fa fa-close"></i></button>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="showCreatedField">
                    <div class="col-2 mt-4">Created Date</div>
                    <div class="col-4">

                        <label>Start Date</label>

                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="sCreateDate"
                                placeholder="Select From Date">

                        </div>

                    </div>


                    <div class="col-4">
                        <label>End Date</label>
                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="eCreateDate"
                                placeholder="Select To Date">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(6)"><i
                                    class="fa fa-close"></i></button>
                        </div>


                    </div>


                </div>
                <div class="row" *ngIf="showModifedField">
                    <div class="col-2 mt-4">Modified Date</div>
                    <div class="col-4">

                        <label>Start Date</label>

                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="sModifiedDate"
                                placeholder="Select From Date">

                        </div>

                    </div>
                    <div class="col-4">
                        <label>End Date</label>
                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="eModifiedDate"
                                placeholder="Select To Date">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(7)"><i
                                    class="fa fa-close"></i></button>
                        </div>
                    </div>
                </div>


                <div class="row" *ngIf="showDisburseField">
                    <div class="col-2 mt-4">Disburse Date</div>
                    <div class="col-4">

                        <label>Start Date</label>

                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="sDisburseDate"
                                placeholder="Select From Date">

                        </div>

                    </div>
                    <div class="col-4">
                        <label>End Date</label>
                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="eDisburseDate"
                                placeholder="Select To Date">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(12)"><i
                                    class="fa fa-close"></i></button>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="showApplyField">
                    <div class="col-2 mt-4">Apply Date</div>
                    <div class="col-4">

                        <label>Start Date</label>

                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="sApplyDate"
                                placeholder="Select From Date">

                        </div>

                    </div>
                    <div class="col-4">
                        <label>End Date</label>
                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="eApplyDate"
                                placeholder="Select To Date">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(13)"><i
                                    class="fa fa-close"></i></button>
                        </div>
                    </div>
                </div>


                <div class="row"  *ngIf="showTransactionField">
                    <div class="col-2 mt-4">Transaction Date</div>
                    <div class="col-4">
                
                        <label>From Date</label>
                
                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="fromDate" placeholder="Select From Date">
                
                        </div>
                
                    </div>
                    <div class="col-4">
                        <label>To Date</label>
                        <div class="input-group">
                            <input type="date" class="form-control" [(ngModel)]="toDate" placeholder="Select To Date">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(11)"><i class="fa fa-close"></i></button>
                        </div>
                    </div>
                </div>

                <div class="col-12 row">
                    <div class="col-sm-4"></div>
                    <div class="col-6">
                        <button class="btn btn-primary pull-right" (click)="SearchByParameter()"
                            *ngIf="parameterSearch">All Search</button>

                        <button class="btn btn-primary pull-right" (click)="loadCustomerDataByName()"
                            *ngIf="otherSearch">Search</button>


                        <!-- <button class="btn btn-primary pull-right" (click)="loadCustomerDataByName()"
                            *ngIf="otherSearch">Search</button>
                        <button class="btn btn-primary pull-right" (click)="loadLoanRelatedData()"
                            *ngIf="loanSearch">Search</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ------------------------------------------------------Customer Information-------------------------------------------------------- -->
    <div class="card" *ngIf="OtherInfoTable">
        <div *ngIf="noDataAvailable">

            <p style="text-align: center;font-size: 25px;font-weight: 700;">No data available</p>
        </div>
        <div *ngIf="!noDataAvailable">
            <h4 style="margin-top: 16px;color: #888888;margin-left: 18px;">Customer Info</h4>
            <hr>
            <div class="card-body">
                <div class="table-container" style="max-height: 700px; overflow: auto;">

                    <table id="example" class="table table-striped table-bordered scrollable-table"
                        style="width: 100% !important;">
                        <thead style="position: sticky; top: 0; background-color: #f8f9fa; z-index: 1;">
                            <tr>
                                <th>SL No.</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Email </th>
                                <th>NID No </th>
                                <th>Birth Date </th>
                                <th>Gender </th>
                                <th>Phone Number</th>
                                <th>Father Name</th>
                                <th>Mother Name</th>
                                <th>Credit Score</th>
                                <th>Created Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of customerList ; let i = index;">
                                <!-- <td>{{i + 1}}</td> -->
                                <td>{{i + 1 }}</td>
                                <td><img class="avatar" src="data:image/jpeg;base64,{{item.baseString}}" alt="avatar"
                                        height="35" width="35" /></td>

                                <td>{{item.customerNameEng}}</td>
                                <td>{{item.email}}</td>
                                <td>{{item.nidNo}}</td>
                                <td>{{item.dateOfBirth | date: 'yyyy-MM-dd'}}</td>
                                <td>{{item.genderName}}</td>
                                <td>{{item.mobileNo}}</td>
                                <td>{{item.fatherNameEng}}</td>
                                <td>{{item.motherNameEng}}</td>
                                <td>{{item.lastCreditScore}}</td>
                                <td>{{item.createdAt | date: 'yyyy-MM-dd'}}</td>
                                <td>

                                    <!-- <button class="btn btn-danger btn-sm mb-1" title="Loan Details" (click)="openLoanDetailsModel(loanIfoDetails)"
                (click)="getCustomerLoan(item.id)">
                <i class="fa fa-retweet"></i>
            </button>
           -->

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

    <!-- ------------------------------------------------------ Loan Information -------------------------------------------------------- -->
    <div class="card" *ngIf="LoanInfoTable">
        <div *ngIf="noDataAvailable">

            <p style="text-align: center;font-size: 25px;font-weight: 700;">No data available</p>
        </div>
        <div *ngIf="!noDataAvailable">
            <h4 style="margin-top: 16px;color: #888888;margin-left: 18px;">Loan Info</h4>
            <hr>
            
                <div style="max-height: 700px; overflow: auto;">

                    <table id="example" class="table table-striped table-bordered scrollable-table"
                        style="width: 100% !important;">
                        <thead style="position: sticky; top: 0; background-color: #f8f9fa; z-index: 1;">
                            <tr>
                                <th>SL No.</th>
                                <th>Image</th>
                                <th> Name</th>
                                <th>Email </th>
                                <th>Mobile No </th>
                                <th>Account No </th>
                                <th>Product Name</th>
                                <th>Opening Balance</th>
                                <th>Installment Size</th>
                                <th>Credit Score</th>
                                <th>Status</th>
                                <th>Created Date</th>
                                <th>Apply Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of loanList ; let i = index;">
                                <!-- <td>{{i + 1}}</td> -->
                                <td>{{i + 1 }}</td>
                                <td><img class="avatar" src="data:image/jpeg;base64,{{item.baseString}}" alt="avatar"
                                        height="35" width="35" /></td>

                                <td>{{item.customerNameEng}}</td>
                                <td>{{item.email}}</td>
                                <td>{{item.mobileNo}}</td>
                                <td>{{item.accountNo}}</td>
                                <td>{{item.productName}}</td>
                                <td>{{item.openingBalance}}</td>
                                <td>{{item.installmentSize}}</td>
                                <td>{{item.lastCreditScore}}</td>
                                <td>{{item.statusname}}</td>
                                <td>{{item.createdAt | date: 'yyyy-MM-dd' }}</td>
                                <td>{{item.applydate | date: 'yyyy-MM-dd' }}</td>
                                <td>

                                    <!-- <button class="btn btn-danger btn-sm mb-1" title="Loan Details" (click)="openLoanDetailsModel(loanIfoDetails)"
                    (click)="getCustomerLoan(item.id)">
                    <i class="fa fa-retweet"></i>
                </button>
               -->

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
         

        </div>
    </div>



    <!-- ------------------------------------------------------Guarantor Information-------------------------------------------------------- -->
    <div class="card" *ngIf="GuarantorInfoTable">
        <div *ngIf="noDataAvailable">

            <p style="text-align: center;font-size: 25px;font-weight: 700;">No data available</p>
        </div>
        <div *ngIf="!noDataAvailable">
            <h4 style="margin-top: 16px;color: #888888;margin-left: 18px;">Guarantor Info</h4>
            <hr>
            <div class="card-body">
                <div class="table-container" style="max-height: 700px; overflow: auto;">

                    <table id="example" class="table table-striped table-bordered scrollable-table"
                        style="width: 100% !important;">
                        <thead style="position: sticky; top: 0; background-color: #f8f9fa; z-index: 1;">
                            <tr>
                                <th>SL No.</th>

                                <th>Guarantor Name</th>
                                <th>Customer Name</th>
                                <th>Customer Mibile</th>
                                <th>Email </th>
                                <th>NID No </th>
                                <th>Mobile </th>
                                <th>Permanent Address </th>
                                <th>Monthly Income</th>
                                <th>Created Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of GuarantorList ; let i = index;">

                                <td>{{i + 1 }}</td>

                                <td>{{item.name}}</td>
                                <td>{{item.customer?.customerNameEng}}</td>
                                <td>{{item.customer?.mobileNo}}</td>

                                <td>{{item.email}}</td>
                                <td>{{item.nidNo}}</td>

                                <td>{{item.mobile}}</td>
                                <td>{{item.permanentAddress}}</td>
                                <td>{{item.netMonthlyIncome}}</td>
                                <td>{{item.createdAt | date: 'yyyy-MM-dd'}}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>




<!-- ------------------------------------------------------Transaction Information-------------------------------------------------------- -->

<div class="card" *ngIf="TransactionInfoTable">

    <div *ngIf="noDataAvailable">
    
        <p style="text-align: center;font-size: 25px;font-weight: 700;">No data available</p>
    </div>

<div *ngIf="!noDataAvailable">

    <br>
    <div class="table-container" style="margin-left: 25px;max-height: 700px; overflow: auto;">

        <table id="example" class="table table-striped table-bordered scrollable-table" style="width: 100% !important;">
            <thead style="position: sticky; top: 0; background-color: #f8f9fa; z-index: 1;">
                <tr>
                    <th>SL No.</th>
                     <th>Account Info </th>
                    <th>Transaction No </th>
                    <th>Transaction Date </th>
                    <th>Transaction Type</th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Balance</th>

                    <th>Receiver</th>
                    <th>Sender</th>


                    <!-- <th>Action</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of transactionList; let i = index;">
                    <td>{{i + 1}}</td>

                   <td>{{item.customerAccountNo}} - {{item.customerName}}
              </td> 
                    <td>{{item.transactionNo}}</td>
                    <td>{{formatDate(item.transactionDate)}}</td>

                    <td>
                        {{item.transactionType===0 ? 'Account':
                        item.transactionType===1?'Payment':item.transactionType===2?'Add
                        Money':item.transactionType===3?'Fund Transfer':''}}
                        <!-- <br>{{item.receiverAccoountId === null?'': 'Received'}} -->

                    </td>
                    <td>{{item.currency}}</td>
                    <td>{{item.transactionType===1?'-':item.transactionType===2?'+':item.transactionType===3?'-':''}}
                        {{item.amount | number}}</td>
                    <td>{{item.balance | number}}</td>

                    <td>
                        {{item.receiverAccountNo}}
                        {{item.receiverName}}

                    </td>
                    <td>
                        {{item.senderAccountNo}}
                        {{item.senderName}}

                    </td>


                    <!-- <td>
                    <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit"></i></button>
                    <button [disabled]="isEdit" class="btn btn-danger"  (click)="delete(item.Id)"><i class="fa fa-trash-o "></i></button>
                 
                </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>
</div>




</section>