<section>
    <div class="card" style="width: 100%;">
        <div class="card-header row">
            <div class="col-6">
                <h6 class="m-0 font-weight-bold text-primary">White List Customer Entry</h6>
            </div>
            <div class="col-6">
                <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
            
                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                </button>
            </div>
        </div>

<hr>
        <div class="card-body">
            <div class="container">
                
                   <a [routerLink]="['/customer/whitelist-excel-upload']" class="btn btn-info ml-1">White list upload excel</a>
                <a [routerLink]="['/customer/whitelist-insert']" class="btn btn-info ml-1">Manual White list entry</a>
            </div>
        </div>
    </div>
</section>

