<!-- <p>accured works!</p> -->

<section>
  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Accured Add</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
          (click)="onClickShowSaveForm('saveList')"
          style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
        <hr>
        <form [formGroup]="accuredFromGrp">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Accured Name</label>
                <div class="col-sm-8">
                  <input type="text" formControlName="name" class="form-control" placeholder="Type here...">
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="status" class="col-sm-4 col-form-label"> Status</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="status">
                    <option value="">Select</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="remarks" class="col-sm-4 col-form-label">Remarks</label>
                <div class="col-sm-8">
                  <input type="text" formControlName="remarks" class="form-control" placeholder="Type here...">
                </div>
              </div>
            </div>
  
  <div class="col-md-6">
    <div class="form-group row">
      <label for="sortorder" class="col-sm-4 col-form-label">Product Type</label>
      <div class="col-sm-8">
        <select class="form-control" formControlName="productTypeId">
          <ng-container *ngFor="let type of productBuilderTypes | keyvalue">
            <option *ngIf="type.key!=='0' && type.key!=='1' && type.key!=='2'" [value]="type.value">{{ type.key }}</option>
        </ng-container>
        </select>
      </div>
    </div>
  </div>
  </div>
          <button [disabled]="!accuredFromGrp.valid" data-toggle="tooltip" title="Save" type="submit" value="Submit" (click)="onSubmit()"
            class="btn btn-success" style="float:right; margin-top:5px;">
            <span>{{ isEdit ? 'Update' : 'Save' }}</span>
          </button>
          <button type="button" data-toggle="tooltip" title="Refresh" (click)="refresh()" class="btn btn-secondary"
            style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
        </form>
      </div>
    </div>
  </div>

  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Accured List</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary" (click)="onClickShowSaveForm('saveForm')"
          style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
        <hr>
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th>SL No.</th>
              <th>Accured Name</th>
              <th>Remarks</th>
              <th>Status</th>
              <th>Type</th>
              <th style="width: 25%;text-align: center;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of accuredList; let i = index">
              <td>{{i+1}}</td>
              <td>{{item.name}}</td>
              <td>{{item.remarks}}</td>
              <td>{{item.status=== true ? "Active" : "Inactive"}}</td>
              <td>{{productBuilderTypes[item.productBuilderType]}} Product</td>
              <td style="width: 25%;">
                <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;"
                  (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>