import { Component, OnInit, ChangeDetectorRef, NgZone } from "@angular/core";
import { CustomerService } from "../service/customer.service";
import { RegisterService } from "app/services/register.service";
import { Customer } from "../../customer/model/customer.model";
import { FormBuilder, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "app/services/shared.service"; //for sweetalert
import { NidBasic } from "../model/nid-basic.model";
import { map } from "rxjs/operators";
import { Location } from "@angular/common";
@Component({
  selector: "app-whitelist-insert",
  templateUrl: "./whitelist-insert.component.html",
  styleUrls: ["./whitelist-insert.component.scss"],
})
export class WhitelistInsertComponent implements OnInit {
  isCutomerRestration: boolean = true;
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = "";
  preview = "";
  proPreview = "";

  frontPreview = "";
  backPreview = "";

  mobileNumber: string = null;
  nidNumber: string = null;

  imageInfos?: Observable<any>;
  isEdit: boolean = false;
  model = new Customer();
  nid = new NidBasic();

  showResendButton = false;
  countdown = 120;
  countdownInterval: any;
  mobileNoValidationMessage: string = "";
  isMobileNoValid: boolean = false;
  isSubmitButtonEnabled(): boolean {
    return (
      this.model.creditScore !== null &&
      this.model.creditScore !== "" &&
      this.model.creditScore !== undefined &&
      this.model.CustomerNameEng !== null &&
      this.model.CustomerNameEng !== "" &&
      this.model.CustomerNameEng !== undefined &&
      this.model.MobileNo !== null &&
      this.model.MobileNo !== "" &&
      this.model.MobileNo !== undefined &&
      this.model.Email !== null &&
      this.model.Email !== "" &&
      this.model.Email !== undefined
    );
  }

  constructor(
    private _service: CustomerService,
    private registerService: RegisterService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private shared: SharedService,
    private ngZone: NgZone,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.loadGenderList();
  }

  CheckWhiteList() {
    this.isMobileNoValid =
      this.model.MobileNo && this.model.MobileNo.length === 11;
    this.isMobileNoValid =
      this.model.MobileNo && this.model.MobileNo.length === 11;

    // You can also reset the validation message when the user starts typing again
    if (this.isMobileNoValid) {
      this.mobileNoValidationMessage = "";
    }

    this.registerService.checkCustomerBymobile(this.model.MobileNo).subscribe(
      (res) => {
        if (res.mobileNo !== null) {
          this.shared.ShowAlert(
            "Warning...",
            "Already this customer is exist in white list!",
            "warning"
          );
        }
      },
      (error) => {
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  genderList: any;
  loadGenderList() {
    this._service.GetGenderList().subscribe((res) => {
      this.genderList = of(res);
    });
    this.changeDetectorRef.detectChanges();
  }

  resetCust() {
    this.isEdit = false;
  }

  onsubmit(data: Customer) {
    this.model.profileImage = this.proPreview;
    this.model.CustomerRCode = "0001";
    this.model.CustomerNameEng = this.model.CustomerNameEng;
    this.model.CustomerNameBan = this.model.CustomerNameBan;
    this.model.FatherNameEng = this.model.FatherNameEng;
    this.model.FatherNameBan = this.model.FatherNameBan;
    this.model.MotherNameEng = this.model.MotherNameEng;
    this.model.MotherNameBan = this.model.MotherNameBan;
    this.model.DateOfBirth = this.model.DateOfBirth;
    this.model.GenderId = this.model.GenderId;
    this.model.MobileNo = this.model.MobileNo;
    this.model.Email = this.model.Email;
    this.model.NidNo = this.model.NidNo;
    this.model.tin = this.model.tin;
    this.model.creditScore = this.model.creditScore;

    if (
      this.model.creditScore === null ||
      this.model.creditScore === "" ||
      this.model.creditScore === undefined
    ) {
      this.shared.ShowAlert("Warning", "Credit score is required.", "warning");
    } else {
      this._service.saveWhiteListCustomerInfo(this.model).subscribe(
        (res) => {
          this.shared.ShowAlert(
            "Success",
            "customer entry successfull",
            "success"
          );
          setTimeout(() => {
            //this.router.navigate(['/user']);
            this.router.navigate(["/customer/whitelist-customer"]);
          }, 2000);
        },
        (error) => {
          this.shared.ShowAlert("Wrong", "Something wrong", "error");
        }
      );
    }
  }

  goBack() {
    this.location.back();
  }

  // getCustomerByMobile(nidNumber: string, mobileNumber: string) {
  //   this._service.getCustomerByMobile(nidNumber, mobileNumber).subscribe(res => {

  //     this.model.CustomerNameEng = res['fullNameEN'];
  //     this.model.CustomerNameBan = res['fullNameBN'];
  //     this.model.FatherNameEng = res['fathersNameEN'];
  //     this.model.FatherNameBan = res['fathersNameBN'];
  //     this.model.MotherNameEng = res['mothersNameEN'];
  //     this.model.MotherNameBan = res['mothersNameBN'];
  //     //this.model.profileImage = res['photoUrl'];
  //    // this.proPreview = res['photoUrl'];
  //     this.model.presentAddressEN = res['presentAddressEN'];
  //     this.model.permenantAddressEN = res['permenantAddressEN'];
  //    // this.model.SpouseNameEng = res['spouseNameEN'];
  //     this.model.NidNo = res['nid'];
  //     this.model.MobileNo = res['customer'];
  //     this.model.DateOfBirth = res['dob'];
  //     this.model.creditScore = res['creditScore'];

  //     //for gender
  //     const genderName = res['gender'];
  //     this.genderList.pipe(
  //       map((genderArray: any[]) => {
  //         const genderItem = genderArray.find(item => item.name === genderName);
  //         if (genderItem) {
  //           this.model.GenderId = genderItem.id;
  //         }
  //       })
  //     ).subscribe();
  //     this.changeDetectorRef.detectChanges();
  //   });
  //   this.changeDetectorRef.detectChanges();
  // }
}
