import { Component, OnInit, AfterViewInit, Renderer2, ElementRef, ViewChild, ComponentFactoryResolver, ViewContainerRef  } from '@angular/core';
import { VoucherInfoService } from '../service/voucher-info.service';
import { SharedService } from 'app/services/shared.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { VoucherReportComponent } from 'app/pdf-reporting/voucher-report/voucher-report.component';
import { HttpClient } from '@angular/common/http';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Location } from "@angular/common";
@Component({
  selector: "app-voucher-master",
  templateUrl: "./voucher-master.component.html",
  styleUrls: ["./voucher-master.component.scss"],
})
export class VoucherMasterComponent implements OnInit {
  @ViewChild("dynamicDataContainer", { static: false })
  dynamicDataContainer: ElementRef;

  list: any[] = [];
  voucherDetailsInfo: any[] = [];
  voucherMaster: any[] = [];
  closeResult: string;
  voucherMasterList: any[] = [];

  currentPage = 1;
  itemsPerPage = 10;
  currentItems = 0;
  totalItems = 0;
  searchValue = "";
  fromDate: Date = new Date();
  endDate: Date = new Date();

  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private _voucherServie: VoucherInfoService,
    private shared: SharedService,
    private router: Router,
    private modalService: NgbModal,
    private pdfService: PdfService,
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    //this.getVoucherMasterDetailsList();
    //this.getVoucherList();
    this.getVoucherMasterList();
  }

  getVoucherMasterDetailsList() {
    this._voucherServie.getVoucherMasterWithDetailsList().subscribe((res) => {
      this.list = res;
    });
  }

  //////////////////////////////
  // getVoucherList() {
  //   this._voucherServie.getVoucherMasterList().subscribe((res) => {
  //     this.voucherMaster = res;
  //   });
  // }

  getVoucherMasterList() {
    this._voucherServie
      .getVoucherMasterListWithSearchAndPagination(
        this.currentPage,
        this.itemsPerPage,
        this.searchValue
      )
      .subscribe((res: any) => {
        this.voucherMasterList = res.data;
        this.totalItems = res.totalItems;
        this.currentItems = res.currentItems;
      });
  }

  getVoucherMasterListWithDate() {
    this._voucherServie
      .getVoucherMasterListFromDateToDate(this.fromDate, this.endDate)
      .subscribe((res: any) => {
        if (res != null) {
          this.voucherMasterList = res.data;
          this.voucherMasterList.forEach((item) => {
            item.voucherDate = new Date(item.voucherDate);
          });
          this.voucherMasterList.sort((a, b) => {
            return b.voucherDate.getTime() - a.voucherDate.getTime();
          });
        }
      });
  }

  getVoucherDetails(id: any) {
    this._voucherServie.getVoucherDetailsByMasterId(id).subscribe((data) => {
      this.voucherDetailsInfo = data;
    });
  }

  openVoucherDetailsModal(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  OpenPDF(voucherId) {
    this.pdfService.OpenVoucherPdf(voucherId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getVoucherMasterList();
  }

  onSearch() {
    this.currentPage = 1;
    this.getVoucherMasterList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getVoucherMasterList();
  }
  goBack() {
    this.location.back();
  }

  /////--------------No Use--------------////
  async PrintVoucherPdf(voucherId: number) {
    console.log(voucherId);

    const fileName = "my_document.pdf";

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        VoucherReportComponent
      );
    const componentRef = componentFactory.create(
      this.viewContainerRef.injector
    );

    if (voucherId !== undefined) {
      componentRef.instance.id = voucherId;
      componentRef.changeDetectorRef.detectChanges();
    }
    //componentRef.hostView.detectChanges();

    // // Wait for the dynamic content to be fully loaded and rendered
    // await new Promise<void>((resolve) => {
    //   setTimeout(() => {
    //     resolve();
    //   }, 2000); // Adjust the delay as per your requirement, or use an event or other method to detect when the content is loaded.
    // });

    const htmlElement: HTMLElement = (componentRef.changeDetectorRef as any)
      .rootNodes[0] as HTMLElement;

    if (htmlElement) {
      await this.pdfService.generatePDF(htmlElement, fileName);
      // await this.generatePDF();
      // await this.pdfService.generateDynamicPDF(fileName, this.dynamicDataContainer);
    }

    componentRef.onDestroy(() => {
      // Cleanup code if needed
    });
  }

  async convertUrlToHtmlElement(url: string): Promise<HTMLElement> {
    try {
      const htmlContent = await this.http
        .get(url, { responseType: "text" })
        .toPromise();
      const domParser = new DOMParser();
      const doc = domParser.parseFromString(htmlContent, "text/html");
      return doc.documentElement;
    } catch (error) {
      console.error(
        "Error fetching or parsing the HTML content from the URL:",
        error
      );
      return null;
    }
  }

  generatePDF() {
    setTimeout(() => {
      if (this.dynamicDataContainer) {
        const options = {
          margin: 10,
          filename: "my_document.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        html2canvas(this.dynamicDataContainer.nativeElement).then((canvas) => {
          const dynamicDataImage = canvas.toDataURL("image/jpeg", 0.98);
          const pdf = new jsPDF("p", "mm", "a4");
          pdf.addImage(dynamicDataImage, "JPEG", 0, 0, 210, 297); // Adjust width and height as needed
          pdf.save(options.filename);
        });
      }
    }, 0);
  }
  /////--------------No Use--------------////
}
