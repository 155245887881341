import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InterestCalculationService } from '../service/interest-calculation.service';
import { Router } from '@angular/router';
import { InterestCalculationFrequency } from '../model/interest-calculation-frequency';
import { SharedService } from 'app/services/shared.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';

@Component({
  selector: 'app-interest-calculation-frequency',
  templateUrl: './interest-calculation-frequency.component.html',
  styleUrls: ['./interest-calculation-frequency.component.scss']
})
export class InterestCalculationFrequencyComponent implements OnInit {

  frequencyList: any[] = [];
  isEdit: boolean = false;
  freqFromGrp: FormGroup;


  frequencyModel: any = {};
  //frequencyModel = new InterestCalculationFrequency();



  currentPage = 1;
  itemsPerPage = 5;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  productTypeId: number = 0;
  productBuilderTypes = ProductBuilderTypeEnum;
  constructor(private formBuilder: FormBuilder,
              private _frequencyService: InterestCalculationService,
              private shared:SharedService,
              private router: Router) { 

              }


onChangeForShow(value: any) {
  this.currentPage = 1;
  this.itemsPerPage = value;
  this.getAllFrequencyList();
}
onPageChange(pageNumber: number) {
  this.currentPage = pageNumber;
  this.getAllFrequencyList();
}

onSearch() {
  this.currentPage = 1; 
  this.getAllFrequencyList();
}
  ngOnInit(): void {
    this.frequencyForm();
    this.getAllFrequencyList();
    this.showSaveForm='saveList';
  }

  frequencyForm() {
    this.freqFromGrp = this.formBuilder.group({
      id: [0],
      name: [''],
      days:[0],
      productTypeId:[0]

    })
  }


  refresh() {
    this.isEdit = false;
    this.frequencyForm();
    this.frequencyModel = {};
  }

  showSaveForm:string='';

  onClickShowSaveForm(value:string){
    this.showSaveForm=value
    this.refresh();
  }

  getFrequencyList() {
    this._frequencyService.getFrequencyList().subscribe(res => {
      this.frequencyList = res;
    })
  }
  getAllFrequencyList() {
    this._frequencyService.getListFreqWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.frequencyList = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }
  onSubmit() {
    if (this.isEdit) {
      this.frequencyModel.FrequencyId = this.freqFromGrp.value.id;
    }
    this.frequencyModel.name = this.freqFromGrp.value.name;
    this.frequencyModel.days = this.freqFromGrp.value.days;
    this.frequencyModel.productTypeId = this.freqFromGrp.value.productTypeId;
    this._frequencyService.saveFrequency(this.frequencyModel).subscribe(res => {
      this.shared.ShowAlert("Success","Save Successfully","success");
      this.getAllFrequencyList();
      this.isEdit=false;
      this.showSaveForm='saveList';
    })
  }

  edit(item:any){
    this.isEdit=true;
    this.showSaveForm='saveForm';
    //alert(item.id)
    this.freqFromGrp.patchValue({
      id:item.id,
      name:item.name,
      days:item.days,
      productTypeId: item.productBuilderType
    })
  }

  delete(id:any){
    this._frequencyService.deleteFrequency(id).subscribe(res=>{
      this.shared.ShowAlert('Alert','Data deleted successfully.','warning');
      this.getAllFrequencyList();
      this.showSaveForm='saveList';
    })
  }

}
