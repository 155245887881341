
    <div class="card" style="width: 100%;">
      <div class="card-body">
        <div class="">
          <h5 class="card-title" style="color: black">Security Type</h5>
          <hr>
          <form [formGroup]="frmGroupSecurity">
            <div asp-validation-summary="All" class="text-danger"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Good right of title deed</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="goodRightofTitleDeed"
                      placeholder="goodRightofTitleDeed...">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label"> Vetting Report

                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="vettingReport"
                      placeholder="vettingReport...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Valuation of the Property</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="valuationofProperty"
                      placeholder="valuationofProperty...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label"> Owner of the property</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="ownerofProperty"
                      placeholder="ownerofProperty...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Relationship with applicant</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="relationwithApplicant"
                      placeholder="relationwithApplicant...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Nature and schedule of property

                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="natureScheduleofProperty"
                      placeholder="natureScheduleofProperty...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Years of purchase

                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="yearsofPurchase"
                      placeholder="yearsofPurchase...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Purchase value

                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="purchaseValue"
                      placeholder="purchaseValue...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Internal Value

                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="internalValue"
                      placeholder="internalValue...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Land Market Value

                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="marketValue" placeholder="marketValue...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Land Forced sales Value


                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="forcedSalesValue"
                      placeholder="forcedSalesValue...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Current Property status


                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="currentPropertyStatus"
                      placeholder="currentPropertyStatus...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Location of the collateral</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="locationofCollateral"
                      placeholder="locationofCollateral...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Name of District</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="nameofDistrict"
                      placeholder="nameofDistrict...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">land Office</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="landOffice" placeholder="landOffice...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Mouza Name</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="mouzaName" placeholder="mouzaName...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">khatian No</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="khatianNo" placeholder="khatianNo...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Mutation khatian No</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="mutationkhatianNo"
                      placeholder="mutationkhatianNo...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Dag No</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="dagNo" placeholder="dagNo...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Holding No</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="holdingNo" placeholder="holdingNo...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Road Connectivity</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="roadConnectivity"
                      placeholder="roadConnectivity...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Demarcation Mode</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="demarcationMode"
                      placeholder="demarcationMode...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Owner ship Category</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="ownershipCategory"
                      placeholder="ownershipCategory...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Near by Land Mark</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="nearbyLandmark"
                      placeholder="nearbyLandmark...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Sub Registery Office</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="subregisteryOffice"
                      placeholder="subregisteryOffice...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">land Area</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="landArea" placeholder="landArea...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">FDR Value</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="fdrValue" placeholder="fdrValue...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Collateral Address</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="collateralAddress"
                      placeholder="collateralAddress...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Loan Without Security</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="loanWithoutSecurity"
                      placeholder="loanWithoutSecurity...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Type Of Security</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="typeofSecurity"
                      placeholder="typeofSecurity...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Designation</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="designation" placeholder="designation...">

                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Status</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="status" placeholder="status...">

                  </div>
                </div>

              </div>

            </div>

            <button [disabled]="!frmGroupSecurity.valid" data-toggle="tooltip" title="SaveAddress" type="submit"
              (click)="onSecurity()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
              <span>Save</span>
              <!-- <span>{{ isSecurityTypeEdit ? 'Update' : 'Save' }}</span> -->
            </button>
          </form>
        </div>
      </div>
    </div>
