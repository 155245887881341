<div class="row">
    <hr>
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-10">
                <h5 class="card-title" style="color: #888888; font-family: Arial; font-size: 20px !important;">Loan Outstanding</h5>

                    </div>
                    <div class="col-2">
                        
                <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                  </button>
                    </div>
                </div>
                  <hr>
            </div>
            
            <div class="card-body">
                <div class="col-md-12">
                    <div class="form-group row" id="DateRangeSelect">
                        <label for="asOnDate" class="col-sm-2 col-form-label text-left">As on Date <span class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">
                            <input type="date" class="form-control" [(ngModel)]="asOnDate" placeholder="Select From Date">
                        </div>

                        <label for="leadId" class="col-sm-2 col-form-label text-left">Lead ID</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" [(ngModel)]="leadId" placeholder="Enter Lead ID (default is 0)">
                        </div>
                    </div>
                    
                    <!-- <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Get Report By</label>
                        <div class="col-sm-10">
                            <select type="text" class="form-control" [(ngModel)]="reportBy" default="12">
                                <option value="1">Loan Outstanding</option>
                            </select>
                        </div>
                    </div> -->

                    <div class="form-group row" style="margin-top: 10px;">
                        <label class="col-sm-8"></label>
                        <div class="col-sm-4">
                            <button class="btn btn-success" style="margin-left: 5px; float: right;" (click)="OpenPDF()">Print</button>
                            <button class="btn btn-info" style="margin-left: 5px; float: right;" (click)="OpenExcel()">Excel</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
