import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { CutomerLeadComponent } from './cutomer-lead/cutomer-lead.component';
import { CustomerleadAppliedComponent } from './customerlead-applied/customerlead-applied.component';
import { CustomerleadApprovedComponent } from './customerlead-approved/customerlead-approved.component';
import { WaitingLoanAppComponent } from './admin-dashboard-page/waiting-loan-app/waiting-loan-app.component';
import { ForwardLoanAppComponent } from './admin-dashboard-page/forward-loan-app/forward-loan-app.component';
import { ApprovedLoanAppComponent } from './admin-dashboard-page/approved-loan-app/approved-loan-app.component';
import { RejectedLoanAppComponent } from './admin-dashboard-page/rejected-loan-app/rejected-loan-app.component';
import { ApplicationDisburseListComponent } from './application-disburse-list/application-disburse-list.component';
import { AppApproveDisburseComponent } from './app-approve-disburse/app-approve-disburse.component';
import { EMIPayBillComponent } from './emi-pay-bill/emi-pay-bill.component';
import { EMIAmountPayComponent } from './emi-amount-pay/emi-amount-pay.component';
import { CollectionDetlsComponent } from './collection-detls/collection-detls.component';
import { FinalDisburseListComponent } from './admin-dashboard-page/final-disburse-list/final-disburse-list.component';
import { AuthGuard } from 'app/services/auth-guard/auth-guard.service';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';
import { NewAdminDashboardComponent } from './new-admin-dashboard/new-admin-dashboard.component';
import { LoanSettlementComponent } from './loan-settlement/loan-settlement.component';
import { AccountantDashboardComponent } from './accountant-dashboard/accountant-dashboard.component';
import { OperationalDashboardComponent } from './operational-dashboard/operational-dashboard.component';
import { WaitingPaymentComponent } from './admin-dashboard-page/waiting-payment/waiting-payment.component';
import { TodayCollectionListComponent } from './today-collection-list/today-collection-list.component';
import { ClcalculationInfoComponent } from './clcalculation-info/clcalculation-info.component';
import { ReceivableListComponent } from './admin-dashboard-page/receivable-list/receivable-list.component';
import { OverdueLoanListComponent } from './admin-dashboard-page/overdue-loan-list/overdue-loan-list.component';
import { PayableLoanListComponent } from './admin-dashboard-page/payable-loan-list/payable-loan-list.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'admin-dashboard',
        component: AdminDashboardComponent,
        data: {
          title: 'admin-dashboard'
        },
        canActivate:[AuthGuard]
      },

      

      {
        path: 'customer-dashboard',
        component: CustomerDashboardComponent,
        data: {
          title: 'Customer-Dashboard'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'cutomer-lead/:parameter',
        component: CutomerLeadComponent,
        data: {
          title:'customer-lead'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'customerlead-applied',
        component: CustomerleadAppliedComponent,
        data: {
          title:'customer-lead'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'customerlead-approved',
        component: CustomerleadApprovedComponent,
        data: {
          title:'customer-lead'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'waiting-application',
        component: WaitingLoanAppComponent,
        data: {
          title:'waiting-application'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'forward-loan-app',
        component: ForwardLoanAppComponent,
        data: {
          title:'waiting-application'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'approved-loan-app',
        component: ApprovedLoanAppComponent,
        data: {
          title:'approved-application'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'rejected-loan-app',
        component: RejectedLoanAppComponent,
        data: {
          title:'rejected-application'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'application-disburse-list',
        component: ApplicationDisburseListComponent,
        data: {
          title:'application-disburse-list'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'app-receivable-list',
        component: ReceivableListComponent,
        data: {
          title:'app-receivable-list'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'app-payable-loan-list',
        component: PayableLoanListComponent,
        data: {
          title:'app-payable-loan-list'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'app-overdue-loan-list',
        component: OverdueLoanListComponent,
        data: {
          title:'app-overdue-loan-list'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'final-disburse-list',
        component: FinalDisburseListComponent,
        data: {
          title:'final-disburse-list'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'app-approve-disburse',
        component: AppApproveDisburseComponent,
        data: {
          title:'approved-disburse'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'emi-pay-bill/:parameter',
        component: EMIPayBillComponent,
        data: {
          title:'emi-pay-bill'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'emi-amount-pay',
        component: EMIAmountPayComponent,
        data: {
          title:'emi-amount-pay'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'collection-detls/:parameter',
        component: CollectionDetlsComponent,
        data: {
          title:'collection-detls'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'dashboard-new',
        component: DashboardNewComponent,
        data: {
          title: 'dashboard-new'
        },
       
      },

      {
        path: 'new-admin-dashboard',
        component: NewAdminDashboardComponent,
        data: {
          title: 'new-admin'
        },

      },
        {
          path: 'loan-settlement',
          component: LoanSettlementComponent,
          data: {
            title: 'loan-settlement'
          },

        },

        {
        path: 'accountant-dashboard',
        component: AccountantDashboardComponent,
        data: {
          title: 'new-admin'
        },
       },

       {
        path: 'operational-dashboard',
        component: OperationalDashboardComponent,
        data: {
          title: 'new-admin'
        },
       },

       {
        path: 'waiting-payment',
        component: WaitingPaymentComponent,
        data: {
          title: 'waiting-payment'
        },
       },
       {
        path: 'today-collection-list',
        component: TodayCollectionListComponent,
        data: {
          title: 'today-collection-list'
        },
       },

       {
        path: 'clcalculation-info',
        component: ClcalculationInfoComponent,
        data: {
          title: 'clcalculation-info'
        },
       },





    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardCustomRoutingModule { }
