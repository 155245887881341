import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { InterestCalculationBalanceService } from '../service/interest-calculation-balance.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';

@Component({
  selector: 'app-interest-calculation-balance',
  templateUrl: './interest-calculation-balance.component.html',
  styleUrls: ['./interest-calculation-balance.component.scss']
})
export class InterestCalculationBalanceComponent implements OnInit {

  calculationBalanceList: any[] = [];
  isEdit: boolean = false;
  calculationBalanceFromGrp: FormGroup;

  calculationBalanceModel: any = {};
  currentPage = 1;
  itemsPerPage = 5;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = '';
  productTypeId: number = 0;
  productBuilderTypes = ProductBuilderTypeEnum;
  constructor(private formBuilder: FormBuilder,
    private shared: SharedService,
    private _calBalanceService: InterestCalculationBalanceService) { }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getAllChargeList();
  }
  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getAllChargeList();
  }

  onSearch() {
    this.currentPage = 1;
    this.getAllChargeList();
  }
  ngOnInit(): void {
    this.calculationBalanceForm();
    this.getCalculationBalanceList();
    this.showSaveForm = 'saveList';
  }

  calculationBalanceForm() {
    this.calculationBalanceFromGrp = this.formBuilder.group({
      id: [0],
      name: ['', Validators.required],
      days: ['', Validators.required],
      productTypeId:[0]
    })
  }

  refresh() {
    this.isEdit = false;
    this.calculationBalanceForm();
    this.calculationBalanceModel = {};
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();
  }

  getCalculationBalanceList() {
    this._calBalanceService.getCalculationBalanceList().subscribe(res => {
      this.calculationBalanceList = res;
    })
  }
  getAllChargeList() {
    this._calBalanceService.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.calculationBalanceList = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }
  onSubmit() {
    if (this.isEdit) {
      this.calculationBalanceModel.id = this.calculationBalanceFromGrp.value.id;
    }
    this.calculationBalanceModel.name = this.calculationBalanceFromGrp.value.name;
    this.calculationBalanceModel.days = this.calculationBalanceFromGrp.value.days;
    this.calculationBalanceModel.productTypeId = this.calculationBalanceFromGrp.value.productTypeId;

    this._calBalanceService.saveInterestCalculationBalance(this.calculationBalanceModel).subscribe(res => {
      this.shared.ShowAlert("Success", "Save Successfully", "success");
      this.getCalculationBalanceList();
      this.showSaveForm = 'saveList';
      this.refresh();
    })
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';

    this.calculationBalanceFromGrp.patchValue({
      id: item.id,
      name: item.name,
      days: item.days,
      productTypeId:item.productBuilderType
    })
  }

  delete(id: any) {
    this._calBalanceService.deleteInterestCalculationBalance(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getCalculationBalanceList();
      this.showSaveForm = 'saveList';
    })
  }

}
