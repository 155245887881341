import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { PaymentRequestVm } from './models/payment-request-vm';
import { TransactionLog } from './models/transaction-log';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private apiUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  checkoutPaymentShurjo(paymentRequest: PaymentRequestVm): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/api/Payment/CheckoutPayment`, paymentRequest);
  }  


   checkoutPayment(paymentRequest: PaymentRequestVm): Observable<any> {
   
    return this.http.post<any>(`${this.apiUrl}/api/Payment/CheckoutPaymentSSL`, paymentRequest);
   
  } 

  getTransactionLogByTransactionId(transId: string): Observable<TransactionLog> {
    const apiUrl = `${this.apiUrl}/api/Payment/TransactionLogByTransactionId/${transId}`;
    return this.http.get<TransactionLog>(apiUrl);
  }

  getTransactionLogs(): Observable<TransactionLog[]> {
    const apiUrl = `${this.apiUrl}/api/Payment/GetAllPaymentLogs`;
    return this.http.get<TransactionLog[]>(apiUrl);
  }
}
