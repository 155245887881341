import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LoanProductService } from "app/product/service/loan-product.service";
import { CustomerService } from "../customer.service";
import { SharedService } from "app/services/shared.service";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-terms-condition",
  templateUrl: "./terms-condition.component.html",
  styleUrls: ["./terms-condition.component.scss"],
})
export class TermsConditionComponent implements OnInit {
  frmGroupTerms: FormGroup;
  productList: any[] = [];
  selectedProductId: number;
  conditions: string;
  expireDate: Date = new Date();
  TermsId: any = 0;
  model: any = {};

  constructor(
    private _loanProductService: LoanProductService,
    private changeDetectorRef: ChangeDetectorRef,
    private customerService: CustomerService,
    private shared: SharedService,
    private formBuilder: FormBuilder
  ) {
    this.getLoanProductBuilderInfoList();
  }

  ngOnInit(): void {
    this.termspopulateForm();
    this.getLoanProductBuilderInfoList();
  }

  getLoanProductBuilderInfoList() {
    this._loanProductService.getLoanProductList().subscribe((res) => {
      this.productList = res;
      this.selectedProductId = 0;
      this.changeDetectorRef.detectChanges();
    });
  }

  termspopulateForm() {
    this.frmGroupTerms = this.formBuilder.group({
      TermsId: [0],
      selectedProductId: [0],
      expireDate: [""],
      conditions: [""],
    });
  }

  

  onSubmit() {

   
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save this Contact Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save Contact!",
    }).then((res) => {
      if (res.isConfirmed) {
        //this.model.TermsId = this.frmGroupTerms.value.TermsId;
        this.model.expireDate = this.frmGroupTerms.value.expireDate;
        this.model.selectedProductId =this.frmGroupTerms.value.selectedProductId;
        this.model.conditions = this.frmGroupTerms.value.conditions;
        console.log("-----------this.model");
        console.log(this.model);
        alert(this.model.expireDate);
        alert(this.frmGroupTerms.value.expireDate);

        this.customerService.saveTerms(this.model).subscribe((res) => {
          //this.clientInfoId = this.model.clientInfoID;
          this.shared.ShowAlert(
            "Thank you...",
            "You save succesfully!",
            "success"
          );
        });
      }
    });
  }
  
}
