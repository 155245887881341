<div class="row" (resized)="onResized($event)">
    <div class="card" style="width: 100%;">
        <div class="row card-header py-3 d-flex flex-row align-items-center">
            
            <div class="col-6">
                <h6 class="m-0 font-weight-bold text-primary">Applied Loan List</h6>
            </div>
            <div class="col-6">
                <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                  
                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div class="container">
                <table id="example" class="table table-striped table-bordered" style="width:100%">
                    <!-- <thead>
                        <tr>
                            <th>SL No.</th>
                            <th>Loan Account</th>
                            <th>Customer</th>
                            <th>Create at</th>
                            <th>Loan Amount</th>
                            <th>Status</th>
                           
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of LeadList; let i = index;">
                            <td>{{i + 1}}</td>
                            <td>{{item?.customerAccountNo}}</td>
                           
                            <td>
                                <span>Name:{{item.customers?.customerNameEng }}</span> <br>
                                <span>Mobile No:{{item.customers?.mobileNo}} </span><br>
                             </td>
                             
                           <td>{{item?.createdAt | date:'dd-MM-yyyy'}}</td>
                           <td>{{item?.leadGeneration?.loanAmount}}</td>
                            <td>
                               
                                <div class="badge badge-pill">
                                    Approved
                                </div>
                            </td> -->

                            <thead>
                                <tr style="width: 100%;">
                                    <th style="width: 5%;">SL No.</th>
                                    <th>Loan Code </th>
                                    <th>Customer Info </th>
                                    <th>Loan Type </th>
                                    <th>Loan Amount</th>
                                    <th>Status</th>
                                    <th style="width: 20%;">Action </th>
                                </tr>
                            </thead>
                            
                            <tbody>
                            
                                <tr *ngFor="let item of LeadList; let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{item.leadCode}}</td>
                                    <td>
                                        <span>Name: {{item?.customer?.customerNameEng}}</span><br>
                                        <span>Mobile No.: {{item?.customer?.mobileNo}}</span>
                                    </td>
                                    <td>{{item.loanProduct?.loanType?.typeName}}</td>
                                    <td>{{item?.loanAmount}}</td>
                            <td>
                                <!-- [ngClass]="{'bg-light-primary':  item.statusInfoId == 2}" -->
                                <div>
                                    Applied
                                </div>
                            </td>
                            <td>
                                <!-- <button class="btn btn-sm btn-secondary" (click)="loanDisburse(item?.leadGeneration?.id)" type="button" title="Disburse Loan" style="background-color: #6f7270;">Disburse</button> -->
                                <!-- <button class="btn btn-sm btn-secondary" (click)="goDisburse(item?.leadGeneration?.id,item?.leadGeneration?.customerId,item?.leadGeneration?.loanProductId)" type="button" title="Disburse Loan" style="background-color: #6f7270;">Details</button> -->
                                <button class="btn btn-sm btn-info" (click)="goDisburse(item?.id,item?.customerId,item?.loanProductId)" type="button" title="Disburse Loan">Disburse</button>
                                <button class="btn btn-sm btn-info " (click)="goDetailsView(item?.id)" type="button" title="Details">Details</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>