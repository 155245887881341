
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { CreditscoreService } from '../service/creditscore.service';
import { creditscore } from '../model/creditscore';



@Component({
  selector: 'app-credit-score-insert',
  templateUrl: './credit-score-insert.component.html',
  styleUrls: ['./credit-score-insert.component.scss']
})
export class CreditScoreInsertComponent implements OnInit {

  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: creditscore[] = [];
  model = new creditscore();

  currentPage = 1;
  itemsPerPage = 5;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = '';

  constructor(
    private _service: CreditscoreService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,
  ) {
    this.frmGroup = new FormGroup({
      id: new FormControl(),
      creditMinScore: new FormControl(),
      creditMaxScore: new FormControl(),
      Parcent: new FormControl(),
      remarks: new FormControl(),

    });
    this.getAllList()
    //this.buildForm();
  }

  ngOnInit(): void {
    this.getAllList();
    this.showSaveForm = 'saveList';
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getAllListPagination();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getAllListPagination();
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value
    this.refresh();
  }

  refresh() {
    this.isEdit = false;
    this.frmGroup.patchValue({
      id: null,
      creditMinScore: null,
      creditMaxScore: null,
      Parcent: null,
      remarks: null
    })
  }

  getAllList() {
    this._service.getlist().subscribe(res => {
      this.list = res;
    })
  }
  getAllListPagination() {
    this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  onsubmit() {
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    this.model.creditMinScore = this.frmGroup.value.creditMinScore;
    this.model.creditMaxScore = this.frmGroup.value.creditMaxScore;
    this.model.Parcent = this.frmGroup.value.Parcent;
    this.model.remarks = this.frmGroup.value.remarks;

    this._service.save(this.model).subscribe(res => {
      if (this.isEdit) {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
      }
      else {
        this.shared.ShowAlert("Success", "Updated Successfully", "success");
      }
      this.isEdit = false;
      this.showSaveForm = 'saveList';
      this.getAllList();
    })
  }


  delete(id: any) {
    this._service.DeleteSlabById(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getAllList();
    })

  }

  edit(item: any) {
   
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.frmGroup.patchValue({

      id: item.id,
      creditMinScore: item.creditMinScore,
      creditMaxScore: item.creditMaxScore,
      Parcent: item.parcent,
      remarks: item.remarks
    })
  }
}

