import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerRoutingModule } from './customer-routing.module';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomercreateComponent } from './customercreate/customercreate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CashtransactionListComponent } from './cashtransaction-list/cashtransaction-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { LoanApplicationModule } from 'app/loan-application/loan-application.module';
import { DeclarationEditComponent } from './declaration-edit/declaration-edit.component';
import { NomineeListComponent } from './nominee-list/nominee-list.component';
import { NomineeEditComponent } from './nominee-edit/nominee-edit.component';
import { CustomerAccountListComponent } from './customer-account-list/customer-account-list.component';
import { CustomerEditForAdminComponent } from './customer-edit-for-admin/customer-edit-for-admin.component';
import { WhitelistEntryComponent } from './whitelist-entry/whitelist-entry.component';
import { WhitelistCustomerComponent } from './whitelist-customer/whitelist-customer.component';
import { WhitelistExcelUploadComponent } from './whitelist-excel-upload/whitelist-excel-upload.component';
import { WhitelistInsertComponent } from './whitelist-insert/whitelist-insert.component';
import { CustomerInqueryComponent } from './customer-inquery/customer-inquery.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';


@NgModule({
  declarations: [
    CustomerListComponent,
    CustomercreateComponent,
    CashtransactionListComponent,
    CustomerEditComponent,
    DeclarationEditComponent,
    NomineeListComponent,
    NomineeEditComponent,
    CustomerAccountListComponent,
    CustomerEditForAdminComponent,
    WhitelistEntryComponent,
    WhitelistCustomerComponent,
    WhitelistExcelUploadComponent,
    WhitelistInsertComponent,
    CustomerInqueryComponent,
    TermsConditionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomerRoutingModule,
    NgxPaginationModule,
    LoanApplicationModule,
  ]
})
export class CustomerModule { }
