<div class="card" style="width: 100%;">
    <div class="card-body">
      <div class="">
        <ul class="list-unstyled mb-0">
          <li class="d-inline-block mr-4">
            <h5 class="card-title" style="color: rgb(78, 77, 77);">Profession Type</h5>
          </li>
          <li class="d-inline-block mr-2">
            <div class="radio">
              <input type="radio" id="radio1" checked name="basic-radio-1" value="1" #option1
                (change)="onRadioChange(option1.value)">
              <label for="radio1">Service Holder</label>
            </div>
          </li>
          <li class="d-inline-block mr-2">
            <div class="radio">
              <input type="radio" name="basic-radio-1" id="radio2" value="2" #option2
                (change)="onRadioChange(option2.value)" />
              <label for="radio2">Self-Employeed</label>
            </div>
          </li>
          <li class="d-inline-block mr-2">
            <div class="radio">
              <input type="radio" name="basic-radio-1" id="radio3" value="3" #option3
                (change)="onRadioChange(option3.value)" />
              <label for="radio3">Business</label>
            </div>
          </li>
        </ul>

        <hr>
        <div *ngIf="selectedOption === '1'">
          <h4 class="card-title" [ngStyle]="{'color': isDataNull=== true ? 'rgb(78, 77, 77)' : 'rgb(78, 77, 77)'}">
            {{ isDataNull === true ? 'Profession Information (Incomplete)' : '' }}</h4> 
          <form [formGroup]="frmGroup1">
            <div asp-validation-summary="All" class="text-danger"></div>           
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label"> Company Name<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">
                    <input type="hidden" class="form-control" formControlName="id">
                    <input type="text" class="form-control" formControlName="Presentcompany"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
                
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label"> Designation</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="PresentDesignation"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
               
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Office Address
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="Presentofficeaddress"
                      placeholder="Type here....">
                  </div>
                </div>
              </div> 

            </div>
            <!-- <h4 style="color: black;">Previous Profession Information</h4><br />  

            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Company Name
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="PrevEmployersName"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Designation
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="PrevDesignation"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
               <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Office Telephone No
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="PrevofficeTel"
                      placeholder="Type here....">
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Address
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="PrevAddress"placeholder="Type here....">
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Length of Service</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="Prevlengthofservice"
                      placeholder="Type here....">
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Total experience</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="totalexperience" style="text-align: right;"
                    (input)="frmGroup1.get('totalexperience').setValue(formatNumber($event.target.value))" placeholder="0">
                    
                    <span *ngIf="frmGroup1.get('totalexperience').hasError('pattern')" class="text-danger">
                      Only numeric values are allowed.
                  </span> 
                  </div>
                </div>
              </div> 
            </div> -->
            <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Length of Service</label>
                  <div class="col-sm-8">
                    <input type="date" class="form-control" formControlName="Presentlengthofservice"
                      placeholder="Type here....">
                  </div>
                </div> 
              </div> -->
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Office Telephone no.</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="PresentofficeTel"
                      placeholder="Type here....">
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label"> Employer's Name<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="PresentEmployersName"> 
                   
                  </div>
                </div>
              </div> -->
               
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label"> Business Nature<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="Presentbusinessnature"
                      placeholder="Type here....">
                  </div>
                </div>

              </div> -->
            <button data-toggle="tooltip" title="Save" type="submit"
              (click)="onserviceholder()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
              <span>{{ isEdit ? 'Update' : 'Save' }}</span>
            </button>
            <button type="button" data-toggle="tooltip" title="Refresh" (click)="reset()" class="btn btn-secondary"
              style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
          </form>
        </div>
        <div *ngIf="selectedOption === '2'" style="width:150% !important">
          <form [formGroup]="frmGroup2">
            <div asp-validation-summary="All" class="text-danger"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Profession <span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-4">
                    <select class="form-control" formControlName="selfEmployedTypeId">
                      <option value="">Select</option>
                      <option *ngFor="let item of selfprofList" [value]="item.id">{{ item.type }}</option>
                    </select>
                    <input type="hidden" class="form-control" formControlName="id" >
                  </div>
                </div>
                </div>

                    <div class="col-md-6">
                      <div class="form-group row">
                       
                        <div class="col-sm-4">
                      <button data-toggle="tooltip" title="Save" type="submit" (click)="onselfEmployeed()" value="Submit"
                        class="btn btn-success"  style="margin-right: 2px;">
                        <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                      </button>
                      <button type="button" data-toggle="tooltip" title="Refresh" (click)="reset()" class="btn btn-secondary"
                        style="margin-right: -20px;">Refresh</button>
                        </div>
                      </div>
                    </div>
         
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label"> Company Name<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">
                    
                    <input type="text" class="form-control" formControlName="instituteName"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
             
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label"> Designation</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="PresentDesignation"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Address
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="instituteaddress"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>  -->
               
               
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">NO. Of Years in Practice</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="Practiceyear"
                      placeholder="Type here....">
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Institute Name

                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="instituteName"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Institute Address

                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="instituteaddress"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Institute Telephone
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="institutetelephone"
                      placeholder="Type here....">
                  </div>
                </div>
              </div> -->

            </div>
            <!-- <button data-toggle="tooltip" title="Save" type="submit" (click)="onselfEmployeed()" value="Submit"
              class="btn btn-success" style="margin-top:5px;">
              <span>{{ isEdit ? 'Update' : 'Save' }}</span>
            </button>
            <button type="button" data-toggle="tooltip" title="Refresh" (click)="reset()" class="btn btn-secondary"
              style=" margin-top:5px;margin-left: 1px;">Refresh</button> -->
          </form>
        </div>
        <div *ngIf="selectedOption === '3'">
          <form [formGroup]="frmGroup3">
            <div asp-validation-summary="All" class="text-danger"></div>

            <div class="row">
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Organization Name<span style="color:red; font-size: 15px; ">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input type="hidden" class="form-control" formControlName="id">
                    <input type="text" class="form-control" formControlName="orgName"placeholder="Type here....">
                  </div>
                </div>

              </div> -->
              
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label"> Organization Name<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">
                    <input type="hidden" class="form-control" formControlName="id">
                    <input type="text" class="form-control" formControlName="orgName"
                      placeholder="Type here....">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Nature Of Business<span style="color:red; font-size: 15px; ">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="businessType"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
               
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Business Address
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="businessAddress"
                      placeholder="Type here....">
                  </div>
                </div>
              </div> 
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label"> Designation</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="PresentDesignation"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Business Address<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="businessAddress"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Years in Business<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="yearsinBusiness"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Number of Staff<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="numberofstaff"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Office telephone no.<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="officetelno"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Previous Business Address<span style="color:red; font-size: 15px; ">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="prevbusinessaddress"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Previous Business Type<span style="color:red; font-size: 15px; ">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="prevbusinesstype"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Previous Business Duration<span style="color:red; font-size: 15px; ">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="prevbusinessduration"
                      placeholder="Type here....">
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="roleName" class="col-sm-4 col-form-label">Total Business Experience<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="totalbusinessExperience"
                      placeholder="Type here....">
                  </div>
                </div>

              </div> -->



            </div>
            <button [disabled]="!frmGroup3.valid" data-toggle="tooltip" title="Save" type="submit"
              (click)="onbusiness()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
              <span>{{ isEdit ? 'Update' : 'Save' }}</span>
            </button>
            <button type="button" data-toggle="tooltip" title="Refresh" (click)="reset()" class="btn btn-secondary"
              style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
          </form>
        </div>

      </div>
    </div>
  </div>
