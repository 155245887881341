import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsRoutingModule } from './products-routing.module';
import { TaxKeyComponent } from './tax-key/tax-key.component';
import { ExciseDutyComponent } from './excise-duty/excise-duty.component';
import { InterestCalculationFrequencyComponent } from './interest-calculation-frequency/interest-calculation-frequency.component';
import { InterestCalculationTypeComponent } from './interest-calculation-type/interest-calculation-type.component';
import { BalancetypeComponent } from './balancetype/balancetype.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccuredComponent } from './accured/accured.component';
import { DaySizeComponent } from './day-size/day-size.component';
import { CommissionComponent } from './commission/commission.component';
import { CapitalizationRuleComponent } from './capitalization-rule/capitalization-rule.component';
import { ChargeComponent } from './charge/charge.component';
import { SlabComponent } from './slab/slab.component';
import { TransectionTypeComponent } from './transection-type/transection-type.component';
import { InterestCalculationBalanceComponent } from './interest-calculation-balance/interest-calculation-balance.component';
import { CapitalizeFrequencyComponent } from './capitalize-frequency/capitalize-frequency.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { InterestCalculationTypeListComponent } from './interest-calculation-type-list/interest-calculation-type-list.component';
import { LoanLimitListComponent } from './loan-limit-list/loan-limit-list.component';
import { LoanLimitEntryComponent } from './loan-limit-entry/loan-limit-entry.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CreditScoreInsertComponent } from './credit-score-insert/credit-score-insert.component';


@NgModule({
  declarations: [
  
    TaxKeyComponent,
        ExciseDutyComponent,
        InterestCalculationFrequencyComponent,
        InterestCalculationTypeComponent,
        BalancetypeComponent,
        AccuredComponent,
        DaySizeComponent,
        CommissionComponent,
        CapitalizationRuleComponent,
        ChargeComponent,SlabComponent, 
        TransectionTypeComponent, 
        InterestCalculationBalanceComponent, 
        CapitalizeFrequencyComponent, InterestCalculationTypeListComponent, LoanLimitListComponent, LoanLimitEntryComponent, CreditScoreInsertComponent
  ],
  imports: [
    CommonModule,FormsModule,ReactiveFormsModule,
    ProductsRoutingModule,
    NgxPaginationModule,
    NgbDatepickerModule
  ]
})
export class ProductsModule { }
