<div class="card" style="width: 100%;">
    <div class="card-body">
      <div asp-validation-summary="All" class="text-danger"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-sm-2">
            

              <ng-container *ngIf="currentUser?.userName === 'opus.admin'; else showLoanCode">
               <h5> Lead Code:</h5>
              </ng-container>
              <ng-template #showLoanCode>
               <h5>Temporary Account No :</h5> 
              </ng-template>



            </div>
            <div class="col-sm-6">
              <h5 class="card-title" style="color: black">{{ leadCode }}</h5>
            </div>
            <div class="col-sm-4">
              <button class="btn" style="background-color: #616D89;color:white;float: right;" (click)="forwardto(leadCode)">Submit</button>


              <!-- <button class="btn" 
              style="background-color: #616D89; float: right;color:white;" 
              [disabled]="!loanRegisterId" 
              (click)="forwardto(leadCode)">
             Submit
             </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  

  <div style="display: flex; justify-content: space-between;">
    <button class="btnMenu topBtn" style="margin-right: 5px; width: 180px; height: 50px; padding: 10px;"
      [ngClass]="{'active': activeButton === 1}" (click)="setMainTab('1')">Customer
    </button>
   
    <button class="btnMenu topBtn" style="margin-right: 5px; width: 180px; height: 50px; padding: 10px;"
      [ngClass]="{'active': activeButton === 2}" (click)="setMainTab('2')">Loan Register </button>
    <button class="btnMenu topBtn" style="margin-right: 5px; width: 180px; height: 50px; padding: 10px;"
      [ngClass]="{'active': activeButton === 3}" (click)="setMainTab('3')">Loan Guarantor</button>
    
      <!-- <button class="btnMenu topBtn" style="margin-right: 5px; width: 180px; height: 50px; padding: 10px;"
      [ngClass]="{'active': activeButton === 4}" (click)="setMainTab('4')">Co-Lateral Security</button> -->

    <button class="btnMenu topBtn" style="margin-right: 5px; width: 180px; height: 50px; padding: 10px;"
      [ngClass]="{'active': activeButton === 5}" (click)="setMainTab('5')">Document</button>
      <button class="btnMenu topBtn" style="margin-right: 5px; width: 180px; height: 50px; padding: 10px;"
      [ngClass]="{'active': activeButton === 7}" (click)="setMainTab('7')">Consent</button>
      <button class="btnMenu topBtn" style="margin-right: 5px; width: 180px; height: 50px; padding: 10px;"
      [ngClass]="{'active': activeButton === 6}" (click)="setMainTab('6')">E-KYC</button>
  </div>


<!-- Start Customer Info -->
<div class="row" *ngIf="mainTab == '1'">
    <div class="col-md-2">
      <div style="display: flex; flex-direction: column; margin-top: 14px;">
        <button class="btnMenu active" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
          [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('1-1')">Customer Details</button>

        <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
          [ngClass]="{'active': activeSubButton === 2}" (click)="setActiveButton('1-2')"
          [ngClass]="{'active': activeTab === '1-2'}">Customer Contact Details</button>


        <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
          [ngClass]="{'active': activeSubButton === 3}" (click)="setActiveButton('1-3-1')"
          [ngClass]="{'active': activeTab === '1-3-1'}">Customer Address Details</button>

        <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
          [ngClass]="{'active': activeSubButton === 3}" (click)="setActiveButton('1-3-2')"
          [ngClass]="{'active': activeTab === '1-3-2'}">Professional Details</button>

        <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
          [ngClass]="{'active': activeSubButton === 4}" (click)="setActiveButton('1-4')"
          [ngClass]="{'active': activeTab === '1-4'}">Income Details</button>
          
        <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
          [ngClass]="{'active': activeSubButton === 5}" (click)="setActiveButton('1-5')"
          [ngClass]="{'active': activeTab === '1-5'}">Expense Details</button>

        <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
          [ngClass]="{'active': activeSubButton === 6}" (click)="setActiveButton('1-6')"
          [ngClass]="{'active': activeTab === '1-6'}">Bank Account Details</button>

        <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px; padding: 5px;"
          [ngClass]="{'active': activeSubButton === 7}" (click)="setActiveButton('1-7')"
          [ngClass]="{'active': activeTab === '1-7'}">Reference</button>

          
        
          

      </div>
      <div style="background-color: antiquewhite;">
        <svg id="fillgauge1" width="97%" height="250"></svg>
      </div>
    </div>
  
    <div class="col-md-10" style="padding-left: 48px;">
      <ng-container *ngIf="activeTab === '1-1'">
        <app-basic-info-self></app-basic-info-self>
      </ng-container>
      <ng-container *ngIf="activeTab === '1-2'">
        <app-contact-info></app-contact-info>
      </ng-container>
      <ng-container *ngIf="activeTab === '1-3-1'">
        <app-address-info></app-address-info>
      </ng-container>
      <ng-container *ngIf="activeTab === '1-3-2'">
        <app-professional-info></app-professional-info>
      </ng-container>
      <ng-container *ngIf="activeTab === '1-4'">
        <app-income-details></app-income-details>
      </ng-container>
      <ng-container *ngIf="activeTab === '1-5'">
        <app-expense-details></app-expense-details>
      </ng-container>

      <ng-container *ngIf="activeTab === '1-6'">
        <app-bankaccount-details></app-bankaccount-details>
      </ng-container>

      <ng-container *ngIf="activeTab === '1-7'">
        <app-reference-details></app-reference-details>
      </ng-container>

      



    </div>
  
  </div>



  <!-- End Customer Info -->

 

    <div class="row" *ngIf="mainTab == '2'">
        <div class="col-md-2">
          <div style="display: flex; flex-direction: column; margin-top: 14px;">
            <!-- Buttons to display when Button 3 is active -->
            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
              [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('2-1')">Loan Register</button>

            <!-- <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
              [ngClass]="{'active': activeSubButton === 2}" (click)="setActiveButton('2-2')">Home Loan</button>
           
              <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
              [ngClass]="{'active': activeSubButton === 3}" (click)="setActiveButton('2-3')">Vehicle Loan</button> -->
          </div>
        </div>
        <div class="col-md-10" style="padding-left: 48px;">
          <ng-container *ngIf="activeTab === '2-1'">
            <!-- <app-loan-register></app-loan-register> -->
          <app-loan-register (loanRegisterUpdateSuccess)="getLeadInfo()"> </app-loan-register>
          </ng-container>
          <!-- <ng-container *ngIf="activeTab === '2-2'">
            <app-home-loan></app-home-loan>
          </ng-container>
          <ng-container *ngIf="activeTab === '2-3'">
            <app-vehicle-loan></app-vehicle-loan>
          </ng-container> -->
        </div>
      </div>


      <div class="row" *ngIf="mainTab == '3'">
        <div class="col-md-2">
          <div style="display: flex; flex-direction: column; margin-top: 14px;">
            <!-- Buttons to display when Button 3 is active -->
            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
              [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('3-1')">Loan Guarantor</button>
            
              <!-- <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
              [ngClass]="{'active': activeSubButton === 2}" (click)="setActiveButton('3-2')">Business standing</button>

              
            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
              [ngClass]="{'active': activeSubButton === 3}" (click)="setActiveButton('3-3')">Credit Worthiness</button>

            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
              [ngClass]="{'active': activeSubButton === 4}" (click)="setActiveButton('3-4')">Net worth of the applicant</button>
 -->

          </div>
        </div>
        <div class="col-md-10" style="padding-left: 48px;">
          <ng-container *ngIf="activeTab === '3-1'">
            <app-gurantor-info></app-gurantor-info>
          </ng-container>

          <!-- <ng-container *ngIf="activeTab === '3-2'">
            <app-gurantor-business-standing></app-gurantor-business-standing>
          </ng-container>

          <ng-container *ngIf="activeTab === '3-3'">
            <app-credit-worthiness></app-credit-worthiness>
          </ng-container>

          <ng-container *ngIf="activeTab === '3-4'">
            <app-gurantor-networth></app-gurantor-networth>
          </ng-container> -->
          
        </div>
      </div>


      <div class="row" *ngIf="mainTab == '4'">
        <div class="col-md-2">
          <div style="display: flex; flex-direction: column; margin-top: 14px;">
            <!-- Buttons to display when Button 3 is active -->
            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
              [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('4-1')">Security Type
            </button>
           
          </div>
        </div>
        <div class="col-md-10" style="padding-left: 48px;">
          <ng-container *ngIf="activeTab === '4-1'">
            <app-security-type></app-security-type>
          </ng-container>
          
        </div>
      </div>

      <div class="row" *ngIf="mainTab == '5'">
        <div class="col-md-2">
          <div style="display: flex; flex-direction: column; margin-top: 14px;">
            <!-- Buttons to display when Button 3 is active -->
            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
              [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('5-1')">Document
            </button>
           
          </div>
        </div>
        <div class="col-md-10" style="padding-left: 48px;">
          <ng-container *ngIf="activeTab === '5-1'">
            <app-loan-document></app-loan-document>
          </ng-container>
          
        </div>
      </div>

      <div class="row" *ngIf="mainTab == '6'">
        <div class="col-md-2">
          <div style="display: flex; flex-direction: column; margin-top: 14px;">
            <!-- Buttons to display when Button 3 is active -->
            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
              [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('6-1')">E-KYC
            </button>
           
          </div>
        </div>
        <div class="col-md-10" style="padding-left: 48px;">
          <ng-container *ngIf="activeTab === '6-1'">
          <app-loan-ekyc></app-loan-ekyc>
          </ng-container>
          
        </div>
      </div>

      <div class="row" *ngIf="mainTab == '7'">
        <div class="col-md-2">
          <div style="display: flex; flex-direction: column; margin-top: 14px;">
            <!-- Buttons to display when Button 3 is active -->
            <button class="btnMenu" style="margin-bottom: 5px; width: 180px; height: 50px;"
              [ngClass]="{'active': activeSubButton === 1}" (click)="setActiveButton('7-1')">Consent
            </button>
           
          </div>
        </div>
        <div class="col-md-10" style="padding-left: 48px;">
          <ng-container *ngIf="activeTab === '7-1'">
            
<div class="container">
  <!-- <h3 class="card-title" style="margin-top: 3%;color: black;text-align: center;"> eKyc - Customer Registration</h3> -->
  <div class="card">
      <div class="card-body">
         <div class="col-md-10" style="padding-left: 48px;">
          <input type="checkbox" id="consentId" style="transform: scale(1.5)"/> &nbsp;&nbsp;
          <strong style="font-size: 15px;">I consent</strong><br>
                <p>
                  A Terms and Conditions agreement acts as a legal contract between you (the company) and the user. It's where you maintain your rights to exclude users from your app in the event that they abuse your website/app, set out the rules for using your service and note other important details and disclaimers.
                Having a Terms and Conditions agreement is completely optional. No laws require you to have one. Not even the super-strict and wide-reaching General Data Protection Regulation (GDPR).
                Your Terms and Conditions agreement will be uniquely yours. While some clauses are standard and commonly seen in pretty much every Terms and Conditions agreement, it's up to you to set the rules and guidelines that the user must agree to.
                Terms and Conditions agreements are also known as Terms of Service or Terms of Use agreements. These terms are interchangeable, practically speaking. More rarely, it may be called something like an End User Services Agreement (EUSA).
                </p>
                
                <input type="checkbox" style="transform: scale(1.5)" id="termsId" /> &nbsp;&nbsp;
                <strong style="font-size: 15px;">I Agree with the</strong> &nbsp;&nbsp;<a style="color: blue;">Terms and Conditions </a>
               
         </div>
        </div>
      </div>
    </div>
          </ng-container>
          
        </div>
      </div>