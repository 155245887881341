import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommissionService } from '../service/commission.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';
@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.scss']
})
export class CommissionComponent implements OnInit {
  list: any[] = [];
  isEdit: boolean = false;
  frmGroup: FormGroup;

  model: any = {};
  productBuilderTypes = ProductBuilderTypeEnum;
  productTypeId: number=0;
  currentPage = 1;
  itemsPerPage = 5;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm:string='';

  constructor(private _service: CommissionService,private _productService: ProductBuilderService,private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute,private shared:SharedService) 
  { 
    this.getAllCommissionList();
  }

  ngOnInit(): void {
    this.populateForm();
    this.GetAllCommissionList();
    this.showSaveForm='saveList';
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getAllCommissionList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getAllCommissionList();
  }

  onSearch() {
    this.currentPage = 1; 
    this.getAllCommissionList();
  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

  onClickShowSaveForm(value:string){
    this.showSaveForm=value;
    this.getCodeByType(4);
    this.reset();
  }

  getCodeByType(type: number) {
    this._productService.getProductMasterDateCodeByType(type).subscribe(res => {
      this.frmGroup.patchValue({
        code: res['code']
      })
    })
  }
  populateForm(){
    this.frmGroup = this.formBuilder.group({
      id: [0],
      name: [''],
      code: [''],
      amount: [''],
      status: [''],
      productTypeId:[0]
      
    })
  }

  GetAllCommissionList(){
    this._service.GetAllCommissionList().subscribe(res =>{
      this.list = res;
      
    })
  }

  getAllCommissionList() {
    this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  onsubmit(){
    if(this.isEdit){
      this.model.id = this.frmGroup.value.id;
    }    
    this.model.name = this.frmGroup.value.name;
    this.model.code = this.frmGroup.value.code;
    this.model.amount = this.frmGroup.value.amount;
    this.model.productTypeId = this.frmGroup.value.productTypeId;
    this.model.status = (this.frmGroup.value.status==="true")?true:false;
    
    if(this.model.id){
      this._service.SaveCommission(this.model).subscribe(res =>{
        this.shared.ShowAlert("Success","Save Successfully","success");  
        this.isEdit=false;
        this.showSaveForm='saveList';
        this.getAllCommissionList();
        
      })
    }
    else{
      this._service.SaveCommission(this.model).subscribe(res =>{
        this.shared.ShowAlert("Success","Save Successfully","success"); 
       this.getAllCommissionList();
       this.isEdit=false;
       this.showSaveForm='saveList';
      })
    }
  }

  edit(item:any){
    this.isEdit=true;
    this.showSaveForm='saveForm';
    this.frmGroup.patchValue({
      id:item.id,
      code:item.code,
      name:item.name,
      amount:item.amount,
      status:item.status,
      productTypeId:item.productBuilderType
    })
  }

  delete(id:any){
    this._service.DeleteCommission(id).subscribe(res=>{
      this.shared.ShowAlert('Alert','Data deleted successfully.','warning');
      this.getAllCommissionList();
      this.isEdit=false;
      this.showSaveForm='saveList';
    })
  }
}
