import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';
import { SharedService } from 'app/services/shared.service';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { PaymentRequestVm } from 'app/payment/models/payment-request-vm';
import { PaymentService } from 'app/payment/payment.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import Swal from 'sweetalert2'
import { Location } from "@angular/common";
@Component({
  selector: "app-emi-amount-pay",
  templateUrl: "./emi-amount-pay.component.html",
  styleUrls: ["./emi-amount-pay.component.scss"],
})
export class EMIAmountPayComponent implements OnInit {
  frmGroup: FormGroup;
  leadId: 0;
  //collectionAmount: any;
  List: any[] = [];
  model: any = {};
  model2: any = {};
  dueAmount: number;
  selectedProductId: string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private shared: SharedService,
    private _loanProductService: LoanProductService,
    private paymentService: PaymentService,
    private pdfService: PdfService,
    private location: Location
  ) {
    this.frmGroup = this.formBuilder.group({
      leadId: 0,
      outstanding: "",
      collectionAmount: [
        "",
        [Validators.required, Validators.pattern("^[0-9,.]*$")],
      ],
      customername: "",
      leadCode: "",
      accountNo: "",
      productBuilder: "",
      finalOutstanding: "",
      penaltyAmount: "0",
      finalOverDue: "0",
      emiAmount: "0",
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.leadId = params["parameter"];
    });

    // this.getCustomerLeadList(this.leadId);
    this.getCustomerAccountByLeadId();
  }
  goBack() {
    this.location.back();
  }
  getCustomerAccountByLeadId() {
    this._serviceDashboard.getCustomerAccount().subscribe((res: any) => {
      this.List = res;
    });
  }

  getCustomerLeadList(leadId) {
    this._serviceDashboard
      .GetOutStadingByLeadId(leadId)
      .subscribe((res: any) => {
        if (res != null) {
          this.frmGroup.patchValue({
            leadId: res.leadId,
            outstanding: res.outstanding,
            finalOutstanding: res.outstanding,
            penaltyAmount: res.penaltyAmount,
            finalOverDue: res.finalOverDue,
            customername: res.customername,
            leadCode: res.leadCode,
            accountNo: res.accountNo,
            productBuilder: res.productBuilder,
            emiAmount: res.emiAmount,
            //collectionamount: '',
          });
        }
      });
  }

  Savecollectionamount() {
    this.model.leadId = this.frmGroup.value.leadId;
    this.model.installmentAmount = this.frmGroup.value.outstanding;
    /////this.model.collectionAmount = this.frmGroup.value.collectionAmount;

    this.model.collectionAmount = this.frmGroup.value.collectionAmount.replace(
      /,/g,
      ""
    );

 
    //alert(this.frmGroup.value.finalOverDue);
    //alert(this.model.collectionAmount);

    if (this.model.leadId == 0) {
      this.shared.ShowAlert(
        "Warning...",
        "Please select any loan account First!",
        "warning"
      );
    } else if (
      this.model.collectionAmount == null ||
      this.model.collectionAmount == "" ||
      this.model.collectionAmount == 0
    ) {
      this.shared.ShowAlert(
        "Warning...",
        "Please select loan amount First!",
        "warning"
      );
    }
     else if (
       this.frmGroup.value.finalOverDue>0 &&
       this.model.collectionAmount > this.frmGroup.value.finalOverDue
     ) {
       //if (this.model.collectionAmount > this.frmGroup.value.finalOverDue) {
      
       this.shared.ShowAlert(
         "Warning...",
         "You can't pay more than EMI !!",
         "warning"
       );
       //}
     } else if (
       this.frmGroup.value.finalOverDue ==0 &&
       this.model.collectionAmount > this.frmGroup.value.emiAmount
     ) {
      
       this.shared.ShowAlert(
         "Warning...",
         "You can't pay more than EMI !!",
         "warning"
       );
     } else {
      
       Swal.fire({
         title: "Are you sure?",
         text: "Do you want to pay this loan!",
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Yes,pay loan!",
       }).then((res) => {
         if (res.isConfirmed) {
           // this._loanProductService.SaveloanCollectionsbyCustomer(this.model).subscribe((response) => {

           //   this.model2 = response;

           //   console.log(this.model2);

           //   if (this.model2.result === "Failed") {
           //     alert(this.model2.errorMessage)
           //     this.shared.ShowAlert('Sorry...', this.model2.errorMessage, 'warning');
           //   }
           //   else {

           this.dueAmount =
             this.frmGroup.value.outstanding -
             parseFloat(this.frmGroup.value.collectionAmount.replace(/,/g, ""));
           if (this.dueAmount > 0) {
             const paymentRequest: PaymentRequestVm = {
               name: [this.frmGroup.value.customername],
               price: [
                 parseFloat(
                   this.frmGroup.value.collectionAmount.replace(/,/g, "")
                 ),
               ],
               url: ["url1"],
               qty: [1],
               pname: "Loan Collection",
               purl: "Loan Collection",
               pprice: parseFloat(
                 this.frmGroup.value.collectionAmount.replace(/,/g, "")
               ),
               customer_account: this.frmGroup.value.accountNo,
               card_no: "",
               valueType: "collection",
             };
             this.paymentService.checkoutPayment(paymentRequest).subscribe(
               //this.paymentService.checkoutPaymentShurjo(paymentRequest).subscribe(
               (response) => {
                 if (response.gatewayPageURL) {
                   window.location.href = response.gatewayPageURL;
                 } else {
                   console.error("No gatewayPageURL found in the response");
                 }
                 ///------------------------------Shurjo Pay-------------------------
                 // if (response && response.checkout_url) {

                 //   window.location.href = response.checkout_url;
                 // }
                 // else {
                 //   console.error('No CheckOutUrl found in the response');
                 // }
                 ///------------------------------Shurjo Pay-------------------------
               },
               (error) => {
                 console.error("Payment request failed", error);
               }
             );
             //this.shared.ShowAlert('Thank you...', 'Loan payment succesfull!', 'success');
           } else {
             this.shared.ShowAlert(
               "Sorry...",
               "Loan payment Failed",
               "warning"
             );
           }

           //   }
           // })
         } else if (res.isDismissed) {
           this.router.navigate(["/dashboard/emi-amount-pay"]);
         }
       });
     }
  }

  SchedulePDF() {
    this.model.leadId = this.frmGroup.value.leadId;

    if (this.model.leadId > 0) {
      this.pdfService.OpenSchedulePdf(this.model.leadId).subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
    } else {
      this.shared.ShowAlert(
        "Warning...",
        "Please select any loan account First!",
        "warning"
      );
    }
  }

  CollentionPDF() {
    this.model.leadId = this.frmGroup.value.leadId;

    if (this.model.leadId > 0) {
      this.pdfService.OpenCollectionPdf(this.model.leadId).subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
    } else {
      this.shared.ShowAlert(
        "Warning...",
        "Please select any loan account First!",
        "warning"
      );
    }
  }

  formatNumber(value: string): string {
    const formattedValue = value
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  }

  goDashboard() {
    this.router.navigate(["/dashboard/dashboard-new"]);
  }
}
