import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScoretagService } from '../scoretag.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { CustomerService } from 'app/customer/customer.service';
import { Location } from "@angular/common";


@Component({
  selector: "app-bank-beneficiar-create",
  templateUrl: "./bank-beneficiar-create.component.html",
  styleUrls: ["./bank-beneficiar-create.component.scss"],
})
export class BankBeneficiarCreateComponent implements OnInit {
  //model = new BankBenificiary();

  selectedBankName: string | null = null;
  model: any = {};
  isEdit: boolean = false;
  bankInfoList: any = [];
  branchList: any = [];
  list: any = [];
  bankForm: FormGroup;
  id: number;
  bankInfoId: any;
  branchId: any = "";
  AccountName: string;
  customerAccountId: number;
  AccountNumber: any;

  // status: '';
  // accountNumber: string;
  // accountName: string;

  selectedBranchName: string | null = null;
  constructor(
    private _scoreService: ScoretagService,
    private router: Router,
    private shared: SharedService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private _CustomerService: CustomerService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      this.id = params["id"];
    });
    this.buildForm();
    this.getAllBankList();
    //this.GetAllBankBranch();
    this.getBank();
  }

  onBankInfoChange() {
    this.selectedBankName = this.model.bankInfoId
      ? this.bankInfoList.find((item) => item.id === this.model.bankInfoId)
          ?.bankName
      : null;
  }

  getAllBankList() {
    this._scoreService.getAllMasterBankList().subscribe((res) => {
      this.bankInfoList = res;
      this.model.bankInfoId = "";
      this.changeDetectorRef.detectChanges;
    });
  }

  GetAllBankBranchByBankId(selectedBank: any) {

    const bankId = selectedBank.id;

    this._scoreService.GetAllBankBranchByBankId(bankId).subscribe((res) => {
      this.branchList = res;
      this.changeDetectorRef.detectChanges();
    });

    if (bankId == 1) {
      this.loadUserDataByAccountNoCashPayment(this.AccountNumber);
    } else {
     
    }
  }

  // GetAllBankBranchByBankId(bankId: any) {
  //   alert(0);
  //   alert(bankId);
  //   this._scoreService.GetAllBankBranchByBankId(bankId).subscribe((res) => {
  //     this.branchList = res;
  //     //this.model.branchId = '';
  //     this.changeDetectorRef.detectChanges;
  //   });
  //   if (bankId == 1) {
  //     this.loadUserDataByAccountNoCashPayment(this.AccountNumber);
  //   } else {
  //   }
  // }

  loadUserDataByAccountNoCashPayment(AccountNumber: string) {
    this._CustomerService
      .getCustomerByAccountNo(AccountNumber)
      .subscribe((res) => {
        if (res != null) {
          this.AccountName = res.customerNameEng;
          this.customerAccountId = res.id;
        } else {
          swal("Warning", "Invalid Account No", "warning");
        }
      });
  }

  // GetLedgerRelationlist() {
  //   this._scoreService.GetLedgerRelationlist().subscribe(res => {
  //     this.list = res;
  //     //console.log(res)
  //     this.changeDetectorRef.detectChanges;
  //   });

  // }
  onsubmit() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to add!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, save it!",
    }).then((res) => {
      if (res.isConfirmed) {
        this.model.id = this.bankForm.value.id;
        this.model.bankInfoId = this.bankForm.value.bankInfoId;
        this.model.branchId = this.bankForm.value.branchId;
        this.model.ledgerRelationId = this.bankForm.value.ledgerRelationId;

        if (
          this.AccountName == null ||
          this.AccountName == "" ||
          this.AccountName == undefined
        ) {
          this.model.AccountName = this.bankForm.value.AccountName;
        } else {
          this.model.AccountName = this.AccountName;
        }
        this.model.accountNumber = this.bankForm.value.accountNumber;
        this.model.status = this.bankForm.value.status;
        this.model.customerAccountId = this.customerAccountId;

        // this.model.ledgerRelationId = this.bankForm.value.ledgerRelationId;

        this._scoreService.SaveBankBeneficiary(this.model).subscribe((data) => {
          this.shared.ShowAlert("Saved", "Save Successfully", "success");
          this.reset();
          this.router.navigate(["/score/bank-beneficiar-list"]);
        });

        this.changeDetectorRef.detectChanges();
        //this.getBank();
      } else if (res.isDismissed) {
        this.router.navigate(["/score/bank-beneficiar-create"]);
      }
    });
  }

  reset() {
    this.model.bankInfoId = "";
    this.model.branchId = "";
    this.model.AccountName = "";
    this.model.accountNumber = "";
    this.model.status = "";
  }

  buildForm() {
    this.bankForm = this.formBuilder.group({
      id: [0],
      bankInfoId: [0],
      branchId: [""],
      AccountName: [""],
      AccountNumber: [""],
      status: [""],
      ledgerRelationId: [null],
    });
  }

  getBank() {
    this.activeRoute.paramMap.subscribe((params) => {
      const SID = +params.get("id");
  
      //this.id = +params.get('id');
      if (SID != null) {
        //this.isEdit = true;
        this._scoreService.GetbankbenificiaryById(SID).subscribe(
          (res: any) => {
            
            this.selectedBankName =
              this.bankInfoList.find((item) => item.id === res.masterBankId)
                ?.bankName || null;
             
            this.GetAllBankBranchByBankId(res.masterBankId);
            this.bankForm.patchValue({
              id: res.id,
              bankInfoId: res.masterBankId,
              branchId: res.branchId,
              AccountName: res.accountName,
              AccountNumber: res.accountNumber,
              status: res.status,
              ledgerRelationId: res.ledgerRelationId,
            });
          },

          (error: any) => {
            this.shared.ShowAlert("Error", "Failed to retrieve.", "error");
          }
        );
      }
    });
  }
  goBack() {
    this.location.back();
  }
}
