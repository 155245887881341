import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustomerService } from '../customer.service';
import { SharedService } from 'app/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  Location } from "@angular/common";

@Component({
  selector: "app-whitelist-customer",
  templateUrl: "./whitelist-customer.component.html",
  styleUrls: ["./whitelist-customer.component.scss"],
})
export class WhitelistCustomerComponent implements OnInit {
  customerList: any[] = [];
  constructor(
    private customerService: CustomerService,
    private modalService: NgbModal,
    private shared: SharedService,
    private changeDef: ChangeDetectorRef,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getCustomerList();
  }
  goBack() {
    this.location.back();
  }
  getCustomerList() {
    this.customerService.getlistWhiteListedCustomer().subscribe((res) => {
      console.log("fgfgfg");
      console.log(res);
      this.customerList = res;
      this.changeDef.detectChanges();
    });
  }
}
