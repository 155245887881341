import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InterestCalculationService } from '../service/interest-calculation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { ExciseDutyService } from '../service/excise-duty.service';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';

@Component({
  selector: 'app-interest-calculation-type',
  templateUrl: './interest-calculation-type.component.html',
  styleUrls: ['./interest-calculation-type.component.scss']
})
export class InterestCalculationTypeComponent implements OnInit {
  list: any[] = [];
  dlist: any = {};
  transactionlist: any = [];
  isEdit: boolean = false;
  frmGroup: FormGroup;
  model: any = {};
  TaxkeyId: any = 0;
  productTypeId: number = 0;
  productBuilderTypes = ProductBuilderTypeEnum;
  amount: number;
  taxPercent: number;
  type: number | null = 1;
  TaxDetailId: number;

  taxes: { amount: number; taxPercent: number, type: number, TaxDetailId: number }[] = [];
  editingIndex: number = -1;


  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = '';

  constructor(private _service: InterestCalculationService, private _exservice: ExciseDutyService,
    private _productService: ProductBuilderService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private shared: SharedService)
  {
    this.getInterestCalculationTypeList();
  }

  ngOnInit(): void {
    this.populateForm();
    this.reset();
    this.getTransactiontypeList();
    this.showSaveForm = 'saveList';
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getInterestCalculationTypeList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getInterestCalculationTypeList();
  }

  onSearch() {
    this.currentPage = 1;
    this.getInterestCalculationTypeList();
  }

  getTransactiontypeList() {
    this._exservice.gettansactionList().subscribe(res => {
      this.transactionlist = res;
    })
  }
  getCodeByType(type:number){
    this._productService.getProductMasterDateCodeByType(type).subscribe(res=>{
      this.frmGroup.patchValue({
        code:res['code']
      })
    })
  }
  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      name: ['',],
      code: [''],
      transectionTypeId: [''],
      productTypeId:[0]
    })
  }

  GetAllInterestCalculationType() {
    this._service.GetAllInterestCalculationType().subscribe(res => {
      this.list = res;
    })
  }

  getInterestCalculationTypeList() {
    this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = [];
      res.list.forEach(sD => {
        this.list.push(sD);
      })
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  Updatedetails(id: number) {
    this.isEdit = true;
    this._service.GetDetailsByMasterId(id).subscribe(res => {
      this.dlist = res;
      this.taxes = [];
      this.dlist.forEach((item: any) => {
        this.taxes.push({
          amount: item.amount,
          taxPercent: item.taxPercent,
          type: item.type,
          TaxDetailId: item.id
        });
      });
    })

  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.getCodeByType(1);
  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

  edit(item: any) {
    this.isEdit = true;
    this.TaxkeyId = item.instCalType.id
    this.showSaveForm = 'saveForm';
    this.frmGroup.patchValue({
      id: item.instCalType.id,
      name: item.instCalType.name,
      transectionTypeId: item.instCalType.transectionTypeId,
      code: item.instCalType.code,
      productTypeId: item.instCalType.productBuilderType
    });
    this.Updatedetails(this.TaxkeyId);
  }

  delete(id: any) {
    this._service.DeleteInterestCalculationType(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getInterestCalculationTypeList();
      this.isEdit = false;
      this.showSaveForm = 'saveList';
    })
  }

  addTax() {
    if (this.frmGroup.valid) {
      if (this.editingIndex === -1) {
        this.taxes.push({
          amount: this.amount,
          taxPercent: this.taxPercent,
          type: this.type,
          TaxDetailId: this.TaxDetailId

        });
      }
      else {
        this.taxes[this.editingIndex] = {
          amount: this.amount,
          taxPercent: this.taxPercent,
          type: this.type,
          TaxDetailId: this.TaxDetailId
        };
        this.editingIndex = -1;
      }
      this.amount = null;
      this.taxPercent = null;
      this.type = 1;
    }
  }

  editTax(index: number) {
    this.editingIndex = index;
    this.amount = this.taxes[index].amount;
    this.taxPercent = this.taxes[index].taxPercent;
    this.type = this.taxes[index].type;
    this.TaxDetailId = this.taxes[index].TaxDetailId;
  }

  deleteTax(index: number) {
    this.taxes.splice(index, 1);
    if (index === this.editingIndex) {
      this.editingIndex = -1;
      this.amount = null;
      this.taxPercent = null;
      this.type = null;
    }
  }

  prepareDataForAPI() {
    const formData = this.frmGroup.value;
    formData.interestDetails = this.taxes;
    return formData;
  }

  onSubmit() {
    if (this.frmGroup.valid) {
      const keydetails = this.prepareDataForAPI();
      this._service.SaveInterestCalculationType(keydetails).subscribe(
        (res: any) => {
          if (res === null) {
            this.shared.ShowAlert("Error", "No Information Given for Details.", "error");
          }
          else {
            this.shared.ShowAlert("Success", "Save Successfully", "success");
            this.getInterestCalculationTypeList();
            this.isEdit = false;
            this.showSaveForm = 'saveList';
          }
        },
        error => {
          console.log(error);
          this.shared.ShowAlert("Error", "Failed to save data.", "error");
        }
      );
    }
  }
}
