<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h5 class="card-title" style="color: black">Loan Limit Create</h5>
                </div>
                <div class="col" style="text-align: right;">
                    <button type="button" data-toggle="tooltip" [routerLink]="['/products/loan-limit-list']" class="btn btn-info"
                        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Go to List</button>
                </div>
            </div>
            <hr>
            <form [formGroup]="ngForm">
                <!-- <form #limitForm="ngForm"> -->
                <div asp-validation-summary="All" class="text-danger"></div>
                
                
                <div class="row">
                    <!-- <div class="col-md-6">
                        <div class="form-group row">
                            <label for="Version" class="col-sm-4 col-form-label">Product Name</label>
                            <div class="col-sm-8">
                                <select class="form-control" id="loanProductId" name="loanProductId" [(ngModel)]="llModel.loanProductId" #loanProductId="ngModel" [ngClass]="{'is-invalid': loanProductId.invalid && (loanProductId.dirty || loanProductId.touched || limitForm.submitted),
                                'is-valid': loanProductId.valid && (loanProductId.dirty || loanProductId.touched || limitForm.submitted)}" required="required">
                                    <option value="">Select Product</option>
                                    <option *ngFor="let item of productList" [value]="item.id">{{item.name}}
                                    </option>
                                </select>
                                <span class="text-danger" *ngIf="loanProductId.invalid && (loanProductId.dirty || loanProductId.touched || limitForm.submitted) && loanProductId.errors?.required">Product can't be blank</span>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="Version" class="col-sm-4 col-form-label">Product Name</label>
                            <div class="col-sm-8">
                                <select class="form-control" id="loanProductId" name="loanProductId" [(ngModel)]="llModel.loanProductId" #loanProductId="ngModel" [ngClass]="{'is-invalid': loanProductId.invalid && (loanProductId.dirty || loanProductId.touched),
                                'is-valid': loanProductId.valid && (loanProductId.dirty || loanProductId.touched)}" required="required">
                                    <option value="">Select Product</option>
                                    <option *ngFor="let item of productList" [value]="item.id">{{item.name}}
                                    </option>
                                </select>
                                <span class="text-danger" *ngIf="loanProductId.invalid && (loanProductId.dirty || loanProductId.touched) && loanProductId.errors?.required">Product can't be blank</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="amount" class="col-sm-4 col-form-label">Amount</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" id="amount" name="amount"
                                [(ngModel)]="llModel.amount" required="required" pattern="^[0-9]*$"   placeholder="Limit Amount"
                                #amount="ngModel" #prjId [ngClass]="{'is-invalid': amount.invalid && (amount.dirty || amount.touched),
                        'is-valid': amount.valid && (amount.dirty || amount.touched)}">

                        <span class="text-danger" *ngIf="amount.invalid && (amount.dirty || amount.touched) && amount.errors?.required">Amount can't be blank</span>
                            </div>
                        </div>

                    </div>
                </div>
 

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="maxAmount" class="col-sm-4 col-form-label">Max Amount</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" id="maxAmount" name="maxAmount"
                                [(ngModel)]="llModel.maxAmount" required="required" pattern="^[0-9]*$"   placeholder="Max Amount"
                                #maxAmount="ngModel" #prjId [ngClass]="{'is-invalid': maxAmount.invalid && (maxAmount.dirty || maxAmount.touched),
                                'is-valid': maxAmount.valid && (maxAmount.dirty || maxAmount.touched)}">

                        <span class="text-danger" *ngIf="maxAmount.invalid && (maxAmount.dirty || maxAmount.touched) && maxAmount.errors?.required">Max Amount can't be blank</span>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="minAmount" class="col-sm-4 col-form-label">Min Amount</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" id="minAmount" name="minAmount"
                                [(ngModel)]="llModel.minAmount" required="required" pattern="^[0-9]*$" placeholder="Min Amount"
                                #minAmount="ngModel" #prjId [ngClass]="{'is-invalid': minAmount.invalid && (maxAmount.dirty || maxAmount.touched),
                                'is-valid': maxAmount.valid && (minAmount.dirty || minAmount.touched)}">
                                <span class="text-danger" *ngIf="minAmount.invalid && (minAmount.dirty || minAmount.touched) && minAmount.errors?.required">Min Amount can't be blank</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="creditMaxScore" class="col-sm-4 col-form-label">Credit Max Score</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" id="creditMaxScore" name="creditMaxScore"
                                [(ngModel)]="llModel.creditMaxScore" required="required" pattern="^[0-9]*$" placeholder="Credit Max Score"
                                #creditMaxScore="ngModel" #prjId [ngClass]="{'is-invalid': creditMaxScore.invalid && (creditMaxScore.dirty || creditMaxScore.touched),
                                'is-valid': creditMaxScore.valid && (creditMaxScore.dirty || creditMaxScore.touched )}">
                                <span class="text-danger" *ngIf="creditMaxScore.invalid && (creditMaxScore.dirty || creditMaxScore.touched) && creditMaxScore.errors?.required">Credit Max Score can't be blank</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="creditMinScore" class="col-sm-4 col-form-label">Credit Min Score</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" id="creditMinScore" name="creditMinScore"
                                [(ngModel)]="llModel.creditMinScore" required="required" pattern="^[0-9]*$" placeholder="Credit Min Score"
                                #creditMinScore="ngModel" #prjId [ngClass]="{'is-invalid': creditMinScore.invalid && (creditMinScore.dirty || creditMinScore.touched),
                                'is-valid': creditMinScore.valid && (creditMinScore.dirty || creditMinScore.touched)}">
                                <span class="text-danger" *ngIf="creditMinScore.invalid && (creditMinScore.dirty || creditMinScore.touched) && creditMinScore.errors?.required">Credit Min Score can't be blank</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="activeDate" class="col-sm-4 col-form-label">Active Date</label>
                            <div class="col-sm-8">
                                <input type="date" class="form-control" id="activeDate" name="activeDate"
                                [(ngModel)]="llModel.activeDate" placeholder="Active Date">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="expireDate" class="col-sm-4 col-form-label">Expire Date</label>
                            <div class="col-sm-8">
                                <input type="date" class="form-control" id="expireDate" name="expireDate"
                                [(ngModel)]="llModel.expireDate"  placeholder="Expire Date">
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="productAmount" class="col-sm-4 col-form-label">Product Amount</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" id="productAmount" name="productAmount"
                                [(ngModel)]="llModel.productAmount" required="required" pattern="^[0-9]*$"   placeholder="Product Amount"
                                #productAmount="ngModel" #prjId [ngClass]="{'is-invalid': productAmount.invalid && (productAmount.dirty || productAmount.touched),
                              'is-valid': productAmount.valid && (productAmount.dirty || productAmount.touched)}">

                        <span class="text-danger" *ngIf="productAmount.invalid && (productAmount.dirty || productAmount.touched) && productAmount.errors?.required">Product Amount can't be blank</span>
                            </div>
                        </div>

                    </div>
                    <!-- <div class="col-md-6">
                        <div class="form-group row">
                            <label for="personAmount" class="col-sm-4 col-form-label">Person Amount</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" id="personAmount" name="personAmount"
                                [(ngModel)]="llModel.personAmount" required="required" pattern="^[0-9]*$"   placeholder="Person Amount"
                                #personAmount="ngModel" #prjId [ngClass]="{'is-invalid': personAmount.invalid && (personAmount.dirty || personAmount.touched),
                                'is-valid': personAmount.valid && (personAmount.dirty || personAmount.touched)}">

                        <span class="text-danger" *ngIf="personAmount.invalid && (personAmount.dirty || personAmount.touched) && personAmount.errors?.required">Person Amount can't be blank</span>
                            </div>
                        </div>

                    </div> -->
                </div>
               
                <button data-toggle="tooltip" title="Save" type="submit"
                    (click)="onsubmit()" value="Submit" class="btn btn-success"
                    style="float:right; margin-top:5px;">
                    <span>Save</span>
                </button>
                <button type="button" data-toggle="tooltip" title="Refresh" (click)="reset()"
                    class="btn btn-secondary"
                    style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
            </form>
        </div>
    </div>
</div>
