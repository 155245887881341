import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { DaySizeService } from '../service/day-size.service';
import { ProductBuilderTypeEnum } from '../model/productBuilderTypeEnum';

@Component({
  selector: 'app-day-size',
  templateUrl: './day-size.component.html',
  styleUrls: ['./day-size.component.scss']
})
export class DaySizeComponent implements OnInit {

  daySizeList: any[] = [];
  isEdit: boolean = false;
  daySizeFromGrp: FormGroup;

  daySizeModel: any = {};
  productBuilderTypes = ProductBuilderTypeEnum;
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = '';

  constructor(private formBuilder: FormBuilder,
    private shared: SharedService,
    private _daySizeService: DaySizeService
  ) 
  {

  }

  ngOnInit() {
    this.daySizeForm();
    this.getAllDaySizeList();
    this.showSaveForm = 'saveList';
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getAllDaySizeList();
  }
  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getAllDaySizeList();
  }

  onSearch() {
    this.currentPage = 1;
    this.getAllDaySizeList();
  }

  calculateAndSetDivision(): void {
    const upValue = this.daySizeFromGrp.get('upMark').value;
    const downValue = this.daySizeFromGrp.get('downMark').value;
    const divisionResult = this.calculateDivision(upValue, downValue);
    if(upValue===''){
      this.daySizeFromGrp.get('size').patchValue(0);
    }
    else if(downValue===''){
      this.daySizeFromGrp.get('size').patchValue(0);
    }
    else{
      this.daySizeFromGrp.get('size').patchValue(divisionResult);
    }
  }

  calculateDivision(upValue: number, downValue: number): number {
    if (downValue === 0) {
      return 0;
    }
    else if(upValue === 0){
      return 0;
    }
    else{
      const result = upValue / downValue;
      return parseFloat(result.toFixed(2));
    }
  }
  daySizeForm() {
    this.daySizeFromGrp = this.formBuilder.group({
      id: [0],
      name: [''],
      size: [''],
      remarks: [''],
      status: [''],
      upMark: [''],
      downMark: [''],
      productTypeId:[0]
    })
  }

  refresh() {
    this.isEdit = false;
    this.daySizeForm();
    this.daySizeModel = {};
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();
  }

  getDaySizeList() {
    this._daySizeService.getDaySizeList().subscribe(res => {
      this.daySizeList = res;
    })
  }
  getAllDaySizeList() {
    this._daySizeService.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.daySizeList = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }
  onSubmit() {
    if (!this.daySizeFromGrp.value.upMark || !this.daySizeFromGrp.value.downMark) {
      this.shared.ShowAlert("Error", "UpMark and DownMark are required", "error");
      return;
    }

    if (this.isEdit) {
      this.daySizeModel.id = this.daySizeFromGrp.value.id;
    }
    this.daySizeModel.name = this.daySizeFromGrp.value.name;
    this.daySizeModel.remarks = this.daySizeFromGrp.value.remarks;
    this.daySizeModel.upMark = this.daySizeFromGrp.value.upMark.toString();
    this.daySizeModel.downMark = this.daySizeFromGrp.value.downMark.toString();
    this.daySizeModel.size = this.daySizeFromGrp.value.size;
    this.daySizeModel.productTypeId = this.daySizeFromGrp.value.productTypeId;
    this.daySizeModel.status = (this.daySizeFromGrp.value.status === "true") ? true : false;
    this._daySizeService.saveDaySize(this.daySizeModel).subscribe(res => {
      this.shared.ShowAlert("Success", "Save Successfully", "success");
      this.getAllDaySizeList();
      this.refresh();
      this.showSaveForm = 'saveList';
    })
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.daySizeFromGrp.patchValue({
      id: item.id,
      name: item.name,
      size: item.size,
      remarks: item.remarks,
      status: item.status,
      upMark: item.upMark,
      downMark: item.downMark,
      productTypeId:item.productBuilderType
    })
  }

  delete(id: any) {
    this._daySizeService.deleteDaySize(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getAllDaySizeList();
      this.showSaveForm = 'saveList';
    })
  }

}
