import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoanRegister } from '../model/loan-register';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { LoanLimitService } from 'app/master-data/products/service/loan-limit.service';
import { CustommeraccountService } from 'app/customer-account/custommeraccount.service';
import { LoanapplicationApprovalService } from 'app/loanapplication-approval/loanapplication-approval.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { CustomerService } from 'app/customer/customer.service';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: "app-loan-register",
  templateUrl: "./loan-register.component.html",
  styleUrls: ["./loan-register.component.scss"],
})
export class LoanRegisterComponent implements OnInit {
  @Output() loanRegisterUpdateSuccess: EventEmitter<void> =
    new EventEmitter<void>();
  model: any = {};
  customerId: any = 0;
  productId: any = 0;
  tenureId: any = 0;
  frmGroupLoanReg: FormGroup;
  leadCode: any;
  leadId: any = 0;
  loanRegId: number;
  loanRegInfoId: any;
  isLoanRegInfoEdit = false;
  productDetails: any = [];
  loanScheduleModel:any[]=[];
  creditScoreInfo: any = {};

  loanLimitDetails: any;
  customerAccountList: any = [];
  lstCharge: any = [];
  customerAccountId: number;
  loanRegisterId: number;

  currentUser: any;
  currentUserTypeId: number;
  insRate: any;

  monthDuration: number;
  loanAmnt: any = 0;
  //frequency: any=1;
  installmentSize: number;
  interestAmount: any;

  processingFeesInfo: any;
  otherFeesInfo: any;
  processingFee: number = 0;
  otherFee: number = 0;
  submitted = false;
  isValidAmount: boolean = true;
  msgLoanAmt: string;
  firstInstallmentDate: any;
  businessDate: any;
  durationName: any;
  mfsAccountList: any[];
  processingCharge: any;
  closeResult: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private shared: SharedService,
    private datePipe: DatePipe,
    private _leadGennarationService: LeadGenerationServiceService,
    private _loanProduct: LoanProductService,
    private _loanLimitService: LoanLimitService,
    private _customerService: CustommeraccountService,
    private loanService: LoanapplicationApprovalService,
    private layoutService: LayoutService,
    private _CustomerService: CustomerService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.getCurrentBusinessDate();
    this.LoanRegpopulateForm();
    this.getMFSList();
    this.route.paramMap.subscribe((params) => {
      this.customerId = parseInt(params.get("customersId"), 10);
      this.leadId = parseInt(params.get("leadId"), 10);
      this.productId = parseInt(params.get("loanProductsId"), 10);
      this.tenureId = parseInt(params.get("tenureId"), 10);
    });
    this.loanScheduleModel=null;
    this.loanScheduleModel=[
      {
          "sl": 1,
          "principleAmount": 2344,
          "interestAmount": 356,
          "emiAmount": 2700,
          "emiDate": "2024-03-12T00:00:00"
      }
  ];
    // this.LoanProductAmountFromLoanLimit(this.productId);
    // this.getLeadInfo(this.leadId);
    //this.getLoanRegisterId(this.leadId);
    //this.ProcessingFeesInfoByLoanProductId(this.productId);
    //this.OtherFeesInfoByLoanProductId(this.productId);

    this.LoanProductInfoById(this.productId);
    this.GetCustomerAccountList(this.customerId);
    this.GetLoanRegistersById(this.leadId);
    this.getUserTypeId();
  }

  LoanRegpopulateForm() {
    this.frmGroupLoanReg = this.formBuilder.group({
      id: [0],
      customerId: this.customerId,

      loanAmount: [
        "0",
        [
          Validators.required,
          Validators.pattern("^[0-9,.]*$"),
          Validators.min(this.creditScoreInfo.minAmount),
          Validators.max(this.creditScoreInfo.maxAmount),
        ],
      ],
      //loanAmount:  ['', [Validators.required, Validators.pattern('^[0-9,.]*$')],Validators.min(this.creditScoreInfo.minAmount),Validators.max(this.creditScoreInfo.maxAmount)],
      //loanAmount:  ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      loanNature: ["Regular", Validators.required],
      installmentSize: [0, Validators.required],
      interestAmount: [0, Validators.required],
      frequency: ["1"],

      accountTypes: ["", Validators.required],
      disburselinkingAccountId: [null],

      // customerAccountId: [0],
      //disburselinkingAccountId: [0],
      tenureId: [""],
      interestRate: [0],
      leadId: this.leadId,
      ledgerRelationId: [null],
      mfsNumber: [""],
      firstInstallmentDate: ["", [], [this.dateValidator()]],
  
    });
  }

  getCurrentBusinessDate() {
    return this.layoutService.getCurrentBusinessDate().pipe(
      map((res: any) => {
        const transformedDate = this.datePipe.transform(
          res.business_Date,
          "yyyy-MM-dd"
        );
        return new Date(transformedDate);
      })
    );
  }

  dateValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.getCurrentBusinessDate().pipe(
        map((businessDate) => {
          const selectedDate = new Date(control.value);
          if (
            selectedDate < businessDate ||
            selectedDate >
              new Date(
                businessDate.getFullYear(),
                businessDate.getMonth() + 2,
                businessDate.getDate()
              )
          ) {
            return { invalidDate: true };
          }

          return null;
        })
      );
    };
  }

  get productAmount(): number {
    return this.loanLimitDetails?.personAmount || 0;
  }

  get loanAmount(): number {
    return this.frmGroupLoanReg.get("loanAmount")?.value || 0;
  }

  getLoanAmountValue(event: any) {
    this.loanAmnt = String(event.target.value);
  }

  isLoanAmountValid(): boolean {
    const loanAmountWithoutCommas = String(this.loanAmnt).replace(/,/g, "");
    const loanAmount = parseFloat(loanAmountWithoutCommas);

    if (loanAmount > this.creditScoreInfo.maxAmount) {
      this.isValidAmount = false;
      this.msgLoanAmt =
        "Loan amount can't exceed " + this.creditScoreInfo.maxAmount;
    } else if (loanAmount < this.creditScoreInfo.minAmount) {
      this.isValidAmount = false;
      this.msgLoanAmt =
        "Loan amount must be greater than " + this.creditScoreInfo.minAmount;
    } else {
      this.isValidAmount = true;
      this.msgLoanAmt = "";
    }
    return this.isValidAmount;
  }

  formatNumber(value: string): string {
    const formattedValue = value
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  }

  GetCustomerAccountList(customerId: number) {
    this._customerService
      .getCustomerAccountListByCustomerId(customerId)
      .subscribe((res) => {
        this.customerAccountList = res;
        this.customerAccountList = this.customerAccountList.filter(
          (item) => item.accountType === "deposit"
        );
        this.customerAccountId = 0;
        this.changeDetectorRef.detectChanges();
      });
  }

  LoanProductAmountFromLoanLimit(productId: number) {
    this._loanLimitService
      .getLoanLimitInfoByProductId(productId)
      .subscribe((res) => {
        this.loanLimitDetails = res;
        this.changeDetectorRef.detectChanges();
      });
  }

  LoanProductInfoById(productId: number) {
   
    this._loanProduct
      .getLoanProductBuilderDetailsById(productId)
      .subscribe((res) => {
        this.productDetails = res;
        this.insRate = res.productInfo?.interestRate;

        this.creditScoreInfo = this.productDetails.creditScoreInfo;
        this.lstCharge = this.productDetails.charges;

        const chargeWithId1 = this.lstCharge.find((charge) => charge.id === 1);
        this.processingCharge = chargeWithId1.amount;
          

      const loanAmounts = parseFloat(this.loanAmount.toString().replace(/,/g, ""));

        if (loanAmounts > 0) {
          this.processingFee = loanAmounts * (this.processingCharge / 100);
        }


        if (productId === 26) {
          this._loanProduct
            .getTenureInfoByTenureId(this.tenureId)
            .subscribe((res) => {
              this.monthDuration = res.duration?.months;
              this.durationName = res.duration?.monthName;

              ////this.getMonthFromProductDetails(res.duration?.monthName);
            });
        } else {
          this.monthDuration =
            this.productDetails?.productInfo?.loanDuration?.months;
          this.durationName =
            this.productDetails?.productInfo?.loanDuration?.monthName;
          //this.getMonthFromProductDetails(this.productDetails?.productInfo?.loanDuration?.monthName);
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  GetLoanRegistersById(leadId: number) {
   
    this._leadGennarationService
      .GetLoanRegistersById(this.leadId)
      .subscribe((loanRegister: LoanRegister) => {

        if (loanRegister.mfsType !== null) {
          this.frmGroupLoanReg.patchValue({
            accountTypes: "mfs",
          });
        } else if (loanRegister.disburselinkingAccountId !== null) {
          this.frmGroupLoanReg.patchValue({
            accountTypes: "disburse",
          });
        } else {
          this.frmGroupLoanReg.patchValue({
            accountTypes: "",
          });
        }

        if (loanRegister && loanRegister.id >0) {
           this.LoanProductInfoById(this.productId);
          this.isLoanRegInfoEdit = true;
          this.frmGroupLoanReg.patchValue({
            id: loanRegister.id,
            customerId: this.customerId,
            loanAmount: loanRegister.loanAmount.toLocaleString(),
            loanNature: loanRegister.loanNature,
            installmentSize: loanRegister.installmentSize,
            interestAmount: loanRegister.interestAmount,
            frequency: loanRegister.frequency,
            disburselinkingAccountId: loanRegister.disburselinkingAccountId,
            leadGenerationId: loanRegister.leadGenerationId,
            interestRate: loanRegister.interestRate,
            firstInstallmentDate: this.datePipe.transform(
              loanRegister.firstInstallmentDate,
              "yyyy-MM-dd"
            ),
            mfsType: loanRegister.mfsType,
            mfsNumber: loanRegister.mfsNumber,
            ///disburselinkingAccountId: loanRegister.disburselinkingAccountId,
            ledgerRelationId: loanRegister.ledgerRelationId,

            // securityType: loanRegister.securityType,
            // securityValue: loanRegister.securityValue,
            //expiryDate: this.datePipe.transform(loanRegister.expiryDate, 'yyyy-MM-dd'),
          });
  
        } else {
          this.LoanRegpopulateForm();
        }
      });
  }

  // getMonthFromProductDetails(monthName: any) {
  //    alert(monthName)
  //   if (monthName) {
  //     var extractedMonth = monthName.match(/\d+/);
  //     if (extractedMonth) {
  //       this.monthDuration = extractedMonth;

  //       console.log(extractedMonth);
  //     } else {
  //       console.log("No numeric value found in monthName");
  //     }
  //   } else {
  //     console.log("monthName is not defined");
  //   }
  // }

  calculateLoanDetails() {
    //alert(this.monthDuration)
    const loanAmount = parseFloat(this.loanAmnt.replace(/,/g, ""));
    this.interestAmount = parseFloat(
      ((loanAmount * this.insRate) / 100).toFixed(2)
    );

    const totalAmount = loanAmount + this.interestAmount;
    this.installmentSize = parseFloat(
      (totalAmount / this.monthDuration).toFixed(2)
    );
    const processingFee = loanAmount * (this.processingCharge / 100);
    this.processingFee = processingFee;
  }

  getUserTypeId() {
    this.layoutService.getCurrentUserName().subscribe((res) => {
      this.currentUser = res;
      this.currentUserTypeId = res.userTypeId;
      this.changeDetectorRef.detectChanges();
    });
  }

  LoanRegreset() {
    this.LoanRegpopulateForm();
    this.model = {};
  }

  onLoanRegSubmit() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save Loan Register Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Register it!",
    }).then((res) => {
      if (res.isConfirmed) {
        if (this.customerId > 0) {
          this.model.id = this.frmGroupLoanReg.value.id;
          // this.model.customerId = this.frmGroupLoanReg.value.customerId;
          this.model.loanAmount = this.frmGroupLoanReg.value.loanAmount.replace(
            /,/g,
            ""
          );
          this.model.loanNature = this.frmGroupLoanReg.value.loanNature;
          this.model.installmentSize = this.installmentSize;
          this.model.interestAmount = this.interestAmount;
          this.model.frequency = "1";
          ///this.model.customerAccountId = this.frmGroupLoanReg.value.customerAccountId;
          this.model.disburselinkingAccount =
            this.frmGroupLoanReg.value.disburselinkingAccount;
          this.model.customerId = this.customerId;
          this.model.leadId = this.leadId;
          //this.model.leadGenerationId = this.leadId;
          this.model.productId = this.productId;
          this.model.tenureId = this.tenureId;
          this.model.ledgerRelationId =
            this.frmGroupLoanReg.value.ledgerRelationId || null;
          this.model.mfsNumber = this.frmGroupLoanReg.value.mfsNumber;
          this.model.firstInstallmentDate =
            this.frmGroupLoanReg.value.firstInstallmentDate;

          const lstChargeInfo = this.lstCharge.map((charge) => ({
            loanChargeId: charge.id,
            FeeName: charge.name,
            amount: charge.amount,
          }));

          this.model.lstChargeInfo = lstChargeInfo;

          if (
            this.frmGroupLoanReg.value.firstInstallmentDate === null ||
            this.frmGroupLoanReg.value.firstInstallmentDate === ""
          ) {
            this.shared.ShowAlert(
              "Warning...",
              "First Installment Date is required!",
              "warning"
            );
          } else if (this.frmGroupLoanReg.value.accountTypes === "") {
            this.shared.ShowAlert(
              "Warning...",
              "Select Disburse linking or MFS account!",
              "warning"
            );
          } else {
            this.model.interestRate =
              this.frmGroupLoanReg.value.interestRate != ""
                ? this.frmGroupLoanReg.value.interestRate
                : this.insRate;
            this._leadGennarationService
              .saveLoanRegister(this.model)
              .subscribe((res: any) => {
                this.loanRegId = res;
                if (this.loanRegId > 0) {
                  this.shared.ShowAlert(
                    "Thank you...",
                    "Updated succesfully!",
                    "success"
                  );
                  this.getLoanRegisterId(this.leadId);
                  this.GetLoanRegistersById(this.leadId);
                  this.loanRegisterUpdateSuccess.emit();
                } else {
                  this.shared.ShowAlert(
                    "Thank you...",
                    "Saved succesfully!",
                    "success"
                  );
                  this.getLoanRegisterId(this.leadId);
                  this.loanRegisterUpdateSuccess.emit();
                }
                //his.getLeadInfo(this.leadId);
              });
          }
        } else {
          this.shared.ShowAlert(
            "Warning...",
            "First Create a Customer application!",
            "warning"
          );
          //this.getLeadInfo();
        }
      } else if (res.isDismissed) {
        this.router.navigate([
          "/loan-application/loan-application/" +
            this.leadId +
            "/" +
            this.customerId +
            "/" +
            1 +
            "/" +
            1,
        ]);
      }
    });
  }

  getLoanRegisterId(leadId: number) {
    this.loanService.getLoanregisterbyLeadId(leadId).subscribe((res: any) => {
      this.loanRegisterId = res;
      this.changeDetectorRef.detectChanges();
    });
  }

  getMFSList() {
    this._loanProduct.getMFSAccountList().subscribe((res) => {
      this.mfsAccountList = res;
      this.changeDetectorRef.detectChanges();
    });
  }

  getLoanSchedulePreview() {
    let amount = this.frmGroupLoanReg.value.loanAmount.replace(/,/g,"");
    let date = this.frmGroupLoanReg.value.firstInstallmentDate;
    this._leadGennarationService.GetLoanSchedulePreview(this.productId,amount,this.tenureId,date).subscribe((res: any) => {
      this.loanScheduleModel = res.schedules;
       this.changeDetectorRef.detectChanges();
    });
  }

  openLoanScheduleModal(content) {
    
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  //  AddBenificiary(){
  //   this.router.navigate(['/score/bank-beneficiar-create']);
  // }
}
