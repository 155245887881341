<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header row" >
              
            <div class="col-10">
                <h5 class="card-title" style="color: #888888;font-family: Arial;font-size: 20px !important;">Ledger Book</h5>
            </div>
            
            <div class="col-2">
                <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
            
                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                </button>
            </div>
              </div>
          <hr>
            <div class="card-body">
                <div class="col-md-12">
                    <div class="form-group row" id="ledgerId">
                        <label for="ledgerId" class="col-sm-3 col-form-label">Select Ledger</label>
                        <div class="col-sm-9">
                            <select type="text" class="form-control" (change)="onLedgerChange($event.target.value)" id="ledgerId">
                                <option value="0">Select</option>
                                <option *ngFor="let item of AccountNoList | async" [value]="item.id">{{item.ledger?.accountName}} -
                                    {{item.ledger?.accountCode}}</option>
                              
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="subledgerStatus === 'Show'"  id="LedgerProject">
                        <label for="ledgerId" class="col-sm-3 col-form-label">Select Sub Ledger</label>
                        <div class="col-sm-9">
                            <select type="text" (change)="onSubLedgerChange($event.target.value)" class="form-control" id="SubledgerId">
                                <option value="0">Select</option>
                                <option *ngFor="let item of AccountSubLedgerList" [value]="item.id">{{item.subLedger?.accountName}} - {{item.subLedger?.accountCode}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" id="DateRangeSelect">
                        <label for="fromDate" class="col-sm-3 col-form-label text-left">From Date <span class="redStar">*</span></label>
                        <div class="col-sm-3 input-group">
                           
                                <input type="date" class="form-control" [(ngModel)]="fromDate" placeholder="Select From Date">
                
                           
                            
                        </div>
                        <label for="toDate" class="col-sm-2 col-form-label text-left">To Date <span class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">
                            <input type="date" class="form-control" [(ngModel)]="toDate" placeholder="Select From Date">
                        </div>
                    </div>
                  

                    <div class="form-group row" style="margin-top:10px;">
                        <label class="col-sm-8"></label>
                        <div class="col-sm-4">
                            <button  class="btn btn-success" style="margin-left: 5px; float: right" (click)="OpenPDF()">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />