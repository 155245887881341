import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@microsoft/signalr';
import { SharedService } from 'app/services/shared.service';
import { ProfessionalDetailService } from '../professional-detail.service';
import { ProfessionalDetail } from '../model/professional-detail';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { CustomerService } from 'app/customer/customer.service';
import swal from 'sweetalert';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-professional-info',
  templateUrl: './professional-info.component.html',
  styleUrls: ['./professional-info.component.scss']
})
export class ProfessionalInfoComponent implements OnInit {

  @Output() professionUpdateSuccess: EventEmitter<void> = new EventEmitter<void>();

  customerDetailsInfos:any;
  model: any = {};
  selfprofList: any = [];
  clientInfoId: any = 0;
  leadId: any = 0;
  frmGroup1: FormGroup;
  frmGroup2: FormGroup;
  frmGroup3: FormGroup;
  customersId: any = 0;
  isAuto: boolean=false;
  isClientInfoEdit: boolean = false;
  profileUpdate: any;
  isDataNull:boolean;
  isvalid: boolean = true;
  isBtnValid: boolean = true;
  
  
  constructor(private formBuilder: FormBuilder,
              private router: Router, 
              private shared: SharedService, 
              private activeRoute: ActivatedRoute,
              private _serviceProfessionalDetail:ProfessionalDetailService,
              private _leadGennarationService: LeadGenerationServiceService,
              private customerService: CustomerService,
              private changeDetectorRef: ChangeDetectorRef, 
  
  ) { }

  ngOnInit(): void {
    this.GetCustomerDetailsForEdit();
    this.getselfemployeedtypeList();
    this.serviceholderForm();
    this.selfEmployeedForm();
   
    this.businessForm();
    this.professionalReset();
    //this.getLeadInfo();
    this.activeRoute.paramMap.subscribe(params => {
      if(params.get('id')==null){
        this.customerService.GetCustomerDetailsForEdit().subscribe((data:any)  => {
          this.customersId = data.customerDetailsInfos.customerId;
          this.LoadProfessionalInfoGrp1(this.customersId);
          this.LoadProfessionalInfoGrp2(this.customersId);
          this.LoadProfessionalInfoGrp3(this.customersId);
      })
      }else{
        this.customersId = parseInt(params.get('id'), 10);
        this.LoadProfessionalInfoGrp1(this.customersId);
        this.LoadProfessionalInfoGrp2(this.customersId);
        this.LoadProfessionalInfoGrp3(this.customersId);
      }
      
      
      
    });

 
  }
  
  LoadProfessionalInfoGrp1(customersId:number){

    this._serviceProfessionalDetail.GetLeadGenerationByLeadNoforProfessional(customersId).subscribe(res =>{
    
      if(res != null){
        

        this.isAuto=true;
       
        this.frmGroup1.patchValue({
          id:res.id,
          customerId: res.customerId,
          
          //leadId: res.id,
         
          PresentEmployersName: res.presentEmployersName,
          Presentcompany: res.presentcompany,
          Presentbusinessnature: res.presentbusinessnature,
          PresentDesignation: res.presentDesignation,
          Presentlengthofservice: res.presentlengthofservice,
          PresentofficeTel: res.presentofficeTel,
          Presentofficeaddress: res.presentofficeaddress,
            PrevEmployersName: res.prevEmployersName,
            PrevDesignation: res.prevDesignation,
            PrevofficeTel: res.prevofficeTel,
            PrevAddress: res.prevAddress,
            Prevlengthofservice: res.prevlengthofservice,
            totalexperience: res.totalexperience,

         
        });
       
      }
      else{
        this.isDataNull = true;
        this.frmGroup1 = this.formBuilder.group({
          id: 0,
         customerId: 0,
          
          PresentEmployersName: '',
          Presentcompany: '',
          Presentbusinessnature: '',
          PresentDesignation: '',
          Presentlengthofservice:'',
          PresentofficeTel: '',
          Presentofficeaddress: '',
            PrevEmployersName: '',
            PrevDesignation: '',
            PrevofficeTel: '',
            PrevAddress: '',
            Prevlengthofservice: '',
            totalexperience: '',
     
        });
        
      }
      this.changeDetectorRef.detectChanges;
      
    })
  }

  LoadProfessionalInfoGrp2(customersId:number){
   
    this._serviceProfessionalDetail.GetLeadGenerationByLeadNoforProfessional(customersId).subscribe(res =>{
      if(res != null){
        this.isAuto=true;
        this.frmGroup2.patchValue({
   
          id: res.id,
          customerId: res.customerId,
          //leadId: res.id,
          professionname: res.professionname,
          practiceyear: res.practiceyear,
          instituteName: res.instituteName,
          instituteaddress: res.instituteaddress,
          institutetelephone: res.institutetelephone,
          professionaltypeId: res.professionaltypeId,
          selfEmployedTypeId: res.selfEmployedTypeId,
         
         
        });
        
      }
      else{
      
        this.frmGroup2 = this.formBuilder.group({
          id: 0,
          leadId: 0,
          customerId: 0,
          professionname: '',
          practiceyear: '',
          instituteName: '',
          instituteaddress: '',
          institutetelephone:'',
          professionaltypeId: 0,
          selfEmployedTypeId:0,
        });
        
      }
      
    })
  }
  LoadProfessionalInfoGrp3(customersId:number){
   
    this._serviceProfessionalDetail.GetLeadGenerationByLeadNoforProfessional(customersId).subscribe(res =>{
      
      if(res != null){
        this.isAuto=true;
        this.frmGroup3.patchValue({
   
          id: res.id,
          customerId: res.customerId,
          leadId: res.id,
          orgName: res.orgName,
          businessType: res.businessType,
          businessAddress: res.businessAddress,
          yearsinBusiness: res.yearsinBusiness,
          numberofstaff: res.numberofstaff,
          officetelno: res.officetelno,
          prevbusinessaddress: res.prevbusinessaddress,
          prevbusinesstype: res.prevbusinesstype,
          prevbusinessduration: res.prevbusinessduration,
          totalbusinessExperience:res.totalbusinessExperience,
         // professionaltypeId=res.professionaltypeId

         
        });
        
      }
      else{
     
        this.frmGroup3 = this.formBuilder.group({
          id: 0,
          customerId: 0,
          leadId: 0,
          orgName: '',
          businessType: '',
          businessAddress: '',
          yearsinBusiness: '',
          numberofstaff: '',
          officetelno: '',
          prevbusinessaddress: '',
          prevbusinesstype: '',
          prevbusinessduration:'',
          totalbusinessExperience: '',
        });
        
      }
      
    })
  }
  selectedOption: string = '1';

  onRadioChange(value: string) {
   
    this.selectedOption = value;

    if (this.selectedOption === "1")
    {
      this.LoadProfessionalInfoGrp1(this.customersId);
    }
    else if (this.selectedOption === "2")
    {
      this.LoadProfessionalInfoGrp2(this.customersId);
    }
    else if (this.selectedOption ==="3")
    {
      this.LoadProfessionalInfoGrp3(this.customersId);
    }



    //this.GetprofesionalinfoByClientId(this.clientInfoId, value)

  }

  getLeadInfo() {
    this.activeRoute.paramMap.subscribe(params => {
      const leadGNId = +params.get('leadId');
      this.leadId = +params.get('leadId');
      if (leadGNId != null&& this.leadId>0) {
        this._leadGennarationService.searchLeadCodebyId(leadGNId).subscribe(
          (res: any) => {
            //console.log(res);
            //this.leadCode = res.leadCode;
            this.clientInfoId=res.customerId;
          },
          (error: any) => {
           this.shared.ShowAlert('Error', 'Failed to retrieve.', 'error');
          }
        );
      }
    });
  }


  GetprofesionalinfoByClientId(id: number, professionaltypeId) {
    this.isClientInfoEdit = true;
    this._serviceProfessionalDetail.GetprofesionalinfoByClientandTypeId(id, professionaltypeId).subscribe(
      (professionalDetails: ProfessionalDetail) => {
        if (professionalDetails && professionalDetails.id > 0) {
          this.frmGroup1.patchValue({

            id: professionalDetails.id,
            clientInfoId: professionalDetails.clientInfoId,
            PresentEmployersName: professionalDetails.presentEmployersName,
            Presentcompany: professionalDetails.presentcompany,
            Presentbusinessnature: professionalDetails.presentbusinessnature,
            PresentDesignation: professionalDetails.presentDesignation,
            Presentlengthofservice: professionalDetails.presentlengthofservice,
            PresentofficeTel: professionalDetails.presentofficeTel,
            Presentofficeaddress: professionalDetails.presentofficeaddress,
            PrevEmployersName: professionalDetails.prevEmployersName,
            PrevDesignation: professionalDetails.prevDesignation,
            PrevofficeTel: professionalDetails.prevofficeTel,
            PrevAddress: professionalDetails.prevAddress,
            Prevlengthofservice: professionalDetails.prevlengthofservice,
            totalexperience: professionalDetails.totalexperience,

          });
        }
        this.frmGroup2.patchValue({

          id: professionalDetails.id,
          clientInfoId: professionalDetails.clientInfoId,
          professionname: professionalDetails.professionname,
          Practiceyear: professionalDetails.practiceyear,
          instituteName: professionalDetails.instituteName,
          instituteaddress: professionalDetails.instituteaddress,
          institutetelephone: professionalDetails.institutetelephone,
          professionaltypeId: professionalDetails.professionaltypeId,
          selfEmployedTypeId:  professionalDetails.selfEmployedTypeId,

        });


        this.frmGroup3.patchValue({

          id: professionalDetails.id,
          clientInfoId: professionalDetails.clientInfoId,
          orgName: professionalDetails.orgName,
          businessType: professionalDetails.businessType,
          businessAddress: professionalDetails.businessAddress,
          yearsinBusiness: professionalDetails.yearsinBusiness,
          numberofstaff: professionalDetails.numberofstaff,
          officetelno: professionalDetails.officetelno,
          prevbusinessaddress: professionalDetails.prevbusinessaddress,
          prevbusinesstype: professionalDetails.prevbusinesstype,
          prevbusinessduration: professionalDetails.prevbusinessduration,
          totalbusinessExperience: professionalDetails.totalbusinessExperience,
          professionaltypeId: professionalDetails.professionaltypeId,
          PresentDesignation:professionalDetails.presentDesignation
        });
      },
      error => {
        // Handle error
      }
    );
  }
  serviceholderForm() {
    this.frmGroup1 = this.formBuilder.group({
      id: [0],
      PresentEmployersName: ['', Validators.required],
      Presentcompany: ['', Validators.required],
      Presentbusinessnature: ['', Validators.required],
      Presentlengthofservice: [''],
      PresentofficeTel: [''],
      Presentofficeaddress: [''],
      PresentDesignation: [null],
      PrevEmployersName: [null],
      PrevDesignation: [null],

      Prevlengthofservice: [null],
      PrevofficeTel: [null],
      PrevAddress: [null],
      //totalexperience: [null],
      totalexperience: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      professionaltypeId: [1],

    })
  }
  selfEmployeedForm() {
    this.frmGroup2 = this.formBuilder.group({
      id: [0],
      professionname: ['', Validators.required],
      Practiceyear: [0, Validators.required],
      instituteName: [null],
      instituteaddress: [null],
      institutetelephone: [null],
      professionaltypeId: [2],
      selfEmployedTypeId:  [0],
    })
  }
  businessForm() {
    this.frmGroup3 = this.formBuilder.group({
      id: [0],
      orgName: ['', Validators.required],
      businessType: ['', Validators.required],
      businessAddress: [null],
      yearsinBusiness: [null],
      numberofstaff: [null],
      officetelno: [null],
      prevbusinessaddress: [null],
      prevbusinesstype: [null],
      prevbusinessduration: [null],
      totalbusinessExperience: [null],
      professionaltypeId: [3],
      PresentDesignation:''

    })
  }


  
  onserviceholder() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save Serviceholder Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save it!"
    }).then((res) => {
      if (res.isConfirmed) {

    if (this.customersId > 0) {
      this.model.Id = this.frmGroup1.value.id;
      this.model.PresentEmployersName =
        this.frmGroup1.value.PresentEmployersName;
      this.model.Presentcompany = this.frmGroup1.value.Presentcompany;
      this.model.Presentbusinessnature =
        this.frmGroup1.value.Presentbusinessnature;
      this.model.Presentlengthofservice =
        this.frmGroup1.value.Presentlengthofservice;
      this.model.PresentofficeTel = this.frmGroup1.value.PresentofficeTel;
      this.model.Presentofficeaddress =
        this.frmGroup1.value.Presentofficeaddress;
      this.model.PresentDesignation = this.frmGroup1.value.PresentDesignation;
      this.model.PrevEmployersName = this.frmGroup1.value.PrevEmployersName;
      this.model.PrevDesignation = this.frmGroup1.value.PrevDesignation;
      this.model.PrevAddress = this.frmGroup1.value.PrevAddress;
      this.model.Prevlengthofservice = this.frmGroup1.value.Prevlengthofservice;
      this.model.PrevofficeTel = this.frmGroup1.value.PrevofficeTel;
      this.model.totalexperience = this.frmGroup1.value.totalexperience.replace(
        /,/g,
        ""
      );
      this.model.professionaltypeId = 1;
      //this.model.clientInfoID = this.clientInfoId;
      this.model.customerId = this.customersId;

      this._serviceProfessionalDetail.save(this.model).subscribe((res) => {
        this.shared.ShowAlert(
          "Thank you...",
          "You save succesfully!",
          "success"
        );
        //this.professionalReset();
        this.isDataNull = false;
        this.LoadProfessionalInfoGrp1(this.customersId);
        this.professionUpdateSuccess.emit();
        this.getProfileUpdateInfo();
      });
    } else {
      this.shared.ShowAlert(
        "Warning...",
        "First Create a Client application!",
        "warning"
      );
      this.professionalReset();
    }


  }
  else if (res.isDismissed) {
    this.router.navigate(['/customer/customer-edit']);
  }
});

}


  onselfEmployeed() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save SelfEmployeed Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save it!"
    }).then((res) => {
      if (res.isConfirmed) {


    if (this.customersId > 0) {
      this.model.id = this.frmGroup2.value.id;
      // this.model.professionname = this.frmGroup2.value.professionname;
      // this.model.Practiceyear = this.frmGroup2.value.Practiceyear;
      // this.model.instituteName = this.frmGroup2.value.instituteName;
      // this.model.instituteaddress = this.frmGroup2.value.instituteaddress;
      // this.model.institutetelephone = this.frmGroup2.value.institutetelephone;
      this.model.selfEmployedTypeId = this.frmGroup2.value.selfEmployedTypeId;
      this.model.professionaltypeId = 2;
      //this.model.clientInfoID = this.clientInfoId;

      this.model.customerId = this.customersId;

      this._serviceProfessionalDetail.saveform2(this.model).subscribe((res) => {
        this.shared.ShowAlert(
          "Thank you...",
          "You save succesfully!",
          "success"
        );
        this.professionUpdateSuccess.emit();
        this.isDataNull = false;
        this.LoadProfessionalInfoGrp2(this.customersId);
        this.getProfileUpdateInfo();
      });
    } else {
      this.shared.ShowAlert(
        "Warning...",
        "First Create a Client application!",
        "warning"
      );
      this.professionalReset();
    }

  }
  else if (res.isDismissed) {
    this.router.navigate(['/customer/customer-edit']);
  }
});

}
 

  
  onbusiness() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save Business Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save it!"
    }).then((res) => {
      if (res.isConfirmed) {


    if (this.customersId > 0) {
      this.model.id = this.frmGroup3.value.id;
      this.model.orgName = this.frmGroup3.value.orgName;
      this.model.businessType = this.frmGroup3.value.businessType;
      this.model.businessAddress = this.frmGroup3.value.businessAddress;
      this.model.PresentDesignation = this.frmGroup3.value.PresentDesignation;
      // this.model.yearsinBusiness = this.frmGroup3.value.yearsinBusiness;
      // this.model.numberofstaff = this.frmGroup3.value.numberofstaff;
      // this.model.officetelno = this.frmGroup3.value.officetelno;
      // this.model.prevbusinessaddress = this.frmGroup3.value.prevbusinessaddress;
      // this.model.prevbusinesstype = this.frmGroup3.value.prevbusinesstype;
      // this.model.prevbusinessduration = this.frmGroup3.value.prevbusinessduration;
      // this.model.totalbusinessExperience = this.frmGroup3.value.totalbusinessExperience.replace(/,/g, '');
      this.model.professionaltypeId = 3;
      //this.model.clientInfoID = this.clientInfoId;
      this.model.customerId = this.customersId;
      this._serviceProfessionalDetail.saveform3(this.model).subscribe((res) => {
        this.shared.ShowAlert(
          "Thank you...",
          "You save succesfully!",
          "success"
        );
        this.isDataNull = false;
        this.LoadProfessionalInfoGrp3(this.customersId);
        this.professionUpdateSuccess.emit();
        this.getProfileUpdateInfo();
      });
    } else {
      this.shared.ShowAlert(
        "Warning...",
        "First Create a Client application!",
        "warning"
      );
      //this.professionalReset();
    }
  }
  else if (res.isDismissed) {
    this.router.navigate(['/customer/customer-edit']);
  }
});

}

formatNumber(value: string): string {

  const formattedValue = value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return formattedValue;
}

 getselfemployeedtypeList () {
    this._serviceProfessionalDetail.getselfprofList().subscribe(res => {
      this.selfprofList = res;
    })
  }

  professionalReset() {
    this.serviceholderForm();
    this.selfEmployeedForm();
    this.serviceholderForm();
    this.model = {};
  }

  GetCustomerDetailsForEdit() {
 
    this.customerService.GetCustomerDetailsForEdit().subscribe((data:any)  => {

        this.customerDetailsInfos = data;
        this.clientInfoId = data.customerDetailsInfos.customerId; 
      })
  }


  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe(res => {
      this.profileUpdate = res;
    })
  }

}
