
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'app/customer/customer.service';
import { SharedService } from 'app/services/shared.service';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { LoanapplicationApprovalService } from 'app/loanapplication-approval/loanapplication-approval.service';
import { LayoutService } from 'app/shared/services/layout.service';
import swal from 'sweetalert';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { DatePipe, Location } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoanRegister } from '../model/loan-register';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { LoanLimitService } from 'app/master-data/products/service/loan-limit.service';
import { CustommeraccountService } from 'app/customer-account/custommeraccount.service';

import Swal from 'sweetalert2'
import { PaymentService } from 'app/payment/payment.service';
import { PaymentRequestVm } from 'app/payment/models/payment-request-vm';

@Component({
  selector: "app-disburse-lead-info",
  templateUrl: "./disburse-lead-info.component.html",
  styleUrls: ["./disburse-lead-info.component.scss"],
})
export class DisburseLeadInfoComponent implements OnInit {
  customersId: any = 0;
  loanProductsId: any = 0;
  frmGroup: FormGroup;
  isAuto: boolean = false;
  leadCode: any;
  leadId: any = 0;
  loanRegInfoId: any;
  currentUser: any;
  loanRegisterId: number;

  loanAmount: number = 0;

  model: any = {};
  customerId: any = 0;
  productId: any = 0;
  frmGroupLoanReg: FormGroup;
  loanRegId: number;
  isLoanRegInfoEdit = false;
  productDetails: any = [];
  loanLimitDetails: any;
  customerAccountList: any = [];
  customerAccountId: number;
  currentUserTypeId: number;
  insRate: any;
  monthDuration: number;
  loanAmnt: number = 0;
  frequency: number = 0;
  installmentSize: number;

  paymentName: string = "";

  activeTab: string = "1-1";
  mainTab: string = "1";
  activeButton = 1;
  activeSubButton = 1;
  activeFormbody = 1;

  loanId: number = 0;
  leadNo: string = "";
  isApprover: boolean = true;
  clientInfoId: any = 0;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal,
    private shared: SharedService,
    private _serviceClientInfo: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private _leadGennarationService: LeadGenerationServiceService,
    private loanService: LoanapplicationApprovalService,
    private layoutService: LayoutService,
    private _serviceDashboard: DashboardCustomService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private _loanProduct: LoanProductService,
    private _loanLimitService: LoanLimitService,
    private _customerService: CustommeraccountService,
    private paymentService: PaymentService,
    private location: Location
  ) {
    this.frmGroup = this.formBuilder.group({
      id: 0,
      leadId: 0,
      customerRCode: "",
      customerNameEng: "",
      customerNameBan: "",
      dateOfBirth: "",
      GenderId: "select",
      mobileNo: "",
      email: "",
      nidNo: "",
      fatherNameEng: "",
      fatherNameBan: "",
      motherNameEng: "",
      motherNameBan: "",
      maritalStatus: "",
      spouseNameEng: "",
      spouseNameBan: "",
      spouseMobileNo: "",
      districtId: "",
      upazilaId: "",
    });
  }

  ngOnInit(): void {
    this.getLeadInfo();
    //this.loanRegisterId === null
    this.activeRoute.paramMap.subscribe((params) => {
      this.leadId = parseInt(params.get("leadId"), 10);
      this.customersId = parseInt(params.get("customersId"), 10);
      this.loanProductsId = parseInt(params.get("loanProductsId"), 10);
    });
    this.LoadPersonalInfo(this.leadId, this.customersId, this.loanProductsId);
    this.layoutService.getCurrentUserName().subscribe((res) => {
      this.currentUser = res;
    });
    this.LoanRegpopulateForm();
    this.LoanProductInfoById(this.loanProductsId);
    this.LoanProductAmountFromLoanLimit(this.loanProductsId);
    this.GetCustomerAccountList(this.loanProductsId);

    this.getLoanRegisterId(this.leadId);

    this.GetLoanRegistersById(this.leadId);

    this.getUserTypeId();
  }

  LoadPersonalInfo(
    leadId: number,
    customersId: number,
    loanProductsId: number
  ) {
    this._serviceClientInfo
      .GetLeadGenerationByLeadNoforBasic(leadId, customersId, loanProductsId)
      .subscribe((res) => {
        // console.log(res)
        if (res != null) {
          this.isAuto = true;
          this.frmGroup.patchValue({
            id: res.clientInfo?.id,
            leadId: res.id,
            customerRCode: res.customer?.customerRCode,
            customerNameEng: res.customer?.customerNameEng,
            customerNameBan: res.customer?.customerNameBan,
            dateOfBirth: this.datePipe.transform(
              res.customer?.dateOfBirth,
              "dd-MMM-yyyy"
            ),
            GenderId: res.customer?.gender?.name,
            mobileNo: res.customer?.mobileNo,
            email: res.customer?.email,
            nidNo: res.customer?.nidNo,
            fatherNameEng: res.customer?.fatherNameEng,
            fatherNameBan: res.customer?.fatherNameBan,
            motherNameEng: res.customer?.motherNameEng,
            motherNameBan: res.customer?.motherNameBan,
            maritalStatus: res.customer?.maritalStatus,
            spouseNameEng: res.customer?.spouseNameEng,
            spouseNameBan: res.customer?.spouseNameBan,
            spouseMobileNo: res.customer?.spouseMobileNo,
            districtId: res.customer?.district?.districtName,
            upazilaId: res.customer?.upazila?.upazilaName,
          });
        } else {
          this.frmGroup = this.formBuilder.group({
            id: 0,
            leadId: 0,
            customerRCode: "",
            customerNameEng: "",
            NameBangla: "",
            dateOfBirth: "",
            GenderId: "select",
            email: "",
            mobileNoHome: "",
            nidNo: "",
            fatherNameEng: "",
            fatherNameBan: "",
            motherNameEng: "",
            motherNameBan: "",
            maritalStatus: "",
            spouseNameEng: "",
            spouseNameBan: "",
            spouseMobileNo: "",
            districtId: "",
            upazilaId: "",
          });
        }
      });
  }

  gender: string = "select";
  onGenderStatusChange(value: string) {
    this.gender = value;
  }

  //#region loan

  get productAmount(): number {
    return this.loanLimitDetails?.personAmount || 0;
  }

  // get loanAmount(): number {
  //   return this.frmGroupLoanReg.get('loanAmount')?.value || 0;
  // }

  isLoanAmountValid(): boolean {
    return this.loanAmount <= this.productAmount;
  }

  GetLoanRegistersById(leadId: number) {
    this._leadGennarationService
      .GetLoanRegistersById(this.leadId)
      .subscribe((loanRegister: LoanRegister) => {
        if (loanRegister && loanRegister.id) {
          this.isLoanRegInfoEdit = true;
          this.frmGroupLoanReg.patchValue({
            id: loanRegister.id,
            customerId: this.customerId,
            loanAmount: loanRegister.loanAmount,
            loanNature: loanRegister.loanNature,
            installmentSize: loanRegister.installmentSize,
            frequency: loanRegister.frequency,
            customerAccountId: loanRegister.customerAccountId,
            leadGenerationId: loanRegister.leadGenerationId,
            interestRate: loanRegister.interestRate,
            // securityType: loanRegister.securityType,
            // securityValue: loanRegister.securityValue,
            //expiryDate: this.datePipe.transform(loanRegister.expiryDate, 'yyyy-MM-dd'),
          });
        } else {
        }
      });
  }

  GetCustomerAccountList(customerId: number) {
    this._customerService
      .getCustomerAccountListByCustomerId(customerId)
      .subscribe((res) => {
        this.customerAccountList = res;
        this.customerAccountList = this.customerAccountList.filter(
          (item) => item.accountType === "deposit"
        );
        this.customerAccountId = 0;
        this.changeDetectorRef.detectChanges();
      });
  }

  LoanProductAmountFromLoanLimit(productId: number) {
    this._loanLimitService
      .getLoanLimitInfoByProductId(productId)
      .subscribe((res) => {
        this.loanLimitDetails = res;

        this.changeDetectorRef.detectChanges();
      });
  }

  LoanProductInfoById(productId: number) {
    this._loanProduct
      .getLoanProductBuilderDetailsById(productId)
      .subscribe((res) => {
        this.productDetails = res;
        this.insRate = res?.productInfo?.interestRate;
        this.getMonthFromProductDetails(
          this.productDetails?.productInfo?.loanDuration?.monthName
        );
        this.changeDetectorRef.detectChanges();
      });
  }

  getMonthFromProductDetails(monthName: any) {
    if (monthName) {
      var extractedMonth = monthName.match(/\d+/); // This extracts all numeric digits
      if (extractedMonth) {
        this.monthDuration = extractedMonth;

        console.log(extractedMonth); // This will log the first numeric match
      } else {
        console.log("No numeric value found in monthName");
      }
    } else {
      console.log("monthName is not defined");
    }
  }

  getLoanAmountValue(event: any) {
    this.loanAmnt = event.target.value;
  }

  getFrequencyValue(event: any) {
    this.frequency = event.target.value;
  }

  calculateInstallmentSize() {
    const value = (this.loanAmnt / this.monthDuration) * this.frequency;
    this.installmentSize = parseFloat(value.toFixed(2));
  }

  getUserTypeId() {
    this.layoutService.getCurrentUserName().subscribe((res) => {
      this.currentUser = res;
      this.currentUserTypeId = res.userTypeId;
    });
  }

  LoanRegpopulateForm() {
    this.frmGroupLoanReg = this.formBuilder.group({
      id: [0],
      customerId: this.customerId,
      loanAmount: ["", Validators.required],
      loanNature: ["", Validators.required],
      installmentSize: [0, Validators.required],
      frequency: [0, Validators.required],
      customerAccountId: [0],

      interestRate: [0],
      // securityType: ['', Validators.required],
      // securityValue: [''],
      // expiryDate: ['', Validators.required],
    });
  }

  //#endregion

  getLeadInfo() {
    this.activeRoute.paramMap.subscribe((params) => {
      const leadGNId = +params.get("leadId");
      this.leadId = +params.get("leadId");
      if (leadGNId != null) {
        this._leadGennarationService.searchLeadCodebyId(leadGNId).subscribe(
          (res: any) => {
            this.leadCode = res.leadCode;
            this.getLoanRegisterId(this.leadId);
          },
          (error: any) => {
            this.shared.ShowAlert("Error", "Failed to retrieve.", "error");
          }
        );
      }
    });
  }

  // loanDisburse(){

  //   this.activeRoute.paramMap.subscribe(params => {
  //     const leadGNId = +params.get('leadId');

  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "Do you want to schedule this loan!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, schedule it!"
  //     }).then((res) => {
  //       if (res.isConfirmed) {
  //         this._serviceDashboard.disbursLoan(leadGNId).subscribe((res: any[]) => {

  //           if (res != null) {
  //             this.shared.ShowAlert('Thank you...', 'Saved succesfully!', 'success');
  //             this.changeDetectorRef.detectChanges();
  //             this.router.navigate(['/dashboard/app-approve-disburse']);

  //           }
  //           else {
  //             this.shared.ShowAlert('Error', 'Failed to save.', 'error');
  //           }

  //         })

  //       }
  //       else if (res.isDismissed){
  //         this.router.navigate(['/dashboard/application-disburse-list']);
  //       }
  //     });

  //     // swal('Confirm First', 'Do you want to schedule this loan', 'warning').then(x=>{
  //     //   this._serviceDashboard.disbursLoan(leadGNId).subscribe((res: any[]) =>{

  //     //     if(res != null)
  //     //     {
  //     //       this.shared.ShowAlert('Thank you...', 'Saved succesfully!', 'success');
  //     //       this.changeDetectorRef.detectChanges();
  //     //       this.router.navigate(['/dashboard/app-approve-disburse']);

  //     //     }
  //     //     else
  //     //     {
  //     //       this.shared.ShowAlert('Error', 'Failed to save.', 'error');
  //     //     }

  //     //   })
  //     // });

  //   });

  // }

  goBack() {
    this.location.back();
  }

  loanDisburse() {
    this.activeRoute.paramMap.subscribe((params) => {
      const leadGNId = +params.get("leadId");

      this.loanAmnt = this.frmGroupLoanReg.get("loanAmount")?.value;
      //this.customerAccount = customerAccountNo;
      if (this.loanAmnt > 0) {
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to disburse this loan!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, disburse it!",
        }).then((res) => {
          if (res.isConfirmed) {
            this._serviceDashboard
              .disbursLoanByLeadCode(this.leadCode)
              .subscribe((res: string) => {
                if (res != null) {
                  const paymentRequest: PaymentRequestVm = {
                    name: [this.paymentName],
                    price: [this.loanAmnt],
                    url: ["url1"],
                    qty: [1],
                    pname: "Loan Disbursement",
                    purl: "Loan Disbursement",
                    pprice: this.loanAmnt,
                    customer_account: res.toString(),
                    card_no: res.toString(),
                    valueType: "disbursement",
                  };

                  this.paymentService.checkoutPayment(paymentRequest).subscribe(
                    // this.paymentService.checkoutPaymentShurjo(paymentRequest).subscribe(
                    (response) => {
                      if (response.gatewayPageURL) {
                        // Use the navigateByUrl method to navigate to the specified URL
                        window.location.href = response.gatewayPageURL;
                      } else {
                        console.error(
                          "No gatewayPageURL found in the response"
                        );
                      }
                      ///------------------------------Shurjo-------------------------
                      //  if (response && response.checkout_url) {

                      //   window.location.href = response.checkout_url;
                      // } else {
                      //   console.error('No CheckOutUrl found in the response');
                      // }
                    },
                    (error) => {
                      console.error("Payment request failed", error);
                    }
                  );
                } else {
                  this.shared.ShowAlert("Error", "Failed to save.", "error");
                }
              });
          } else if (res.isDismissed) {
            this.router.navigate(["/dashboard/application-disburse-list"]);
          }
        });
      }
    });
  }

  RejectLoanByID() {
    this.activeRoute.paramMap.subscribe((params) => {
      const leadGNId = +params.get("leadId");

      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to reject this loan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reject it!",
      }).then((res) => {
        if (res.isConfirmed) {
          this._serviceDashboard
            .RejectLoan(leadGNId)
            .subscribe((res: any[]) => {
              if (res != null) {
                this.shared.ShowAlert(
                  "Thank you...",
                  "Reject succesfully!",
                  "success"
                );
                this.changeDetectorRef.detectChanges();
                this.router.navigate(["/dashboard/application-disburse-list"]);
              } else {
                this.shared.ShowAlert("Error", "Failed to save.", "error");
              }
            });
        } else if (res.isDismissed) {
          this.router.navigate(["/dashboard/application-disburse-list"]);
        }
      });
    });
  }

  getLoanRegisterId(leadId: number) {
    this.loanService.getLoanregisterbyLeadId(leadId).subscribe((res: any) => {
      console.log(res);
      this.loanRegisterId = res;
      this.changeDetectorRef.detectChanges();
    });
  }
}