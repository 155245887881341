import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { environment } from 'environments/environment';

//import { HttpClient } from '@microsoft/signalr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomerService } from 'app/customer/customer.service';
import { DocumentMetadata } from '../model/document-metadata';
import swal from 'sweetalert';
import Swal from 'sweetalert2'
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { ProfessionalDetailService } from '../professional-detail.service';

const apiUrlDoc = environment.baseUrl + '/api/LoanApplication/';

@Component({
  selector: 'app-loan-document',
  templateUrl: './loan-document.component.html',
  styleUrls: ['./loan-document.component.scss']
})
export class LoanDocumentComponent implements OnInit {
  leadId: any = 0;
  LeadDocumentTypeList: any[] = [];
  model:any={};
  frmGroup:FormGroup;
  //remarks:string;
  //documentList: DocumentMetadata[] = [];
  //documentList: any[] = [];
  list: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private shared: SharedService,
    private datePipe: DatePipe,
    private http: HttpClient,
    private customerService: CustomerService,
    private _leadGennarationService: LeadGenerationServiceService,
    private _professionaService:ProfessionalDetailService,
  ) {
    this.frmGroup=this.formBuilder.group({
      remarks:'',
      documentsTypeId:'',
      leadGenerationId:'',
      base64String:'',
      docFileName:''
    })
   }

  ngOnInit(): void {
    this.getleaddocumenttypeList();
    this.getLeadInfo();
    this.getDocumentList(this.leadId);
  }


  documentList: DocumentMetadata[] = [];
  fileToUpload: File | null = null;
  fileUrl: string | null = null;
  docFileName:string;
  selectedFile: File | null = null;
  selectedFileType: string | null = null;
  base64StringFile:string|null=null;
  previewImage: string | null = null;
  defaultDocxImage: string = 'assets/img/document/docx_image.jpeg';
  defaultPdfImage: string = 'assets/img/document/pdf_image.jpeg';
  defaultExcelImage: string = 'assets/img/document/excel_image.jpeg';
  documentInfo: any = {};
  getLeadInfo() {

    this.activeRoute.paramMap.subscribe(params => {
      const leadGNId = +params.get('leadId');
      this.leadId = +params.get('leadId');
      if (leadGNId != null) {
        this._leadGennarationService.searchLeadCodebyId(leadGNId).subscribe(
          (res: any) => {
            //console.log(res);
            //this.clientInfoId = res.customerId;
          },
          (error: any) => {
            this.shared.ShowAlert('Error', 'Failed to retrieve.', 'error');
          }
        );
      }
    });
  }
  getUploadImage() {
    if (this.selectedFileType && this.selectedFileType.startsWith('image/')) {
      return this.previewImage;
    } else if (this.selectedFileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return 'assets/img/document/docx_image.jpeg';
    } else if (this.selectedFileType === 'application/pdf') {
      return 'assets/img/document/pdf_image.jpeg';
    } else if (this.selectedFileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return 'assets/img/document/excel_image.jpeg';
    } else {
      return 'assets/img/document/upload_image.png'; // Default upload image for other file types
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.selectedFileType = this.selectedFile?.type || '';
    const file: File = event.target.files[0];
    if (file && this.isValidFileSize(file)) {
      this.convertFileToBase64(file).then((base64String) => {
        this.base64StringFile=base64String;
        // You can now use the base64String as needed, e.g., send it to the server.
      });
    }
    else{
      console.error('Error uploading file:');
        this.shared.ShowAlert('Warning...', 'The uploaded file size should be less than or equal to 1 MB.', 'warning');
    }
    // Reset the preview image
    this.previewImage = null;

    // Read the selected file and display the preview image or placeholder
    if (this.selectedFile && this.selectedFile.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previewImage = e.target.result;
      };
      reader.readAsDataURL(this.selectedFile);
    }

    // Display the file name
    const fileInput = event.target;
    const fileNameSpan = document.getElementById('fileNameSpan'); // Replace 'fileNameSpan' with the ID of your <span> tag
    if (fileNameSpan) {
      fileNameSpan.textContent = fileInput.files[0].name;
    }
    this.docFileName=fileInput.files[0].name;
  }

  getPreviewImage() {
    if (this.selectedFileType && this.selectedFileType.startsWith('image/')) {
      return this.previewImage;
    } else if (this.selectedFileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return this.defaultDocxImage;
    } else if (this.selectedFileType === 'application/pdf') {
      return this.defaultPdfImage;
    } else if (this.selectedFileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.selectedFileType === 'application/vnd.ms-excel' ||
      this.selectedFileType === 'application/vnd.ms-excel.sheet.binary.macroenabled.12') {
      return this.defaultExcelImage;
    } else {
      // Return a generic file icon or placeholder image
      return 'assets/img/document/upload_image.png';
    }

  }


  getDocumentList(id: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });
    this.http.get<any[]>(apiUrlDoc + 'GetDocumentList?id=' + id,{ headers }).subscribe(
      (response) => {
        this.documentList = response;
      },
      (error) => {
        console.error('Error retrieving file metadata:', error);
      }
    );
  }

  downloadFile(DocumentMetadata: DocumentMetadata) {
    const fileUrl = `${apiUrlDoc}download?id=${DocumentMetadata.id}`;
    window.open(fileUrl, '_blank');
  }

  getPreviewUrl(fileUrl: string): string {
    return `${apiUrlDoc}Preview?fileUrl=${fileUrl}`;
  }

  fullPreviewImage(fileUrl: string) {
    const previewUrl = this.getPreviewUrl(fileUrl);
    // Open the preview in a new tab or show it in a modal, depending on your requirements
    window.open(previewUrl, '_blank');
  }



  documentSubmit() {


    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save this Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Submit it!"
    }).then((res) => {
      if (res.isConfirmed) {
        this.model.remarks=this.frmGroup.value.remarks;
        this.model.documentsTypeId=this.frmGroup.value.documentsTypeId;
        this.model.leadGenerationId=this.leadId;
        this.model.base64String=this.base64StringFile;
        this.model.docFileName=this.docFileName;
    // const formData = new FormData();
    // formData.append('file', this.selectedFile || '');
    // formData.append('documentsTypeId', this.documentInfo.documentsTypeId || '');
    // formData.append('remarks', this.documentInfo.remarks || '');
    // formData.append('leadGenerationId', this.leadId || '');
    this._professionaService.saveDocument(this.model).subscribe(
      (response) => {
        //this.fileUrl = response.fileUrl;
        this.getDocumentList(this.leadId);
        this.documentInfo = {};
        this.selectedFileType = null;
        swal({
          title: 'File Uploaded!',
          text: 'The file has been successfully uploaded.',
          icon: 'success',
          //confirmButtonText: 'OK'
        }).then(() => {

          const fileNameSpan = document.getElementById('fileNameSpan'); // Replace 'fileNameSpan' with the ID of your <span> tag
          if (fileNameSpan) {
            fileNameSpan.textContent = null;
          }
        });
      },
      (error) => {
        console.error('Error uploading file:', error);
        this.shared.ShowAlert('Warning...', 'An error occurred while uploading the file.', 'warning');
      }
    );
  }
  else if (res.isDismissed) {
    //this.router.navigate(['/loan-application/loan-application/' + this.leadId + '/' + this.customerId + '/' + 1]);
  }
});

}

  getleaddocumenttypeList() {
    this.customerService.getLeadDocumentTypeList().subscribe(res => {
      this.LeadDocumentTypeList = res;
    })
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = e.target?.result as string;
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  isValidFileSize(file: File): boolean {
    // Check if the file size is less than or equal to 1 MB
    const maxSizeInBytes = 1024 * 1024; // 1 MB
    return file.size <= maxSizeInBytes;
  }

}
