import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';
const apiUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class LedgerService {

  constructor(private http: HttpClient) { }

  GetLedgerById(id: number):Observable<any> {
    return this.http.get(apiUrl + '/GetLedgerById/' + id);
  }
  save(model: any) {
    return this.http.post(apiUrl + '/api/Ledger/SaveLedger', model);

  }

  getlist(): Observable<any> {
    return this.http.get(apiUrl + '/api/ledger/GetLedgerList');
  }

  GetLedgerListByNatureId(gId: number) {

    return this.http.get(apiUrl + '/api/ledger/GetLedgerListByNatureId?gId=' + gId);
  }

  GetLedgerListWithGLByNatureId(gId: number) {

    return this.http.get(apiUrl + '/api/ledger/GetLedgerListWithGLByNatureId?gId=' + gId);
  }

  getGrpLedgerList():Observable<any>{
    return this.http.get(apiUrl + '/api/ledger/GetGrpLedgerList')
  }

  
  getGroupListWithSearchAndPagination(currentPage : any, itemsPerPage : any, searchValue : string){
    //alert(220);
   return this.http.get(apiUrl + '/api/Ledger/GetGroupLedgerListWithSearchAndPagination?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }

  getSubGrpLedgerList():Observable<any>{
    return this.http.get(apiUrl + '/api/ledger/GetSubGrpLedgerList')
  }

  getSubGroupListWithSearchAndPagination(currentPage : any, itemsPerPage : any, searchValue : string){
    //alert(333);
    return this.http.get(apiUrl + '/api/Ledger/GetSubGroupLedgerListWithSearchAndPagination?subGroupPage=' + currentPage + '&subGroupPageSize=' + itemsPerPage + '&subGroupSearch=' + searchValue);
  }

  getLedgerRelationlist(): Observable<any> {
    return this.http.get(apiUrl + '/api/ledger/GetLedgerRelationlist');
  }

  getMFSLedgerRelation(): Observable<any> {
    return this.http.get(apiUrl + '/api/ledger/GetMFSLedgerRelationlist');
  }

  GetBusinessDatebyActiveStatus(): Observable<any> {
    
    return this.http.get(apiUrl + '/api/Ledger/GetBusinessDatebyActiveStatus');
   }
  GetAccountCode(gId: number) {

    return this.http.get(apiUrl + '/api/ledger/GetAccountCodeByGroupId?gId=' + gId);
  }

  delete(Id: any) {

    return this.http.delete(apiUrl + '/api/ledger/DeleteLegerById?Id=' + Id);
  }

//#region GLGenerate
  GetAllLedger(): Observable<any> {
    return this.http.get(apiUrl + '/api/ledger/GetAllLedger')

  }
  GetLedgerListWithOutGL(): Observable<any> {
    return this.http.get(apiUrl + '/api/ledger/GetLedgerListWithOutGL')

  }
  PostGLGenerate(model: any) {
    //debugger
    return this.http.post(apiUrl + '/api/Ledger/PostGLGenerate', model);

  }

  GetGLGenerate():Observable<any>{
    return this.http.get(apiUrl+ '/api/Ledger/GetGLGenerate')
  }


  GetGlGenerateWithSearchAndPagination(currentPage: any, itemsPerPage : any, searchValue : string){
   
    return this.http.get(apiUrl + '/api/Ledger/GetGlAccountListWithPaginationAndSearch?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }

  DeleteGLGenerateById(Id: any) {

    return this.http.delete(apiUrl + '/api/ledger/DeleteGLGenerateById?Id=' + Id);
  }
  getGLGenerateAuto(): Observable<any[]> {
    return this.http.get<any[]>(apiUrl +'/api/Ledger/GetGLGenerate');
  }
  getAutocompleteSuggestions(searchTerm: string): Observable<any[]> {
       return this.http.get<any[]>(apiUrl +'/api/Ledger/GetGLGenerateAutoCompleate', { params: { searchTerm } });
  }
  //#endregion

//#region BusinessDate
GetAllStatus(): Observable<any> {
  return this.http.get(apiUrl + '/api/ledger/GetAllStatus')

}
PostBusinessDate(model: any) {
 
  return this.http.post(apiUrl + '/api/Ledger/PostBusinessDate', model);

}

GetBusinessDate():Observable<any>{
  return this.http.get(apiUrl+ '/api/Ledger/GetBusinessDate')
}


GetBusinessDateWithSearchAndPagination(currentPage: any, itemsPerPage : any, searchValue : string){
  //alert(searchValue);
  return this.http.get(apiUrl + '/api/Ledger/GetBusinessDateWithSearchAndPagination?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
}

DeleteBusinessDateById(Id: any) {

  return this.http.delete(apiUrl + '/api/ledger/DeleteBusinessDateById?Id=' + Id);
}


GetLastAciteBusinessDate():Observable<any>{
  return this.http.get(apiUrl+ '/api/Ledger/GetLastActiveBusinessDate')
}

//#endregion

//#region BusinessDate

PostHoliday(model: any) {
 
  return this.http.post(apiUrl + '/api/Ledger/PostHoliday', model);

}

GetHoliday():Observable<any>{
  return this.http.get(apiUrl+ '/api/Ledger/GetHoliday')
}


GetHolidayListWithSearchAndPagination(currentPage: any, itemsPerPage : any, searchValue : string){
  return this.http.get(apiUrl + '/api/Ledger/GetHolidayWithSearchAndPagination?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
}

DeleteHolidayById(Id: any) {

  return this.http.delete(apiUrl + '/api/ledger/DeleteHolidayById?Id=' + Id);
}

GetAllLedgerList(): Observable<any> {

  return this.http.get(apiUrl + '/api/ledger/GetAllLedgerList');
}


GetMainLedgerForPaymentOrReceiveVouhcer(): Observable<any> {

  return this.http.get(apiUrl + '/api/ledger/GetMainLedgerForPaymentOrReceiveVouhcer');
}

GetParticularLedgerForPaymentVoucher(): Observable<any> {
  return this.http.get(apiUrl + '/api/ledger/GetParticularLedgerForPaymentVoucher');
}

GetParticularLedgerForRecieveVoucher(): Observable<any> {
  return this.http.get(apiUrl + '/api/ledger/GetParticularLedgerForRecieveVoucher');
}
GetParticularLedgerForJournalVoucher(): Observable<any> {
  return this.http.get(apiUrl + '/api/ledger/GetParticularLedgerForJournalVoucher');
}

GetAllSubLedgerbyLrId(Id: number): Observable<any> {
  return this.http.get(environment.baseUrl + '/api/ledger/GetAllSubLedgerbyLrId?id=' + Id);
}
GetAllSubLedgerbyLId(Id: number): Observable<any> {
  return this.http.get(environment.baseUrl + '/api/ledger/GetAllSubLedgerbyLId?subLedgerId=' + Id);
}
GetAllSubLedger(): Observable<any> {
  return this.http.get(environment.baseUrl + '/api/ledger/GetAllSubLedger');
}
GetCoaTree(): Observable<any> {
  return this.http.get(apiUrl + '/api/ledger/GetCoaTree')

}
//#endregion

}
