import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { ExpenseDetailsService } from '../expense-details.service';
import { LeadGenerationServiceService } from '../lead-generation-service.service';
import { CustomerService } from 'app/customer/customer.service';
import swal from 'sweetalert';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-reference-details',
  templateUrl: './reference-details.component.html',
  styleUrls: ['./reference-details.component.scss']
})
export class ReferenceDetailsComponent implements OnInit {
  @Output() referenceUpdateSuccess: EventEmitter<void> = new EventEmitter<void>();
  frmGroupReference: FormGroup;
  clientInfoId: any = 0;
  leadId: any = 0;
  model: any = {};
  isEdit: boolean = false;
  Reflist: any[] = [];
  customerDetailsInfos: any;
  profileUpdate: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private shared: SharedService,
    private _expenseService: ExpenseDetailsService,
    private _leadGennarationService: LeadGenerationServiceService,
    private customerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {this.GetReferenceinfoByClientId(this.clientInfoId); }

  ngOnInit(): void {
  
    this.getLeadInfo();
    this.ReferencepopulateForm();
   
    this.GetCustomerDetailsForEdit();
    //this.GetReferenceinfoByClientId();


this.activeRoute.paramMap.subscribe((params) => {
  console.log("params");
  console.log(params);

  if (params.get("id") == null) {
    this.customerService.GetCustomerDetailsForEdit().subscribe((data: any) => {
      this.clientInfoId = data.customerDetailsInfos.customerId;
      this.GetReferenceinfoByClientId(this.clientInfoId);
    });
  } else {
    this.clientInfoId = parseInt(params.get("id"), 10);
    this.GetReferenceinfoByClientId(this.clientInfoId);
  }
});








  }

  getLeadInfo() {
    this.activeRoute.paramMap.subscribe(params => {
      const leadGNId = +params.get('leadId');
      this.leadId = +params.get('leadId');
      if (leadGNId != null&& this.leadId > 0) {
        this._leadGennarationService.searchLeadCodebyId(leadGNId).subscribe(
          (res: any) => {
     
            this.clientInfoId=res.customerId;
            this.GetReferenceinfoByClientId(this.clientInfoId);
          },
          (error: any) => {
           this.shared.ShowAlert('Error', 'Failed to retrieve.', 'error');
          }
        );
      }
    });
  }

ReferencepopulateForm() {
  this.frmGroupReference = this.formBuilder.group({
    id: [0],
    name: [''],
    relationship: [''],
    residenceAddress: [''],
    residentialStatus: [''],
    companyName: '',
    refDesignation: [''],
    companyDesignation: [''],
    companyAddress: [''],
    companyPhoneResidence: [''],
    companyPhoneOffice: [''],
    companyMobile: [''],

  })
}

onReference() {

  Swal.fire({
    title: "Are you sure?",
    text: "Do you want to save Reference!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Save Reference!"
  }).then((res) => {
    if (res.isConfirmed) {

  if (this.isEdit) {
    this.model.id = this.frmGroupReference.value.id;
  }

  this.model.id= this.frmGroupReference.value.id;
  this.model.name = this.frmGroupReference.value.name;
  this.model.relationship = this.frmGroupReference.value.relationship;
  this.model.residenceAddress = this.frmGroupReference.value.residenceAddress;
  this.model.residentialStatus = this.frmGroupReference.value.residentialStatus;
  this.model.companyName = this.frmGroupReference.value.companyName;
  this.model.refDesignation = this.frmGroupReference.value.refDesignation;
  this.model.companyDesignation = this.frmGroupReference.value.companyDesignation;
  this.model.companyAddress = this.frmGroupReference.value.companyAddress;
  this.model.companyPhoneResidence = this.frmGroupReference.value.companyPhoneResidence;
  this.model.companyPhoneOffice = this.frmGroupReference.value.companyPhoneOffice;
  this.model.companyMobile = this.frmGroupReference.value.companyMobile;
  this.model.customerId = this.clientInfoId;
  if (this.model.id) {
    this._expenseService.saveReference(this.model).subscribe(res => {
      this.shared.ShowAlert('Thank you...', 'You updated succesfully!', 'success');
      this.Referencereset();
      //this.GetReferenceinfoByClientId(this.clientInfoId)
      this.isEdit = false;
    })
  } 
  else {
    this._expenseService.saveReference(this.model).subscribe(res => {
      this.shared.ShowAlert('Thank you...', 'You save succesfully!', 'success');
      this.Referencereset();
      this.getProfileUpdateInfo();
      this.referenceUpdateSuccess.emit();
      //this.GetReferenceinfoByClientId(this.clientInfoId)
    })
  }

}
else if (res.isDismissed) {
  this.router.navigate(['/customer/customer-edit']);
}
});

}

  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe(res => {
      this.profileUpdate = res;
    })
    this.changeDetectorRef.detectChanges();
  }


Referencereset() {
  this.model = {};
  this.GetReferenceinfoByClientId(this.clientInfoId);
  this.isEdit = false;
  this.ReferencepopulateForm();
}
GetReferenceinfoByClientId(clientInfoID:any) {
  this._expenseService.GetReferenceinfoByClientId(clientInfoID).subscribe(res => {
  
    this.Reflist = res;
    this.changeDetectorRef.detectChanges();
  })
}
edit(item: any) {
  this.isEdit = true;
  this.frmGroupReference.patchValue({
    id: item.id,
    name: item.name,
    relationship: item.relationship,
    residenceAddress: item.residenceAddress,
    residentialStatus: item.residentialStatus,
    companyName: item.companyName,
    refDesignation: item.refDesignation,
    companyDesignation: item.companyDesignation,
    companyAddress: item.companyAddress,
    companyPhoneResidence: item.companyPhoneResidence,
    companyPhoneOffice: item.companyPhoneOffice,
    companyMobile: item.companyMobile,

  });
}
delete(id: any) {

  this._expenseService.delete(id).subscribe(res => {
    this.shared.ShowAlert('Thank you...', 'Delete succesfully!', 'success');
    this.GetReferenceinfoByClientId(this.clientInfoId);
  })
}


GetCustomerDetailsForEdit() {

  this.customerService.GetCustomerDetailsForEdit().subscribe((data:any) => {

    this.customerDetailsInfos = data;
    
    this.clientInfoId = data.customerDetailsInfos.customerId;
    this.GetReferenceinfoByClientId(this.clientInfoId);
    this.changeDetectorRef.detectChanges();
  })
}
}
